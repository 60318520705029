import PropTypes from 'prop-types';

import Presenter from 'utils/PropTypesPresenter';

import DatasetFieldPresenter from 'presenters/DatasetFieldPresenter';

export default new Presenter(
  {
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    updatedAt: PropTypes.string.isRequired,
    fileType: PropTypes.string.isRequired,
    fields: PropTypes.arrayOf(DatasetFieldPresenter.shape()).isRequired,
  },
  {},
);
