import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  header: {
    display: 'block',
    padding: [16, 40],
    background: theme.palette.background.white,
    boxShadow: ['inset', 0, -1, 0, theme.palette.shadowHeader],
    zIndex: 1600,
    position: 'relative',
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: 1360,
    margin: [0, 'auto'],
  },
  authUser: {
    display: 'flex',
    alignItems: 'center',
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    paddingRight: 23,
    textAlign: 'right',
  },
  name: {
    color: theme.palette.text.primary,
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 1.3,
  },
  role: {
    color: theme.palette.text.secondary,
    fontWeight: 'normal',
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 1.3,
  },
  logo: {
    width: 124,
  },
  buttonExit: {
    minWidth: 'auto',
    padding: 0,
    marginLeft: 20,
  },
  iconExit: {
    width: 24,
  },
}));
