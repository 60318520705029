import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { isNil } from 'ramda';
import { Button, IconButton, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import { CloseOutlined as CloseOutlinedIcon } from '@material-ui/icons';

import { TextField, Select } from 'components/Common';
import { appRoutes } from 'routes/appRoutes';
import { useDatasets } from 'slices/hooks';
import history from 'utils/history';
import { initialValues, validationSchema } from 'forms/CreateDatasetForm';
import { handleErrors } from 'utils/errors';
import DatasetPresenter from 'presenters/DatasetPresenter';
import { isBlank } from 'utils/conditions';
import { is3DMapping, FILE_TYPES_OPTIONS, FILE_TYPE_2D } from 'helpers/fieldDetailsOptions';

const CreateDataset = (props) => {
  const { onClose } = props;
  const { createDataset, isLoading, apiErrors, resetCurrentDataset, currentDataset } = useDatasets();

  useEffect(() => {
    resetCurrentDataset();
  }, []); // eslint-disable-line

  useEffect(() => {
    if (!isBlank(currentDataset)) {
      const currentDatasetId = DatasetPresenter.id(currentDataset);
      history.push(appRoutes.datasetPath(currentDatasetId));
    }
  }, [currentDataset]); // eslint-disable-line

  const handleCreateDataset = (values, { setErrors }) => {
    return createDataset(values).catch((errors) => handleErrors(errors, setErrors));
  };

  const { values, errors, setFieldValue, submitForm, touched } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleCreateDataset,
    validateOnChange: true,
  });

  const handleTextFieldChange = (field) => ({ target: { value } }) => {
    setFieldValue(field, value);
  };

  const handleChangeOption = (field) => ({ target: { value } }) => {
    setFieldValue(field, value);
    if (value === FILE_TYPE_2D) {
      setFieldValue('adaptiveTemplateUrl', initialValues.adaptiveTemplateUrl);
    }
  };

  const allErrors = { ...errors, ...apiErrors };

  const handleSubmitForm = (e) => {
    e.preventDefault();
    submitForm(values);
  };

  const getTextErrorsField = (name) => (touched[name] && !isNil(allErrors[name]) ? allErrors[name] : '');
  const isErrorsExist = (name) => touched[name] && !!allErrors[name];
  const is3DMappingType = is3DMapping(values.fileType);

  return (
    <Dialog onClose={onClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" open>
      <DialogTitle>Create Dataset</DialogTitle>
      <IconButton color="inherit" onClick={onClose}>
        <CloseOutlinedIcon />
      </IconButton>
      <form onSubmit={handleSubmitForm}>
        <DialogContent>
          <TextField
            value={values.name}
            onChange={handleTextFieldChange('name')}
            error={isErrorsExist('name')}
            name="name"
            placeholder="Name of the Data Set"
            label="Name"
            helperText={getTextErrorsField('name')}
          />
          <TextField
            value={values.endpointUrl}
            onChange={handleTextFieldChange('endpointUrl')}
            error={isErrorsExist('endpointUrl')}
            name="endpointUrl"
            placeholder="http://"
            label="End-Point URL"
            helperText={getTextErrorsField('endpointUrl')}
          />
          <TextField
            value={values.endpointSecret}
            onChange={handleTextFieldChange('endpointSecret')}
            error={isErrorsExist('endpointSecret')}
            name="endpointSecret"
            label="End-Point Secret"
            helperText={getTextErrorsField('endpointSecret')}
          />
          <Select
            value={values.fileType}
            label="Excel File Type"
            onChange={handleChangeOption('fileType')}
            name="fileType"
            options={FILE_TYPES_OPTIONS}
            error={isErrorsExist('fileType')}
            helperText={getTextErrorsField('fileType')}
          />
          <TextField
            value={values.externalDefinitionUrl}
            onChange={handleTextFieldChange('externalDefinitionUrl')}
            error={isErrorsExist('externalDefinitionUrl')}
            name="externalDefinitionUrl"
            placeholder="http://"
            label="External Definition URL"
            helperText={getTextErrorsField('externalDefinitionUrl')}
          />
          {is3DMappingType && (
            <TextField
              value={values.adaptiveTemplateUrl}
              onChange={handleTextFieldChange('adaptiveTemplateUrl')}
              error={isErrorsExist('adaptiveTemplateUrl')}
              name="adaptiveTemplateUrl"
              placeholder="http://"
              label="Adaptive Template URL"
              helperText={getTextErrorsField('adaptiveTemplateUrl')}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="outlined">
            Cancel
          </Button>
          <Button disabled={isLoading} type="submit" variant="contained">
            Create
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

CreateDataset.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default CreateDataset;
