import { isEmpty, isNil, uniq } from 'ramda';
import MappingPresenter from 'presenters/MappingPresenter';
import MappingErrorPresenter from 'presenters/MappingErrorPresenter';
import { prepareRows2D, prepareRows3D } from 'helpers/prepareValidateData';
import TableChangePresenter from 'presenters/TableChangePresenter';

export const ERROR_STATES = {
  fixed: 'fixed',
  new: 'new',
  skip: 'skip',
};

export const getErrorTypes = (validators) => {
  if (isNil(validators)) return [];
  return (
    !isEmpty(validators) && uniq(MappingPresenter.errors(validators).map((error) => MappingErrorPresenter.type(error)))
  );
};

export const getErrorTypesBySchemas = (validators) => {
  if (isNil(validators)) return [];
  return {
    errorTypes:
      !isEmpty(validators) &&
      uniq(MappingPresenter.errors(validators).map((error) => MappingErrorPresenter.type(error))),
    sheetSchemaId: validators.sheetSchemaId,
  };
};

export const getPreparedRowsWithErrors = (validators, is3DMappingType, errorTypes) =>
  is3DMappingType ? prepareRows3D(validators, errorTypes) : prepareRows2D(validators, errorTypes);

export const getAllErrors = (validators) => {
  return Object.values(validators).reduce((acc, item) => {
    return [
      ...acc,
      ...item.reduce((errors, row) => {
        return [...errors, ...row.errors];
      }, []),
    ];
  }, []);
};

export const getTabs = (validators) =>
  !isEmpty(validators) &&
  getErrorTypes(validators).map((type, index) => {
    return {
      id: index,
      name: type,
    };
  });

const mergeValuesLevel = (elem, cell) => {
  const elemValues = [...elem.values];
  let isRepeatedValue = false;
  const newValue = {
    nameInMapping: TableChangePresenter.nameInMapping(cell),
    value: TableChangePresenter.value(cell),
  };

  const array = elemValues.map((item) => {
    if (item.nameInMapping === newValue.nameInMapping) {
      isRepeatedValue = true;
      return newValue;
    }
    return item;
  });

  if (!isRepeatedValue) {
    array.push(newValue);
  }

  return array;
};

const updateMergedData = (oldChange, newChange, cell) =>
  oldChange.data.map((elem) => {
    if (elem.row === newChange.data[0].row) {
      return {
        ...elem,
        values: [...mergeValuesLevel(elem, cell)],
      };
    }
    return elem;
  });

export const mergeChanges = (oldChanges, newChange, cell) => {
  if (!oldChanges?.length) return [newChange];
  const isFix = oldChanges.some((oldChange) => oldChange.sheetSchemaId === newChange.sheetSchemaId);
  if (isFix) {
    return oldChanges.map((oldChange) => {
      const id = oldChange.sheetSchemaId;
      if (newChange.sheetSchemaId === id) {
        if (oldChange.data.some((elem) => elem.row === newChange.data[0].row)) {
          const updatedData = updateMergedData(oldChange, newChange, cell);
          return {
            sheetSchemaId: oldChange.sheetSchemaId,
            data: updatedData,
          };
        }

        const updatedData = [...oldChange.data, ...newChange.data];

        return {
          sheetSchemaId: oldChange.sheetSchemaId,
          data: updatedData,
        };
      }
      return oldChange;
    });
  }
  return [...oldChanges, newChange];
};
