import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isNil } from 'ramda';
import cn from 'clsx';
import { IconButton, Button } from '@material-ui/core';
import { AddBoxRounded as AddBoxRoundedIcon, CloseOutlined as CloseOutlinedIcon } from '@material-ui/icons';

import { TextField, Select } from 'components/Common';
import { getFieldFormatOptions } from 'helpers/fieldDetailsOptions';
import { useFields } from 'slices/hooks';
import { createInitialParametersWithValues } from 'utils/initialParametersUtils';

import useStyles from './useStyles';

const ParametersList = (props) => {
  const [newInitialParameters, setNewInitialParameters] = useState([]);
  const { label, onChange, apiErrors, initialParameters, datasetId } = props;
  const { fieldFormats } = useFields();

  const classes = useStyles();

  useEffect(() => {
    setNewInitialParameters(initialParameters);
  }, [initialParameters]);

  const handleFieldChange = (field, index) => ({ target: { value } }) => {
    const data = {
      initialParameters: newInitialParameters,
      index,
      field,
      value,
    };
    const newValues = createInitialParametersWithValues(data);
    setNewInitialParameters(newValues);
    onChange(newValues);
  };

  const handleDeleteParameter = (parameterIndex) => () => {
    const newValues = newInitialParameters.filter((parameter, index) => index !== parameterIndex);
    setNewInitialParameters(newValues);
    onChange(newValues);
  };

  const handleAddParameter = () => {
    const newValues = [...newInitialParameters, { displayName: '', name: '', format: 'plain_text', datasetId }];
    setNewInitialParameters(newValues);
    onChange(newValues);
  };

  const getTextErrorsField = (name, index) =>
    !isEmpty(apiErrors) && !isNil(apiErrors[index][name]) ? apiErrors[index][name].join(',') : '';
  const isExistErrors = (name, index) => !isEmpty(apiErrors) && !!apiErrors[index][name];

  return (
    <div className={classes.wrapper}>
      {!isEmpty(newInitialParameters) ? (
        newInitialParameters.map((parameter, index) => (
          <div key={index} className={classes.parameterForm}>
            <div className={classes.fieldWrapper}>
              <TextField
                value={parameter.name}
                onChange={handleFieldChange('name', index)}
                error={isExistErrors('name', index)}
                name="name"
                placeholder="Dataset Field Name"
                label="Dataset Field Name"
                helperText={getTextErrorsField('name', index)}
              />
            </div>
            <div className={classes.fieldWrapper}>
              <TextField
                value={parameter.displayName}
                onChange={handleFieldChange('displayName', index)}
                error={isExistErrors('displayName', index)}
                name="displayName"
                placeholder="Field Name for User"
                label="Field Name for User"
                helperText={getTextErrorsField('displayName', index)}
              />
            </div>
            <div className={classes.selectWrapper}>
              <Select
                value={parameter.format}
                label="Format"
                onChange={handleFieldChange('format', index)}
                name="format"
                options={getFieldFormatOptions(fieldFormats)}
              />
            </div>
            <div className={classes.buttonWrapper}>
              <IconButton onClick={handleDeleteParameter(index)}>
                <CloseOutlinedIcon className={classes.closeIcon} />
              </IconButton>
            </div>
          </div>
        ))
      ) : (
        <div className={classes.hint}>Initial parameters are not set</div>
      )}
      <Button
        className={cn(classes.buttonAdd, { [classes.disabled]: !isEmpty(apiErrors) })}
        aria-controls="dropdown-menu"
        aria-haspopup="true"
        onClick={handleAddParameter}
      >
        <AddBoxRoundedIcon className={classes.addIcon} />
        <span className={classes.buttonLabel}>{label}</span>
      </Button>
    </div>
  );
};

ParametersList.propTypes = {
  label: PropTypes.string,
  initialParameters: PropTypes.arrayOf(PropTypes.shape()),
  onChange: PropTypes.func.isRequired,
  apiErrors: PropTypes.arrayOf(PropTypes.shape()),
  datasetId: PropTypes.number.isRequired,
};

ParametersList.defaultProps = {
  label: 'Add Initial Parameter',
  apiErrors: [],
  initialParameters: [],
};

export default ParametersList;
