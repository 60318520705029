import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'clsx';
import { find, isNil, propEq } from 'ramda';
import { Button, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { CloseOutlined as CloseOutlinedIcon } from '@material-ui/icons';

import DatasetFieldPresenter from 'presenters/DatasetFieldPresenter';
import Icon from 'components/Icon';
import { useFields } from 'slices/hooks';
import { isBlank } from 'utils/conditions';
import { is3DMapping } from 'helpers/fieldDetailsOptions';

import useStyles from './useStyles';

const OFFSET_FOR_NESTED_ELEMENTS = 20;
const OFFSET_FOR_FIRST_CELL = 9;

const TableFields = (props) => {
  const { fields, datasetId, selectedName, fileType, fieldsFromTreeToList } = props;
  const classes = useStyles();
  const [checkedRow, setChecked] = useState();
  const { deleteField, loadFields, loadCurrentField, currentField, resetCurrentField } = useFields();

  useEffect(() => {
    const selectedField = find(propEq('nameInMapping', selectedName))(fields);
    const selectedFieldId = DatasetFieldPresenter.id(selectedField);
    if (!isNil(selectedField)) {
      loadCurrentField(selectedFieldId);
      setChecked(selectedFieldId);
    }
  }, [fields]); // eslint-disable-line

  if (isBlank(fields)) {
    return <div className={classes.noRows}>No fields created yet.</div>;
  }

  const handleDeleteField = (id) => (event) => {
    event.stopPropagation();
    deleteField(id)
      .then(() => loadFields({ datasetId }))
      .then(() => {
        if (id === DatasetFieldPresenter.id(currentField)) {
          resetCurrentField();
        }
      });
  };

  const handleCheckedRow = (id) => () => {
    setChecked(id);
    loadCurrentField(id);
  };

  const renderIcon = (fieldValue) =>
    fieldValue ? <Icon name="tick" className={classes.icon} /> : <Icon name="cross" className={classes.icon} />;

  const is3DMappingType = is3DMapping(fileType);

  const renderRow = (field) => {
    const paddingLeft = OFFSET_FOR_NESTED_ELEMENTS * field.depthLevel + OFFSET_FOR_FIRST_CELL;
    return (
      <>
        <TableCell>
          <div className={cn(classes.fieldNameCell, classes.noWrap)} style={{ paddingLeft }}>
            {DatasetFieldPresenter.nameInMapping(field)}
          </div>
        </TableCell>
        <TableCell>
          <div className={cn(classes.fieldNameForUserCell, classes.noWrap)}>
            {DatasetFieldPresenter.displayName(field)}
          </div>
        </TableCell>
        {is3DMappingType && (
          <TableCell>
            <div align="center" className={classes.mappingTypeCell}>
              {DatasetFieldPresenter.mappingType(field)}
            </div>
          </TableCell>
        )}
        <TableCell>
          <div align="center">{renderIcon(DatasetFieldPresenter.isRequired(field))}</div>
        </TableCell>
        <TableCell>
          <div align="center">{renderIcon(DatasetFieldPresenter.isExistValidators(field))}</div>
        </TableCell>
        <TableCell>
          <div align="right">
            <Button className={classes.buttonDelete} onClick={handleDeleteField(DatasetFieldPresenter.id(field))}>
              <CloseOutlinedIcon className={cn(classes.icon, classes.removeIcon)} />
            </Button>
          </div>
        </TableCell>
      </>
    );
  };

  return (
    <>
      <Table className={classes.tableField} aria-label="table fields">
        <TableHead>
          <TableRow>
            <TableCell align="left" className={classes.fieldNameCell}>
              <div className={classes.thead}>
                Dataset Field Name <Icon name="arrowSort" className={classes.sortIcon} />
              </div>
            </TableCell>
            <TableCell align="left" className={classes.fieldNameForUserCell}>
              <div className={classes.thead}>
                Field Name for User <Icon name="arrowSort" className={classes.sortIcon} />
              </div>
            </TableCell>
            {is3DMappingType && (
              <TableCell align="center" className={classes.mappingTypeCell}>
                Mapping
              </TableCell>
            )}
            <TableCell align="center">Required</TableCell>
            <TableCell align="center">Validation</TableCell>
            <TableCell align="right" />
          </TableRow>
        </TableHead>
        <TableBody>
          {fieldsFromTreeToList.map((field, index) => {
            const isChecked = checkedRow === DatasetFieldPresenter.id(field);
            return (
              <TableRow
                onClick={handleCheckedRow(DatasetFieldPresenter.id(field))}
                key={index}
                className={cn(classes.tableRow, { [classes.checked]: isChecked })}
              >
                {renderRow(field)}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
};

TableFields.propTypes = {
  datasetId: PropTypes.number.isRequired,
  fields: PropTypes.arrayOf(DatasetFieldPresenter.shape()),
  fieldsFromTreeToList: PropTypes.arrayOf(DatasetFieldPresenter.shape()),
  selectedName: PropTypes.string.isRequired,
  fileType: PropTypes.string,
};

TableFields.defaultProps = {
  fields: [],
  fieldsFromTreeToList: [],
  fileType: '2D',
};

export default TableFields;
