import { createSlice } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'ramda';

import UsersRepository from 'repositories/UsersRepository';
import LocationPresenter from 'presenters/LocationPresenter';
import UserPresenter from 'presenters/UserPresenter';
import OrganizationPresenter from 'presenters/OrganizationPresenter';
import { useActions } from 'utils/appHooks';
import history from 'utils/history';
import { appRoutes } from 'routes';
import { setupPendo } from 'utils/pendoUtils';
import { isBlank } from 'utils/conditions';

const INVALID_TOKEN = 'token_not_valid';

const initialState = {
  loading: false,
  currentUser: null,
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    loadCurrentUserStart(state) {
      state.loading = true;
    },
    loadCurrentUserSuccess(state, { payload }) {
      state.currentUser = payload;
    },
    loadCurrentUserFail(state, { payload }) {
      state.errors = payload;
    },
    loadCurrentUserFinish(state) {
      state.loading = false;
    },
    resetCurrentUser(state) {
      state.currentUser = null;
    },
  },
});

export default usersSlice.reducer;
export const { resetCurrentUser } = usersSlice.actions;

export const useUsersActions = () => {
  const dispatch = useDispatch();

  const loadCurrentUser = () => {
    dispatch(usersSlice.actions.loadCurrentUserStart());

    return UsersRepository.current()
      .then(({ data }) => {
        dispatch(usersSlice.actions.loadCurrentUserSuccess(data));

        const organization = UserPresenter.organization(data);
        const pendoApiKey = OrganizationPresenter.pendoApiKey(organization);
        const lobbyClientId = UserPresenter.lobbyClientId(data);
        const lobbyCognitoUsername = UserPresenter.lobbyCognitoUsername(data);

        if (isBlank(pendoApiKey)) {
          return;
        }

        setupPendo({ pendoApiKey, lobbyClientId, lobbyCognitoUsername });
      })
      .catch((errors) => {
        const { code } = errors;
        if (code === INVALID_TOKEN) {
          const { location } = history;
          const queryParams = LocationPresenter.search(location);
          isEmpty(queryParams)
            ? history.push(appRoutes.loginPath())
            : history.push(`${appRoutes.loginPath()}${queryParams}`);
        }
        dispatch(usersSlice.actions.loadCurrentUserFail(errors));
      })
      .finally(() => dispatch(usersSlice.actions.loadCurrentUserFinish()));
  };
  const actions = useActions({ resetCurrentUser });

  return {
    ...actions,
    loadCurrentUser,
  };
};
