import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  top: {
    margin: [20, 'auto'],
    maxWidth: 1360,
  },
  wrapper: {
    margin: 0,
  },
  head: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
    boxShadow: [
      ['inset', 0, -1, 0, theme.palette.shadow],
      [1, 1, 5, theme.palette.shadow],
    ],
    background: theme.palette.background.white,
    padding: [0, 20],
  },
  importMessageSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: theme.palette.background.white,
    border: [1, 'solid', theme.palette.shadow],
    borderRadius: [0, 0, 4, 4],
    padding: [20, 40],
    '& h3': {
      fontSize: theme.typography.pxToRem(16),
      color: theme.palette.text.success,
    },
    '& p': {
      fontSize: theme.typography.pxToRem(12),
      color: theme.palette.text.lightDark,
    },
  },
  footer: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    boxShadow: ['inset', 0, 1, 0, theme.palette.shadow],
    padding: [10, 120, 10, 40],
    background: theme.palette.background.white,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: theme.palette.text.lightDark,
    '& p': {
      color: theme.palette.text.lightDark,
      fontSize: theme.typography.pxToRem(12),
    },
    '& Button': {
      marginRight: 10,
    },
  },
  footerActions: {
    display: 'flex',
    alignItems: 'center',
  },
  saved: {
    color: theme.palette.text.secondary,
    display: 'inline-flex',
    alignItems: 'center',
    textTransform: 'uppercase',
    fontSize: theme.typography.pxToRem(12),
  },
  tickIcon: {
    width: 20,
    marginRight: 5,
    '& path': {
      fill: theme.palette.text.secondary,
    },
  },
  dialogContent: {
    color: theme.palette.text.primary,
    marginBottom: 30,
  },
  importSuccess: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    '& p': {
      color: theme.palette.text.primary,
    },
  },
  importFailed: {
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    color: theme.palette.errors,
    '& h3': {
      color: theme.palette.errors,
    },
    '& div': {
      fontSize: theme.typography.pxToRem(12),
      lineHeight: 1.3,
    },
  },
  error: {
    position: 'absolute',
    display: 'block',
    bottom: -25,
    color: theme.palette.errors,
    fontSize: theme.typography.pxToRem(9),
    paddingLeft: 16,
  },
  fieldWrapper: {
    position: 'relative',
  },
  successInfo: {
    marginTop: 32,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 1.3,
  },
  mappingTableTopContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: [1, 'solid', theme.palette.shadow],
    borderBottom: 'none',
    backgroundColor: theme.palette.background.white,
    height: 44,
    padding: [0, 16],
  },
  mappingTableCaption: {
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(17),
    color: theme.palette.title,
    lineHeight: '20px',
  },
  fullscreenToggle: {
    display: 'flex',
    background: 'none',
    border: 'none',
    outline: 'none',
    alignItems: 'center',
    color: theme.palette.title,
    cursor: 'pointer',
    '& svg': {
      marginRight: 10,
    },
  },
}));
