import { apiRoutes } from 'routes';
import FetchHelpers from 'utils/FetchHelpers';

export default {
  index(params) {
    const path = apiRoutes.templates3DPath();
    return FetchHelpers.get(path, params);
  },
  show(templateId) {
    const path = apiRoutes.template3DPath(templateId);
    return FetchHelpers.get(path, templateId);
  },
  create(params) {
    const path = apiRoutes.templates3DPath();
    return FetchHelpers.post(path, params);
  },
  update(templateId, params) {
    const path = apiRoutes.template3DPath(templateId);
    return FetchHelpers.patch(path, params);
  },
  delete(templateId) {
    const path = apiRoutes.template3DPath(templateId);
    return FetchHelpers.delete(path);
  },
  adaptive(templateId, params) {
    const path = apiRoutes.adaptive3DTemplatePath(templateId);
    return FetchHelpers.get(path, params);
  },
};
