import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Input, InputAdornment, Popper } from '@material-ui/core';
import { RotateLeftOutlined as RotateLeftOutlinedIcon } from '@material-ui/icons';
import cn from 'clsx';
import { ResizableBox } from 'react-resizable';
import { useDebouncedCallback } from 'use-debounce';

import 'react-resizable/css/styles.css';
import useStyles from './useStyles';

const FIXED = 'fixed';
const BORDERS_HEIGHTS = 4;

const FixErrorMenu = (props) => {
  const { index, onEdit, header, value, error, errorState, tooltipMessages, nameInMapping } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [isEdited, setEdited] = useState(false);
  const [isRollBackShowing, setIsRollBackShowing] = useState(false);
  const [heightResizableBox, setHeightResizableBox] = useState(20);
  const [widthResizableBox, setWidthResizableBox] = useState(100);
  const [stylesInput, setStylesInput] = useState({});

  const classes = useStyles();

  useEffect(() => {
    setInputValue(value || '');
    setAnchorEl(null);
    if (error && errorState === FIXED) {
      setEdited(true);
    }
  }, [value]); // eslint-disable-line

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  const setWidth = (data) => {
    const { top, width } = data.offsets.reference;

    /* eslint-disable */
    data.styles.width = width;
    data.offsets.popper.top = top;
    data.offsets.popper.width = width;
    /* eslint-enable */

    return data;
  };

  const handleInputFocus = (e) => {
    setAnchorEl(anchorEl ? null : e.currentTarget);
  };

  const handleEdit = (newValue) => {
    const data = {
      index,
      header,
      value: newValue,
      type: 'change',
      nameInMapping,
    };
    onEdit(data);
    if (value === newValue) {
      setIsRollBackShowing(false);
    }
  };

  const handleSaveValue = () => {
    if (value !== inputValue) {
      handleEdit(inputValue);
      setEdited(true);
      setIsRollBackShowing(true);
    }
    setAnchorEl(null);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    /* eslint-disable */
    e.target.style.height = '1px';
    e.target.style.height = `${e.target.scrollHeight}px`;
    /* eslint-disable */
    setHeightResizableBox(e.target.scrollHeight + BORDERS_HEIGHTS);
  };

  const handleResetValue = (e) => {
    handleEdit(value);
    setInputValue(value);
    if (errorState !== FIXED) {
      setEdited(false);
      setIsRollBackShowing(false);
    }
  };

  const renderTooltip = () => (
    <Popper
      id={id}
      open={open}
      anchorEl={anchorEl}
      className={classes.list}
      placement="right"
      modifiers={{
        setPopperWidth: {
          enabled: true,
          order: 849,
          fn: setWidth,
        },
      }}
      container={document.getElementsByClassName('fullscreen')[0]}
    >
      {tooltipMessages.map((message, key) => (
        <div key={key}>{message}</div>
      ))}
    </Popper>
  );

  const [handleDebounceOnResize] = useDebouncedCallback((size) => {
    setStylesInput({ size });
    setWidthResizableBox(size.width);
    setHeightResizableBox(size.height + BORDERS_HEIGHTS);
  }, 500);

  const renderEditableCell = () => (
    <ResizableBox
      width={widthResizableBox}
      height={heightResizableBox}
      minConstraints={[widthResizableBox, heightResizableBox]}
      className={classes.resizedCell}
      onResize={(e, data) => handleDebounceOnResize(data.size)}
    >
      <Input
        type="text"
        value={inputValue}
        onFocus={handleInputFocus}
        onChange={handleInputChange}
        onBlur={handleSaveValue}
        className={cn(classes.tableCell, { [classes.edited]: isEdited, [classes.error]: error })}
        multiline
        style={{ stylesInput }}
        endAdornment={
          isRollBackShowing && (
            <InputAdornment position="end" className={classes.resetButtonWrapper}>
              <IconButton color="inherit" className={classes.resetButton} onClick={handleResetValue}>
                <RotateLeftOutlinedIcon className={classes.resetButtonIcon} />
              </IconButton>
            </InputAdornment>
          )
        }
      />
    </ResizableBox>
  );

  const renderNotEditableCell = () => (
    <ResizableBox
      width={widthResizableBox}
      height={heightResizableBox}
      minConstraints={[widthResizableBox, heightResizableBox]}
      className={cn(classes.resizedNotEditedCell, classes.resizedCell)}
      onResize={(e, data) => handleDebounceOnResize(data.size)}
    >
      <div className={classes.tableCell}>
        <div className={classes.noErrorValue}>{inputValue}</div>
      </div>
    </ResizableBox>
  );

  return (
    <div className={classes.menu}>
      <div className={classes.root}>
        <span className={classes.searchInput}>{error ? renderEditableCell() : renderNotEditableCell()}</span>
        {renderTooltip()}
      </div>
    </div>
  );
};

FixErrorMenu.propTypes = {
  onEdit: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  index: PropTypes.number.isRequired,
  header: PropTypes.string.isRequired,
  error: PropTypes.bool,
  errorState: PropTypes.string,
  tooltipMessages: PropTypes.arrayOf(PropTypes.string),
  nameInMapping: PropTypes.string,
};

FixErrorMenu.defaultProps = {
  value: '',
  error: false,
  errorState: 'new',
  tooltipMessages: [],
  nameInMapping: '',
};

export default FixErrorMenu;
