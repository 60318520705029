import React from 'react';
import cn from 'clsx';
import PropTypes from 'prop-types';

import PreloaderImage from 'assets/images/PreloaderImage';

import useStyles from './useStyles';

const Preloader = (props) => {
  const { type } = props;
  const classes = useStyles();
  return (
    <div className={cn(classes.preloader, { [classes[type]]: Boolean(type) })}>
      <div className={classes.img}>
        <PreloaderImage />
      </div>
    </div>
  );
};

Preloader.propTypes = {
  type: PropTypes.oneOf(['full', 'table', 'inner', null]),
};

Preloader.defaultProps = {
  type: null,
};

export default Preloader;
