import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(
  (theme) => ({
    state: {
      color: theme.palette.text.lightDark,
      fontSize: theme.typography.pxToRem(10),
      lineHeight: 1,
      marginTop: 4,
      fontWeight: 300,
    },
    typeName: {
      fontWeight: 600,
      fontSize: theme.typography.pxToRem(20),
      lineHeight: 1.2,
      color: theme.palette.title,
      textAlign: 'left',
    },
    typeMessage: {
      marginTop: 4,
      fontSize: theme.typography.pxToRem(12),
      lineHeight: 1.2,
      color: theme.palette.text.lightDark,
    },
    resolveButton: {
      marginLeft: 32,
    },
    revertButton: {
      marginLeft: 12,
    },
    controls: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      padding: [20, 32],
    },
    errorIcon: {
      fill: theme.palette.yellow,
    },
    successIcon: {
      fill: theme.palette.green,
    },
    tabLabelWrapper: {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    tabLabel: {
      paddingLeft: 14,
      textAlign: 'left',
    },
    name: {
      fontSize: theme.typography.pxToRem(12),
      lineHeight: 1.3,
      color: theme.palette.text.primary,
      fontWeight: 500,
    },
    verticalTab: {
      backgroundColor: theme.palette.background.gray,
      marginRight: 0,
      marginBottom: 0,
      padding: [12, 16, 12, 18],
      borderBottom: [1, 'solid', theme.palette.shadow],
      borderRadius: 0,
      boxShadow: 'none',
      width: 262,
    },
    verticalValidationTab: {
      backgroundColor: theme.palette.background.white,
      borderLeft: 'none',
      borderRight: 'none',

      '&.Mui-selected': {
        background: `linear-gradient(to right, ${theme.palette.titleNotActive} 4px, ${theme.palette.background.white} 4px)`,
      },
    },
    controlsPanel: {
      height: '100%',
    },
  }),
  { name: 'SheetSchemaName' },
);
