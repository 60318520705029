import { apiRoutes } from 'routes';
import FetchHelpers from 'utils/FetchHelpers';

export default {
  current(documentId) {
    const path = apiRoutes.currentMapping3DPath(documentId);
    return FetchHelpers.get(path);
  },
  create(params) {
    const path = apiRoutes.mappings3DPath();
    return FetchHelpers.post(path, params);
  },
  result(documentId) {
    const path = apiRoutes.mapping3DResult(documentId);
    return FetchHelpers.post(path);
  },
  validate(params) {
    const path = apiRoutes.mappings3DValidatePath();
    return FetchHelpers.post(path, params);
  },
};
