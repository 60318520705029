import PropTypes from 'prop-types';

import Presenter from 'utils/PropTypesPresenter';
import MappingFieldPresenter from './MappingFieldPresenter';

export default new Presenter(
  {
    mappedFields: PropTypes.arrayOf(MappingFieldPresenter.shape()),
    mappingResult: PropTypes.object,
  },

  {
    formattedState(currentMapping) {
      return {
        mappedFields: this.mappedFields(currentMapping),
        mappingResult: this.mappingResult(currentMapping),
      };
    },
  },
);
