import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  sortableItem: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 1.3,
    color: theme.palette.text.primary,
    marginBottom: 12,
    '&:hover': {
      cursor: 'pointer',
      '& $sortableItemName': {
        background: theme.palette.background.hover,
        borderRadius: 2,
        borderLeft: [3, 'solid', theme.palette.title],
      },
      '& svg': {
        fill: theme.palette.title,
      },
      '& $dragIcon': {
        display: 'block',
        fill: theme.palette.text.iconDark,
      },
    },
  },
  removeIcon: {
    position: 'relative',
    margin: [0, 0, 0, 10],
    padding: 0,
    width: 16,
    height: 12,
    '& svg': {
      width: '100%',
      height: '100%',
    },
  },
  sortableWrapper: {
    marginTop: 24,
  },
  sortableItemName: {
    padding: [4, 8],
    transition: ['background', '100ms', 'ease-in-out', '0s'],
    borderLeft: [3, 'solid', theme.palette.transparent],
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  dragIcon: {
    display: 'none',
    position: 'absolute',
    left: -24,
    top: 0,
  },
}));
