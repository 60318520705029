import PropTypes from 'prop-types';

import Presenter from 'utils/PropTypesPresenter';
import { toUpperCaseFirstLetter } from 'utils/stringUtils';
import { formatDate } from 'presenters/DateTimePresenter';
import { DOCUMENT_STATUSES } from 'utils/documentStatuses';
import UserPresenter from './UserPresenter';
import InitialParametersPresenter from './InitialParametersPresenter';
import DatasetPresenter from './DatasetPresenter';

export default new Presenter(
  {
    id: PropTypes.number,
    fileUrl: PropTypes.string,
    fileName: PropTypes.string,
    createdAt: PropTypes.string,
    dataset: DatasetPresenter.shape(),
    state: PropTypes.oneOf(Object.values(DOCUMENT_STATUSES)),
    user: UserPresenter.shape(),
    initialParameters: PropTypes.arrayOf(InitialParametersPresenter.shape()),
  },
  {
    formattedState(document) {
      return {
        id: this.id(document),
        fileUrl: this.fileUrl(document),
        fileName: this.fileName(document),
        dataset: DatasetPresenter.name(this.dataset(document)),
        createdAt: formatDate(this.createdAt(document)),
        state: toUpperCaseFirstLetter(this.state(document)),
        user: UserPresenter.fullName(this.user(document)),
      };
    },
    documentsRows(documents) {
      return documents.map((document) => {
        return this.formattedState(document);
      });
    },
  },
);
