import palette from 'layouts/CustomThemeProvider/palette';

export default {
  MuiAccordionSummary: {
    root: {
      paddingLeft: 32,
      minHeight: 40,
      '&$expanded': {
        minHeight: 40,
        borderBottom: [1, 'solid', palette.shadow],
      },
      '&$disabled': {
        minHeight: 40,
      },
    },
    content: {
      margin: [0],
      '&$expanded': {
        margin: [0],
      },
    },
  },
};
