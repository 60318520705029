export default {
  background: {
    default: '#FAFBFF',
    gray: '#F9F9FB',
    white: '#fff',
    transparentWhite: '#fffc',
    shadow: '#dfe2ef33',
    hover: '#F5F5FB',
    primaryLight: '#ACD8D6',
    blackTransparent: '#00000066',
    selected: '#73bfba1a',
    active: '#6FB9B5',
  },
  shadow: '#dfe2ef',
  shadowHeader: '#0015291f',
  shadowLight: '#0000000f',
  shadowList: '#00000033',
  shadowBorder: '#dfe2ef1a',
  colorShadow: '#73bfba40',
  body: '#FAFBFF',
  title: '#1c4f6d',
  titleNotActive: '#1c4f6db3',
  default: '#73BFBA',
  errors: '#D73E34',
  disabled: '#CDD5DC',
  transparent: 'transparent',
  green: '#56BA78',
  lightGreen: '#56ba781a',
  yellow: '#F1C340',
  text: {
    primary: '#383B3D',
    secondary: '#adbed0',
    gray: '#CFD8DF',
    dark: '#383A43',
    lightDark: '#383a43b3',
    white: '#fff',
    iconDark: '#C6D1DE',
    success: '#56BA78',
    subTitle: '#758DAA',
    darkBlue: '#1C4F6D',
  },
};
