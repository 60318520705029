import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(
  (theme) => ({
    root: {
      minWidth: 150,
      maxHeight: '40vh',
      overflowY: 'auto',
      overflowX: 'hidden',
      background: theme.palette.background.white,
    },
    list: {
      padding: 0,
    },
    listTitle: {
      color: theme.palette.text.dark,
      fontSize: theme.typography.pxToRem(13),
      opacity: 0.4,
      margin: [10, 0, 10],
      padding: [0, 10],
    },
    backLink: {
      cursor: 'pointer',
      background: 'none',
      outline: 'none',
      border: 'none',
      '& svg': {
        marginRight: 10,
      },
    },
    item: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      cursor: 'pointer',
      padding: 8,
      fontSize: theme.typography.pxToRem(12),
      '& span': {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'clip',
        width: '100%',
      },
      '&:hover': {
        background: theme.palette.background.hover,
        '& span': {
          textOverflow: 'clip',
        },
      },
    },

    navigation: {
      marginBottom: 20,
    },

    formatsList: {
      color: theme.palette.text.primary,
      fontSize: theme.typography.pxToRem(12),
      '& li': {
        cursor: 'pointer',
      },
    },
    rowDropdown: {
      padding: 0,
    },
    rowDropdownTitle: {
      fontSize: theme.typography.pxToRem(10),
      paddingLeft: 24,
    },
    rowDropdownContent: {
      minWidth: 200,
    },
    hint: {
      color: theme.palette.text.lightDark,
      padding: 8,
      textAlign: 'center',
      fontSize: theme.typography.pxToRem(10),
    },
  }),
  {
    name: 'MapDropdownContent',
  },
);
