import React, { useState, useEffect } from 'react';
import { ListItem, Tooltip, Popper, IconButton, ClickAwayListener } from '@material-ui/core';
import { KeyboardArrowRight, KeyboardArrowDown } from '@material-ui/icons';
import PropTypes from 'prop-types';
import cn from 'clsx';

import DatasetFieldPresenter from 'presenters/DatasetFieldPresenter';
import Icon from 'components/Icon';
import { isBlank } from 'utils/conditions';

import useStyles from './useStyles';

const MapRowDropdownItem = (props) => {
  const { field, onMapField, mapToIndex, isActive, itemOffset, isOpenedElement, onClick, childrenExist } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    return setAnchorEl(null);
  }, []); // eslint-disable-line

  useEffect(() => {
    if (!isActive) {
      setAnchorEl(null);
    }
  }, [isActive]); // eslint-disable-line

  const currentFieldDisplayName = DatasetFieldPresenter.displayName(field);
  const currentFieldId = DatasetFieldPresenter.id(field);

  const classes = useStyles();

  const open = Boolean(anchorEl);
  const id = open ? 'actions-popper' : undefined;

  const handleFieldSelection = () => onMapField(field);

  const renderButtons = (currentField) => {
    const rowIndexes = DatasetFieldPresenter.rowIndexes(currentField);
    const isRequired = DatasetFieldPresenter.isRequired(currentField);
    const isButtonDisabled = isRequired && !isBlank(rowIndexes);
    if (rowIndexes && rowIndexes.includes(mapToIndex)) {
      return (
        <IconButton color="inherit" onClick={handleFieldSelection}>
          <Icon name="crossOutline" />
        </IconButton>
      );
    }

    return (
      <IconButton
        color="inherit"
        onClick={handleFieldSelection}
        classes={{ disabled: classes.disabled }}
        disabled={isButtonDisabled}
      >
        <Icon name="checkMark" />
      </IconButton>
    );
  };

  const renderTooltipActions = (currentField) => {
    return (
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        className={classes.actions}
        placement="right"
        container={anchorEl ? anchorEl.current?.closest('.fullscreen') : null}
        modifiers={{
          setPopperWidth: {
            enabled: true,
            order: 849,
          },
        }}
      >
        <div>{!childrenExist && renderButtons(currentField)}</div>
      </Popper>
    );
  };

  const handleClickItem = (e) => {
    setAnchorEl(e.currentTarget);
    onClick(field);
  };

  const handleClosePopper = () => {
    setAnchorEl(null);
  };

  return (
    <ClickAwayListener onClickAway={handleClosePopper}>
      <>
        <Tooltip
          classes={{
            popper: classes.popper,
          }}
          key={currentFieldId}
          title={currentFieldDisplayName}
          placement="bottom-start"
          enterDelay={500}
          enterNextDelay={500}
        >
          <ListItem
            key={currentFieldId}
            className={cn(classes.itemRowDropdown, { [classes.active]: isActive }, 'dropdownRowItem')}
            onClick={handleClickItem}
          >
            <div className={classes.itemTitle} style={{ paddingLeft: itemOffset }}>
              <div className={classes.toggleWrap}>
                {childrenExist && (isOpenedElement ? <KeyboardArrowDown /> : <KeyboardArrowRight />)}
              </div>
              <span>{currentFieldDisplayName}</span>
            </div>
          </ListItem>
        </Tooltip>
        {renderTooltipActions(field)}
      </>
    </ClickAwayListener>
  );
};

MapRowDropdownItem.propTypes = {
  onMapField: PropTypes.func,
  field: PropTypes.shape(),
  mapToIndex: PropTypes.number.isRequired,
  isActive: PropTypes.bool,
  itemOffset: PropTypes.number,
  onClick: PropTypes.func.isRequired,
  isOpenedElement: PropTypes.bool,
  childrenExist: PropTypes.bool,
};

MapRowDropdownItem.defaultProps = {
  onMapField: () => {},
  field: {},
  isActive: false,
  itemOffset: 0,
  isOpenedElement: false,
  childrenExist: false,
};

export default MapRowDropdownItem;
