import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'clsx';
import { clone, head, isEmpty, isNil, pluck } from 'ramda';
import { useRowSelect, useRowState, useTable } from 'react-table';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { useLocalStorage } from '@rehooks/local-storage';

import { MapSelector } from 'containers/MapData/components/MapSelector';
import { useMapping, useTemplates } from 'slices/hooks';
import { isBlank } from 'utils/conditions';
import { getMapSelectorInitialValue } from 'utils/mapSelectorUtils';
import { getTemplateByID, TEMPLATE_TYPES } from 'utils/templates';

import useStyles from './useStyles';

const findCell = (elements) => elements.find((element) => element.nodeName === 'TD');

const MapDataTable2D = (props) => {
  const { tableRows, sheetNumber, datasetId, fields, setIsDisabledSubmitButton } = props;
  const [showContextMenu, setShowContextMenu] = useState(false);
  const { setIgnoredRows, undoIgnoreRows, allIgnoredRows } = useMapping();
  const [contextMenuPosition, setContextMenuPosition] = useState({ left: 0, top: 0 });
  const [firstSelectedRow, setFirstSelectedRow] = useState();
  const [currentTemplate, setCurrentTemplate] = useState({});
  const { isTemplateChanged, changeTemplate } = useTemplates();

  const ignoreRows = allIgnoredRows[datasetId] || [];

  const classes = useStyles();

  const [template] = useLocalStorage('template');

  useEffect(() => {
    setShowContextMenu(false);
    if (!isNil(currentTemplate) && !isBlank(currentTemplate.ignoreRows)) {
      setIgnoredRows({ ignoredRows: [...currentTemplate.ignoreRows], id: datasetId });
    }
  }, [datasetId, currentTemplate]); // eslint-disable-line

  useEffect(() => {
    const templateByID = getTemplateByID(template, datasetId, TEMPLATE_TYPES.template2d);
    setCurrentTemplate(templateByID);
  }, [datasetId, template]);

  const data = React.useMemo(() => {
    return clone(tableRows).map((row) => {
      return { ...row };
    });
  }, [tableRows]);

  const columns = React.useMemo(() => {
    return head(tableRows).map((row, i) => {
      return { Header: i + 1, accessor: i.toString() };
    });
  }, [tableRows]);

  const tableInstance = useTable({ columns, data }, useRowSelect, useRowState);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  useEffect(() => {
    const clickOnWindow = (e) => {
      if (e.path && !findCell(e.path)) {
        setShowContextMenu(false);
        tableInstance.toggleAllRowsSelected(false);
      }
    };
    window.addEventListener('click', clickOnWindow);

    return () => {
      window.removeEventListener('click', clickOnWindow);
    };
  }, [tableInstance]);

  const prepareRowsToRender = () => {
    rows.map((row) => {
      prepareRow(row);
      // eslint-disable-next-line no-param-reassign
      row.state.ignored = ignoreRows.includes(Number(row.id));
      return row;
    });
  };

  const handleSelectWithShift = (row) => {
    window.getSelection().removeAllRanges();

    const selectedPreviousRow = firstSelectedRow.index;
    const selectedCurrentRow = row.index;

    tableInstance.rows.map((tableRow, index) => {
      if (selectedPreviousRow > selectedCurrentRow) {
        const shouldSelected = index >= selectedCurrentRow && index <= selectedPreviousRow;
        tableRow.toggleRowSelected(shouldSelected);
      } else {
        const shouldSelected = index >= selectedPreviousRow && index <= selectedCurrentRow;
        tableRow.toggleRowSelected(shouldSelected);
      }
      return tableRow;
    });
  };

  const handleRowClick = (e, row) => {
    const elemRect = e.target.getBoundingClientRect();

    if (isEmpty(tableInstance.selectedFlatRows)) {
      setFirstSelectedRow(row);
    }

    if (e.shiftKey) {
      handleSelectWithShift(row);
      setShowContextMenu(true);
    } else {
      tableInstance.toggleAllRowsSelected(false);
      row.toggleRowSelected();
      setShowContextMenu(!row.isSelected);
      setFirstSelectedRow(row);
    }

    setContextMenuPosition({ left: elemRect.left + 4, top: elemRect.top + elemRect.height + 4 });
  };

  const selectedRowsIndexes = pluck('id', tableInstance.selectedFlatRows);
  const selectedRowsIndexesAsNumber = selectedRowsIndexes.map(Number);

  const setChangedTemplate = () => {
    if (!isTemplateChanged) changeTemplate(true);
  };

  const handleIgnoreRows = () => {
    setChangedTemplate();
    setIgnoredRows({ ignoredRows: selectedRowsIndexesAsNumber, id: datasetId });
    resetStatesOtherRows();
  };

  const handleUndoIgnoreRows = () => {
    setChangedTemplate();
    undoIgnoreRows({ ignoredRows: selectedRowsIndexesAsNumber, id: datasetId });
    resetStatesOtherRows();
  };

  const handleIgnoreRowsRest = () => {
    setChangedTemplate();
    const currentSelectedRow = head(tableInstance.selectedFlatRows);
    const startRowToIgnoreIndex = Number(currentSelectedRow.id);
    resetStatesOtherRows();

    const ignoredRowsArray = tableInstance.rows.reduce((acc, row, index) => {
      if (index >= startRowToIgnoreIndex) {
        return [...acc, index];
      }
      return acc;
    }, []);

    setIgnoredRows({ ignoredRows: ignoredRowsArray, id: datasetId });
  };

  const resetStatesOtherRows = () => {
    setChangedTemplate();
    tableInstance.toggleAllRowsSelected(false);
    setShowContextMenu(false);
  };

  prepareRowsToRender();

  const renderTableRow = (row, i) => (
    <TableRow
      {...row.getRowProps({ onClick: (e) => handleRowClick(e, row) })}
      selected={row.isSelected}
      className={cn({ [classes.firstRow]: i === 0, [classes.ignoredRow]: row.state.ignored })}
    >
      <TableCell>{i + 1}</TableCell>
      {row.cells.map((cell) => {
        return <TableCell {...cell.getCellProps()}>{cell.render('Cell')}</TableCell>;
      })}
    </TableRow>
  );

  const renderSelectorRow = () => {
    if (isEmpty(rows)) {
      return null;
    }
    return (
      <TableRow className={classes.mapSelector} {...head(rows).getRowProps({ key: 'MapSelectorRow' })}>
        <TableCell />
        {head(rows).cells.map((cell) => {
          const initialValue = getMapSelectorInitialValue(currentTemplate, datasetId, cell);

          return (
            <MapSelector
              {...cell.getCellProps()}
              mapToIndex={Number(cell.column.id)}
              mapToName={String(cell.value)}
              initialValue={initialValue}
              sheetNumber={sheetNumber}
              datasetId={datasetId}
              fields={fields}
              setIsDisabledSubmitButton={setIsDisabledSubmitButton}
            />
          );
        })}
      </TableRow>
    );
  };

  const renderHeaderRow = (headerGroup) => (
    <TableRow {...headerGroup.getHeaderGroupProps()}>
      <TableCell />
      {headerGroup.headers.map((column) => (
        <TableCell {...column.getHeaderProps()}>{column.render('Header')}</TableCell>
      ))}
    </TableRow>
  );

  const renderContextMenu = () => {
    const isUndoIgnoreButtonVisible = tableInstance.selectedFlatRows.some((row) => row.state.ignored);
    const isIgnoreButtonVisible = tableInstance.selectedFlatRows.some((row) => !row.state.ignored);

    return (
      showContextMenu && (
        <div style={{ top: contextMenuPosition.top, left: contextMenuPosition.left }} className={classes.contextMenu}>
          {isIgnoreButtonVisible && (
            <button type="button" onClick={handleIgnoreRows}>
              <span className={classes.ignoreIcon}>X</span> Ignore
            </button>
          )}
          {isUndoIgnoreButtonVisible && (
            <button type="button" onClick={handleUndoIgnoreRows}>
              <span className={classes.ignoreIcon}>X</span> Undo Ignore
            </button>
          )}
          <button type="button" onClick={handleIgnoreRowsRest}>
            <span className={classes.ignoreIcon}>X</span> Ignore the rest
          </button>
        </div>
      )
    );
  };

  return (
    <>
      <TableContainer className={classes.table}>
        <Table {...getTableProps()}>
          <TableHead>{headerGroups.map((headerGroup) => renderHeaderRow(headerGroup))}</TableHead>
          <TableBody {...getTableBodyProps()}>
            {renderSelectorRow()}
            {rows.map((row, i) => renderTableRow(row, i))}
          </TableBody>
        </Table>
      </TableContainer>
      {renderContextMenu()}
    </>
  );
};

MapDataTable2D.propTypes = {
  tableRows: PropTypes.instanceOf(Array),
  sheetNumber: PropTypes.number,
  datasetId: PropTypes.number.isRequired,
  fields: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  setIsDisabledSubmitButton: PropTypes.func.isRequired,
};

MapDataTable2D.defaultProps = {
  tableRows: [],
  sheetNumber: 0,
};

export default MapDataTable2D;
