import React from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton } from '@material-ui/core';
import { CloseOutlined as CloseOutlinedIcon } from '@material-ui/icons';

import { TextField } from 'components/Common';
import { useTemplates } from 'slices/hooks';
import { validationSchema } from 'forms/UpdateTemplateForm';
import CurrentTemplatePresenter from 'presenters/CurrentTemplatePresenter';

const EditTemplate = (props) => {
  const { onClose, onSubmit, currentTemplate } = props;
  const { isLoading } = useTemplates();

  const initialTemplateValues = {
    name: CurrentTemplatePresenter.fileName(currentTemplate),
  };

  const { values, errors, setFieldValue, submitForm } = useFormik({
    initialValues: initialTemplateValues,
    validationSchema,
    onSubmit,
    validateOnChange: true,
  });

  const handleTextFieldChange = (field) => ({ target: { value } }) => {
    setFieldValue(field, value);
  };

  const handleSubmitForm = (e) => {
    e.preventDefault();
    submitForm(values);
  };

  return (
    <Dialog onClose={onClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" open>
      <DialogTitle>Rename Template</DialogTitle>
      <IconButton color="inherit" onClick={onClose}>
        <CloseOutlinedIcon />
      </IconButton>
      <form onSubmit={handleSubmitForm}>
        <DialogContent>
          <TextField
            value={values.name}
            onChange={handleTextFieldChange('name')}
            error={!!errors.name}
            name="name"
            placeholder="Template Name"
            label="Name"
            helperText={errors.name}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="outlined">
            Cancel
          </Button>
          <Button type="submit" disabled={isLoading} variant="contained">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

EditTemplate.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  currentTemplate: CurrentTemplatePresenter.shape().isRequired,
};

export default EditTemplate;
