import { useSelector } from 'react-redux';

import { useFieldsActions } from 'slices/UserFieldsSlice';

export const useUserFields = () => {
  const {
    loadFields,
    resetFields,
    setFields,
    setIsMappedColumnField,
    clearIsMappedField,
    setIsMappedRowField,
    setUnMappedRowField,
  } = useFieldsActions();

  const isListOfFieldsLoading = useSelector((state) => state.UserFieldsSlice.loading);
  const documentFieldsErrors = useSelector((state) => state.UserFieldsSlice.errors);
  const fields = useSelector((state) => state.UserFieldsSlice.fields);

  return {
    loadFields,
    resetFields,
    setFields,
    isListOfFieldsLoading,
    documentFieldsErrors,
    fields,
    setIsMappedColumnField,
    clearIsMappedField,
    setIsMappedRowField,
    setUnMappedRowField,
  };
};
