import { apiRoutes } from 'routes';
import FetchHelpers from 'utils/FetchHelpers';

export default {
  index(params) {
    const path = apiRoutes.fieldsDatasetPath();
    return FetchHelpers.get(path, params);
  },
  current(fieldId) {
    const path = apiRoutes.fieldDatasetPath(fieldId);
    return FetchHelpers.get(path);
  },
  create(params) {
    const path = apiRoutes.fieldsDatasetPath(params);
    return FetchHelpers.post(path, params);
  },
  update(fieldId, params) {
    const path = apiRoutes.fieldDatasetPath(fieldId);
    return FetchHelpers.put(path, params);
  },
  delete(fieldId) {
    const path = apiRoutes.fieldDatasetPath(fieldId);
    return FetchHelpers.delete(path);
  },
  loadFormats() {
    const path = apiRoutes.fieldFormatsPath();
    return FetchHelpers.get(path);
  },
  loadValidators() {
    const path = apiRoutes.fieldValidatorsPath();
    return FetchHelpers.get(path);
  },
};
