import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';
import { List } from '@material-ui/core';

import DatasetFieldPresenter from 'presenters/DatasetFieldPresenter';
import DatasetPresenter from 'presenters/DatasetPresenter';
import { isBlank } from 'utils/conditions';

import Field from './Field';

import useStyles from './useStyles';

const AvailableFields3D = (props) => {
  const { fields, dataset } = props;
  const [requiredFields, setRequiredFields] = useState([]);
  const [otherFields, setOtherFields] = useState([]);
  const [requiredFieldsRowType, setRequiredFieldsRowType] = useState([]);
  const [requiredFieldsColumnType, setRequiredFieldsColumnType] = useState([]);
  const [otherFieldsRowType, setOtherFieldsRowType] = useState([]);
  const [otherFieldsColumnType, setOtherFieldsColumnType] = useState([]);

  const isRowType = (field) => DatasetFieldPresenter.mappingType(field) === 'row';
  const isColumnType = (field) => DatasetFieldPresenter.mappingType(field) === 'column';

  const classes = useStyles();

  useEffect(() => {
    setRequiredFields(fields.filter((field) => DatasetFieldPresenter.isRequired(field)));
    setOtherFields(fields.filter((field) => !DatasetFieldPresenter.isRequired(field)));
  }, [fields]);

  useEffect(() => {
    setRequiredFieldsRowType(requiredFields.filter((field) => isRowType(field)));
    setRequiredFieldsColumnType(requiredFields.filter((field) => isColumnType(field)));
  }, [requiredFields]);

  useEffect(() => {
    setOtherFieldsRowType(otherFields.filter((field) => isRowType(field)));
    setOtherFieldsColumnType(otherFields.filter((field) => isColumnType(field)));
  }, [otherFields]);

  const renderFieldsList = (fieldsList) => {
    const mappedRowsIndexes = (field) => {
      const rowIndexes = DatasetFieldPresenter.rowIndexes(field);
      if (isBlank(rowIndexes)) {
        return [];
      }
      return rowIndexes.map((index) => index + 1);
    };

    const getMappingInfo = (field) =>
      isColumnType(field) ? `Col  ${DatasetFieldPresenter.columnIndex(field)}` : `Row  ${mappedRowsIndexes(field)}`;

    return (
      <List>
        {fieldsList.map((field) => (
          <Field
            key={DatasetFieldPresenter.id(field)}
            isMapped={DatasetFieldPresenter.isMapped(field)}
            mappedTo={DatasetFieldPresenter.isMapped(field) ? getMappingInfo(field) : 'Not Mapped'}
            title={DatasetFieldPresenter.displayName(field)}
          />
        ))}
      </List>
    );
  };
  return (
    <div className={classes.root}>
      <div className={classes.head}>
        <h3>Available fields</h3>
        <p>Dataset: {DatasetPresenter.name(dataset)}</p>
      </div>
      <div>
        <div className={classes.listWrapper}>
          <div className={classes.title}>Required Fields</div>
          {!isEmpty(requiredFieldsRowType) && (
            <div>
              <div className={classes.subTitle}>Rows</div>
              {renderFieldsList(requiredFieldsRowType)}
            </div>
          )}
          {!isEmpty(requiredFieldsColumnType) && (
            <div>
              <div className={classes.subTitle}>Columns</div>
              {renderFieldsList(requiredFieldsColumnType)}
            </div>
          )}
        </div>
        <div className={classes.listWrapper}>
          <div className={classes.title}>Optional Fields</div>
          {!isEmpty(otherFieldsRowType) && (
            <div>
              <div className={classes.subTitle}>Rows</div>
              {renderFieldsList(otherFieldsRowType)}
            </div>
          )}
          {!isEmpty(otherFieldsColumnType) && (
            <div>
              <div className={classes.subTitle}>Columns</div>
              {renderFieldsList(otherFieldsColumnType)}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

AvailableFields3D.propTypes = {
  fields: PropTypes.arrayOf(DatasetFieldPresenter.shape()),
  dataset: DatasetFieldPresenter.shape(),
};

AvailableFields3D.defaultProps = {
  fields: [],
  dataset: {},
};

export default AvailableFields3D;
