import palette from 'layouts/CustomThemeProvider/palette';

export default {
  MuiInputLabel: {
    root: {
      fontSize: 10,
      lineHeight: 1,
      color: palette.text.lightDark,
      paddingBottom: 10,
      transform: 'none',
      '&$focused': {
        color: palette.text.lightDark,
      },
    },
    shrink: {
      transform: 'none',
    },
    formControl: {
      top: -3,
    },
  },
};
