import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';
import cn from 'clsx';

import { Box, Button } from '@material-ui/core';

import DocumentPresenter from 'presenters/DocumentPresenter';
import DatasetPresenter from 'presenters/DatasetPresenter';

import FullscreenMainBlockContext from 'contexts/FullscreenMainBlockContext';

import { is3DMapping } from 'helpers/fieldDetailsOptions';

import MappingSteps from 'components/MappingSteps';
import Preloader from 'components/Preloader';

import { MapDataTable2D, MapDataTable3D } from './components/MapDataTable';
import { AvailableFields2D, AvailableFields3D } from './components/AvailableFields';

import useStyles from './useStyles';

const MappingTable = (props) => {
  const {
    currentDocument,
    currentDataset,
    sheets,
    sheetTitles,
    selectedSheetIndex,
    isLoading,
    onSheetButtonClick,
    fields,
    mappingSteps,
    mainHeight,
    fullScreenHandle,
    datasetId,
    setIsDisabledSubmitButton,
  } = props;

  const classes = useStyles();
  const fullscreenRef = useRef();

  const { active } = fullScreenHandle;

  const fileType = DatasetPresenter.fileType(currentDataset);
  const is3DMappingType = is3DMapping(fileType);

  const renderDataTable = () => {
    if (isEmpty(sheets)) {
      return null;
    }

    if (isLoading) {
      return <Preloader />;
    }

    const renderTableWithType = is3DMappingType ? (
      <MapDataTable3D
        datasetId={datasetId}
        tableRows={sheets[selectedSheetIndex].rows}
        sheetNumber={selectedSheetIndex}
        fields={fields}
        setIsDisabledSubmitButton={setIsDisabledSubmitButton}
      />
    ) : (
      <MapDataTable2D
        datasetId={datasetId}
        tableRows={sheets[selectedSheetIndex].rows}
        sheetNumber={selectedSheetIndex}
        fields={fields}
        setIsDisabledSubmitButton={setIsDisabledSubmitButton}
      />
    );

    return !isEmpty(sheets[selectedSheetIndex].rows) ? (
      renderTableWithType
    ) : (
      <div className={classes.emptySheet}>sheet is empty </div>
    );
  };

  const handleSheetButtonClick = (newSheetIndex) => () => onSheetButtonClick(newSheetIndex, datasetId);

  return (
    <>
      <div className={cn(classes.head, { [classes.withoutMappingSteps]: !mappingSteps })}>
        {mappingSteps && (
          <Box ml={1.5}>
            <MappingSteps steps={mappingSteps} activeStep={2} />
          </Box>
        )}
      </div>
      <FullscreenMainBlockContext.Provider value={fullscreenRef}>
        <div
          className={cn({
            [classes.mainFullScreen]: active,
            [classes.main]: !active,
          })}
          style={{ height: mainHeight }}
          aria-label="main"
          ref={fullscreenRef}
        >
          <div className={classes.tableWrapper}>
            {renderDataTable()}
            <div className={classes.selectSheetWrapper} aria-label="button group wrapper">
              <div className={classes.selectSheet} aria-label="button group">
                {sheetTitles &&
                  sheetTitles.map((name, index) => (
                    <Button
                      key={index}
                      className={cn(classes.sheetButton, { [classes.active]: index === selectedSheetIndex })}
                      onClick={handleSheetButtonClick(index)}
                    >
                      {name}
                    </Button>
                  ))}
              </div>
            </div>
          </div>
          {is3DMappingType ? (
            <AvailableFields3D fields={fields} dataset={DocumentPresenter.dataset(currentDocument)} />
          ) : (
            <AvailableFields2D fields={fields} dataset={DocumentPresenter.dataset(currentDocument)} />
          )}
        </div>
      </FullscreenMainBlockContext.Provider>
    </>
  );
};

MappingTable.propTypes = {
  currentDocument: DocumentPresenter.shape().isRequired,
  currentDataset: DatasetPresenter.shape().isRequired,
  sheets: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  fields: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  fullScreenHandle: PropTypes.shape().isRequired,
  mappingSteps: PropTypes.arrayOf(PropTypes.shape()),
  sheetTitles: PropTypes.arrayOf(PropTypes.string).isRequired,
  isLoading: PropTypes.bool.isRequired,
  selectedSheetIndex: PropTypes.number.isRequired,
  mainHeight: PropTypes.string.isRequired,
  onSheetButtonClick: PropTypes.func.isRequired,
  datasetId: PropTypes.number.isRequired,
  setIsDisabledSubmitButton: PropTypes.func.isRequired,
};

MappingTable.defaultProps = {
  mappingSteps: null,
};

export default MappingTable;
