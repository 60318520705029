import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  top: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: [0, 'auto'],
    maxWidth: 1360,
  },
  title: {
    color: theme.palette.title,
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(34),
    margin: [32, 0],
  },
  panelTable: {
    position: 'relative',
    zIndex: 0,
    paddingBottom: 30,
  },
}));
