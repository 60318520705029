import Accordion from './Accordion';
import AccordionDetails from './AccordionDetails';
import AccordionSummary from './AccordionSummary';
import Button from './Button';
import TableCell from './TableCell';
import TableRow from './TableRow';
import Typography from './Typography';
import Paper from './Paper';
import Dialog from './Dialog';
import DialogContent from './DialogContent';
import DialogActions from './DialogActions';
import DialogTitle from './DialogTitle';
import IconButton from './IconButton';
import Input from './Input';
import InputLabel from './InputLabel';
import Pagination from './Pagination';
import PaginationItem from './PaginationItem';
import Breadcrumbs from './Breadcrumbs';
import FormControlLabel from './FormControlLabel';
import Checkbox from './Checkbox';
import MenuItem from './MenuItem';
import Select from './Select';
import Tab from './Tab';
import Tabs from './Tabs';
import Alert from './Alert';
import TabPanel from './TabPanel';
import Tooltip from './Tooltip';
import Menu from './Menu';

export default {
  ...Accordion,
  ...AccordionDetails,
  ...AccordionSummary,
  ...Button,
  ...TableCell,
  ...TableRow,
  ...Typography,
  ...Paper,
  ...Dialog,
  ...DialogTitle,
  ...DialogActions,
  ...DialogContent,
  ...IconButton,
  ...Input,
  ...InputLabel,
  ...Pagination,
  ...Breadcrumbs,
  ...FormControlLabel,
  ...Checkbox,
  ...MenuItem,
  ...Select,
  ...Tab,
  ...Tabs,
  ...PaginationItem,
  ...Alert,
  ...TabPanel,
  ...Tooltip,
  ...Menu,
};
