import PropTypes from 'prop-types';

import Presenter from 'utils/PropTypesPresenter';

export default new Presenter({
  currentPage: PropTypes.number,
  perPage: PropTypes.number,
  totalCount: PropTypes.number,
  totalPages: PropTypes.number,
});
