export const TEMPLATE_TYPES = {
  template2d: '2d',
  template3d: '3d',
};

export const getTemplateByID = (template, datasetId, templateType = TEMPLATE_TYPES.template2d) => {
  return template
    ? template[`templates${templateType}`]?.find((currentTemplate) => {
        return currentTemplate.sheetSchemaId === datasetId || currentTemplate?.sheetSchema?.id === datasetId;
      })
    : {};
};
