import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import cn from 'clsx';
import { Button } from '@material-ui/core';

import useStyles from './useStyles';

const STEP_CLASSNAME = {
  1: 'initialStep',
  2: 'mappingStep',
  3: 'validateStep',
  4: 'summaryStep',
};

const MappingSteps = (props) => {
  const { activeStep, steps } = props;

  const classes = useStyles();

  return (
    <div className={classes.headList}>
      {steps.map((currentStep, index) => {
        const buttonClassName = cn(
          classes.headListItem,
          activeStep === currentStep.id ? classes.activeItem : classes.disabledItem,
          classes[STEP_CLASSNAME[activeStep]],
        );
        return (
          <Button key={currentStep.id} component={Link} to={currentStep.link} className={buttonClassName}>
            <span className={classes.headListIcon}>{index + 1}</span> {currentStep.name}
          </Button>
        );
      })}
    </div>
  );
};

MappingSteps.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      link: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  activeStep: PropTypes.number.isRequired,
};

export default MappingSteps;
