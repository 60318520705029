import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { clone, find, head, propEq } from 'ramda';
import cn from 'clsx';
import { useRowSelect, useRowState, useTable } from 'react-table';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { useLocalStorage } from '@rehooks/local-storage';

import { MapColumnSelector } from 'containers/MapData/components/MapSelector';
import { useMapping } from 'slices/hooks';
import Table3DContext from 'contexts/Table3DContext';
import { getMapSelectorInitialValue } from 'utils/mapSelectorUtils';
import { getTemplateByID, TEMPLATE_TYPES } from 'utils/templates';

import TableRow3D from './TableRow3D';

import useStyles from './useStyles';

const MapDataTable3D = (props) => {
  const { tableRows, sheetNumber, datasetId, fields, setIsDisabledSubmitButton } = props;
  const { mappedRows } = useMapping();
  const [rowSelectorIndex, setRowSelectorIndex] = useState();
  const tableRef = useRef();
  const [currentTemplate, setCurrentTemplate] = useState({});

  const classes = useStyles();

  const [template] = useLocalStorage('template');

  const data = React.useMemo(() => {
    return clone(tableRows).map((row) => {
      return { ...row };
    });
  }, [tableRows]);

  const columns = React.useMemo(() => {
    return head(tableRows).map((row, i) => {
      return { Header: i + 1, accessor: i.toString() };
    });
  }, [tableRows]);

  const tableInstance = useTable({ columns, data }, useRowSelect, useRowState);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    // eslint-disable-next-line no-empty-pattern
    state: {},
  } = tableInstance;

  const prepareRowsToRender = () => rows.map((row) => prepareRow(row));

  useEffect(() => {
    const templateByID = getTemplateByID(template, datasetId, TEMPLATE_TYPES.template3d);
    setCurrentTemplate(templateByID);
  }, [datasetId, template]);

  const renderSelectorColumn = () => (
    <TableRow className={classes.mapSelector} {...head(rows).getRowProps({ key: 'MapSelectorRow' })}>
      <TableCell />
      <TableCell className={classes.fixedEmptyTableCell} />
      {head(rows).cells.map((cell) => {
        const initialValue = getMapSelectorInitialValue(currentTemplate, datasetId, cell);
        return (
          <MapColumnSelector
            {...cell.getCellProps()}
            mapToIndex={Number(cell.column.id)}
            mapToName={String(cell.value)}
            initialValue={initialValue}
            sheetNumber={sheetNumber}
            datasetId={datasetId}
            fields={fields}
            setIsDisabledSubmitButton={setIsDisabledSubmitButton}
          />
        );
      })}
    </TableRow>
  );

  const renderHeaderRow = (headerGroup) => (
    <TableRow {...headerGroup.getHeaderGroupProps()}>
      <TableCell />
      <TableCell />
      {headerGroup.headers.map((column) => (
        <TableCell {...column.getHeaderProps()}>{column.render('Header')}</TableCell>
      ))}
    </TableRow>
  );

  prepareRowsToRender();

  const handleRowClick = (index) => {
    setRowSelectorIndex(index);
  };

  return (
    <Table3DContext.Provider value={tableRef}>
      <TableContainer className={cn(classes.table, classes.table3D)} ref={tableRef}>
        <Table {...getTableProps()}>
          <TableHead>{headerGroups.map((headerGroup) => renderHeaderRow(headerGroup))}</TableHead>
          <TableBody {...getTableBodyProps()}>
            {renderSelectorColumn()}
            {rows.map((row, i) => {
              const isMapped = Boolean(find(propEq('rowIndex', Number(row.id)))(mappedRows));
              const isContextVisible = rowSelectorIndex === Number(row.id);
              return (
                <TableRow3D
                  onClick={handleRowClick}
                  key={i}
                  row={row}
                  index={i}
                  sheetNumber={sheetNumber}
                  mapped={isMapped}
                  isContextVisible={isContextVisible}
                  template={template}
                  datasetId={datasetId}
                  fields={fields}
                  currentTemplate={currentTemplate}
                  setIsDisabledSubmitButton={setIsDisabledSubmitButton}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Table3DContext.Provider>
  );
};

MapDataTable3D.propTypes = {
  tableRows: PropTypes.instanceOf(Array),
  sheetNumber: PropTypes.number,
  datasetId: PropTypes.number.isRequired,
  fields: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  setIsDisabledSubmitButton: PropTypes.func.isRequired,
};

MapDataTable3D.defaultProps = {
  tableRows: [],
  sheetNumber: 0,
};

export default MapDataTable3D;
