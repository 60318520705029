import { useSelector } from 'react-redux';

import { useDatasetsActions } from 'slices/DatasetsSlice';

export const useDatasets = () => {
  const {
    loadDatasets,
    resetDatasets,
    createDataset,
    deleteDataset,
    updateDataset,
    loadCurrentDataset,
    resetCurrentDataset,
    publishDataset,
    resetDatasetErrors,
    loadInitialParameters,
    updateInitialParameters,
  } = useDatasetsActions();
  const isLoading = useSelector((state) => state.DatasetsSlice.loading);
  const apiErrors = useSelector((state) => state.DatasetsSlice.errors);
  const datasets = useSelector((state) => state.DatasetsSlice.datasets);
  const meta = useSelector((state) => state.DatasetsSlice.meta);
  const currentDataset = useSelector((state) => state.DatasetsSlice.currentDataset);
  const isErrorAlertShowing = useSelector((state) => state.DatasetsSlice.isErrorAlertShowing);
  const initialParameters = useSelector((state) => state.DatasetsSlice.initialParameters);

  return {
    loadDatasets,
    resetDatasets,
    createDataset,
    deleteDataset,
    updateDataset,
    loadCurrentDataset,
    resetCurrentDataset,
    isLoading,
    apiErrors,
    datasets,
    meta,
    currentDataset,
    publishDataset,
    isErrorAlertShowing,
    resetDatasetErrors,
    loadInitialParameters,
    updateInitialParameters,
    initialParameters,
  };
};
