import palette from 'layouts/CustomThemeProvider/palette';

export default {
  MuiIconButton: {
    root: {
      fill: palette.text.secondary,
      '&:hover': {
        backgroundColor: 'none',
      },
    },
    colorInherit: {
      position: 'absolute',
      right: 0,
      width: 12,
      height: 12,
      margin: [18, 27],
      color: palette.text.secondary,
      '&:hover': {
        backgroundColor: 'none',
      },
    },
  },
};
