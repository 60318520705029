import { replace } from 'ramda';

const toUpperCaseFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const snakeCaseToCapWords = (string) => {
  const removedUnderscoreString = replace('_', ' ', string);
  return replace(/( |^)[a-z]/g, (char) => char.toUpperCase(), removedUnderscoreString);
};

const camelCaseToCapWords = (string) => {
  const addedSpacesString = string.replace(/([A-Z])/g, ' $1');
  return addedSpacesString.charAt(0).toUpperCase() + addedSpacesString.slice(1);
};

const snakeCaseToWords = (string) => {
  return replace('_', ' ', string);
};

const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

export { toUpperCaseFirstLetter, snakeCaseToCapWords, alphabet, camelCaseToCapWords, snakeCaseToWords };
