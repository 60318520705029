import { useSelector } from 'react-redux';

import { useUsersActions } from 'slices/UsersSlice';

export const useUsers = () => {
  const { loadCurrentUser, resetCurrentUser } = useUsersActions();
  const currentUser = useSelector((state) => state.UsersSlice.currentUser);

  return {
    loadCurrentUser,
    currentUser,
    resetCurrentUser,
  };
};
