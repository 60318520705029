export default {
  MuiTabs: {
    root: {
      overflow: 'visible',
      minHeight: 'auto',
      paddingLeft: 32,
    },
    indicator: {
      display: 'none',
    },
    scroller: {
      overflow: 'visible !important',
    },
  },
};
