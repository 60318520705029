import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  text: {
    width: 380,
    paddingTop: 10,
    paddingBottom: 24,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 1.3,
    color: theme.palette.text.dark,
  },
  buttonLink: {
    position: 'absolute',
    right: 0,
    top: 50,
    zIndex: 1,
  },
  loadMapping: {
    position: 'relative',
  },
  error: {
    position: 'absolute',
    display: 'block',
    bottom: 0,
    color: theme.palette.errors,
    fontSize: theme.typography.pxToRem(9),
    paddingLeft: 16,
  },
  initialParamsErrors: {
    position: 'relative',
    marginTop: 15,
  },
  root: {
    position: ['absolute', '!important'],
    height: '100%',
    '&:first-child': {
      paddingTop: 32,
      paddingBottom: 24,
    },
  },
  paper: {
    textAlign: 'center',
    height: 212,
    minWidth: 345,
    maxWidth: 345,
  },
  container: {
    background: theme.palette.background.transparentWhite,
  },
  title: {
    color: theme.palette.title,
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(20),
    lineHeight: 1.2,
    paddingBottom: 8,
  },
  errorText: {
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 1.7,
    textAlign: 'center',
    color: theme.palette.text.primary,
  },
  rootActions: {
    justifyContent: 'center',
  },
  backdropRoot: {
    position: 'absolute',
    background: 'none',
  },
  details: {
    textAlign: 'left',
    marginTop: 16,
  },
}));
