import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  top: {
    margin: [20, 'auto'],
    maxWidth: 1360,
  },
  wrapper: {
    minHeight: '80vh',
    boxShadow: [1, 1, 5, theme.palette.shadow],
    borderRadius: [5, 5, 0, 0],
    background: theme.palette.background.white,
    margin: [20, 0, 0, 0],
  },
  head: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
    padding: [0, 20],
    boxShadow: ['inset', 0, -1, 0, theme.palette.shadow],
    background: theme.palette.background.white,
  },
  main: {
    display: 'flex',
    height: '100%',
    alignItems: 'stretch',
    background: theme.palette.background.white,
    paddingBottom: 65,
    '& [role="tabpanel"]': {
      flexBasis: '82%',
      overflowX: 'scroll',
    },
  },
  mainFullScreen: {
    display: 'flex',
    height: '100%',
    background: theme.palette.background.white,
  },
  fullscreenToggle: {
    display: 'flex',
    background: 'none',
    border: 'none',
    outline: 'none',
    alignItems: 'center',
    color: theme.palette.title,
    cursor: 'pointer',
    '& svg': {
      marginRight: 10,
    },
  },
  footer: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    boxShadow: ['inset', 0, 1, 0, theme.palette.shadow],
    padding: [10, 120, 10, 40],
    background: theme.palette.background.white,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    zIndex: 10,
    '& p': {
      color: theme.palette.text.lightDark,
      fontSize: theme.typography.pxToRem(12),
    },
    '& Button': {
      background: theme.palette.default,
      padding: 4,
      height: 32,
      width: 112,
    },
  },
  content: {
    padding: [40, 32],
  },
}));
