import palette from 'layouts/CustomThemeProvider/palette';

export default {
  MuiTableRow: {
    root: {
      '&:last-child': {
        borderBottom: 'none',
      },
      '&$selected': {
        background: palette.background.selected,
        '& td': {
          borderTop: [2, 'solid', palette.default],
          borderBottom: [2, 'solid', palette.default],
        },
        '& td:first-child': {
          background: palette.default,
          color: palette.text.white,
          borderRight: 'none',
        },
        '& td:last-child': {
          borderRight: [2, 'solid', palette.default],
        },
      },
      '&$selected:hover': {
        background: palette.background.selected,
      },
    },
    head: {
      backgroundColor: palette.background.gray,
    },
  },
};
