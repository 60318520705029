import React from 'react';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';

import UserPresenter from 'presenters/UserPresenter';
import { useUsers } from 'slices/hooks';
import { appRoutes } from 'routes';
import { USER, MANAGER } from 'utils/userRoles';
import { useRouter } from 'utils/appHooks';
import LocationPresenter from 'presenters/LocationPresenter';
import Logo from 'assets/images/logo.png';

import useStyles from './useStyles';

const ErrorScreen = () => {
  const { currentUser } = useUsers();
  const { location } = useRouter();
  const classes = useStyles();

  const getErrorCode = () => {
    const paths = LocationPresenter.pathname(location).split('/');
    return paths[paths.length - 1];
  };

  const errorCode = getErrorCode();

  const getErrorMessage = () => {
    switch (errorCode) {
      case '403':
        return 'Forbidden';
      case '404':
        return 'Not Found';
      default:
        return 'Error';
    }
  };

  const getMainPageRoute = () => {
    switch (UserPresenter.role(currentUser)) {
      case USER:
        return appRoutes.documentsPath();
      case MANAGER:
        return appRoutes.datasetsPath();
      default:
        return appRoutes.loginPath();
    }
  };
  return (
    <div className={classes.wrapper}>
      <div className={classes.details}>
        <img src={Logo} alt="FormataFile" className={classes.logo} />
        <div className={classes.numberError}>{errorCode}</div>
        <div className={classes.messageError}>{getErrorMessage()}</div>
        <Button component={Link} to={getMainPageRoute()} variant="contained">
          Go to the main page
        </Button>
      </div>
    </div>
  );
};

export default ErrorScreen;
