import PropTypes from 'prop-types';

import Presenter from 'utils/PropTypesPresenter';
import UserPresenter from './UserPresenter';

export default new Presenter(
  {
    id: PropTypes.number,
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string,
    name: PropTypes.string,
    isActive: PropTypes.bool,
    createdBy: UserPresenter.shape(),
    lastUpdatedBy: UserPresenter.shape(),
    fileType: PropTypes.string,
    adaptiveTemplateUrl: PropTypes.string,
    endpointUrl: PropTypes.string,
    externalDefinitionUrl: PropTypes.string,
    validationUrl: PropTypes.string,
    sheetSchemas: PropTypes.arrayOf(PropTypes.shape()),
  },
  {
    status(dataset) {
      return this.isActive(dataset) ? 'Published' : 'Draft';
    },
    options(datasets) {
      return datasets.map((dataset) => {
        return {
          value: String(this.id(dataset)),
          label: this.name(dataset),
        };
      });
    },
    hasSubDatasets(dataset) {
      return true;
    },
  },
);
