import { createSlice } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import DocumentsRepository from 'repositories/DocumentsRepository';
import { useActions } from 'utils/appHooks';
import history from 'utils/history';
import { appRoutes } from 'routes';

const initialState = {
  loading: false,
  documents: [],
  currentDocument: {},
  sheets: [],
  createdDocument: {},
  meta: {},
  errors: {},
};

const documentsSlice = createSlice({
  name: 'documents',
  initialState,
  reducers: {
    loadDocumentsSuccess(state, { payload }) {
      state.documents = payload.items;
      state.meta = payload.meta;
      state.errors = {};
    },
    loadSheetsSuccess(state, { payload }) {
      state.sheets = payload.sheets;
      state.errors = {};
    },
    loadDocumentsFail(state, { payload }) {
      state.errors = payload;
    },
    loadingStart(state) {
      state.loading = true;
    },
    loadingFinish(state) {
      state.loading = false;
    },
    loadCurrentDocumentSuccess(state, { payload }) {
      state.currentDocument = payload;
      state.errors = {};
    },
    createDocumentSuccess(state, { payload }) {
      state.createdDocument = payload;
      state.errors = {};
    },
    createDocumentFail(state, { payload }) {
      state.errors = payload;
    },
    resetDocuments(state) {
      state.loading = initialState.loading;
      state.documents = initialState.documents;
      state.meta = initialState.meta;
      state.errors = initialState.errors;
      state.sheets = initialState.sheets;
    },
    resetUsedDocuments(state) {
      state.createdDocument = initialState.createdDocument;
      state.currentDocument = initialState.currentDocument;
    },
    deleteDocumentStart(state) {
      state.loading = true;
    },
    deleteDocumentFinish(state) {
      state.loading = false;
    },
    deleteDocumentSuccess(state) {
      state.errors = {};
    },
    deleteDocumentFail(state, { payload }) {
      state.errors = payload;
      state.isErrorAlertShowing = true;
    },
    updateDocumentFail(state, { payload }) {
      state.errors = payload;
    },
    updateDocumentSuccess(state) {
      state.errors = initialState.errors;
    },
  },
});

export default documentsSlice.reducer;
export const { resetDocuments, resetUsedDocuments } = documentsSlice.actions;

export const useDocumentsActions = () => {
  const dispatch = useDispatch();

  const loadDocuments = (params) => {
    dispatch(documentsSlice.actions.loadingStart());
    return DocumentsRepository.index(params)
      .then((response) => dispatch(documentsSlice.actions.loadDocumentsSuccess(response)))
      .catch((errors) => dispatch(documentsSlice.actions.loadDocumentsFail(errors)))
      .finally(() => dispatch(documentsSlice.actions.loadingFinish()));
  };

  const createDocument = (params) => {
    dispatch(documentsSlice.actions.loadingStart());
    return DocumentsRepository.create(params)
      .then((response) => dispatch(documentsSlice.actions.createDocumentSuccess(response.data)))
      .catch((errors) => dispatch(documentsSlice.actions.createDocumentFail(errors)))
      .finally(() => dispatch(documentsSlice.actions.loadingFinish()));
  };

  const loadCurrentDocument = (documentId) => {
    dispatch(documentsSlice.actions.loadingStart());
    return DocumentsRepository.current(documentId)
      .then((response) => dispatch(documentsSlice.actions.loadCurrentDocumentSuccess(response.data)))
      .catch((errors) => dispatch(documentsSlice.actions.loadDocumentsFail(errors)))
      .finally(() => dispatch(documentsSlice.actions.loadingFinish()));
  };

  const loadDocumentSheets = (documentId) => {
    dispatch(documentsSlice.actions.loadingStart());
    return DocumentsRepository.preview(documentId)
      .then((response) => dispatch(documentsSlice.actions.loadSheetsSuccess(response.data)))
      .catch((errors) => dispatch(documentsSlice.actions.loadDocumentsFail(errors)))
      .finally(() => dispatch(documentsSlice.actions.loadingFinish()));
  };
  const deleteDocument = (documentId) => {
    dispatch(documentsSlice.actions.deleteDocumentStart());
    return DocumentsRepository.delete(documentId)
      .then((response) => {
        dispatch(documentsSlice.actions.deleteDocumentSuccess(response.data));
        history.push(appRoutes.documentsTabPath('documents'));
      })
      .catch((errors) => dispatch(documentsSlice.actions.deleteDocumentFail(errors)))
      .finally(() => dispatch(documentsSlice.actions.deleteDocumentFinish()));
  };

  const updateDocument = (documentId, params) => {
    dispatch(documentsSlice.actions.loadingStart());
    return DocumentsRepository.update(documentId, params)
      .then((response) => {
        dispatch(documentsSlice.actions.updateDocumentSuccess(response.data));
      })
      .catch((errors) => dispatch(documentsSlice.actions.updateDocumentFail(errors)))
      .finally(() => dispatch(documentsSlice.actions.loadingFinish()));
  };

  const actions = useActions({ resetDocuments, resetUsedDocuments });

  return {
    loadDocuments,
    createDocument,
    loadCurrentDocument,
    loadDocumentSheets,
    deleteDocument,
    updateDocument,
    ...actions,
  };
};
