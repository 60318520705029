import { createSlice } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { append, difference, find, isEmpty, isNil, omit, path, propEq } from 'ramda';

import MappingRepository from 'repositories/MappingRepository';
import Mapping3DRepository from 'repositories/Mapping3DRepository';
import MappingMultiSheetRepository from 'repositories/MappingMultiSheetRepository';

import { useActions } from 'utils/appHooks';
import history from 'utils/history';
import { appRoutes } from 'routes';

const initialState = {
  loading: false,
  mapping: {},
  mappedColumns: {},
  mappedRows: {},
  numberOfIgnoredRows: 0,
  errors: {},
  validators: {},
  errorRows: {},
  mappingResult: {},
  changes: {
    changes2d: [],
    changes3d: [],
  },
  isErrorAlertShowing: false,
  sendResultErrors: {},
  currentMapping: {},
  ignoreRows: {},
  uncorrectableErrors: [],
  importMessage: null,
};

const mappingSlice = createSlice({
  name: 'mapping',
  initialState,
  reducers: {
    validateMappingStart(state) {
      state.loading = true;
    },
    validateMappingSuccess(state, { payload }) {
      state.validators = payload;
      state.errors = {};
    },
    validateMappingFail(state, { payload }) {
      state.errors = payload;
    },
    validateMappingFinish(state) {
      state.loading = false;
    },
    createStart(state) {
      state.mapping = true;
    },
    createFinish(state) {
      state.mapping = false;
    },
    createFail(state, action) {
      state.errors = { mapping: action };
    },
    createSuccess(state) {
      state.mapping = false;
    },
    resetCurrentMapping(state, { payload: datasetId }) {
      state.loading = initialState.loading;
      state.errors = initialState.errors;
      state.validators = initialState.validators;
      state.isErrorAlertShowing = initialState.isErrorAlertShowing;
      state.sendResultErrors = initialState.sendResultErrors;
      state.mappingResult = initialState.mappingResult;
      state.currentMapping = initialState.currentMapping;
      state.changes = initialState.changes;
      state.ignoreRows = omit([datasetId], state.ignoreRows);
      state.mappedColumns = omit([datasetId], state.mappedColumns);
      state.mappedRows = omit([datasetId], state.mappedRows);
      state.mapping = initialState.mapping;
      state.numberOfIgnoredRows = initialState.numberOfIgnoredRows;
      state.uncorrectableErrors = initialState.uncorrectableErrors;
      state.importMessage = initialState.importMessage;
    },
    addMappedColumn(state, { payload }) {
      const { datasetId, fieldName } = payload;
      const currentDatasetMappingColumn = state.mappedColumns[datasetId];
      const currentColumn = currentDatasetMappingColumn
        ? find(propEq('fieldName', fieldName))(state.mappedColumns[datasetId])
        : null;
      if (isNil(currentColumn)) {
        state.mappedColumns[datasetId] = append(omit(['datasetId'], payload), currentDatasetMappingColumn || []);
      }
    },
    removeMappedColumn(state, { payload }) {
      const { datasetId, id } = payload;
      if (state.mappedColumns[datasetId]) {
        const indexToRemove = state.mappedColumns[datasetId].findIndex((column) => {
          return column.id === id;
        });
        state.mappedColumns[datasetId].splice(indexToRemove, 1);
      }
    },
    resetMappedColumns(state) {
      state.mappedColumns = initialState.mappedColumns;
      state.ignoredRows = initialState.ignoredRows;
    },
    setIgnoredRows(state, { payload }) {
      state.ignoreRows = {
        ...state.ignoreRows,
        [payload.id]: state.ignoreRows[payload.id]
          ? [...state.ignoreRows[payload.id], ...payload.ignoredRows]
          : [...payload.ignoredRows],
      };
    },
    loadValidateMappingErrors(state, { payload }) {
      state.validators = payload;
    },
    loadStart(state) {
      state.loading = true;
    },
    loadFinish(state) {
      state.loading = false;
    },
    loadCurrentMappingSuccess(state, { payload }) {
      state.currentMapping = payload;
      state.errors = {};
    },
    loadCurrentMappingFail(state, { payload }) {
      state.errors = payload;
    },
    loadResultSuccess(state, { payload }) {
      state.mappingResult = payload;
      state.errors = {};
    },
    loadResultFail(state, { payload }) {
      state.sendResultErrors = payload;
      state.isErrorAlertShowing = true;
    },
    resetMappingErrors(state) {
      state.sendResultErrors = initialState.sendResultErrors;
      state.isErrorAlertShowing = initialState.isErrorAlertShowing;
    },
    addChangesMappingErrors(state, { payload }) {
      state.changes = payload;
    },
    resetCommonErrors(state) {
      state.errors = initialState.errors;
    },
    resetChanges(state) {
      state.changes = initialState.changes;
    },
    undoIgnoreRows(state, { payload }) {
      state.ignoreRows[payload.id] = difference(state.ignoreRows[payload.id], payload.ignoredRows);
    },
    resetIgnoreRows(state, { payload }) {
      state.ignoreRows[payload.id] = initialState.ignoreRows;
    },
    resetAllIgnoreRows(state) {
      state.ignoreRows = initialState.ignoreRows;
    },
    addMappedRow(state, { payload }) {
      const { datasetId } = payload;

      state.mappedRows[datasetId] = append(omit(['datasetId'], payload), state.mappedRows[datasetId] || []);
    },
    removeMappedRow(state, { payload }) {
      const { datasetId } = payload;
      if (state.mappedRows[datasetId]) {
        const indexToRemove = state.mappedRows[datasetId].findIndex(
          (row) => row.id === payload.id && row.rowIndex === payload.mapToIndex,
        );
        state.mappedRows[datasetId].splice(indexToRemove, 1);
      }
    },
    resetMappedRows(state) {
      state.mappedRows = initialState.mappedRows;
    },
    loadValidateMappingUncorrectableErrors(state, { payload }) {
      state.uncorrectableErrors = payload;
    },
    loadResultSubmitted(state) {
      state.importMessage = 'The data was submitted. You will see the report in the Lobby in an hour.';
    },
  },
});

export default mappingSlice.reducer;
export const {
  resetCurrentMapping,
  addMappedColumn,
  removeMappedColumn,
  resetMappedColumns,
  setIgnoredRows,
  addChangesMappingErrors,
  resetMappingErrors,
  resetCommonErrors,
  resetChanges,
  undoIgnoreRows,
  addMappedRow,
  removeMappedRow,
  resetMappedRows,
  resetIgnoreRows,
  resetAllIgnoreRows,
} = mappingSlice.actions;

const getUncorrectableErrors = (errors) => {
  const { validators = {} } = errors.nonFieldErrors;
  const allResults = Object.values(validators).reduce((acc, results) => [...acc, ...results], []);
  return allResults.reduce((acc, item) => {
    if (item?.uncorrectableErrors) {
      return [...acc, ...item.uncorrectableErrors];
    }
    return acc;
  }, []);
};

export const useMappingActions = () => {
  const dispatch = useDispatch();

  const validateMapping = (params) => {
    dispatch(mappingSlice.actions.validateMappingStart(params));
    return MappingRepository.validate(params)
      .then((response) => {
        dispatch(mappingSlice.actions.validateMappingSuccess(response.data));
      })
      .catch((errors) => {
        const validators = path(['nonFieldErrors', 'validators'], errors);
        if (validators) {
          dispatch(mappingSlice.actions.loadValidateMappingErrors(validators));
        } else {
          dispatch(mappingSlice.actions.validateMappingFail(errors));
        }
      })
      .finally(() => dispatch(mappingSlice.actions.validateMappingFinish()));
  };

  const validate3DMapping = (params) => {
    dispatch(mappingSlice.actions.validateMappingStart(params));
    return Mapping3DRepository.validate(params)
      .then((response) => dispatch(mappingSlice.actions.validateMappingSuccess(response.data)))
      .catch((errors) => {
        const validators = path(['nonFieldErrors', 'validators'], errors);
        const uncorrectableErrors = getUncorrectableErrors(errors);
        if (validators) {
          dispatch(mappingSlice.actions.loadValidateMappingErrors(validators));
        } else {
          dispatch(mappingSlice.actions.validateMappingFail(errors));
        }
        if (!isEmpty(uncorrectableErrors)) {
          dispatch(mappingSlice.actions.loadValidateMappingUncorrectableErrors(uncorrectableErrors));
        }
      })
      .finally(() => dispatch(mappingSlice.actions.validateMappingFinish()));
  };

  const validateMultiSheetMapping = (params) => {
    dispatch(mappingSlice.actions.validateMappingStart(params));
    return MappingMultiSheetRepository.validate(params)
      .then((response) => dispatch(mappingSlice.actions.validateMappingSuccess(response.data)))
      .catch(async (errors) => {
        const validators = path(['nonFieldErrors', 'validators'], errors);
        const uncorrectableErrors = getUncorrectableErrors(errors);
        if (validators) {
          await dispatch(mappingSlice.actions.loadValidateMappingErrors(validators));
          return validators;
        }
        dispatch(mappingSlice.actions.validateMappingFail(errors));

        if (!isEmpty(uncorrectableErrors)) {
          dispatch(mappingSlice.actions.loadValidateMappingUncorrectableErrors(uncorrectableErrors));
        }
        return errors;
      })
      .finally(() => dispatch(mappingSlice.actions.validateMappingFinish()));
  };

  const createMapping = (data) => {
    dispatch(mappingSlice.actions.createStart());
    return MappingRepository.create(data)
      .then((response) => {
        localStorage.setItem('template', JSON.stringify(data.template));
        history.push(appRoutes.mappingSummary(data.documentId));
        dispatch(mappingSlice.actions.createSuccess(response.data));
      })
      .catch((errors) => {
        const validators = path(['nonFieldErrors', 'validators'], errors);
        localStorage.setItem('template', JSON.stringify(data.template));
        if (validators) {
          dispatch(mappingSlice.actions.loadValidateMappingErrors(validators));
          history.push(appRoutes.validateMappingPath(data.documentId));
        } else {
          dispatch(mappingSlice.actions.createFail(errors));
        }
      })
      .finally(() => dispatch(mappingSlice.actions.createFinish()));
  };

  const create3DMapping = (data) => {
    dispatch(mappingSlice.actions.createStart());
    return Mapping3DRepository.create(data)
      .then((response) => {
        localStorage.setItem('template', JSON.stringify(data.template));
        history.push(appRoutes.mappingSummary(data.documentId));
        dispatch(mappingSlice.actions.createSuccess(response.data));
      })
      .catch((errors) => {
        const validators = path(['nonFieldErrors', 'validators'], errors);
        const uncorrectableErrors = getUncorrectableErrors(errors);
        localStorage.setItem('template', JSON.stringify(data.template));
        if (validators) {
          dispatch(mappingSlice.actions.loadValidateMappingErrors(validators));
          history.push(appRoutes.validateMappingPath(data.documentId));
        } else {
          dispatch(mappingSlice.actions.createFail(errors));
        }
        if (!isEmpty(uncorrectableErrors)) {
          dispatch(mappingSlice.actions.loadValidateMappingUncorrectableErrors(uncorrectableErrors));
        }
      })
      .finally(() => dispatch(mappingSlice.actions.createFinish()));
  };

  const createMultiSheetMapping = (data) => {
    dispatch(mappingSlice.actions.createStart());
    return MappingMultiSheetRepository.create(data)
      .then((response) => {
        localStorage.setItem('template', JSON.stringify(data.template));
        history.push(appRoutes.mappingSummary(data.documentId));
        dispatch(mappingSlice.actions.createSuccess(response.data));
      })
      .catch((errors) => {
        const validators = path(['nonFieldErrors', 'validators'], errors);
        localStorage.setItem('template', JSON.stringify(data.template));
        if (validators) {
          dispatch(mappingSlice.actions.loadValidateMappingErrors(validators));
        } else {
          dispatch(mappingSlice.actions.createFail(errors));
        }
      })
      .finally(() => dispatch(mappingSlice.actions.createFinish()));
  };

  const loadCurrentMapping = (documentId) => {
    dispatch(mappingSlice.actions.loadStart());
    return MappingRepository.current(documentId)
      .then((response) => dispatch(mappingSlice.actions.loadCurrentMappingSuccess(response.data)))
      .catch((errors) => dispatch(mappingSlice.actions.loadCurrentMappingFail(errors)))
      .finally(() => dispatch(mappingSlice.actions.loadFinish()));
  };

  const loadCurrent3DMapping = (documentId) => {
    dispatch(mappingSlice.actions.loadStart());
    return Mapping3DRepository.current(documentId)
      .then((response) => dispatch(mappingSlice.actions.loadCurrentMappingSuccess(response.data)))
      .catch((errors) => dispatch(mappingSlice.actions.loadCurrentMappingFail(errors)))
      .finally(() => dispatch(mappingSlice.actions.loadFinish()));
  };

  const loadCurrentMultiSheetMapping = (documentId) => {
    dispatch(mappingSlice.actions.loadStart());
    return MappingMultiSheetRepository.current(documentId)
      .then((response) => dispatch(mappingSlice.actions.loadCurrentMappingSuccess(response.data)))
      .catch((errors) => dispatch(mappingSlice.actions.loadCurrentMappingFail(errors)))
      .finally(() => dispatch(mappingSlice.actions.loadFinish()));
  };

  const loadResult = (documentId) => {
    dispatch(mappingSlice.actions.loadStart());
    return MappingRepository.result(documentId)
      .then((response) => dispatch(mappingSlice.actions.loadResultSuccess(response.data)))
      .catch((errors) => {
        if (errors.nonFieldErrors[0].includes('During processing')) {
          dispatch(mappingSlice.actions.loadResultSubmitted());
        } else {
          dispatch(mappingSlice.actions.loadResultFail(errors));
        }
      })
      .finally(() => dispatch(mappingSlice.actions.loadFinish()));
  };

  const load3DResult = (documentId) => {
    dispatch(mappingSlice.actions.loadStart());
    return Mapping3DRepository.result(documentId)
      .then((response) => dispatch(mappingSlice.actions.loadResultSuccess(response.data)))
      .catch((errors) => {
        if (errors.nonFieldErrors[0].includes('During processing')) {
          dispatch(mappingSlice.actions.loadResultSubmitted());
        } else {
          dispatch(mappingSlice.actions.loadResultFail(errors));
        }
      })
      .finally(() => dispatch(mappingSlice.actions.loadFinish()));
  };

  const loadMultiSheetResult = (documentId) => {
    dispatch(mappingSlice.actions.loadStart());
    return MappingMultiSheetRepository.result(documentId)
      .then((response) => dispatch(mappingSlice.actions.loadResultSuccess(response.data)))
      .catch((errors) => {
        if (errors.nonFieldErrors && errors.nonFieldErrors[0].includes('During processing')) {
          dispatch(mappingSlice.actions.loadResultSubmitted());
        } else {
          dispatch(mappingSlice.actions.loadResultFail(errors));
        }
      })
      .finally(() => dispatch(mappingSlice.actions.loadFinish()));
  };

  const actions = useActions({
    resetCurrentMapping,
    addMappedColumn,
    removeMappedColumn,
    resetMappedColumns,
    setIgnoredRows,
    addChangesMappingErrors,
    resetMappingErrors,
    resetCommonErrors,
    resetChanges,
    undoIgnoreRows,
    addMappedRow,
    removeMappedRow,
    resetMappedRows,
    resetIgnoreRows,
    resetAllIgnoreRows,
  });

  return {
    validateMapping,
    createMapping,
    loadCurrentMapping,
    loadResult,
    create3DMapping,
    loadCurrent3DMapping,
    load3DResult,
    validate3DMapping,
    createMultiSheetMapping,
    loadCurrentMultiSheetMapping,
    validateMultiSheetMapping,
    loadMultiSheetResult,
    ...actions,
  };
};
