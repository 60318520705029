import { createSlice } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import TemplatesRepository from 'repositories/TemplatesRepository';
import Templates2DRepository from 'repositories/Templates2DRepository';
import Templates3DRepository from 'repositories/Templates3DRepository';
import TemplatesMultiSheetRepository from 'repositories/TemplatesMultiSheetRepository';
import TemplatePresenter from 'presenters/TemplatePresenter';
import { useActions } from 'utils/appHooks';
import history from 'utils/history';
import { appRoutes } from 'routes';

const initialState = {
  loading: false,
  templates: null,
  templates2D: null,
  templates3D: null,
  meta: {},
  errors: {},
  currentTemplate: {},
  createLoading: false,
  isTemplateChanged: false,
};

const templatesSlice = createSlice({
  name: 'templates',
  initialState,
  reducers: {
    loadingStart(state) {
      state.loading = true;
    },
    loadingFinish(state) {
      state.loading = false;
    },
    loadTemplatesAllSuccess(state, { payload }) {
      state.templates = payload.items;
      state.meta = payload.meta;
      state.errors = {};
    },
    loadTemplates2DSuccess(state, { payload }) {
      state.templates2D = payload.items;
      state.meta = payload.meta;
      state.errors = {};
    },
    loadTemplatesFail(state, { payload }) {
      state.errors = payload;
    },
    loadTemplatesFinish(state) {
      state.loading = false;
    },
    updateTemplateStart(state) {
      state.loading = true;
    },
    updateTemplateFinish(state) {
      state.loading = false;
    },
    updateTemplateSuccess(state) {
      state.errors = {};
    },
    updateTemplateFail(state, { payload }) {
      state.errors = payload;
    },
    deleteTemplateStart(state) {
      state.loading = true;
    },
    deleteTemplateFinish(state) {
      state.loading = false;
    },
    deleteTemplateSuccess(state) {
      state.errors = {};
    },
    deleteTemplateFail(state, { payload }) {
      state.errors = payload;
      state.isErrorAlertShowing = true;
    },
    resetTemplates(state) {
      state.loading = initialState.loading;
      state.templates = initialState.templates;
      state.meta = initialState.meta;
      state.errors = initialState.errors;
      state.currentTemplate = initialState.currentTemplate;
      state.templates3D = initialState.templates3D;
      state.templates2D = initialState.templates2D;
    },
    createTemplateSuccess(state, { payload }) {
      state.currentTemplate = payload;
      state.errors = {};
    },
    createTemplateFail(state, { payload }) {
      state.errors = payload;
    },
    createTemplateStart(state) {
      state.createLoading = true;
    },
    createTemplateFinish(state) {
      state.createLoading = true;
    },
    resetCurrentTemplate(state) {
      state.currentTemplate = initialState.currentTemplate;
    },
    validateTemplateStart(state) {
      state.loading = true;
    },
    validateTemplateSuccess(state) {
      state.errors = {};
    },
    validateTemplateFail(state, { payload }) {
      state.errors = payload;
    },
    validateTemplateFinish(state) {
      state.loading = false;
    },
    loadTemplates3DSuccess(state, { payload }) {
      state.templates3D = payload.items;
      state.meta = payload.meta;
      state.errors = {};
    },
    adaptiveTemplateSuccess(state, { payload }) {
      state.currentTemplate = payload;
      state.errors = {};
    },
    adaptiveTemplateFail(state, { payload }) {
      state.errors = payload;
    },
    adaptiveTemplateStart(state) {
      state.createLoading = true;
    },
    adaptiveTemplateFinish(state) {
      state.createLoading = true;
    },
    changeTemplate(state, { payload }) {
      state.isTemplateChanged = payload;
    },
  },
});

export default templatesSlice.reducer;
export const { resetTemplates, resetCurrentTemplate, changeTemplate } = templatesSlice.actions;

export const useTemplatesActions = () => {
  const dispatch = useDispatch();

  const loadTemplates = (params) => {
    dispatch(templatesSlice.actions.loadingStart());
    return TemplatesRepository.index(params)
      .then((response) => dispatch(templatesSlice.actions.loadTemplatesAllSuccess(response)))
      .catch((errors) => dispatch(templatesSlice.actions.loadTemplatesFail(errors)))
      .finally(() => dispatch(templatesSlice.actions.loadingFinish()));
  };

  const load2DTemplates = (params) => {
    dispatch(templatesSlice.actions.loadingStart());
    return Templates2DRepository.index(params)
      .then((response) => dispatch(templatesSlice.actions.loadTemplates2DSuccess(response)))
      .catch((errors) => dispatch(templatesSlice.actions.loadTemplatesFail(errors)))
      .finally(() => dispatch(templatesSlice.actions.loadingFinish()));
  };

  const loadMultiSheetTemplates = (params) => {
    dispatch(templatesSlice.actions.loadingStart());
    return TemplatesMultiSheetRepository.index(params)
      .then((response) => dispatch(templatesSlice.actions.loadTemplatesAllSuccess(response)))
      .catch((errors) => dispatch(templatesSlice.actions.loadTemplatesFail(errors)))
      .finally(() => dispatch(templatesSlice.actions.loadingFinish()));
  };

  const create2DTemplate = (params) => {
    dispatch(templatesSlice.actions.createTemplateStart());
    return Templates2DRepository.create(params)
      .then((response) => dispatch(templatesSlice.actions.createTemplateSuccess(response.data)))
      .catch((errors) => dispatch(templatesSlice.actions.createTemplateFail(errors)))
      .finally(() => dispatch(templatesSlice.actions.createTemplateFinish()));
  };

  const createMultiSheetTemplate = (params) => {
    dispatch(templatesSlice.actions.createTemplateStart());
    return TemplatesMultiSheetRepository.create(params)
      .then((response) => dispatch(templatesSlice.actions.createTemplateSuccess(response.data)))
      .catch((errors) => dispatch(templatesSlice.actions.createTemplateFail(errors)))
      .finally(() => dispatch(templatesSlice.actions.createTemplateFinish()));
  };

  const updateTemplate = (templateId, params) => {
    dispatch(templatesSlice.actions.updateTemplateStart());
    return TemplatesRepository.update(templateId, params)
      .then((response) => dispatch(templatesSlice.actions.updateTemplateSuccess(response.data)))
      .catch((errors) => dispatch(templatesSlice.actions.updateTemplateFail(errors)))
      .finally(() => dispatch(templatesSlice.actions.updateTemplateFinish()));
  };

  const updateMultiSheetTemplate = (templateId, params) => {
    dispatch(templatesSlice.actions.updateTemplateStart());
    return TemplatesMultiSheetRepository.update(templateId, params)
      .then((response) => dispatch(templatesSlice.actions.updateTemplateSuccess(response.data)))
      .catch((errors) => dispatch(templatesSlice.actions.updateTemplateFail(errors)))
      .finally(() => dispatch(templatesSlice.actions.updateTemplateFinish()));
  };

  const deleteTemplate = (templateId) => {
    dispatch(templatesSlice.actions.deleteTemplateStart());
    return TemplatesRepository.delete(templateId)
      .then((response) => {
        dispatch(templatesSlice.actions.deleteTemplateSuccess(response.data));
        history.push(appRoutes.documentsTabPath('templates'));
      })
      .catch((errors) => dispatch(templatesSlice.actions.deleteTemplateFail(errors)))
      .finally(() => dispatch(templatesSlice.actions.deleteTemplateFinish()));
  };

  const deleteMultiSheetTemplate = (templateId) => {
    dispatch(templatesSlice.actions.deleteTemplateStart());
    return TemplatesMultiSheetRepository.delete(templateId)
      .then((response) => {
        dispatch(templatesSlice.actions.deleteTemplateSuccess(response.data));
        history.push(appRoutes.documentsTabPath('templates'));
      })
      .catch((errors) => dispatch(templatesSlice.actions.deleteTemplateFail(errors)))
      .finally(() => dispatch(templatesSlice.actions.deleteTemplateFinish()));
  };

  const validateTemplate = (params) => {
    dispatch(templatesSlice.actions.validateTemplateStart(params));
    return Templates2DRepository.validate(params)
      .then((response) => {
        dispatch(templatesSlice.actions.validateTemplateSuccess(response.data));

        const validateRoute = `${appRoutes.validateMappingPath(params.documentId)}?template=${TemplatePresenter.id(
          params.template,
        )}`;
        history.push(validateRoute);
      })
      .catch((errors) => dispatch(templatesSlice.actions.validateTemplateFail(errors)))
      .finally(() => dispatch(templatesSlice.actions.validateTemplateFinish()));
  };

  const load3DTemplates = (params) => {
    dispatch(templatesSlice.actions.loadingStart());
    return Templates3DRepository.index(params)
      .then((response) => dispatch(templatesSlice.actions.loadTemplates3DSuccess(response)))
      .catch((errors) => dispatch(templatesSlice.actions.loadTemplatesFail(errors)))
      .finally(() => dispatch(templatesSlice.actions.loadingFinish()));
  };

  const create3DTemplate = (params) => {
    dispatch(templatesSlice.actions.createTemplateStart());
    return Templates3DRepository.create(params)
      .then((response) => dispatch(templatesSlice.actions.createTemplateSuccess(response.data)))
      .catch((errors) => dispatch(templatesSlice.actions.createTemplateFail(errors)))
      .finally(() => dispatch(templatesSlice.actions.createTemplateFinish()));
  };

  const adaptive3DTemplate = (templateId, params) => {
    dispatch(templatesSlice.actions.adaptiveTemplateStart());
    return Templates3DRepository.adaptive(templateId, params)
      .then((response) => {
        const adaptiveTemplate = response.data;
        dispatch(templatesSlice.actions.adaptiveTemplateSuccess(adaptiveTemplate));
        localStorage.setItem('template', JSON.stringify(adaptiveTemplate));
      })
      .catch((errors) => dispatch(templatesSlice.actions.adaptiveTemplateFail(errors)))
      .finally(() => dispatch(templatesSlice.actions.adaptiveTemplateFinish()));
  };

  const adaptiveMultiSheetTemplate = (templateId, params) => {
    dispatch(templatesSlice.actions.adaptiveTemplateStart());
    return TemplatesMultiSheetRepository.adaptive(templateId, params)
      .then((response) => {
        const adaptiveTemplate = response.data;
        dispatch(templatesSlice.actions.adaptiveTemplateSuccess(adaptiveTemplate));
        localStorage.setItem('template', JSON.stringify(adaptiveTemplate));
      })
      .catch((errors) => dispatch(templatesSlice.actions.adaptiveTemplateFail(errors)))
      .finally(() => dispatch(templatesSlice.actions.adaptiveTemplateFinish()));
  };

  const actions = useActions({ resetTemplates, resetCurrentTemplate, changeTemplate });

  return {
    updateTemplate,
    loadTemplates,
    create2DTemplate,
    deleteTemplate,
    create3DTemplate,
    validateTemplate,
    load3DTemplates,
    load2DTemplates,
    adaptive3DTemplate,
    createMultiSheetTemplate,
    loadMultiSheetTemplates,
    updateMultiSheetTemplate,
    deleteMultiSheetTemplate,
    adaptiveMultiSheetTemplate,
    ...actions,
  };
};
