import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '@material-ui/core';

import useStyles from './useStyles';

const MappingToolbar = (props) => {
  const classes = useStyles();
  const { onContinueButtonClick, disabled } = props;

  return (
    <div className={classes.footer}>
      <p>When you’re done matching your data, press Continue to validate it.</p>
      <Button variant="contained" onClick={onContinueButtonClick} disabled={disabled}>
        Continue
      </Button>
    </div>
  );
};

MappingToolbar.propTypes = {
  onContinueButtonClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default MappingToolbar;
