import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(
  (theme) => ({
    linkIcon: {
      margin: [0, 5, 0, 0],
      '& path': {
        fill: theme.palette.text.secondary,
      },
    },
    stateText: {
      color: theme.palette.text.secondary,
    },
    dropdownInput: {
      margin: [5, 0],
      '& input[type="text"]': {
        border: [1, 'solid', theme.palette.default],
        width: '100%',
        padding: [4, 8],
      },
    },
    popper: {
      padding: 20,
      background: 'white',
      boxShadow: [0, 8, 24, theme.palette.shadow],
      marginTop: 5,
      borderRadius: 4,
      width: 100,
    },
    searchInput: {
      position: 'relative',
      '& svg': {
        position: 'absolute',
        right: 10,
        top: 5,
      },
    },
    whiteText: {
      color: theme.palette.text.white,
    },
    rowSelector: {
      margin: 0,
      padding: 0,
      '& input[type="text"]': {
        border: 'none',
        width: '100%',
        padding: [4, 8],
        margin: 0,
        minHeight: 20,
        height: '100%',
      },
    },
    mapped: {
      background: ['linear-gradient(0deg, #56BA78, #56BA78), #FFFFFF', '!important'],
      '& svg path': {
        fill: theme.palette.background.white,
      },
    },
  }),
  {
    name: 'MapSelector',
  },
);
