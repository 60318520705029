import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  field: {
    width: '100%',
  },
  marginBottomSm: {
    marginBottom: 12,
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  marginBottomMd: {
    marginBottom: 24,
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  error: {
    position: 'absolute',
    color: theme.palette.errors,
    fontSize: theme.typography.pxToRem(9),
    paddingLeft: 16,
    top: '100%',
  },
  uploadIcon: {
    width: 40,
    height: 40,
  },
  uploadField: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    height: 80,
    borderRadius: 4,
    border: [1.5, 'dashed', theme.palette.shadow],
    marginTop: 20,
  },
  uploadText: {
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 1.3,
    paddingLeft: 16,
    textAlign: 'left',
    width: 170,
    color: theme.palette.text.secondary,
  },
  input: {
    position: 'absolute',
    opacity: 0,
    width: '100%',
    height: '100%',
    top: 0,
  },
  uploadWrapper: {
    display: 'flex',
    alignItems: 'center',
    margin: [0, 'auto'],
  },
  excelIcon: {
    width: 24,
    height: 24,
  },
  uploadedFile: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 20,
  },
  uploadedFileName: {
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 1,
    paddingLeft: 8,
  },
  removeButton: {
    margin: 0,
  },
  removeButtonIcon: {
    width: 12,
    height: 12,
  },
}));
