import palette from 'layouts/CustomThemeProvider/palette';

export default {
  MuiDialogTitle: {
    root: {
      fontSize: 20,
      fontWeight: 600,
      fontStyle: 'normal',
      boxShadow: ['inset', 0, -1, 0, palette.shadow],
      color: palette.title,
      padding: [18, 32],
    },
  },
};
