import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  menu: {
    position: 'absolute',
    right: -8,
    top: 0,
  },
  icon: {
    fill: theme.palette.shadow,
    cursor: 'pointer',
    '&:first-child': {
      fontSize: theme.typography.pxToRem(22),
    },
  },
  list: {
    width: 180,
  },
  menuItem: {
    width: 180,
  },
  buttonAdd: {
    padding: 0,
    minWidth: 'auto',
    '&:hover': {
      backgroundColor: theme.palette.background.white,
    },
  },
}));
