import * as yup from 'yup';

export const validationFields = {
  name: yup.string().required('Name is required'),
};

export const validationSchema = yup.object().shape({ ...validationFields });

export const initialValues = {
  name: '',
};
