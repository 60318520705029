import React from 'react';
import { create } from 'jss';
import PropTypes from 'prop-types';
import { StylesProvider, ThemeProvider } from '@material-ui/styles';
import jssPresetDefault from 'jss-preset-default';
import GlobalStyles from 'layouts/GlobalStyles';
import theme from './theme';

const jss = create(jssPresetDefault());

const CustomThemeProvider = (props) => {
  const { children } = props;
  return (
    <StylesProvider jss={jss}>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        {children}
      </ThemeProvider>
    </StylesProvider>
  );
};

CustomThemeProvider.propTypes = {
  children: PropTypes.node,
};

CustomThemeProvider.defaultProps = {
  children: null,
};

export default CustomThemeProvider;
