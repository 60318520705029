import React, { Fragment, useEffect, useState, useContext, useRef } from 'react';
import { List, ListItem, Collapse, ClickAwayListener } from '@material-ui/core';
import PropTypes from 'prop-types';
import { isEmpty, isNil, without } from 'ramda';
import cn from 'clsx';
import arrayToTree from 'array-to-tree';
import { isArray } from 'utils/storeUtils';

import DatasetFieldPresenter from 'presenters/DatasetFieldPresenter';
import FullscreenMainBlockContext from 'contexts/FullscreenMainBlockContext';
import MapRowDropdownItem from 'containers/MapData/components/MapRowDropdownItem';
import Table3DContext from 'contexts/Table3DContext';

import useStyles from './useStyles';

const PADDING_OFFSET = 10;
const HEIGHT_OF_SHEETS_PANEL = 33;

const MapRowDropdownContent = (props) => {
  const { onMapField, fields, mapToIndex } = props;
  const [fieldsRowType, setFieldsRowType] = useState([]);
  const [openedTreeElements, setOpenedTreeElements] = useState([]);
  const [activeField, setActiveField] = useState(null);
  const activeFieldId = DatasetFieldPresenter.id(activeField);
  const fullscreenRef = useContext(FullscreenMainBlockContext);
  const tableRef = useContext(Table3DContext);
  const rowDropdownRef = useRef();

  const isRowType = (field) => DatasetFieldPresenter.mappingType(field) === 'row';

  const classes = useStyles();

  const fieldsAsTree = arrayToTree(fieldsRowType, { parentProperty: 'parentId' });
  const isOpenedTreeElement = (field) => openedTreeElements.includes(DatasetFieldPresenter.id(field));
  const hasChildren = ({ children }) => !isNil(children) && isArray(children);

  useEffect(() => {
    if (isNil(tableRef)) {
      return;
    }
    if (!isNil(activeField) && !hasChildren(activeField)) {
      tableRef.current.style.overflow = 'hidden';
      rowDropdownRef.current.style.overflow = 'hidden';
    } else {
      tableRef.current.style.overflow = 'scroll';
      rowDropdownRef.current.style.overflow = 'scroll';
    }
  }, [activeField]); // eslint-disable-line

  const handleListItemClick = (field) => {
    setActiveField(field);
    toggleTreeElement(field);
  };

  const toggleTreeElement = (field) => {
    const fieldId = DatasetFieldPresenter.id(field);
    const openedElementsList = isOpenedTreeElement(field)
      ? without([fieldId], openedTreeElements)
      : [...openedTreeElements, fieldId];
    setOpenedTreeElements(openedElementsList);
  };

  useEffect(() => {
    setFieldsRowType(fields.filter((field) => isRowType(field)));
  }, [fields]); // eslint-disable-line

  useEffect(() => {
    if (!isNil(rowDropdownRef) && !isNil(fullscreenRef)) {
      const calculatedDropdownMaxHeight = `${(fullscreenRef.current.offsetHeight - HEIGHT_OF_SHEETS_PANEL) / 2}px`;
      rowDropdownRef.current.style.maxHeight = calculatedDropdownMaxHeight;
    }
  }, [fullscreenRef]); // eslint-disable-line

  const handleMapField = (field) => {
    setActiveField(null);
    onMapField(field, false);
  };

  const renderTreeItem = (field, depthLevel = 0) => {
    const currentFieldId = DatasetFieldPresenter.id(field);
    const childrenDepthLevel = depthLevel + 1;
    const isOpenedElement = isOpenedTreeElement(field);
    const itemOffset = PADDING_OFFSET * depthLevel;
    const childrenExist = hasChildren(field);

    return (
      <Fragment key={currentFieldId}>
        <MapRowDropdownItem
          key={currentFieldId}
          onMapField={handleMapField}
          field={field}
          mapToIndex={mapToIndex}
          isActive={activeFieldId === currentFieldId}
          itemOffset={itemOffset}
          onClick={handleListItemClick}
          isOpenedElement={isOpenedElement}
          childrenExist={childrenExist}
        />
        {childrenExist && (
          <Collapse in={isOpenedElement}>
            <List className={classes.list}>
              {field.children.map((childrenField) => renderTreeItem(childrenField, childrenDepthLevel))}
            </List>
          </Collapse>
        )}
      </Fragment>
    );
  };

  const handleClosePopper = () => {
    setActiveField(null);
  };

  return (
    <div className={cn(classes.root, classes.rowDropdownContent)} ref={rowDropdownRef}>
      {isEmpty(fieldsRowType) ? (
        <div className={classes.hint}>no fields available</div>
      ) : (
        <ClickAwayListener onClickAway={handleClosePopper}>
          <List className={classes.list}>
            <ListItem className={cn(classes.listTitle, classes.rowDropdownTitle)}>Map to ...</ListItem>
            {fieldsAsTree.map((field) => renderTreeItem(field))}
          </List>
        </ClickAwayListener>
      )}
    </div>
  );
};

MapRowDropdownContent.propTypes = {
  onMapField: PropTypes.func,
  fields: PropTypes.arrayOf(DatasetFieldPresenter.shape()),
  mapToIndex: PropTypes.number.isRequired,
};

MapRowDropdownContent.defaultProps = {
  onMapField: () => {},
  fields: [],
};

export default MapRowDropdownContent;
