import PropTypes from 'prop-types';

import Presenter from 'utils/PropTypesPresenter';

export default new Presenter({
  lastModified: PropTypes.number,
  lastModifiedDate: PropTypes.instanceOf(Date),
  name: PropTypes.string,
  size: PropTypes.number,
  type: PropTypes.oneOf(['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']),
  webkitRelativePath: PropTypes.string,
});
