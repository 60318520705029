import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(
  (theme) => ({
    table: {
      height: '100%',

      '& table': {
        resize: 'both',
        overflow: 'auto',
      },
      '& th': {
        border: [1, 'solid', theme.palette.shadow],
        borderLeft: 'none',
        borderTop: 'none',
        fontSize: theme.typography.pxToRem(10),
        lineHeight: 1.6,
        textAlign: 'center',
        background: theme.palette.background.gray,
        color: theme.palette.text.lightDark,
        fontWeight: 'normal',
        padding: [2, 4],
        '&:first-child': {
          background: 'none',
        },
      },
      '& td': {
        position: 'relative',
        padding: 0,
        borderRight: [1, 'solid', theme.palette.shadow],
        height: 20,
        background: theme.palette.background.gray,
        '& div': {
          height: '100%',
          width: '100%',
        },
        '&:first-child': {
          width: 30,
          padding: 0,
          background: theme.palette.background.gray,
          '& div': {
            background: theme.palette.background.gray,
            width: '100%',
            height: '100%',
            padding: [2, 8, 2, 0],
            textAlign: 'right',
            fontSize: theme.typography.pxToRem(10),
            lineHeight: 1.4,
            color: theme.palette.text.lightDark,
            fontWeight: 'normal',
          },
        },
        '&:last-child': {
          paddingRight: 0,
        },
      },
    },
    header: {
      '& th': {
        background: theme.palette.green,
        color: theme.palette.text.white,
        textAlign: 'left',
        fontSize: theme.typography.pxToRem(12),
        lineHeight: 1.4,
        fontWeight: 600,
        '&:first-child': {
          background: 'none',
        },
      },
    },
    table3D: {
      '& th:nth-child(2)': {
        background: theme.palette.background.gray,
      },
      '& td:nth-child(2)': {
        background: theme.palette.green,
        color: theme.palette.text.white,
        padding: [2, 4],
        textAlign: 'left',
        fontSize: theme.typography.pxToRem(12),
        lineHeight: 1.4,
        fontWeight: 600,
      },
    },
    rowName: {
      minWidth: 200,
    },
  }),
  { name: 'MapDataTable' },
);
