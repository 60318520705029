import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  list: {
    width: 'auto',
    padding: [10, 16],
    color: theme.palette.text.white,
    fontSize: theme.typography.pxToRem(10),
    lineHeight: 1.5,
    textTransform: 'uppercase',
    background: 'linear-gradient(0deg, #73BFBA, #73BFBA), #FDFDFD',
    boxShadow: [0, 2, 12, theme.palette.colorShadow],
    borderRadius: 4,
    maxWidth: 300,
    minWidth: 150,
    zIndex: 1,
  },
  menuItem: {
    padding: 0,
    display: 'flex',
    marginTop: 12,
    fontWeight: 500,
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    '&:first-child': {
      marginTop: 0,
    },
  },
  icon: {
    width: 16,
    height: 16,
    fontSize: theme.typography.pxToRem(16),
  },
  iconList: {
    minWidth: 16,
    marginRight: 5,
  },
  tableCell: {
    border: 'none',
    background: theme.palette.background.white,
    '& textarea': {
      width: '100%',
      outline: 'none',
      lineHeight: 1.4,
      padding: [1, 4],
      border: [1, 'solid', theme.palette.transparent],
      overflow: 'hidden',
    },
  },
  resetButtonWrapper: {
    marginLeft: 0,
  },
  resetButtonIcon: {
    marginLeft: 0,
    width: 16,
    height: 16,
    fill: theme.palette.default,
    position: 'relative',
    top: 0,
  },
  resetButton: {
    margin: 0,
    width: 16,
    height: 16,
    padding: [1, 0],
    marginRight: 5,
  },
  error: {
    border: [1, 'solid', theme.palette.yellow],
    borderRadius: 0,
    minHeight: 20,
    height: 'auto',
    '& textarea': {
      background: 'linear-gradient(0deg, rgba(241, 195, 64, 0.1), rgba(241, 195, 64, 0.1)), #FFFFFF',
      border: [1, 'solid', theme.palette.transparent],
      padding: [0, 4],
      minHeight: ['calc(100% - 2px)', '!important'],
      minWidth: 'calc(100% - 10px)',
      '&:focus': {
        border: [1, 'solid', theme.palette.yellow],
        lineHeight: 1.4,
        background: theme.palette.background.white,
      },
    },
  },
  edited: {
    '& textarea': {
      background: 'linear-gradient(0deg, rgba(86, 186, 120, 0.1), rgba(86, 186, 120, 0.1)), #FFFFFF',
      border: [1, 'solid', theme.palette.green],
    },
  },
  noErrorValue: {
    padding: [2, 4],
    border: 'none',
    width: '100%',
    height: '100%',
    outline: 'none',
    lineHeight: 1.4,
  },
  resizedCell: {
    minWidth: '100%',
    minHeight: '100%',
    '& .react-resizable-handle-se': {
      width: 10,
      height: 10,
    },
  },
  resizedNotEditedCell: {
    height: ['auto', '!important'],
    lineHeight: 1.4,
    background: theme.palette.background.white,
  },
}));
