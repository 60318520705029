import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isNil } from 'ramda';
import { Dialog, DialogTitle, IconButton } from '@material-ui/core';
import { CloseOutlined as CloseOutlinedIcon } from '@material-ui/icons';
import { deleteFromStorage } from '@rehooks/local-storage';

import { useDocuments, useMapping, useTemplates, useUserDatasets, useUserFields } from 'slices/hooks';
import DocumentPresenter from 'presenters/DocumentPresenter';
import DatasetPresenter from 'presenters/DatasetPresenter';
import { LoadMappingForm, UploadFileForm } from './Forms';

const STATES = {
  createDocument: 'CREATE_DOCUMENT',
  loadMapping: 'LOAD_MAPPING',
};
const ImportFile = (props) => {
  const { onClose } = props;
  const { resetDatasets, resetInitialParameters } = useUserDatasets();
  const { loadMultiSheetTemplates, resetTemplates } = useTemplates();
  const { createDocument, createdDocument, resetUsedDocuments } = useDocuments();
  const { clearIsMappedField } = useUserFields();
  const { apiErrors, resetCommonErrors, resetCurrentMapping } = useMapping();
  const [stepUploading, changeStepUploading] = useState(STATES.createDocument);

  const currentDataset = DocumentPresenter.dataset(createdDocument);
  const currentDatasetId = DatasetPresenter.id(currentDataset);

  useEffect(() => {
    return () => {
      resetDatasets();
      resetTemplates();
      resetCommonErrors();
      resetUsedDocuments();
      resetInitialParameters();
    };
  }, []); // eslint-disable-line

  useEffect(() => {
    if (!isEmpty(createdDocument)) {
      changeStepUploading(STATES.loadMapping);
      loadMultiSheetTemplates({ datasetId: currentDatasetId });
    }
  }, [createdDocument]); // eslint-disable-line

  const handleUploadFile = (values) => {
    deleteFromStorage('template');
    return createDocument(values).then(() => {
      resetCurrentMapping();
      if (!isNil(currentDatasetId)) {
        clearIsMappedField({ datasetId: currentDatasetId });
      }
    });
  };

  const handleBack = () => {
    changeStepUploading(STATES.createDocument);
  };

  const renderStepsUpload = () => {
    switch (stepUploading) {
      case STATES.createDocument:
        return <UploadFileForm uploadFile={handleUploadFile} onClose={onClose} />;
      case STATES.loadMapping:
        return <LoadMappingForm onClose={onClose} backPreviousStep={handleBack} apiErrors={apiErrors} />;
      default:
        return onClose();
    }
  };

  return (
    <Dialog onClose={onClose} aria-label="import-document" open>
      <DialogTitle>Import File</DialogTitle>
      <IconButton color="inherit" onClick={onClose}>
        <CloseOutlinedIcon />
      </IconButton>
      {renderStepsUpload()}
    </Dialog>
  );
};

ImportFile.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default ImportFile;
