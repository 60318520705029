import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(
  (theme) => ({
    top: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: [0, 'auto'],
      maxWidth: 1360,
    },
    title: {
      color: theme.palette.title,
      fontWeight: 600,
      fontSize: theme.typography.pxToRem(34),
      margin: [32, 0],
    },
    fieldDetails: {
      position: 'relative',
      flexBasis: '30%',
      minWidth: 288,
      height: '100vh',
      padding: [0, 0, 32, 32],
      boxShadow: ['inset', 1, 0, 0, theme.palette.shadow],
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'stretch',
    },
    form: {
      marginTop: 23,
    },
    titleHead: {
      fontWeight: 500,
      fontSize: theme.typography.pxToRem(20),
      lineHeight: 1.2,
      color: theme.palette.title,
      marginRight: 32,
    },
    head: {
      display: 'flex',
      alignItems: 'center',
    },
    tableWrapper: {
      flexBasis: '80%',
      paddingRight: 40,
    },
    content: {
      position: 'relative',
      width: '100%',
    },
    container: {
      display: 'block',
      maxWidth: 1360,
      margin: [0, 'auto'],
      borderRadius: 8,
    },
    colorContainer: {
      background: theme.palette.background.white,
      padding: [24, 32],
      minHeight: '100vh',
      boxShadow: [0, 2, 18, theme.palette.shadowLight],
      borderRadius: 8,
      marginBottom: 60,
    },
    topControls: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: [16, 0, 24, 0],
    },
    addIcon: {
      '&:first-child': {
        fontSize: theme.typography.pxToRem(12),
      },
    },
    settingsIcon: {
      '&:first-child': {
        fontSize: theme.typography.pxToRem(14),
      },
    },
    buttonWrapper: {
      '& button:first-child': {
        marginRight: 34,
      },
    },
  }),
  { name: 'DatasetConstructor' },
);
