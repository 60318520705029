import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  actions: {
    height: 32,
    marginLeft: 13,
    zIndex: 3,
    '& button': {
      width: 32,
      height: 32,
      background: theme.palette.default,
      margin: 0,
      position: 'relative',
      borderRadius: 3,
      marginRight: 4,
      padding: 8,
      '& svg': {
        width: 16,
        height: 16,
      },
    },
  },
  popper: {
    marginLeft: 24,
  },
  itemRowDropdown: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    cursor: 'pointer',
    padding: [6, 20],
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 1.4,
    '&:last-child': {
      marginBottom: 10,
    },
    '& span': {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'clip',
      width: '100%',
    },
    '&:hover': {
      background: theme.palette.background.hover,
      '& span': {
        textOverflow: 'clip',
      },
    },
    '&:focus': {
      background: theme.palette.background.active,
      '& span': {
        color: theme.palette.text.white,
      },
      '&:hover': {
        background: theme.palette.background.active,
      },
    },
  },
  itemTitle: {
    color: theme.palette.text.dark,
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    '& svg': {
      marginRight: 5,
      width: 20,
      verticalAlign: 'middle',
      opacity: 0.5,
    },
  },
  active: {
    background: theme.palette.background.active,
    '& span': {
      color: theme.palette.text.white,
    },
    '&:hover': {
      background: theme.palette.background.active,
    },
    '& svg': {
      fill: theme.palette.text.white,
      opacity: 1,
    },
  },
  toggleWrap: {
    width: 20,
    marginRight: 5,
  },
  disabled: {
    background: [theme.palette.disabled, '!important'],
  },
}));
