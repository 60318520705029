import React from 'react';
import PropTypes from 'prop-types';
import { InputLabel, FormControl, Select as MUISelect, MenuItem, FormHelperText } from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';

import useStyles from './useStyles';

const Select = (props) => {
  const { label, name, options, value, onChange, placeholder, helperText, error } = props;
  const classes = useStyles();
  return (
    <FormControl className={classes.field} id={name}>
      <InputLabel shrink htmlFor={name}>
        {label}
      </InputLabel>
      <MUISelect
        value={value}
        IconComponent={ExpandMoreIcon}
        onChange={onChange}
        displayEmpty
        error={error}
        MenuProps={{
          disablePortal: true,
        }}
      >
        <MenuItem value="" disabled>
          {placeholder}
        </MenuItem>
        {options.map((option, index) => (
          <MenuItem value={option.value} key={index}>
            {option.label}
          </MenuItem>
        ))}
      </MUISelect>
      <FormHelperText className={classes.error}>{helperText}</FormHelperText>
    </FormControl>
  );
};

Select.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), label: PropTypes.string }),
  ).isRequired,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  helperText: PropTypes.string,
  error: PropTypes.bool,
};

Select.defaultProps = {
  label: '',
  value: '',
  onChange: null,
  placeholder: 'Select value',
  helperText: '',
  error: false,
};

export default Select;
