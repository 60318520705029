import React from 'react';
import PropTypes from 'prop-types';
import cn from 'clsx';
import { Input, InputLabel, FormControl, FormHelperText, InputAdornment, IconButton } from '@material-ui/core';
import { isNil } from 'ramda';

import Icon from 'components/Icon';
import useStyles from './useStyles';

const TextField = (props) => {
  const { label, placeholder, name, value, onChange, error, helperText, fieldClassName, type, endAdornment } = props;
  const classes = useStyles();
  const isPassword = type === 'password';

  const [showPassword, setValues] = React.useState(!isPassword);

  const handleClickShowPassword = () => {
    setValues(!showPassword);
  };

  const renderHelperText = () => [...helperText].map((item, index) => <span key={index}>{item}</span>);
  const renderEndAdornment = () => {
    if (!isNil(endAdornment)) {
      return endAdornment;
    }
    return (
      isPassword && (
        <InputAdornment position="end">
          <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword}>
            {showPassword ? (
              <Icon name="eyeOpen" className={classes.icon} />
            ) : (
              <Icon name="eyeClose" className={classes.icon} />
            )}
          </IconButton>
        </InputAdornment>
      )
    );
  };

  return (
    <FormControl className={cn(classes.field, classes[fieldClassName])}>
      {label && (
        <InputLabel shrink htmlFor={name}>
          {label}
        </InputLabel>
      )}
      <Input
        value={value}
        onChange={onChange}
        type={showPassword ? 'text' : 'password'}
        id={name}
        name={name}
        placeholder={placeholder}
        error={error}
        endAdornment={renderEndAdornment()}
      />
      <FormHelperText className={classes.error}>{renderHelperText()}</FormHelperText>
    </FormControl>
  );
};

TextField.propTypes = {
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.bool,
  onChange: PropTypes.func,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  label: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  fieldClassName: PropTypes.string,
  endAdornment: PropTypes.node,
};

TextField.defaultProps = {
  type: 'text',
  helperText: '',
  error: false,
  onChange: null,
  value: '',
  label: null,
  placeholder: '',
  fieldClassName: 'marginBottomMd',
  endAdornment: null,
};

export default TextField;
