import palette from 'layouts/CustomThemeProvider/palette';

export default {
  MuiPagination: {
    root: {
      display: 'inline-block',
    },
    ul: {
      '& li': {
        '&:first-child': {
          marginRight: 4,
          boxShadow: [0, 2, 12, palette.shadowLight],
          borderRadius: 3,
        },
        '&:last-child': {
          marginLeft: 4,
          boxShadow: [0, 2, 12, palette.shadowLight],
          borderRadius: 3,
        },
      },
    },
  },
};
