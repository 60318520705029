import React from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { head } from 'ramda';
import { Button, IconButton, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import { CloseOutlined as CloseOutlinedIcon } from '@material-ui/icons';

import { TextField } from 'components/Common';
import { validationSchema } from 'forms/CreateNewDatasetFieldForm';
import { handleErrors } from 'utils/errors';
import { useFields } from 'slices/hooks';
import { DEFAULT_FORMAT, is3DMapping, MAPPING_TYPES_OPTIONS } from 'helpers/fieldDetailsOptions';

const CreateNewDatasetField = (props) => {
  const { onClose, datasetId, onCreate, fileType } = props;

  const { isLoading, loadFields } = useFields();

  const handleChangeSettings = (values, { setErrors }) => {
    const params = {
      ...values,
      datasetId,
      nameInMapping: values.displayName,
      isRequired: false,
      format: DEFAULT_FORMAT,
      validators: {},
    };

    return onCreate(params)
      .then(() => loadFields({ datasetId }))
      .then(() => onClose())
      .catch((errors) => handleErrors(errors, setErrors));
  };

  const getInitialValues = () => {
    const initialValues = {
      displayName: '',
    };
    return is3DMapping(fileType) ? { ...initialValues, mappingType: head(MAPPING_TYPES_OPTIONS).value } : initialValues;
  };

  const { values, errors, setFieldValue, submitForm } = useFormik({
    initialValues: getInitialValues(),
    validationSchema,
    onSubmit: handleChangeSettings,
    validateOnChange: true,
  });

  const handleTextFieldChange = (field) => ({ target: { value } }) => {
    setFieldValue(field, value);
  };

  const handleSubmitForm = (e) => {
    e.preventDefault();
    submitForm(values);
  };

  return (
    <Dialog onClose={onClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" open>
      <DialogTitle>Create New Field</DialogTitle>
      <IconButton color="inherit" onClick={onClose}>
        <CloseOutlinedIcon />
      </IconButton>
      <form onSubmit={handleSubmitForm}>
        <DialogContent>
          <TextField
            value={values.displayName}
            onChange={handleTextFieldChange('displayName')}
            error={!!errors.displayName}
            name="displayName"
            placeholder="Input the Field Name"
            label="Dataset Field Name"
            helperText={errors.displayName}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="outlined">
            Cancel
          </Button>
          <Button disabled={isLoading} type="submit" variant="contained">
            Create
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

CreateNewDatasetField.propTypes = {
  onClose: PropTypes.func.isRequired,
  datasetId: PropTypes.number.isRequired,
  onCreate: PropTypes.func.isRequired,
  fileType: PropTypes.string.isRequired,
};

export default CreateNewDatasetField;
