import { snakeCaseToCapWords } from 'utils/stringUtils';
import { camelizeStr } from 'utils/keysConverter';
import { isNil, flatten } from 'ramda';

import DatasetFieldPresenter from 'presenters/DatasetFieldPresenter';

const INITIAL_DEPTH_LEVEL = 0;
const DEFAULT_FORMAT = 'plain_text';
const FILE_TYPE_3D = '3D';
const FILE_TYPE_2D = '2D';
const MAPPING_TYPES = {
  row: 'row',
  column: 'column',
};

const MAPPING_TYPES_OPTIONS = [
  {
    value: MAPPING_TYPES.column,
    label: 'Column',
  },
  {
    value: MAPPING_TYPES.row,
    label: 'Row',
  },
];

const FILE_TYPES_OPTIONS = [
  {
    value: '2D',
    label: '2D',
  },
  {
    value: '3D',
    label: '3D',
  },
];

const is3DMapping = (fileType) => fileType === FILE_TYPE_3D;

const getFieldFormatOptions = (formats, shouldContainNoFormat) => {
  const options = Object.keys(formats).map((key) => {
    return {
      value: formats[key],
      label: snakeCaseToCapWords(formats[key]),
    };
  });

  if (shouldContainNoFormat) {
    options.push({
      value: null,
      label: 'No Format',
    });
  }

  return options;
};

const getValidatorsOptions = (validators) => {
  return Object.keys(validators).map((key) => {
    return {
      value: camelizeStr(validators[key]),
      label: snakeCaseToCapWords(validators[key]),
    };
  });
};

const getFieldTreeOptions = (fieldsTree, currentFieldId, currentFieldMappingType) => {
  const fieldsList = getListFromTree(fieldsTree, currentFieldId);

  const optionValues = fieldsList.reduce((acc, field) => {
    if (DatasetFieldPresenter.mappingType(field) === currentFieldMappingType) {
      return [
        ...acc,
        {
          value: DatasetFieldPresenter.id(field),
          label: '- '.repeat(field.depthLevel) + DatasetFieldPresenter.displayName(field),
        },
      ];
    }
    return acc;
  }, []);

  return optionValues;
};

const fieldWithChildrenValues = (field, depthLevel, currentFieldId) => {
  if (DatasetFieldPresenter.id(field) === currentFieldId) {
    return [];
  }

  const fieldValue = { ...field, depthLevel };
  if (isNil(field.children)) {
    return [fieldValue];
  }

  const childrenValues = field.children.map((childrenField) =>
    fieldWithChildrenValues(childrenField, depthLevel + 1, currentFieldId),
  );
  return [fieldValue, ...childrenValues];
};

const getListFromTree = (fieldsTree, currentFieldId) => {
  const fieldList = fieldsTree.map((field) => fieldWithChildrenValues(field, INITIAL_DEPTH_LEVEL, currentFieldId));
  return flatten(fieldList);
};

export {
  DEFAULT_FORMAT,
  FILE_TYPE_3D,
  MAPPING_TYPES_OPTIONS,
  MAPPING_TYPES,
  getFieldFormatOptions,
  getValidatorsOptions,
  is3DMapping,
  getFieldTreeOptions,
  getListFromTree,
  FILE_TYPES_OPTIONS,
  FILE_TYPE_2D,
};
