import React, { useState } from 'react';
import { Button, Menu, MenuItem } from '@material-ui/core';
import { MoreHoriz as MoreHorizIcon } from '@material-ui/icons';
import { EditTemplate } from 'components/Dialogs';

import useStyles from './useStyles';
import { useTemplates } from 'slices/hooks';
import { handleErrors } from 'utils/errors';
import CurrentTemplatePresenter from 'presenters/CurrentTemplatePresenter';

const ActionsCell = (props) => {
  const { row } = props;
  const { updateMultiSheetTemplate, deleteMultiSheetTemplate, loadMultiSheetTemplates } = useTemplates();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isEditTemplateDialogOpen, setEditTemplateDialogOpen] = useState(false);

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditTemplateDialogOpen = () => {
    setEditTemplateDialogOpen(true);
    setAnchorEl(null);
  };

  const handleEditTemplateDialogClose = () => {
    setEditTemplateDialogOpen(false);
  };

  const handleDeleteTemplate = (id) => () => {
    setAnchorEl(null);
    setEditTemplateDialogOpen(false);
    deleteMultiSheetTemplate(id).then(() => loadMultiSheetTemplates());
  };

  const handleEditTemplate = (values, { setErrors }) => {
    return updateMultiSheetTemplate(row.id, values)
      .then(() => loadMultiSheetTemplates())
      .then(() => handleEditTemplateDialogClose())
      .catch((errors) => handleErrors(errors, setErrors));
  };

  return (
    <>
      <Button aria-controls="dropdown-menu" aria-haspopup="true" className={classes.actionsCell} onClick={handleClick}>
        ACTIONS
        <MoreHorizIcon className={classes.icon} />
      </Button>
      <Menu id={row.id} anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={() => handleClose()}>
        <MenuItem key="rename" onClick={handleEditTemplateDialogOpen}>
          Rename
        </MenuItem>
        <MenuItem key="delete" onClick={handleDeleteTemplate(row.id)}>
          Delete template
        </MenuItem>
      </Menu>
      {isEditTemplateDialogOpen && (
        <EditTemplate currentTemplate={row} onSubmit={handleEditTemplate} onClose={handleEditTemplateDialogClose} />
      )}
    </>
  );
};

ActionsCell.propTypes = {
  row: CurrentTemplatePresenter.shape().isRequired,
};

export default ActionsCell;
