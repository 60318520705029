import { isEmpty, path, findIndex, propEq, toLower } from 'ramda';

import MappingPresenter from 'presenters/MappingPresenter';
import MappingErrorPresenter from 'presenters/MappingErrorPresenter';
import Mapping3DRowPresenter from 'presenters/Mapping3DRowPresenter';

const prepareRows = (validators, errorTypes) => {
  if (isEmpty(validators)) {
    return [];
  }

  const rowsByType =
    !isEmpty(errorTypes) &&
    errorTypes.reduce((acc, type) => {
      return { ...acc, [type]: [] };
    }, []);

  MappingPresenter.errors(validators).map((error) => {
    const typeName = MappingErrorPresenter.type(error);
    const rowIndex = MappingErrorPresenter.row(error);
    const errorIndex = findIndex(propEq('id', rowIndex))(rowsByType[typeName]);

    const currentError = {
      errorField: path(['fieldName'], error),
      errorState: MappingErrorPresenter.state(error),
      tooltipMessages: MappingErrorPresenter.messages(error),
    };

    if (errorIndex === -1) {
      rowsByType[typeName] = [
        ...rowsByType[typeName],
        {
          id: rowIndex,
          errors: [currentError],
        },
      ];
    } else {
      rowsByType[typeName][errorIndex] = {
        ...rowsByType[typeName][errorIndex],
        errors: [...rowsByType[typeName][errorIndex].errors, currentError],
      };
    }
    return false;
  });
  return rowsByType;
};

const updateErrors = (row, cell) => {
  const { nameInMapping, displayName } = cell;
  return row.errors.map((error) => {
    if (error.errorField === nameInMapping) {
      return { ...error, errorField: toLower(displayName), nameInMapping };
    }
    return error;
  });
};

const prepareRows2D = (validators, errorTypes) => {
  const preparedRows = prepareRows(validators, errorTypes);
  return Object.keys(preparedRows).reduce((acc, type) => {
    return {
      ...acc,
      [type]: preparedRows[type].map((row) => {
        const rowValues = MappingPresenter.rows(validators)[row.id].reduce((accumulator, cell) => {
          row.errors = updateErrors(row, cell); // eslint-disable-line no-param-reassign
          return { ...accumulator, [cell.displayName]: cell.value };
        }, {});
        return { ...row, values: { ...rowValues } };
      }),
    };
  }, []);
};

const prepareRows3D = (validators, errorTypes) => {
  const preparedRows = prepareRows(validators, errorTypes);
  return Object.keys(preparedRows).reduce((acc, type) => {
    return {
      ...acc,
      [type]: preparedRows[type].map((row) => {
        row.rowDisplayName = MappingPresenter.rows(validators)[row.id].displayName; // eslint-disable-line no-param-reassign
        const currentRow = MappingPresenter.rows(validators)[row.id];
        const currentRowColumns = Mapping3DRowPresenter.columns(currentRow);
        const rowValues = currentRowColumns.reduce((accumulator, cell) => {
          row.errors = updateErrors(row, cell); // eslint-disable-line no-param-reassign
          return { ...accumulator, [cell.displayName]: cell.value };
        }, {});
        return { ...row, values: { ...rowValues } };
      }),
    };
  }, []);
};

export { prepareRows2D, prepareRows3D };
