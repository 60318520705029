import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { pluck } from 'ramda';

import { useDocumentsActions } from 'slices/DocumentsSlice';

export const useDocuments = () => {
  const {
    loadDocuments,
    resetDocuments,
    loadCurrentDocument,
    loadDocumentSheets,
    createDocument,
    deleteDocument,
    updateDocument,
    resetUsedDocuments,
  } = useDocumentsActions();
  const isLoading = useSelector((state) => state.DocumentsSlice.loading);
  const apiErrors = useSelector((state) => state.DocumentsSlice.errors);
  const documents = useSelector((state) => state.DocumentsSlice.documents);
  const meta = useSelector((state) => state.DocumentsSlice.meta);
  const currentDocument = useSelector((state) => state.DocumentsSlice.currentDocument);
  const sheets = useSelector((state) => state.DocumentsSlice.sheets);
  const createdDocument = useSelector((state) => state.DocumentsSlice.createdDocument);

  const getSheets = (state) => state.DocumentsSlice.sheets;

  const getSheetTitlesList = createSelector(getSheets, (sheetList) => pluck('title', sheetList));

  const sheetTitles = useSelector(getSheetTitlesList);

  return {
    loadDocuments,
    createDocument,
    loadCurrentDocument,
    currentDocument,
    loadDocumentSheets,
    sheets,
    resetDocuments,
    isLoading,
    apiErrors,
    documents,
    meta,
    createdDocument,
    deleteDocument,
    sheetTitles,
    updateDocument,
    resetUsedDocuments,
  };
};
