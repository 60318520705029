import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  panel: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: [8, 40],
    background: theme.palette.background.default,
    boxShadow: ['inset', 0, 1, 0, theme.palette.shadow],
    zIndex: 10,
  },
  message: {
    color: theme.palette.text.lightDark,
    fontSize: theme.typography.pxToRem(12),
  },
  buttonWrapper: {
    '& > button:last-child': {
      marginLeft: 8,
    },
  },
}));
