import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  bodyMessage: {
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.text.white,
  },
  body: {
    width: 190,
    display: 'flex',
    justifyContent: 'space-between',
  },
  iconWrapper: {
    display: 'flex',
    padding: [7, 0],

    '& svg': {
      width: 28,
      height: 28,
    },
  },
  root: {
    padding: [8, 24, 8, 24],
    borderRadius: 8,
    bottom: 70,
    right: 60,
  },
  message: {
    paddingLeft: 0,
  },
  icon: {
    '& svg': {
      fontSize: 32,
      fill: theme.palette.text.white,
    },
  },
  filledSuccess: {
    backgroundColor: theme.palette.text.success,
  },
}));
