import humps from 'humps';

import { isObject, isFile, isArray } from './storeUtils';

export const camelizeStr = (str) => humps.camelize(str);

export const camelize = (obj) => humps.camelizeKeys(obj);

export const decamelize = (obj) => {
  if (isArray(obj)) {
    return obj.map((item) => decamelize(item));
  }
  if (isObject(obj) && !isFile(obj)) {
    return Object.keys(obj).reduce((acc, next) => {
      const decamelizedKey = humps.decamelize(next.replace(/[0-9]/g, '_$&'));
      return {
        ...acc,
        [decamelizedKey]: decamelize(obj[next]),
      };
    }, {});
  }
  return obj;
};
