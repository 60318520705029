import PropTypes from 'prop-types';

import Presenter from 'utils/PropTypesPresenter';
import Mapping3DRowPresenter from './Mapping3DRowPresenter';
import Mapping2DRowPresenter from './Mapping2DRowPresenter';
import MappingErrorPresenter from './MappingErrorPresenter';

export default new Presenter({
  errors: PropTypes.arrayOf(MappingErrorPresenter.shape()),
  rows: PropTypes.arrayOf(PropTypes.oneOf([Mapping2DRowPresenter.shape(), Mapping3DRowPresenter.shape()])),
});
