import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { MenuItem, Menu, Button } from '@material-ui/core';
import { MoreVertSharp as MoreVertSharpIcon } from '@material-ui/icons';

import DatasetFieldPresenter from 'presenters/DatasetFieldPresenter';
import { useFields } from 'slices/hooks';
import { isBlank } from 'utils/conditions';

import useStyles from './useStyles';

const DetailsMenu = (props) => {
  const { label, current, datasetId } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const { createField, loadFields } = useFields();

  const handleDuplicate = () => {
    const data = {
      datasetId,
      displayName: DatasetFieldPresenter.displayName(current),
      nameInMapping: `${DatasetFieldPresenter.nameInMapping(current)} (copy)`,
      isRequired: DatasetFieldPresenter.isRequired(current),
      format: DatasetFieldPresenter.format(current),
      validators: DatasetFieldPresenter.validators(current),
    };
    createField(data).then(() => loadFields({ datasetId }));
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.menu}>
      <Button
        disabled={isBlank(current)}
        className={classes.buttonAdd}
        aria-controls="dropdown-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertSharpIcon className={classes.icon} />
      </Button>
      <Menu
        className={classes.list}
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem className={classes.menuItem} value={label} onClick={handleDuplicate}>
          {label}
        </MenuItem>
      </Menu>
    </div>
  );
};

DetailsMenu.propTypes = {
  label: PropTypes.string,
  current: DatasetFieldPresenter.shape(),
  datasetId: PropTypes.number.isRequired,
};

DetailsMenu.defaultProps = {
  label: 'Duplicate',
  current: {},
};

export default DetailsMenu;
