import React, { useEffect, useState } from 'react';
import { find, isEmpty, propEq, uniq } from 'ramda';
import { Button, Tab, Tabs } from '@material-ui/core';
import {
  CheckCircleOutlineRounded as CheckCircleOutlineRoundedIcon,
  ErrorOutline as ErrorOutlineIcon,
} from '@material-ui/icons';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import cn from 'clsx';
import { useParams } from 'react-router';
import { pluralizeCells, pluralizeRows } from 'utils/pluralize';
import { useLocalStorage } from '@rehooks/local-storage';

import Breadcrumbs from 'components/Breadcrumbs';
import { useDocuments, useMapping, useUserDatasets } from 'slices/hooks';
import { appRoutes } from 'routes';
import DocumentPresenter from 'presenters/DocumentPresenter';
import MappingPresenter from 'presenters/MappingPresenter';
import MappingErrorPresenter from 'presenters/MappingErrorPresenter';
import Icon from 'components/Icon';
import TableChangePresenter from 'presenters/TableChangePresenter';
import { getTabProps } from 'utils/tabsUtils';
import { snakeCaseToCapWords } from 'utils/stringUtils';
import TabsPresenter from 'presenters/TabsPresenter';
import Preloader from 'components/Preloader';
import { ErrorAlert } from 'components/Alerts';
import SuccessMappingAlert from './components/SuccessMappingAlert';
import MappingSteps from 'components/MappingSteps';
import { getSingleDatasetMappingSteps } from 'components/MappingSteps/steps';
import { prepareRows2D, prepareRows3D } from 'helpers/prepareValidateData';
import { is3DMapping } from 'helpers/fieldDetailsOptions';
import DatasetPresenter from 'presenters/DatasetPresenter';
import { isBlank } from 'utils/conditions';
import { MapDataTable2D, MapDataTable3D, TabPanel, UncorrectableErrorScreen } from './components';

import useStyles from './useStyles';

const ERROR_STATES = {
  fixed: 'fixed',
  new: 'new',
  skip: 'skip',
};

const MapValidate = () => {
  const {
    validators,
    validateMapping,
    createMapping,
    addChangesMappingErrors,
    changes,
    isLoading,
    apiErrors,
    resetCommonErrors,
    validate3DMapping,
    create3DMapping,
    uncorrectableErrors,
  } = useMapping();
  const { loadCurrentDataset, currentDataset, resetCurrentDataset } = useUserDatasets();
  const fullScreenHandle = useFullScreenHandle();
  const [currentTabIndex, setCurrentTab] = useState(0);
  const { loadCurrentDocument, currentDocument } = useDocuments();
  const { documentId } = useParams();
  const [isErrorShowing, setErrorShowing] = useState(false);
  const [template] = useLocalStorage('template');

  const classes = useStyles();

  const fileType = DatasetPresenter.fileType(currentDataset);
  const is3DMappingType = is3DMapping(fileType);
  const currentDocumentId = DocumentPresenter.id(currentDocument);

  useEffect(() => {
    loadCurrentDocument(documentId);
    if (isBlank(currentDataset)) {
      const currentDatasetId = template.datasetId;
      loadCurrentDataset(currentDatasetId);
    }
    return resetCurrentDataset;
  }, []); // eslint-disable-line

  useEffect(() => {
    if (!isBlank(currentDataset) && !isBlank(template)) {
      const data = {
        documentId,
        template,
      };
      is3DMappingType ? validate3DMapping(data) : validateMapping(data);
    }
  }, [currentDataset]); // eslint-disable-line

  useEffect(() => {
    if (!isEmpty(apiErrors)) {
      setErrorShowing(true);
    }
  }, [apiErrors]); // eslint-disable-line

  const handleChangeTab = (event, newIndex) => {
    setCurrentTab(newIndex);
  };

  const errorTypes =
    !isEmpty(validators) && uniq(MappingPresenter.errors(validators).map((error) => MappingErrorPresenter.type(error)));

  const preparedRowsWithErrors = is3DMappingType
    ? prepareRows3D(validators, errorTypes)
    : prepareRows2D(validators, errorTypes);

  const TABS =
    !isEmpty(validators) &&
    errorTypes.map((type, index) => {
      return {
        id: index,
        name: type,
      };
    });

  const getResolvedIssueCount = () => {
    return errorTypes.reduce((acc, type) => {
      const allErrors = preparedRowsWithErrors[type].reduce((errorsArray, row) => {
        return [...errorsArray, ...row.errors];
      }, []);
      return allErrors.every((error) => error.errorState === ERROR_STATES.fixed) ? acc + 1 : acc;
    }, 0);
  };

  const handleContinue = () => {
    const data = {
      documentId,
      template,
      changes,
    };
    is3DMappingType ? create3DMapping(data) : createMapping(data);
  };

  const handleValidateMapping = (cell) => {
    const createChange = () => {
      switch (TableChangePresenter.type(cell)) {
        case 'change':
          return {
            row: TableChangePresenter.index(cell),
            type: TableChangePresenter.type(cell),
            values: [
              { nameInMapping: TableChangePresenter.nameInMapping(cell), value: TableChangePresenter.value(cell) },
            ],
          };
        case 'skip':
          return { row: TableChangePresenter.index(cell), type: TableChangePresenter.type(cell) };
        default:
          return {};
      }
    };
    const createNewChanges = () => {
      let newChanges = [];
      const currentChange = createChange();

      if (!isEmpty(changes)) {
        const currentFix = find(propEq('row', Number(TableChangePresenter.index(cell))))(changes);
        if (currentFix) {
          const newFixes = changes.filter((fix) => fix.row !== TableChangePresenter.index(cell));
          const editedFix = {
            ...currentFix,
            values: [
              ...currentFix.values,
              { nameInMapping: TableChangePresenter.nameInMapping(cell), value: TableChangePresenter.value(cell) },
            ],
          };
          newChanges = [...newFixes, editedFix];
        } else {
          newChanges = [...changes, currentChange];
        }
      } else {
        newChanges = [currentChange];
      }
      return newChanges;
    };
    const allNewChanges = createNewChanges();
    addChangesMappingErrors(allNewChanges);
  };

  const handleSendChanges = (changesArray) => () => {
    const data = {
      documentId,
      template,
      changes: changesArray,
    };
    is3DMappingType ? validate3DMapping(data) : validateMapping(data);
    addChangesMappingErrors(changesArray);
  };

  const isAllErrorsResolved = () => getResolvedIssueCount() === errorTypes.length;

  const renderTab = (tab) => {
    const allErrors = preparedRowsWithErrors[tab.name].reduce((acc, row) => {
      return [...acc, ...row.errors];
    }, []);
    const isUnresolved = allErrors.some((error) => error.errorState === ERROR_STATES.new);
    const isResolved = allErrors.filter((error) => error.errorState === ERROR_STATES.fixed);
    const isSkipped = allErrors.filter((error) => error.errorState === ERROR_STATES.skip);

    const resolvedInfo = !isEmpty(isResolved) ? `${pluralizeCells(isResolved.length)} edited` : '';
    const skippedInfo = !isEmpty(isSkipped) ? `, ${pluralizeRows(isSkipped.length)} ignored` : '';

    const renderTabLabel = () => {
      return (
        <div className={classes.tabLabel}>
          <div className={classes.name}>{snakeCaseToCapWords(TabsPresenter.name(tab))}</div>
          <div className={classes.state}>{isUnresolved ? 'Unresolved' : `${resolvedInfo}${skippedInfo}`}</div>
        </div>
      );
    };
    return (
      <Tab
        {...getTabProps(tab)}
        icon={
          isUnresolved ? (
            <ErrorOutlineIcon className={classes.errorIcon} />
          ) : (
            <CheckCircleOutlineRoundedIcon className={classes.successIcon} />
          )
        }
        label={renderTabLabel(tab)}
      />
    );
  };

  const renderTabsOfErrors = () => (
    <div
      className={cn({ [classes.mainFullScreen]: fullScreenHandle.active, [classes.main]: !fullScreenHandle.active })}
    >
      <Tabs value={currentTabIndex} className={classes.tabs} onChange={handleChangeTab}>
        {TABS.map((tab) => renderTab(tab))}
      </Tabs>
      {TABS.map((tab) => (
        <TabPanel value={currentTabIndex} index={tab.id} key={tab.id} className={classes.panelTable}>
          <div className={classes.controlsPanel}>
            <div className={classes.controls}>
              <div className={classes.typeOfErrors}>
                <div className={classes.typeName}>{snakeCaseToCapWords(tab.name)}</div>
                <div className={classes.typeMessage}>Click on the cell to edit.</div>
              </div>
              <Button
                type="button"
                disabled={isEmpty(changes)}
                variant="contained"
                className={classes.resolveButton}
                onClick={handleSendChanges(changes)}
              >
                Resolve
              </Button>
              {!isEmpty(changes) && (
                <Button
                  type="button"
                  variant="outlined"
                  className={classes.revertButton}
                  onClick={handleSendChanges([])}
                >
                  Revert changes
                </Button>
              )}
            </div>
            {is3DMappingType ? (
              <MapDataTable3D
                tableRows={preparedRowsWithErrors[tab.name]}
                type={tab.name}
                onEdit={handleValidateMapping}
                errors={MappingPresenter.errors(validators)}
              />
            ) : (
              <MapDataTable2D
                tableRows={preparedRowsWithErrors[tab.name]}
                type={tab.name}
                onEdit={handleValidateMapping}
                errors={MappingPresenter.errors(validators)}
              />
            )}
          </div>
        </TabPanel>
      ))}
    </div>
  );

  const singleDatasetMappingSteps = getSingleDatasetMappingSteps(currentDocumentId);

  return (
    <div className={classes.top}>
      <Breadcrumbs
        breadcrumbs={[
          { link: appRoutes.documentsPath(), name: 'Import Tool' },
          { name: DocumentPresenter.fileName(currentDocument), link: '' },
        ]}
      />
      {!isEmpty(validators) &&
        (!isEmpty(uncorrectableErrors) ? (
          <UncorrectableErrorScreen documentId={currentDocumentId} errors={uncorrectableErrors} />
        ) : (
          <>
            <div className={classes.wrapper}>
              <FullScreen handle={fullScreenHandle}>
                <div className={classes.head}>
                  <MappingSteps steps={singleDatasetMappingSteps} activeStep={3} />
                  <button
                    type="button"
                    tabIndex="0"
                    className={classes.fullscreenToggle}
                    onClick={fullScreenHandle.active ? fullScreenHandle.exit : fullScreenHandle.enter}
                  >
                    <Icon name="fullscreen" /> Toggle Fullscreen
                  </button>
                </div>
                {isLoading ? <Preloader /> : renderTabsOfErrors()}
                {fullScreenHandle.active && (
                  <div className={classes.footer}>
                    <p>When you’re done matching your data, press Continue to validate it.</p>
                    <Button
                      type="button"
                      variant="contained"
                      disabled={!isAllErrorsResolved()}
                      onClick={handleContinue}
                    >
                      Continue
                    </Button>
                  </div>
                )}
              </FullScreen>
            </div>
            <div className={classes.footer}>
              <p>{`Issues Resolved: ${getResolvedIssueCount()}/${errorTypes.length}`}</p>
              {isAllErrorsResolved() && <SuccessMappingAlert severity="success" />}
              <Button
                variant="contained"
                disabled={getResolvedIssueCount() !== errorTypes.length}
                onClick={handleContinue}
              >
                Continue
              </Button>
            </div>
          </>
        ))}
      <ErrorAlert onClose={resetCommonErrors} isShowing={isErrorShowing} errors={apiErrors} />
    </div>
  );
};

export default MapValidate;
