import { Link } from 'react-router-dom';

import TabsPresenter from 'presenters/TabsPresenter';

const getTabLinkProps = (tab) => {
  return {
    label: TabsPresenter.name(tab),
    id: `simple-tab-${TabsPresenter.id(tab)}`,
    'aria-controls': `simple-tabpanel-${TabsPresenter.id(tab)}`,
    key: TabsPresenter.id(tab),
    component: Link,
    to: TabsPresenter.hash(tab),
  };
};

const getTabProps = (tab, currentTabIndex, schemaId) => {
  const dataValue = `${schemaId}-error-${TabsPresenter.id(tab)}`;
  const isTabSelected = currentTabIndex === dataValue;
  return {
    id: `simple-tab-${TabsPresenter.id(tab)}`,
    'aria-controls': `simple-tabpanel-${TabsPresenter.id(tab)}`,
    key: TabsPresenter.id(tab),
    'data-value': dataValue,
    selected: isTabSelected,
  };
};

export { getTabLinkProps, getTabProps };
