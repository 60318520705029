import React from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton } from '@material-ui/core';
import { CloseOutlined as CloseOutlinedIcon } from '@material-ui/icons';
import { isNil, isEmpty } from 'ramda';

import { TextField } from 'components/Common';
import { useDatasets } from 'slices/hooks';
import { validationSchema, initialValues } from 'forms/UpdateDatasetForm';
import { handleErrors } from 'utils/errors';
import DatasetPresenter from 'presenters/DatasetPresenter';

const DatasetSettings = (props) => {
  const { onClose } = props;
  const { updateDataset, isLoading, currentDataset, loadCurrentDataset, apiErrors } = useDatasets();

  const handleChangeSettings = (values, { setErrors }) => {
    const currentDatasetId = DatasetPresenter.id(currentDataset);
    return updateDataset(currentDatasetId, values)
      .then(() => {
        loadCurrentDataset(currentDatasetId);
        onClose();
      })
      .catch((errors) => handleErrors(errors, setErrors));
  };

  const { values, errors, setFieldValue, submitForm, touched } = useFormik({
    initialValues: isEmpty(currentDataset) ? initialValues : currentDataset,
    validationSchema,
    onSubmit: handleChangeSettings,
    validateOnChange: true,
  });

  const handleTextFieldChange = (field) => ({ target: { value } }) => {
    setFieldValue(field, value);
  };

  const handleSubmitForm = (e) => {
    e.preventDefault();
    submitForm(values);
  };

  const allErrors = { ...errors, ...apiErrors };

  const getTextErrorsField = (name) => (touched[name] && !isNil(allErrors[name]) ? allErrors[name] : '');
  const isErrorsExist = (name) => touched[name] && !!allErrors[name];

  return (
    <Dialog onClose={onClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" open>
      <DialogTitle>Dataset Settings</DialogTitle>
      <IconButton color="inherit" onClick={onClose}>
        <CloseOutlinedIcon />
      </IconButton>
      <form onSubmit={handleSubmitForm}>
        <DialogContent>
          <TextField
            value={values.name}
            onChange={handleTextFieldChange('name')}
            error={!!errors.name}
            name="name"
            placeholder="Data Set Name"
            label="Name"
            helperText={errors.name}
          />
          <TextField
            value={values.validationUrl}
            onChange={handleTextFieldChange('validationUrl')}
            error={isErrorsExist('validationUrl')}
            name="validationUrl"
            placeholder="http://"
            label="Validation URL"
            helperText={getTextErrorsField('validationUrl')}
          />
          <TextField
            value={values.endpointUrl}
            onChange={handleTextFieldChange('endpointUrl')}
            error={isErrorsExist('endpointUrl')}
            name="endpointUrl"
            placeholder="http://"
            label="Import endpoint"
            helperText={getTextErrorsField('endpointUrl')}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="outlined">
            Cancel
          </Button>
          <Button type="submit" disabled={isLoading} variant="contained">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

DatasetSettings.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default DatasetSettings;
