import React, { useEffect, useState } from 'react';

import FilesTable from './components/FilesTable';
import Pagination from 'components/Pagination';
import Preloader from 'components/Preloader';
import { useDocuments } from 'slices/hooks/useDocuments';
import { COLUMNS_DOCS } from 'helpers/documents';
import { INITIAL_LOADING_PARAMS, getLoadingParams } from 'utils/loadingParams';
import MetaPresenter from 'presenters/MetaPresenter';
import DocumentPresenter from 'presenters/DocumentPresenter';

const FilesPanel = () => {
  const { loadDocuments, resetDocuments, documents, meta, isLoading } = useDocuments();
  const [documentsPageNumber, setDocumentsPageNumber] = useState(1);

  useEffect(() => {
    loadDocuments(INITIAL_LOADING_PARAMS);
    return resetDocuments;
  }, []); // eslint-disable-line

  const handleChangePage = (event, pageNumber) => {
    setDocumentsPageNumber(pageNumber);
    loadDocuments(getLoadingParams(pageNumber));
  };

  const rows = DocumentPresenter.documentsRows(documents);

  return (
    <>
      {isLoading && <Preloader type="table" />}
      <FilesTable
        rows={rows}
        columns={COLUMNS_DOCS}
        title="Uploaded Files"
        onSearch={loadDocuments}
        documentsPageNumber={documentsPageNumber}
      />
      <Pagination totalPages={MetaPresenter.totalPages(meta)} onChange={handleChangePage} />
    </>
  );
};

export default FilesPanel;
