import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Box, Grid } from '@material-ui/core';
import { CheckCircle as CheckCircleIcon } from '@material-ui/icons';

import SheetSchemaPresenter from 'presenters/SheetSchemaPresenter';

import useStyles from './useStyles';

const SheetSchemaName = ({ sheetSchema, isActive }) => {
  const classes = useStyles();

  return (
    <Grid container spacing={1} alignItems="center" justify="space-between">
      <Grid item className={classes.name}>
        {SheetSchemaPresenter.name(sheetSchema)}
      </Grid>
      <Grid item>
        <Box mt={0.3}>
          <CheckCircleIcon className={clsx(classes.icon, { [classes.active]: isActive })} />
        </Box>
      </Grid>
    </Grid>
  );
};

SheetSchemaName.propTypes = {
  sheetSchema: SheetSchemaPresenter.shape().isRequired,
  isActive: PropTypes.bool.isRequired,
};

export default SheetSchemaName;
