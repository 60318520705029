import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as ArrowDown } from 'assets/icons/arrow_down.svg';
import { ReactComponent as ArrowLeft } from 'assets/icons/arrow_left.svg';
import { ReactComponent as ArrowsSort } from 'assets/icons/arrows_sort.svg';
import { ReactComponent as CheckboxChecked } from 'assets/icons/checkbox_checked.svg';
import { ReactComponent as CheckboxEmpty } from 'assets/icons/checkbox_empty.svg';
import { ReactComponent as CheckMark } from 'assets/icons/check_mark.svg';
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg';
import { ReactComponent as CrossOutlineIcon } from 'assets/icons/cross_outline.svg';
import { ReactComponent as Excel } from 'assets/icons/excel.svg';
import { ReactComponent as Exit } from 'assets/icons/exit.svg';
import { ReactComponent as EyeClose } from 'assets/icons/eye_close.svg';
import { ReactComponent as EyeOpen } from 'assets/icons/eye_open.svg';
import { ReactComponent as Fullscreen } from 'assets/icons/fullscreen.svg';
import { ReactComponent as Home } from 'assets/icons/home.svg';
import { ReactComponent as Link } from 'assets/icons/link.svg';
import { ReactComponent as Noavatar } from 'assets/icons/noavatar.svg';
import { ReactComponent as RightDownArrow } from 'assets/icons/right_down_arrow.svg';
import { ReactComponent as TickIcon } from 'assets/icons/tick.svg';
import { ReactComponent as TickEmpty } from 'assets/icons/tick_empty.svg';
import { ReactComponent as TickFull } from 'assets/icons/tick_full.svg';
import { ReactComponent as TickFullShadow } from 'assets/icons/tick_full_shadow.svg';
import { ReactComponent as Trash } from 'assets/icons/trash.svg';
import { ReactComponent as Uploading } from 'assets/icons/uploading.svg';

const Icon = (props) => {
  const { name, className } = props;
  const svgIcons = {
    arrowDown: <ArrowDown className={className} />,
    arrowLeft: <ArrowLeft className={className} />,
    arrowSort: <ArrowsSort className={className} />,
    checkboxChecked: <CheckboxChecked className={className} />,
    checkboxEmpty: <CheckboxEmpty className={className} />,
    checkMark: <CheckMark className={className} />,
    cross: <CrossIcon className={className} />,
    crossOutline: <CrossOutlineIcon className={className} />,
    excel: <Excel className={className} />,
    exit: <Exit className={className} />,
    eyeClose: <EyeClose className={className} />,
    eyeOpen: <EyeOpen className={className} />,
    fullscreen: <Fullscreen className={className} />,
    home: <Home className={className} />,
    link: <Link className={className} />,
    noAvatar: <Noavatar className={className} />,
    rightDownArrow: <RightDownArrow className={className} />,
    tickEmpty: <TickEmpty className={className} />,
    tickFull: <TickFull className={className} />,
    tickFullShadow: <TickFullShadow className={className} />,
    trash: <Trash className={className} />,
    tick: <TickIcon className={className} />,
    uploading: <Uploading className={className} />,
  };

  return <>{svgIcons[name]}</>;
};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
};

Icon.defaultProps = {
  className: '',
};

export default Icon;
