import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { head, propEq, find } from 'ramda';
import { DialogContent, DialogActions, Button } from '@material-ui/core';
import { useCookies } from 'react-cookie';

import { InputFile, Select } from 'components/Common';
import Preloader from 'components/Preloader';
import { useUserDatasets, useMapping } from 'slices/hooks';
import { validationSchema, initialValues } from 'forms/UploadFileForm';
import { handleErrors } from 'utils/errors';
import DatasetPresenter from 'presenters/DatasetPresenter';

const MAX_COUNT_OF_DATASETS = 50;

const UploadFileForm = (props) => {
  const { onClose, uploadFile } = props;
  const { loadDatasets, datasets, isLoading } = useUserDatasets();
  const { resetCurrentMapping } = useMapping();
  const [cookies, setCookie, removeCookie] = useCookies(['dataset_name']);  // eslint-disable-line

  useEffect(() => {
    loadDatasets({ perPage: MAX_COUNT_OF_DATASETS });
    resetCurrentMapping();
  }, []); // eslint-disable-line

  const handleSubmit = (values, { setErrors }) => {
    uploadFile(values)
      .then(() => removeCookie('dataset_name'))
      .catch((errors) => handleErrors(errors, setErrors));
  };

  const getInitialValues = () => {
    const datasetName = cookies.dataset_name;
    if (datasetName) {
      const selectedDataset = find(propEq('name', datasetName))(datasets);
      const selectedDatasetId = DatasetPresenter.id(selectedDataset);
      return { ...initialValues, datasetId: String(selectedDatasetId) };
    }
    return initialValues;
  };

  const initialValuesDocument = getInitialValues();

  const { values, errors, setFieldValue, submitForm, touched } = useFormik({
    initialValues: initialValuesDocument,
    validationSchema,
    onSubmit: handleSubmit,
    validateOnChange: true,
    enableReinitialize: true,
  });

  const handleFieldChange = (field) => ({ target: { value } }) => {
    setFieldValue(field, value);
  };

  const handleInputFileChange = (field) => (event) => {
    setFieldValue(field, head(event.currentTarget.files));
  };

  const handleInputFileClear = (field) => () => {
    setFieldValue(field, null);
  };

  return (
    <form>
      <DialogContent>
        {isLoading ? (
          <Preloader type="inner" />
        ) : (
          <>
            <InputFile
              file={values.file}
              label="Choose a File"
              name="file"
              onChange={handleInputFileChange('file')}
              onRemove={handleInputFileClear('file')}
              helperText={touched.file && errors.file}
              error={touched.file && !!errors.file}
              accept=".xlsx, .xls, .xlsm"
            />
            <Select
              value={values.datasetId}
              label="Dataset"
              name="datasetId"
              placeholder="Select the Data Set"
              onChange={handleFieldChange('datasetId')}
              options={DatasetPresenter.options(datasets)}
              helperText={touched.datasetId && errors.datasetId}
              error={touched.datasetId && !!errors.datasetId}
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="text">
          Cancel
        </Button>
        <Button disabled={isLoading} onClick={submitForm} variant="contained" data-test="import" autoFocus>
          Import
        </Button>
      </DialogActions>
    </form>
  );
};

UploadFileForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  uploadFile: PropTypes.func.isRequired,
};

export default UploadFileForm;
