import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    boxShadow: ['inset', 1, 0, 0, theme.palette.shadow],
    width: 300,
    overflow: 'auto',
    background: theme.palette.background.white,
    position: 'relative',
    zIndex: 3,
    marginBottom: 32,
    borderBottom: [1, 'solid', theme.palette.shadow],
    borderRight: [1, 'solid', theme.palette.shadow],
    '& h3': {
      fontSize: theme.typography.pxToRem(18),
      color: theme.palette.title,
      fontWeight: 500,
      margin: 0,
    },
  },
  head: {
    padding: [15, 20],
    width: '100%',
    height: 80,
    borderBottom: [1, 'solid', theme.palette.background.shadow],
    '& p': {
      fontSize: theme.typography.pxToRem(12),
      color: theme.palette.text.dark,
    },
  },
  item: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  itemTitle: {
    color: theme.palette.text.dark,
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10,
    width: '100%',
    '& svg': {
      marginRight: 5,
    },
    '& span': {
      width: '100%',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  },
  itemStateText: {
    color: theme.palette.text.dark,
    fontSize: theme.typography.pxToRem(12),
    opacity: 0.4,
    '& svg': {
      marginRight: 5,
      marginLeft: 20,
      '& path': {
        fill: theme.palette.text.dark,
      },
    },
  },
  title: {
    padding: [16, 20],
    color: theme.palette.text.dark,
    fontSize: theme.typography.pxToRem(12),
    opacity: 0.4,
  },
  subTitle: {
    padding: [0, 16],
    color: theme.palette.text.subTitle,
    fontSize: theme.typography.pxToRem(12),
  },
}));
