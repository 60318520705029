import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  InputAdornment,
} from '@material-ui/core';
import { CloseOutlined as CloseOutlinedIcon, DragIndicator as DragIndicatorIcon } from '@material-ui/icons';
import { ReactSortable } from 'react-sortablejs';
import uniqid from 'uniqid';
import { pluck, isEmpty, toLower, map, includes, omit } from 'ramda';

import { TextField } from 'components/Common';
import { initialValues, validationSchema } from 'forms/ManageValuesListForm';

import useStyles from './useStyles';

const ManageValuesList = (props) => {
  const { onClose, onSubmit, options } = props;
  const [valuesList, setValuesList] = useState([]);

  const classes = useStyles();

  useEffect(() => {
    const valuesArray = options.map((item) => {
      return { id: uniqid('value-'), name: item };
    });
    setValuesList(valuesArray);
  }, [options]); // eslint-disable-line

  const { values, errors, setFieldValue, setErrors } = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
  });

  const handleTextFieldChange = (field) => ({ target: { value } }) => {
    setFieldValue(field, value);
  };

  const handleSaveValidators = () => {
    const valuesArray = pluck('name', valuesList);
    onSubmit(valuesArray);
  };

  const isElementExistsInValuesList = () => {
    const nameValue = toLower(values.name);
    const nameList = map(toLower, pluck('name', valuesList));
    return includes(nameValue, nameList);
  };

  const handleAddValue = (e) => {
    e.preventDefault();
    if (isElementExistsInValuesList()) {
      setErrors({ ...errors, name: 'New value should be unique' });
      return;
    }

    setErrors(omit(['name'], errors));
    setValuesList([...valuesList, { id: uniqid('value-'), name: values.name }]);
    setFieldValue('name', '');
  };

  const handleRemoveValue = (id) => {
    const valuesArray = valuesList.filter((value) => value.id !== id);
    setValuesList(valuesArray);
  };

  const renderSortableValuesList = () => (
    <div className={classes.sortableWrapper}>
      <ReactSortable list={valuesList} setList={setValuesList}>
        {valuesList.map((item) => (
          <div className={classes.sortableItemWrapper} key={item.id}>
            <div className={classes.sortableItem}>
              <DragIndicatorIcon className={classes.dragIcon} />
              <span className={classes.sortableItemName}>{item.name}</span>
              <IconButton className={classes.removeIcon} color="inherit" onClick={() => handleRemoveValue(item.id)}>
                <CloseOutlinedIcon />
              </IconButton>
            </div>
          </div>
        ))}
      </ReactSortable>
    </div>
  );

  const renderAddValueForm = () => (
    <form onSubmit={handleAddValue}>
      <TextField
        value={values.name}
        onChange={handleTextFieldChange('name')}
        error={!!errors.name}
        name="name"
        placeholder="Value Name"
        label="Value Name"
        helperText={errors.name}
        endAdornment={
          <InputAdornment position="end">
            <Button variant="text" type="submit" aria-label="add value" disabled={isEmpty(values.name)}>
              Add
            </Button>
          </InputAdornment>
        }
      />
    </form>
  );

  return (
    <Dialog onClose={onClose} open>
      <DialogTitle>Manage Value List</DialogTitle>
      <IconButton color="inherit" onClick={onClose}>
        <CloseOutlinedIcon />
      </IconButton>
      <DialogContent>
        {renderAddValueForm()}
        {renderSortableValuesList()}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="text">
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSaveValidators}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ManageValuesList.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.string),
};

ManageValuesList.defaultProps = {
  options: [],
};

export default ManageValuesList;
