import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogContent, DialogActions } from '@material-ui/core';

import useStyles from 'components/Dialogs/useStyles';

const TemplateLoadingProblems = (props) => {
  const { details } = props;
  const [open, setOpen] = useState(true);

  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };

  const handleReload = () => {
    setOpen(false);
    window.location.reload();
  };

  const renderDetailErrors = () => (
    <div className={classes.details}>
      Details:
      {Object.keys(details).map((item, i) => (
        <div key={i}>{details[item]}</div>
      ))}
    </div>
  );

  return (
    <Dialog
      onClose={handleClose}
      aria-label="alertDialogLoading"
      aria-describedby="alert-dialog-description"
      open={open}
      classes={{ root: classes.root, container: classes.container }}
      BackdropProps={{ classes: { root: classes.backdropRoot } }}
      container={document.getElementsByClassName('fullscreen')[0]}
    >
      <DialogContent>
        <div className={classes.errorText}>
          There was a problem loading the template. Please contact our team to learn why templates are not working at
          the moment. While we are fixing the problem, you may map the file manually.
          {renderDetailErrors()}
        </div>
      </DialogContent>
      <DialogActions classes={{ root: classes.rootActions }}>
        <Button onClick={handleClose} variant="outlined">
          Close
        </Button>
        <Button onClick={handleReload} type="submit" variant="contained">
          Reload
        </Button>
      </DialogActions>
    </Dialog>
  );
};

TemplateLoadingProblems.propTypes = {
  details: PropTypes.shape().isRequired,
};

TemplateLoadingProblems.defaultProps = {};

export default TemplateLoadingProblems;
