import { createSlice } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { uniq, isEmpty, adjust, without, isNil, findIndex, propEq } from 'ramda';

import DocumentFieldsRepository from 'repositories/DocumentFieldsRepository';
import { useActions } from 'utils/appHooks';

const initialState = {
  loading: false,
  fields: {},
  meta: {},
  errors: {},
};

const fieldsSlice = createSlice({
  name: 'userFields',
  initialState,
  reducers: {
    setFields(state, { payload }) {
      state.fields = payload;
    },
    loadFieldsFail(state, { payload }) {
      state.errors = payload;
    },
    loadingStart(state) {
      state.loading = true;
    },
    loadingFinish(state) {
      state.loading = false;
    },
    resetFields(state) {
      state.loading = initialState.loading;
      state.fields = initialState.fields;
      state.meta = initialState.meta;
      state.errors = initialState.errors;
    },
    setIsMappedColumnField(state, { payload }) {
      const { datasetId } = payload;

      state.fields[datasetId] = state.fields[datasetId].map((field) => {
        return field.id === payload.field.id
          ? { ...field, isMapped: payload.isMapped, columnIndex: payload.index, mappedTo: payload.name }
          : field;
      });
    },
    clearIsMappedField(state, { payload: datasetId }) {
      if (!isEmpty(state.fields[datasetId])) {
        state.fields[datasetId] = state.fields[datasetId].map((field) => {
          return { ...field, isMapped: false };
        });
      }
    },
    setIsMappedRowField(state, { payload }) {
      const { datasetId } = payload;

      const updatedFields = state.fields[datasetId].map((field) => {
        return { ...field, rowIndexes: isNil(field.rowIndexes) ? [] : field.rowIndexes };
      });

      const mapField = (field) => {
        const rowIndexes = uniq([...field.rowIndexes, payload.index]);
        return {
          ...field,
          isMapped: payload.isMapped,
          rowIndexes,
          mappedTo: payload.name,
        };
      };
      const fieldIndex = findIndex(propEq('id', payload.field.id))(updatedFields);

      state.fields[datasetId] = adjust(fieldIndex, mapField, updatedFields);
    },
    setUnMappedRowField(state, { payload }) {
      const { datasetId } = payload;

      const unMapField = (field) => {
        const rowIndexes = without([payload.index], field.rowIndexes);
        return { ...field, isMapped: !isEmpty(rowIndexes), rowIndexes, mappedTo: payload.name };
      };
      const fieldIndex = findIndex(propEq('id', payload.field.id))(state.fields[datasetId]);

      state.fields[datasetId] = adjust(fieldIndex, unMapField, state.fields[datasetId]);
    },
  },
});

export default fieldsSlice.reducer;
export const {
  resetFields,
  setIsMappedColumnField,
  clearIsMappedField,
  setIsMappedRowField,
  setUnMappedRowField,
  setFields,
} = fieldsSlice.actions;

export const useFieldsActions = () => {
  const dispatch = useDispatch();

  const loadFields = (documentId, datasetId) => {
    dispatch(fieldsSlice.actions.loadingStart());
    return DocumentFieldsRepository.index(documentId)
      .then((response) => dispatch(fieldsSlice.actions.setFields({ [datasetId]: response.items })))
      .catch((errors) => dispatch(fieldsSlice.actions.loadFieldsFail(errors)))
      .finally(() => dispatch(fieldsSlice.actions.loadingFinish()));
  };

  const actions = useActions({
    resetFields,
    setIsMappedColumnField,
    clearIsMappedField,
    setIsMappedRowField,
    setUnMappedRowField,
    setFields,
  });

  return {
    loadFields,
    ...actions,
  };
};
