import React from 'react';
import PropTypes from 'prop-types';
import { Table as MUITable, TableBody, TableCell, TableHead, TableRow, Paper, TableContainer } from '@material-ui/core';

import { InputSearch } from 'components/Common';
import ActionsCell from './components/ActionsCell';
import { isBlank } from 'utils/conditions';

import useStyles from './useStyles';

const SLICED_CELLS_COUNT = 3;

const FilesTable = (props) => {
  const { rows, columns, tableCellClassName, title, onSearch, documentsPageNumber } = props;
  const classes = useStyles();

  const renderRow = (row) => (
    <>
      {
        <TableCell className={classes[tableCellClassName]} align="left">
          <a className={classes.fileUrl} href={row.fileUrl} rel="noopener noreferrer" target="_blank">
            {row.fileName}
          </a>
        </TableCell>
      }
      {Object.keys(row)
        .slice(SLICED_CELLS_COUNT)
        .map((item, index) => (
          <TableCell
            key={index}
            className={classes[tableCellClassName]}
            align="left"
            dangerouslySetInnerHTML={{ __html: row[item] }}
          />
        ))}
      <TableCell className={classes[tableCellClassName]} align="right">
        <ActionsCell row={row} documentsPageNumber={documentsPageNumber} />
      </TableCell>
    </>
  );

  return (
    <TableContainer component={Paper} elevation={3}>
      <MUITable className={classes.table} aria-label="table">
        <TableHead>
          <TableRow className={classes.header}>
            <TableCell className={classes[tableCellClassName]} colSpan={6}>
              <div className={classes.headerInner}>
                <div className={classes.title}>{title}</div>
                <InputSearch onSearch={onSearch} />
              </div>
            </TableCell>
          </TableRow>
          <TableRow>
            {!isBlank(rows) &&
              columns.map((column, index) => (
                <TableCell key={`th-${index}`} align="left">
                  {column}
                </TableCell>
              ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={index} className={classes.actionsRow}>
              {renderRow(row)}
            </TableRow>
          ))}
        </TableBody>
      </MUITable>
    </TableContainer>
  );
};

FilesTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.object),
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
  tableCellClassName: PropTypes.string,
  title: PropTypes.string,
  onSearch: PropTypes.func.isRequired,
  documentsPageNumber: PropTypes.number,
};

FilesTable.defaultProps = {
  documentsPageNumber: 1,
  rows: [],
  tableCellClassName: 'datasets',
  title: 'Import Datasets',
};

export default FilesTable;
