import { appRoutes } from 'routes';

export const getSingleDatasetMappingSteps = (documentId) => [
  {
    id: 1,
    link: appRoutes.initialParametersPath(documentId),
    name: 'Initial Parameters',
  },
  {
    id: 2,
    link: appRoutes.mappingPath(documentId),
    name: 'Map Data',
  },
  {
    id: 3,
    link: appRoutes.validateMappingPath(documentId),
    name: 'Validation',
  },
  {
    id: 4,
    link: appRoutes.mappingSummary(documentId),
    name: 'Summary',
  },
];

export const getMultipleDatasetsMappingSteps = (documentId) => [
  {
    id: 2,
    link: appRoutes.multiSheetMappingPath(documentId),
    name: 'Map Data',
  },
  {
    id: 3,
    link: appRoutes.multiSheetValidatePath(documentId),
    name: 'Validation',
  },
];
