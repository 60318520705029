import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    height: '100vh',
  },
  authForm: {
    position: 'relative',
    width: 360,
    margin: 'auto',
    padding: [32, 40],
    boxShadow: [0, 2, 18, theme.palette.shadowLight],
    borderRadius: 8,
    textAlign: 'center',
    backgroundColor: theme.palette.background.white,
  },
  title: {
    color: theme.palette.title,
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 1.3,
    margin: [0, 0, 16, 0],
  },
  logo: {
    position: 'absolute',
    width: 124,
    top: -96,
    left: '50%',
    marginLeft: -62,
  },
  loginButton: {
    width: '100%',
  },
  input: {
    marginBottom: 12,
    '&:last-child': {
      marginBottom: 32,
    },
  },
}));
