import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  field: {
    width: '100%',
  },
  marginBottomSm: {
    marginBottom: 12,
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  marginBottomMd: {
    marginBottom: 24,
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  error: {
    position: 'absolute',
    color: theme.palette.errors,
    fontSize: theme.typography.pxToRem(9),
    paddingLeft: 16,
    top: '100%',
  },
  icon: {
    width: 24,
  },
}));
