import palette from 'layouts/CustomThemeProvider/palette';

export default {
  MuiBreadcrumbs: {
    root: {
      color: palette.title,
    },
    li: {
      fontSize: 14,
      lineHeight: 1.4,
    },
  },
};
