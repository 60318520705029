import { apiRoutes } from 'routes';
import FetchHelpers from 'utils/FetchHelpers';

export default {
  index(params) {
    const path = apiRoutes.templatesPath();
    return FetchHelpers.get(path, params);
  },
  update(templateId, params) {
    const path = apiRoutes.templatePath(templateId);
    return FetchHelpers.patch(path, params);
  },
  delete(templateId) {
    const path = apiRoutes.templatePath(templateId);
    return FetchHelpers.delete(path);
  },
};
