import PropTypes from 'prop-types';
import Presenter from 'utils/PropTypesPresenter';

export default new Presenter(
  {
    email: PropTypes.bool,
    maxLength: PropTypes.number,
    minLength: PropTypes.number,
    specificLength: PropTypes.number,
    regularExpression: PropTypes.string,
  },
  {
    isContainKey(validators, key) {
      return Object.keys(validators).includes(key);
    },
  },
);
