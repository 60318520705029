import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isNil } from 'ramda';

import { TextField } from 'components/Common';
import { createInitialParametersWithValues } from 'utils/initialParametersUtils';

import useStyles from './useStyles';

const ParametersList = (props) => {
  const [newInitialParameters, setNewInitialParameters] = useState([]);
  const { initialParameters, onChange, apiErrors } = props;

  const classes = useStyles();

  useEffect(() => {
    setNewInitialParameters(initialParameters);
  }, [initialParameters]);

  const handleFieldChange = (field, index) => ({ target: { value } }) => {
    const data = {
      initialParameters: newInitialParameters,
      index,
      field,
      value,
    };
    const newValues = createInitialParametersWithValues(data);
    setNewInitialParameters(newValues);
    onChange(newValues);
  };

  const getTextErrorsField = (name, index) =>
    !isEmpty(apiErrors) && !isNil(apiErrors[index][name]) ? apiErrors[index][name].join(',') : '';
  const isExistErrors = (name, index) => !isEmpty(apiErrors) && !!apiErrors[index][name];

  return (
    <div className={classes.formWrapper}>
      {newInitialParameters.map((parameter, index) => {
        return (
          <TextField
            key={index}
            value={parameter.value}
            onChange={handleFieldChange('value', index)}
            error={isExistErrors('value', index)}
            name={parameter.name}
            label={parameter.displayName}
            helperText={getTextErrorsField('value', index)}
          />
        );
      })}
    </div>
  );
};

ParametersList.propTypes = {
  initialParameters: PropTypes.arrayOf(PropTypes.shape()),
  onChange: PropTypes.func.isRequired,
  apiErrors: PropTypes.arrayOf(PropTypes.shape()),
};

ParametersList.defaultProps = {
  apiErrors: [],
  initialParameters: [],
};

export default ParametersList;
