import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  item: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    cursor: 'pointer',
    padding: [0],
    fontSize: theme.typography.pxToRem(12),
    '&:hover': {
      background: theme.palette.background.hover,
      '& span': {
        textOverflow: 'clip',
      },
    },
    '& span': {
      position: 'relative',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      width: '100%',
      padding: [8, 8, 8, 0],
    },
  },
  itemTitle: {
    color: theme.palette.text.dark,
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    '& svg': {
      marginRight: 5,
      width: 40,
      minWidth: 40,
    },
  },
  popper: {
    marginLeft: 45,
  },
}));
