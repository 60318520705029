import React from 'react';
import PropTypes from 'prop-types';
import { Table as MUITable, TableBody, TableCell, TableHead, TableRow, Paper, TableContainer } from '@material-ui/core';

import { InputSearch } from 'components/Common';
import ActionsCell from './components/ActionsCell';
import useStyles from './useStyles';
import CurrentTemplatePresenter from 'presenters/CurrentTemplatePresenter';
import { isBlank } from 'utils/conditions';

const TemplatesTable = (props) => {
  const { rows, columns, tableCellClassName, title, onSearch } = props;
  const classes = useStyles();

  const renderRow = (row) => (
    <>
      {Object.keys(row)
        .slice(1)
        .map((item, index) => (
          <TableCell
            key={index}
            className={classes[tableCellClassName]}
            align="left"
            dangerouslySetInnerHTML={{ __html: row[item] }}
          />
        ))}

      <TableCell className={classes[tableCellClassName]} align="right">
        <ActionsCell row={row} />
      </TableCell>
    </>
  );

  return (
    <TableContainer component={Paper} elevation={3}>
      <MUITable className={classes.table} aria-label="table">
        <TableHead>
          <TableRow className={classes.header}>
            <TableCell className={classes[tableCellClassName]} colSpan={5}>
              <div className={classes.headerInner}>
                <div className={classes.title}>{title}</div>
                <InputSearch onSearch={onSearch} />
              </div>
            </TableCell>
          </TableRow>
          <TableRow>
            {!isBlank(rows) &&
              columns.map((column, index) => (
                <TableCell key={`th-${index}`} align="left">
                  {column}
                </TableCell>
              ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={index} className={classes.actionsRow}>
              {renderRow(row)}
            </TableRow>
          ))}
        </TableBody>
      </MUITable>
    </TableContainer>
  );
};

TemplatesTable.propTypes = {
  rows: PropTypes.arrayOf(CurrentTemplatePresenter.shape()),
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
  tableCellClassName: PropTypes.string,
  title: PropTypes.string,
  onSearch: PropTypes.func.isRequired,
};

TemplatesTable.defaultProps = {
  rows: [],
  tableCellClassName: 'templates',
  title: 'Templates',
};

export default TemplatesTable;
