import { apiRoutes } from 'routes';
import FetchHelpers from 'utils/FetchHelpers';

export default {
  index(params) {
    const path = apiRoutes.datasetsPath();
    return FetchHelpers.get(path, params);
  },
  current(datasetId) {
    const path = apiRoutes.datasetPath(datasetId);
    return FetchHelpers.get(path);
  },
  create(params) {
    const path = apiRoutes.datasetsPath(params);
    return FetchHelpers.post(path, params);
  },
  update(datasetId, params) {
    const path = apiRoutes.datasetPath(datasetId);
    return FetchHelpers.patch(path, params);
  },
  delete(datasetId) {
    const path = apiRoutes.datasetPath(datasetId);
    return FetchHelpers.delete(path);
  },
  publish(datasetId) {
    const path = apiRoutes.datasetPublishPath(datasetId);
    return FetchHelpers.post(path);
  },
  loadInitParams(datasetId) {
    const path = apiRoutes.datasetInitialParametersPath(datasetId);
    return FetchHelpers.get(path);
  },
  updateInitParams(datasetId, params) {
    const path = apiRoutes.datasetInitialParametersPath(datasetId);
    return FetchHelpers.put(path, params);
  },
};
