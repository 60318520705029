import * as yup from 'yup';

export const validationFields = {
  file: yup.mixed().required('File is required'),
  datasetId: yup.string().required('Dataset is required'),
};

export const validationSchema = yup.object().shape({ ...validationFields });

export const initialValues = {
  file: null,
  datasetId: '',
};
