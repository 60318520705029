import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  table: {
    minWidth: 650,
    maxWidth: 1360,
  },
  header: {
    backgroundColor: theme.palette.background.white,
  },
  headerInner: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(20),
    color: theme.palette.title,
  },
  templates: {
    maxWidth: 200,
    paddingRight: 10,
    '&:first-child': {
      width: '40%',
    },
    '& .author': {
      color: theme.palette.text.secondary,
    },
  },
  actionsCell: {
    visibility: 'hidden',
  },
  actionsRow: {
    '&:hover td:last-child > button': {
      visibility: 'visible',
    },
  },
}));
