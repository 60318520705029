import React from 'react';
import PropTypes from 'prop-types';

import useStyles from './useStyles';
import TableChangePresenter from 'presenters/TableChangePresenter';
import { isNil } from 'ramda';
import { MapDataTable2D, MapDataTable3D, TabPanel } from 'containers/MapValidate/components';
import MappingPresenter from 'presenters/MappingPresenter';
import { useMapping } from 'slices/hooks';
import {
  getErrorTypes,
  getPreparedRowsWithErrors,
  getTabs,
  mergeChanges,
} from 'containers/MultiSheetMapData/hooks/validation';

const CHANGES = {
  '2d': 'changes2d',
  '3d': 'changes3d',
};

const CHANGE_TYPE = {
  change: 'change',
  skip: 'skip',
};

const ValidateTabPanels = ({ validators, is3DMappingType, currentTabIndex, panelHeight, schemaId }) => {
  const { addChangesMappingErrors, changes } = useMapping();

  const classes = useStyles();

  if (isNil(validators)) return null;
  const errorTypes = getErrorTypes(validators);

  const TABS = getTabs(validators);

  const preparedRowsWithErrors = getPreparedRowsWithErrors(validators, is3DMappingType, errorTypes);

  const handleValidateMapping = (cell) => {
    const createChange = () => {
      switch (TableChangePresenter.type(cell)) {
        case CHANGE_TYPE.change:
          return {
            row: TableChangePresenter.index(cell),
            type: TableChangePresenter.type(cell),
            values: [
              { nameInMapping: TableChangePresenter.nameInMapping(cell), value: TableChangePresenter.value(cell) },
            ],
          };
        case CHANGE_TYPE.skip:
          return {
            row: TableChangePresenter.index(cell),
            type: TableChangePresenter.type(cell),
          };
        default:
          return {};
      }
    };
    const createNewChanges = () => {
      return {
        sheetSchemaId: schemaId,
        data: [createChange()],
      };
    };

    const allNewChanges = {};
    const changesType = is3DMappingType ? CHANGES['3d'] : CHANGES['2d'];

    allNewChanges[changesType] = mergeChanges(changes[changesType], createNewChanges(), cell);

    const allChanges = { ...changes, ...allNewChanges };

    addChangesMappingErrors(allChanges);
  };

  return (
    <>
      {TABS.map((tab) => (
        <TabPanel
          value={currentTabIndex}
          index={`${schemaId}-error-${tab.id}`}
          key={tab.id}
          className={classes.panelTable}
          style={{ height: panelHeight }}
        >
          <div className={classes.controlsPanel}>
            {is3DMappingType ? (
              <MapDataTable3D
                tableRows={preparedRowsWithErrors[tab.name]}
                type={tab.name}
                onEdit={handleValidateMapping}
                errors={MappingPresenter.errors(validators)}
              />
            ) : (
              <MapDataTable2D
                tableRows={preparedRowsWithErrors[tab.name]}
                type={tab.name}
                onEdit={handleValidateMapping}
                errors={MappingPresenter.errors(validators)}
              />
            )}
          </div>
        </TabPanel>
      ))}
    </>
  );
};

ValidateTabPanels.propTypes = {
  validators: PropTypes.arrayOf(PropTypes.any).isRequired,
  is3DMappingType: PropTypes.bool.isRequired,
  currentTabIndex: PropTypes.string.isRequired,
  panelHeight: PropTypes.string.isRequired,
  schemaId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default ValidateTabPanels;
