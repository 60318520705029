import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    display: 'block',
    padding: [0, 40],
    maxWidth: 1440,
    margin: [0, 'auto'],
  },
}));
