import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  mappedColumnsSection: {
    background: theme.palette.background.white,
    boxShadow: [1, 1, 5, theme.palette.shadow],
    borderRadius: [4, 4],
    marginTop: 20,
    '& button': {
      color: theme.palette.default,
      fontSize: theme.typography.pxToRem(10),
      cursor: 'pointer',
      fontWeight: 'normal',
      background: 'none',
      border: 'none',
      outline: 'none',
      '& span': {
        marginRight: 5,
        textTransform: 'uppercase',
      },
      '& svg': {
        transform: 'rotate(180deg)',
      },
      '& svg path': {
        fill: theme.palette.default,
      },
    },
  },
  mappedColumnsSectionHeader: {
    padding: [20, 30],
    boxShadow: [1, 1, 1, theme.palette.shadow],
    color: theme.palette.title,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'space-between',
  },
  icon: {
    width: 20,
    display: 'inline-block',
    verticalAlign: 'middle',
  },
}));
