import palette from 'layouts/CustomThemeProvider/palette';

export default {
  MuiFormControlLabel: {
    root: {
      marginLeft: 0,
      marginRight: 0,
    },
    label: {
      fontSize: 12,
      fontStyle: 'normal',
      color: palette.text.primary,
      paddingLeft: 12,
    },
  },
};
