import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Pagination as MUIPagination } from '@material-ui/lab';

import useStyles from './useStyles';

const Pagination = (props) => {
  const { totalPages, onChange } = props;
  const [pageNumber, setPage] = useState(1);

  const handleChange = (event, pageId) => {
    setPage(pageId);
    onChange(event, pageId);
  };

  const classes = useStyles();

  return (
    <div className={classes.pagination}>
      <MUIPagination count={totalPages} page={pageNumber} onChange={handleChange} />
    </div>
  );
};

Pagination.propTypes = {
  totalPages: PropTypes.number,
  onChange: PropTypes.func.isRequired,
};

Pagination.defaultProps = {
  totalPages: 1,
};

export default Pagination;
