import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(
  (theme) => ({
    center: {
      bottom: 'auto',
      top: '50%',
      left: '50%',
      right: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  }),
  { name: 'ErrorAlert' },
);
