import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  preloader: {
    display: 'flex',
    width: '100%',
    height: '100vh',
    backgroundColor: theme.palette.background.white,
    zIndex: 1,
    textAlign: 'center',
  },
  img: {
    margin: 'auto',
    display: 'block',
  },
  full: {
    width: '100%',
    position: 'relative',
  },
  table: {
    position: 'absolute',
  },
  inner: {
    height: 'auto',
  },
}));
