import palette from 'layouts/CustomThemeProvider/palette';

export default {
  MuiDialog: {
    paper: {
      boxShadow: [0, 2, 18, palette.shadowLight],
      borderRadius: 8,
      backgroundColor: palette.background.white,
      minWidth: 444,
    },
    container: {
      backgroundColor: palette.background.blackTransparent,
    },
  },
};
