import PropTypes from 'prop-types';

import Presenter from 'utils/PropTypesPresenter';
import { camelizeStr } from 'utils/keysConverter';

import OrganizationPresenter from './OrganizationPresenter';

const USER_ROLES = {
  integrationUser: 'User',
  integrationManager: 'Admin',
};

export default new Presenter(
  {
    id: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    role: PropTypes.oneOf(Object.values(USER_ROLES)),
    lobbyClientId: PropTypes.number,
    lobbyCognitoUsername: PropTypes.string,
    organization: OrganizationPresenter.shape(),
  },
  {
    fullName(u) {
      return `${this.firstName(u)} ${this.lastName(u)}`;
    },
    formattedRole(u) {
      const camelizedRole = camelizeStr(this.role(u));
      return USER_ROLES[camelizedRole];
    },
  },
);
