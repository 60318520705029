import React from 'react';
import { Box, Button } from '@material-ui/core';
import { isEmpty } from 'ramda';

import { useDatasets, useFields } from 'slices/hooks';
import DatasetPresenter from 'presenters/DatasetPresenter';
import { ErrorAlert } from 'components/Alerts';

import useStyles from './useStyles';

const PanelControl = () => {
  const classes = useStyles();
  const {
    deleteDataset,
    publishDataset,
    isLoading,
    currentDataset,
    isErrorAlertShowing,
    apiErrors,
    resetDatasetErrors,
  } = useDatasets();
  const { validateErrors } = useFields();
  const handleDeleteDataset = () => {
    deleteDataset(DatasetPresenter.id(currentDataset));
  };

  const handlePublishDataset = () => publishDataset(DatasetPresenter.id(currentDataset));

  return (
    <Box component="div" className={classes.panel}>
      <div className={classes.message}>
        All unsaved changes will be saved as a draft and you can return to it later.
      </div>
      <div className={classes.buttonWrapper}>
        <Button onClick={handleDeleteDataset} disabled={isLoading} variant="outlined">
          delete data set
        </Button>
        <Button onClick={handlePublishDataset} disabled={isLoading || !isEmpty(validateErrors)} variant="contained">
          publish data set
        </Button>
      </div>
      <ErrorAlert onClose={resetDatasetErrors} isShowing={isErrorAlertShowing} errors={apiErrors} />
    </Box>
  );
};

export default PanelControl;
