import React from 'react';
import PropTypes from 'prop-types';
import { is, isEmpty } from 'ramda';
import { Alert } from '@material-ui/lab';

import useStyles from './useStyles';

const ErrorAlert = (props) => {
  const { onClose, errors, isShowing, position } = props;
  const classes = useStyles();

  if (isEmpty(errors)) {
    return null;
  }

  return (
    isShowing && (
      <Alert severity="error" variant="outlined" onClose={onClose} className={!isEmpty(position) && classes[position]}>
        <div>
          {Object.keys(errors).map((key, i) => {
            if (is(Array, errors[key])) {
              return errors[key].map((error, index) => <div key={index}>{error}</div>);
            }
            if (is(Object, errors[key])) {
              if (errors[key]?.payload?.nonFieldErrors) {
                const { nonFieldErrors } = errors[key]?.payload;
                return <div key={i}>{nonFieldErrors.map((error) => error)}</div>;
              }
              return <div key={i}>Check the data</div>;
            }
            return <div key={i}>{errors[key]}</div>;
          })}
        </div>
      </Alert>
    )
  );
};

ErrorAlert.propTypes = {
  onClose: PropTypes.func.isRequired,
  errors: PropTypes.shape(),
  isShowing: PropTypes.bool.isRequired,
  position: PropTypes.oneOf(['center', 'rightBottom']),
};

ErrorAlert.defaultProps = {
  position: 'rightBottom',
  errors: {},
};

export default ErrorAlert;
