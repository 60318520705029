import PropTypes from 'prop-types';

import Presenter from 'utils/PropTypesPresenter';

export default new Presenter(
  {
    importedRows: PropTypes.number,
    ignoredRows: PropTypes.number,
  },

  {
    formattedState(result) {
      return {
        importedRows: this.importedRows(result),
        ignoredRows: this.ignoredRows(result),
      };
    },
  },
);
