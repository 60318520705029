import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'clsx';
import { Popper, ClickAwayListener, IconButton } from '@material-ui/core';

import Icon from 'components/Icon';

import useStyles from './useStyles';

const DropdownInput = (props) => {
  const { value, placeholder, children, type, onChange } = props;
  const [anchorEl, setAnchorEl] = useState(false);
  const [inputValue, setInputValue] = useState(props.value || '');
  const isRowType = type === 'row';
  const textInput = useRef();

  const classes = useStyles();

  useEffect(() => {
    setInputValue(value || '');
    setAnchorEl(null);
    return setAnchorEl(null);
  }, [value]);

  const handleInputFocus = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    onChange(e.target.value);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  const setWidth = (data) => {
    const { width, left, right } = data.offsets.reference;

    /* eslint-disable */
    data.styles.width = width;
    data.offsets.popper.width = width;
    data.offsets.popper.left = left;
    data.offsets.popper.right = right;
    /* eslint-enable */

    return data;
  };

  const handleToggleDropdown = (e) => {
    const element = textInput.current;
    setAnchorEl(anchorEl ? null : element);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className={classes.root}>
        <span className={classes.searchInput}>
          <input
            type="text"
            name="search"
            onFocus={handleInputFocus}
            placeholder={placeholder}
            value={inputValue}
            onChange={handleInputChange}
            className={cn({ [classes.inputRowType]: isRowType && open })}
            ref={textInput}
            autoComplete="off"
          />
          <IconButton className={cn(classes.arrow, { [classes.opened]: open })} onClick={handleToggleDropdown}>
            <Icon name="arrowDown" />
          </IconButton>
        </span>
        <Popper
          id={id}
          open={open}
          anchorEl={anchorEl}
          className={cn(classes.popper, { [classes.rowDropdown]: isRowType })}
          placement="bottom"
          keepMounted
          container={textInput.current?.closest('.fullscreen')}
          modifiers={{
            setPopperWidth: {
              enabled: true,
              order: 849,
              fn: setWidth,
            },
            preventOverflow: {
              enabled: true,
              padding: 91,
              boundariesElement: 'window',
            },
          }}
        >
          {children}
        </Popper>
      </div>
    </ClickAwayListener>
  );
};

DropdownInput.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  children: PropTypes.node,
  type: PropTypes.string,
  onChange: PropTypes.func,
};

DropdownInput.defaultProps = {
  value: '',
  placeholder: '',
  children: null,
  type: '',
  onChange: () => {},
};

export default DropdownInput;
