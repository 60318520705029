export default {
  MuiTableCell: {
    root: {
      fontSize: 12,
    },
    head: {
      padding: [[16, 0]],
      fontWeight: 600,

      '&:first-child': {
        paddingLeft: 12,
        paddingRight: 12,
        width: 32,
        maxWidth: 32,
      },
    },
    body: {
      padding: [[16, 0]],

      '&:first-child': {
        paddingLeft: 12,
        paddingRight: 12,
        width: 32,
        maxWidth: 32,
      },
    },
  },
};
