import { useSelector } from 'react-redux';

import { useMappingActions } from 'slices/MappingSlice';

export const useMapping = () => {
  const {
    validateMapping,
    resetCurrentMapping,
    createMapping,
    addMappedColumn,
    removeMappedColumn,
    resetMappedColumns,
    setIgnoredRows,
    loadCurrentMapping,
    loadResult,
    addChangesMappingErrors,
    resetMappingErrors,
    resetCommonErrors,
    resetChanges,
    undoIgnoreRows,
    addMappedRow,
    removeMappedRow,
    resetMappedRows,
    create3DMapping,
    loadCurrent3DMapping,
    load3DResult,
    validate3DMapping,
    createMultiSheetMapping,
    loadCurrentMultiSheetMapping,
    validateMultiSheetMapping,
    loadMultiSheetResult,
    resetIgnoreRows,
    resetAllIgnoreRows,
  } = useMappingActions();
  const isLoading = useSelector((state) => state.MappingSlice.loading);
  const apiErrors = useSelector((state) => state.MappingSlice.errors);
  const currentMapping = useSelector((state) => state.MappingSlice.currentMapping);
  const mappedColumns = useSelector((state) => state.MappingSlice.mappedColumns);
  const numberOfIgnoredRows = useSelector((state) => state.MappingSlice.numberOfIgnoredRows);
  const validators = useSelector((state) => state.MappingSlice.validators);
  const mappingResult = useSelector((state) => state.MappingSlice.mappingResult);
  const changes = useSelector((state) => state.MappingSlice.changes);
  const isErrorAlertShowing = useSelector((state) => state.MappingSlice.isErrorAlertShowing);
  const sendResultErrors = useSelector((state) => state.MappingSlice.sendResultErrors);
  const allIgnoredRows = useSelector((state) => state.MappingSlice.ignoreRows);
  const mappedRows = useSelector((state) => state.MappingSlice.mappedRows);
  const uncorrectableErrors = useSelector((state) => state.MappingSlice.uncorrectableErrors);
  const importMessage = useSelector((state) => state.MappingSlice.importMessage);

  return {
    validateMapping,
    resetCurrentMapping,
    isLoading,
    apiErrors,
    currentMapping,
    createMapping,
    addMappedColumn,
    removeMappedColumn,
    resetMappedColumns,
    mappedColumns,
    numberOfIgnoredRows,
    setIgnoredRows,
    validators,
    loadCurrentMapping,
    loadResult,
    mappingResult,
    changes,
    addChangesMappingErrors,
    resetMappingErrors,
    isErrorAlertShowing,
    sendResultErrors,
    resetCommonErrors,
    resetChanges,
    allIgnoredRows,
    undoIgnoreRows,
    addMappedRow,
    removeMappedRow,
    resetMappedRows,
    mappedRows,
    create3DMapping,
    loadCurrent3DMapping,
    load3DResult,
    validate3DMapping,
    uncorrectableErrors,
    importMessage,
    createMultiSheetMapping,
    loadCurrentMultiSheetMapping,
    validateMultiSheetMapping,
    loadMultiSheetResult,
    resetIgnoreRows,
    resetAllIgnoreRows,
  };
};
