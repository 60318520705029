import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Tab as MuiTab } from '@material-ui/core';
import { CheckCircleOutlineRounded, ErrorOutline } from '@material-ui/icons';

import useStyles from './useStyles';
import { isEmpty } from 'ramda';
import { pluralizeCells, pluralizeRows } from 'utils/pluralize';
import { snakeCaseToWords } from 'utils/stringUtils';
import TabsPresenter from 'presenters/TabsPresenter';
import { getTabProps } from 'utils/tabsUtils';
import { ERROR_STATES, getErrorTypes, getPreparedRowsWithErrors } from 'containers/MultiSheetMapData/hooks/validation';

const ValidateTab = ({ tab, validators, is3DMappingType, onChange, currentTabIndex, schemaId }) => {
  const classes = useStyles();
  const errorTypes = getErrorTypes(validators);
  const preparedRowsWithErrors = getPreparedRowsWithErrors(validators, is3DMappingType, errorTypes);

  const allErrors = preparedRowsWithErrors[tab.name].reduce((acc, row) => {
    return [...acc, ...row.errors];
  }, []);

  const isUnresolved = allErrors.some((error) => error.errorState === ERROR_STATES.new);
  const isResolved = allErrors.filter((error) => error.errorState === ERROR_STATES.fixed);
  const isSkipped = allErrors.filter((error) => error.errorState === ERROR_STATES.skip);

  const resolvedInfo = !isEmpty(isResolved) ? `${pluralizeCells(isResolved.length)} edited` : '';
  const skippedInfo = !isEmpty(isSkipped) ? `, ${pluralizeRows(isSkipped.length)} ignored` : '';

  const renderTabLabel = () => {
    return (
      <div className={classes.tabLabelWrapper}>
        <div className={classes.tabLabel}>
          <div className={classes.name}>{snakeCaseToWords(TabsPresenter.name(tab))}</div>
          <div className={classes.state}>{isUnresolved ? 'Unresolved' : `${resolvedInfo}${skippedInfo}`}</div>
        </div>
        {isUnresolved ? (
          <ErrorOutline className={classes.errorIcon} />
        ) : (
          <CheckCircleOutlineRounded className={classes.successIcon} />
        )}
      </div>
    );
  };

  const handleTabClick = (e) => {
    onChange(e.currentTarget.dataset.value);
  };

  return (
    <MuiTab
      {...getTabProps(tab, currentTabIndex, schemaId)}
      label={renderTabLabel()}
      onClick={handleTabClick}
      className={clsx(classes.verticalTab, classes.verticalValidationTab)}
    />
  );
};

ValidateTab.propTypes = {
  tab: PropTypes.objectOf(PropTypes.any).isRequired,
  schemaId: PropTypes.number.isRequired,
  validators: PropTypes.oneOfType([PropTypes.objectOf(PropTypes.any), null]),
  is3DMappingType: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  currentTabIndex: PropTypes.string.isRequired,
};

ValidateTab.defaultProps = {
  validators: null,
};

export default ValidateTab;
