import React from 'react';

import { useSession, useUsers } from 'slices/hooks';
import Logo from 'assets/images/logo.png';
import { useRouter } from 'utils/appHooks';
import Form from './components/Form';

import useStyles from './useStyles';

const LOBBY_PROVIDER = 'lobby';
const AUTH_PARAM = 'auth';

const Login = () => {
  const { signIn, isLoading, errors } = useSession();
  const { loadCurrentUser } = useUsers();
  const { location } = useRouter();

  const classes = useStyles();

  const handleSignIn = (params) => {
    const urlParams = new URLSearchParams(location.search);
    const authType = urlParams.get(AUTH_PARAM);

    const data = {
      ...params,
      provider: authType || LOBBY_PROVIDER,
    };
    return signIn(data).then(() => loadCurrentUser());
  };
  return (
    <div className={classes.wrapper}>
      <div className={classes.authForm}>
        <img src={Logo} alt="FormataFile" className={classes.logo} />
        <h1 className={classes.title}>Log in to your account</h1>
        <Form onSignIn={handleSignIn} loading={isLoading} apiErrors={errors} />
      </div>
    </div>
  );
};

export default Login;
