import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'clsx';
import { isEmpty, omit } from 'ramda';
import { MenuItem, Menu, Input, IconButton, Button, Popover } from '@material-ui/core';
import { AddBoxRounded as AddBoxRoundedIcon, CloseOutlined as CloseOutlinedIcon } from '@material-ui/icons';

import { getValidatorsOptions } from 'helpers/fieldDetailsOptions';
import ValidatorsPresenter from 'presenters/ValidatorsPresenter';
import { useFields } from 'slices/hooks';
import { ManageValuesList } from 'components/Dialogs';
import { camelCaseToCapWords } from 'utils/stringUtils';

import useStyles from './useStyles';

const INITIAL_VALUES = {
  minLength: 1,
  maxLength: 1,
  specificLength: 1,
  regularExpression: '',
  email: true,
  valueList: [],
};

const VISIBLED_VALUES_COUNT = 2;

const MultiSelectValidation = (props) => {
  const [newValidators, setValidators] = useState({
    minLength: 1,
    maxLength: 1,
    specificLength: 1,
    regularExpression: '',
    valueList: [],
  });
  const [anchorElValidatorsMenu, setAnchorElValidatorsMenu] = useState(null);
  const { label, validators, onChange, apiErrors, validateErrors, touched, onSaveValidators } = props;
  const { fieldValidators } = useFields();
  const [isManageValuesListOpened, setIsManageValuesListOpened] = useState(false);
  const [anchorElValuesList, setAnchorElValuesList] = useState(false);
  const allErrors = { ...validateErrors, ...apiErrors };
  const apiErrorsWithoutValueList = omit(['valueList'], apiErrors);

  const classes = useStyles();

  useEffect(() => {
    setValidators(validators);
  }, []); // eslint-disable-line

  const createRuleValidation = (ruleName, ruleValue) => {
    switch (ruleName) {
      case 'email':
      case 'minLength':
      case 'maxLength':
      case 'specificLength':
        return { [ruleName]: Number(ruleValue) };
      case 'regularExpression':
        return { [ruleName]: ruleValue };
      case 'valueList':
        return { [ruleName]: ruleValue };
      default:
        return { [ruleName]: ruleValue };
    }
  };

  const handleChangeValidator = (field) => ({ target: { value } }) => {
    setValidators({ ...newValidators, ...createRuleValidation(field, INITIAL_VALUES[field]) });
    onChange({ ...newValidators, ...createRuleValidation(field, INITIAL_VALUES[field]) });
    handleClose();
  };

  const handleFieldChange = (field) => ({ target: { value } }) => {
    setValidators({ ...newValidators, ...createRuleValidation(field, value) });
    onChange({ ...newValidators, ...createRuleValidation(field, value) });
  };

  const handleValueListChange = (value) => {
    setValidators({ ...newValidators, ...createRuleValidation('valueList', value) });
    onChange({ ...newValidators, ...createRuleValidation('valueList', value) });
    onSaveValidators();
  };

  const renderDeleteButton = (validatorName) => (
    <IconButton onClick={handleDeleteValidator(validatorName)}>
      <CloseOutlinedIcon className={classes.closeIcon} />
    </IconButton>
  );

  const getInputClassnames = (name) =>
    cn(classes.inputText, {
      [classes.hasError]: (touched[name] && !!validateErrors[name]) || !!apiErrors[name],
    });

  const handleCloseManageValuesList = () => {
    setIsManageValuesListOpened(false);
  };

  const handleOpenManageValuesList = () => {
    setIsManageValuesListOpened(true);
  };

  const renderValidator = (validatorsList, name) => {
    if (ValidatorsPresenter.isContainKey(validatorsList, name)) {
      return (
        <div className={classes.validatorWrapper}>
          <div className={classes.option}>
            <div className={cn(classes.addedValue, classes.hasInput)}>
              {camelCaseToCapWords(name)}
              <Input
                name={name}
                type="text"
                className={getInputClassnames(name)}
                placeholder="255"
                onChange={handleFieldChange(name)}
                value={validatorsList[name]}
              />
            </div>
            {renderDeleteButton(name)}
          </div>
          {touched[name] && <div className={classes.error}>{validateErrors[name]}</div>}
        </div>
      );
    }
    return null;
  };

  const renderSelectedValidation = (validatorsList) => {
    if (isEmpty(validatorsList)) {
      return null;
    }
    return (
      <>
        {ValidatorsPresenter.isContainKey(validatorsList, 'email') && (
          <div className={classes.option}>
            <div className={classes.addedValue}>Valid Email Address</div>
            {renderDeleteButton('email')}
          </div>
        )}

        {renderValidator(validatorsList, 'specificLength')}
        {renderValidator(validatorsList, 'maxLength')}
        {renderValidator(validatorsList, 'minLength')}
        {renderValidator(validatorsList, 'regularExpression')}

        {ValidatorsPresenter.isContainKey(validatorsList, 'valueList') && (
          <div className={classes.validatorWrapper}>
            <div className={classes.option}>
              <div className={cn(classes.addedValue, classes.hasInput)}>
                <div>
                  Value List
                  <button
                    type="button"
                    className={classes.valuesCount}
                    onMouseOver={handleOpenValuesList}
                    onFocus={handleCloseValuesList}
                  >
                    {validatorsList.valueList.length}
                  </button>
                  {renderValuesListPopper(validatorsList.valueList)}
                </div>
                <Button onClick={handleOpenManageValuesList}>Manage</Button>
              </div>
              {renderDeleteButton('valueList')}
            </div>
            {allErrors.valueList && <div className={classes.error}>{allErrors.valueList}</div>}
            {isManageValuesListOpened && (
              <ManageValuesList
                onClose={handleCloseManageValuesList}
                onSubmit={handleValueListChange}
                options={validatorsList.valueList}
              />
            )}
          </div>
        )}
      </>
    );
  };

  const handleOpenValuesList = (e) => {
    setAnchorElValuesList(e.currentTarget);
  };
  const handleCloseValuesList = () => setAnchorElValuesList(null);

  const openValuesList = Boolean(anchorElValuesList);

  const renderValuesListPopper = (values) => {
    const leftoverValues = values.length - VISIBLED_VALUES_COUNT;
    const visibleValues = values.slice(0, VISIBLED_VALUES_COUNT);

    return (
      <Popover
        className={classes.popover}
        id="mouse-over-popover"
        open={openValuesList}
        anchorEl={anchorElValuesList}
        classes={{
          paper: classes.paper,
        }}
        keepMounted
        disablePortal
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={handleCloseValuesList}
        disableRestoreFocus
      >
        <div>
          {visibleValues.map((value, i) => (
            <div className={classes.valueItem} key={i}>
              {value}
            </div>
          ))}
          <div>{values.length > VISIBLED_VALUES_COUNT && <span>...</span>}</div>
          <button type="button" className={classes.leftoverValues} onClick={handleOpenManageValuesList}>
            And {leftoverValues} more
          </button>
        </div>
      </Popover>
    );
  };

  const handleDeleteValidator = (validator) => () => {
    const newValues = { ...newValidators };
    delete newValues[validator];
    setValidators(newValues);
    onChange(newValues);
  };

  const handleClick = (event) => {
    setAnchorElValidatorsMenu(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElValidatorsMenu(null);
  };

  const validatorOptions = getValidatorsOptions(fieldValidators);
  const prepareValidationOptions = () => {
    if (ValidatorsPresenter.isContainKey(newValidators, 'specificLength')) {
      return validatorOptions.filter((option) => option.value !== 'minLength' && option.value !== 'maxLength');
    }
    if (
      ValidatorsPresenter.isContainKey(newValidators, 'minLength') ||
      ValidatorsPresenter.isContainKey(newValidators, 'maxLength')
    ) {
      return validatorOptions.filter((option) => option.value !== 'specificLength');
    }
    return validatorOptions;
  };

  const preparedValidationOptions = prepareValidationOptions();

  const renderErrors = (errors) =>
    Object.keys(errors).map((key, index) => (
      <span className={classes.error} key={index}>
        {errors[key]}
      </span>
    ));

  return (
    <>
      <Button className={classes.buttonAdd} aria-controls="dropdown-menu" aria-haspopup="true" onClick={handleClick}>
        <AddBoxRoundedIcon className={classes.addIcon} />
        <span className={classes.buttonLabel}>{label}</span>
      </Button>
      <Menu
        className={classes.list}
        id="simple-menu"
        anchorEl={anchorElValidatorsMenu}
        keepMounted
        open={Boolean(anchorElValidatorsMenu)}
        onClose={handleClose}
      >
        {preparedValidationOptions.map((option, index) => (
          <MenuItem value={option.value} key={index} onClick={handleChangeValidator(option.value)}>
            {option.label}
          </MenuItem>
        ))}
      </Menu>
      <div className={classes.addedOptions}>{renderSelectedValidation(newValidators)}</div>
      {!isEmpty(apiErrorsWithoutValueList) && renderErrors(apiErrorsWithoutValueList)}
    </>
  );
};

MultiSelectValidation.propTypes = {
  label: PropTypes.string,
  validators: ValidatorsPresenter.shape().isRequired,
  onChange: PropTypes.func.isRequired,
  apiErrors: PropTypes.shape(),
  validateErrors: PropTypes.shape(),
  touched: PropTypes.shape(),
  onSaveValidators: PropTypes.func.isRequired,
};

MultiSelectValidation.defaultProps = {
  label: 'ADD VALIDATION',
  apiErrors: {},
  validateErrors: {},
  touched: {},
};

export default MultiSelectValidation;
