const initializePendo = (lobbyClientId, lobbyCognitoUsername) => {
  window.pendo.initialize({
    visitor: {
      id: lobbyCognitoUsername,
    },
    account: {
      id: lobbyClientId,
    },
  });
};

/* eslint-disable */
const loadAsync = (u, c) => {
  var d = document, t = 'script',
      o = d.createElement(t),
      s = d.getElementsByTagName(t)[0];
  o.src = u;
  if (c) { o.addEventListener('load', function (e) { c(null, e); }, false); }
  s.parentNode.insertBefore(o, s);
};

const setupPendo = (data) => {
 const { pendoApiKey, lobbyClientId, lobbyCognitoUsername } = data;
  (function (p, e, n, d, o) {
    let v;
    let w;
    let x;
    let y;
    let z;
    o = p[d] = p[d] || {};
    o._q = o._q || [];
    v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];
    for (w = 0, x = v.length; w < x; ++w)
      (function (m) {
        o[m] =
          o[m] ||
          function () {
            o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0)));
          };
      })(v[w]);
    loadAsync(`https://content.pndovl.lobbycre.com/agent/static/${pendoApiKey}/pendo.js`, initializePendo(lobbyClientId, lobbyCognitoUsername))
  })(window, document, 'script', 'pendo');
};
/* eslint-enable */

export { setupPendo };
