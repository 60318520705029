import PropTypes from 'prop-types';

import Presenter from 'utils/PropTypesPresenter';

export default new Presenter({
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      displayName: PropTypes.string,
      nameInMapping: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  displayName: PropTypes.string,
  nameInMapping: PropTypes.string,
});
