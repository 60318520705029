import { apiRoutes } from 'routes';
import FetchHelpers from 'utils/FetchHelpers';

export default {
  index(params) {
    const path = apiRoutes.datasetsUserPath();
    return FetchHelpers.get(path, params);
  },
  current(datasetId) {
    const path = apiRoutes.datasetUserPath(datasetId);
    return FetchHelpers.get(path);
  },
};
