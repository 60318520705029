export const appRoutes = {
  rootPath: () => '/',
  datasetsPath: () => '/manager/datasets',
  datasetPath: (datasetId) => `/manager/datasets/${datasetId}`,
  loginPath: () => '/login',
  documentsPath: () => '/documents',
  documentsTabPath: (tab) => `/documents#${tab}`,
  documentsImportPath: () => `/documents#import`,
  successImportFilePath: () => `/import`,
  mappingPath: (documentId) => `/documents/${documentId}/mapping`,
  multiSheetMappingPath: (documentId) => `/documents/${documentId}/multi-sheet-mapping`,
  errorPath: (errorCode) => `/error/${errorCode}`,
  validateMappingPath: (documentId) => `/documents/${documentId}/validate`,
  multiSheetValidatePath: (documentId) => `/documents/${documentId}/multi-sheet-validate`,
  mappingSummary: (documentId) => `/documents/${documentId}/summary`,
  initialParametersPath: (documentId) => `/documents/${documentId}/initial-parameters`,
};
