import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'clsx';
import { TableCell } from '@material-ui/core';
import { find, isEmpty, isNil, propEq } from 'ramda';

import DropdownInput from 'components/DropdownInput';
import { MapRowDropdownContent } from 'containers/MapData/components/MapDropdownContent';
import { useMapping, useTemplates, useUserFields } from 'slices/hooks';
import DatasetFieldPresenter from 'presenters/DatasetFieldPresenter';
import { isBlank } from 'utils/conditions';
import { searchFields } from 'utils/fieldsUtils';

import useStyles from './useStyles';

const MapRowSelector = React.memo((props) => {
  const {
    mapToName,
    mapToIndex,
    initialValue,
    sheetNumber,
    onClick,
    isContextVisible,
    datasetId,
    fields,
    setIsDisabledSubmitButton,
  } = props;
  const { addMappedRow, removeMappedRow } = useMapping();
  const [isMappedRow, setIsMappedRow] = useState(false);
  const [selectedField, setSelectedField] = useState();
  const { setIsMappedRowField, isListOfFieldsLoading, setUnMappedRowField } = useUserFields();
  const [searchedFields, setSearchedFields] = useState(fields);
  const [searchValue, setSearchValue] = useState('');
  const { isTemplateChanged, changeTemplate } = useTemplates();

  const classes = useStyles();

  useEffect(() => {
    setSearchedFields(fields);
    handleChange(searchValue);
  }, [fields, datasetId]); // eslint-disable-line

  useEffect(() => {
    if (!isBlank(initialValue) && !isEmpty(fields)) {
      const { fieldName } = initialValue;
      const initialField = find(propEq('nameInMapping', fieldName))(fields);
      if (!isNil(initialField)) {
        const isPossibleMap =
          !DatasetFieldPresenter.isMapped(initialField) || !DatasetFieldPresenter.isRequired(initialField);
        isPossibleMap && mapField(initialField);
      }
    }
  }, [isListOfFieldsLoading, initialValue]); // eslint-disable-line

  useEffect(() => {
    setSelectedField(null);
    setIsMappedRow(false);
  }, [sheetNumber]); // eslint-disable-line

  const unmapSelectedField = () => {
    setSelectedField(null);
    setIsMappedRow(false);
    setUnMappedRowField({ datasetId, name: mapToName, index: mapToIndex, isMapped: false, field: selectedField });
    removeMappedRow({
      id: DatasetFieldPresenter.id(selectedField),
      mapToIndex,
      datasetId,
    });
  };

  const mapField = (field) => {
    if (!isTemplateChanged) changeTemplate(true);
    setIsDisabledSubmitButton(true);
    setIsMappedRow(true);
    setSelectedField(field);
    setIsMappedRowField({ datasetId, name: mapToName, index: mapToIndex, isMapped: true, field });
    addMappedRow({
      fieldName: DatasetFieldPresenter.nameInMapping(field),
      rowIndex: mapToIndex,
      id: DatasetFieldPresenter.id(field),
      datasetId,
    });
  };

  const mapFieldToRow = (field) => {
    if (!isNil(selectedField)) {
      unmapSelectedField();
      if (selectedField.id !== field.id) {
        DatasetFieldPresenter.isMapped(field) ? mapField(selectedField) : mapField(field);
      }
    } else {
      const isPossibleMap = !DatasetFieldPresenter.isMapped(field) || !DatasetFieldPresenter.isRequired(field);
      isPossibleMap && mapField(field);
    }
  };

  const handleChange = (value) => {
    if (!fields) return;
    const searchResult = searchFields(fields, value);
    setSearchedFields(searchResult);
    setSearchValue(value);
  };

  return (
    <TableCell
      className={cn(classes.rowSelector, { [classes.mapped]: isMappedRow }, 'rowSelector')}
      onClick={() => onClick(mapToIndex)}
    >
      <DropdownInput
        placeholder="Map to..."
        value={DatasetFieldPresenter.displayName(selectedField)}
        type="row"
        onChange={handleChange}
      >
        {isContextVisible && (
          <MapRowDropdownContent fields={searchedFields} onMapField={mapFieldToRow} mapToIndex={mapToIndex} />
        )}
      </DropdownInput>
    </TableCell>
  );
});

MapRowSelector.propTypes = {
  mapToName: PropTypes.string,
  mapToIndex: PropTypes.number.isRequired,
  datasetId: PropTypes.number.isRequired,
  initialValue: PropTypes.shape(),
  sheetNumber: PropTypes.number,
  onClick: PropTypes.func,
  isContextVisible: PropTypes.bool,
  fields: PropTypes.arrayOf(PropTypes.any),
  setIsDisabledSubmitButton: PropTypes.func.isRequired,
};

MapRowSelector.defaultProps = {
  mapToName: '',
  initialValue: {},
  sheetNumber: 0,
  onClick: () => {},
  isContextVisible: false,
  fields: [],
};

export default MapRowSelector;
