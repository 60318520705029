import * as yup from 'yup';

export const validationFields = {
  email: yup.string().email('Invalid email address').required('Email is required'),
  password: yup.string().required('Password is required'),
};

export const validationSchema = yup.object().shape({ ...validationFields });

export const initialValues = {
  email: '',
  password: '',
};
