import * as yup from 'yup';

export const validationFields = {
  name: yup.string().required('Name is required'),
  validationUrl: yup.string().url('Validation URL must be a valid URL').required('Validation URL is required'),
  endpointUrl: yup.string().url('Import Endpoint must be a valid URL').required('Import Endpoint is required'),
};

export const validationSchema = yup.object().shape({ ...validationFields });

export const initialValues = {
  name: '',
  validationUrl: '',
  endpointUrl: '',
};
