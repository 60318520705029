import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton } from '@material-ui/core';
import { CloseOutlined as CloseOutlinedIcon } from '@material-ui/icons';

import { useTemplates } from 'slices/hooks';

const DeleteFile = (props) => {
  const { onClose, onSubmit, documentId } = props;
  const { isLoading } = useTemplates();

  const handleSubmitForm = (e) => {
    e.preventDefault();
    onSubmit(documentId);
  };

  return (
    <Dialog onClose={onClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" open>
      <DialogTitle>Delete file</DialogTitle>
      <IconButton color="inherit" onClick={onClose}>
        <CloseOutlinedIcon />
      </IconButton>
      <form onSubmit={handleSubmitForm}>
        <DialogContent>Do you want to delete the file? This action is irreversible.</DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="outlined">
            Cancel
          </Button>
          <Button type="submit" disabled={isLoading} variant="contained">
            Delete
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

DeleteFile.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  documentId: PropTypes.number.isRequired,
};

export default DeleteFile;
