import { useSelector } from 'react-redux';

import { useSessionActions } from 'slices/SessionSlice';

export const useSession = () => {
  const { signIn, signOut } = useSessionActions();
  const isLoading = useSelector((state) => state.SessionSlice.loading);
  const errors = useSelector((state) => state.SessionSlice.errors);
  const authToken = useSelector((state) => state.SessionSlice.token);

  return {
    signIn,
    signOut,
    isLoading,
    errors,
    authToken,
  };
};
