import palette from 'layouts/CustomThemeProvider/palette';

export default {
  MuiTab: {
    root: {
      fontSize: 12,
      lineHeight: 1.3,
      color: palette.title,
      minHeight: 'auto',
      minWidth: 'auto',
      boxShadow: [5, -5, 8, palette.shadowLight],
      borderRadius: [6, 6, 0, 0],
      backgroundColor: palette.background.white,
      marginRight: 16,
      marginBottom: -2,
      padding: [10, 32, 12, 32],
      border: [1, 'solid', palette.shadowBorder],
      opacity: 0.3,
      textTransform: 'capitalize',
    },
    textColorInherit: {
      color: palette.title,
      position: 'relative',
      zIndex: 0,
      opacity: 1,

      '&$selected': {
        opacity: 1,
        position: 'relative',
        zIndex: 1,
        backgroundColor: palette.background.white,
      },
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'row',
    },
  },
};
