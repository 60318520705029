export default {
  MuiTypography: {
    root: {
      fontFamily: ['Roboto', 'sans-serif'].join(','),
    },
    h6: {
      fontWeight: 500,
      fontSize: 20,
      lineHeight: 1.2,
    },
  },
};
