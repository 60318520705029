import { useSelector } from 'react-redux';

import { useFieldsActions } from 'slices/FieldsSlice';

export const useFields = () => {
  const {
    loadFields,
    resetFields,
    createField,
    deleteField,
    updateField,
    loadCurrentField,
    resetCurrentField,
    resetFieldsErrors,
    loadValidateFieldErrors,
    resetValidateFieldErrors,
    setMapping,
    clearIsMappedField,
    loadFieldFormats,
    loadFieldValidators,
  } = useFieldsActions();

  const isLoading = useSelector((state) => state.FieldsSlice.loading);
  const apiErrors = useSelector((state) => state.FieldsSlice.errors);
  const fields = useSelector((state) => state.FieldsSlice.fields);
  const meta = useSelector((state) => state.FieldsSlice.meta);
  const currentField = useSelector((state) => state.FieldsSlice.currentField);
  const validateErrors = useSelector((state) => state.FieldsSlice.validateErrors);
  const isErrorAlertShowing = useSelector((state) => state.FieldsSlice.isErrorAlertShowing);
  const fieldFormats = useSelector((state) => state.FieldsSlice.formats);
  const fieldValidators = useSelector((state) => state.FieldsSlice.validators);

  return {
    loadFields,
    resetFields,
    createField,
    deleteField,
    updateField,
    loadCurrentField,
    resetCurrentField,
    isLoading,
    isErrorAlertShowing,
    apiErrors,
    fields,
    meta,
    currentField,
    resetFieldsErrors,
    loadValidateFieldErrors,
    validateErrors,
    resetValidateFieldErrors,
    loadFieldFormats,
    fieldFormats,
    loadFieldValidators,
    fieldValidators,
    setMapping,
    clearIsMappedField,
  };
};
