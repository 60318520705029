import PropTypes from 'prop-types';

import Presenter from 'utils/PropTypesPresenter';

const CHANGES_STATUSES = {
  change: 'change',
  skip: 'skip',
};

export default new Presenter({
  index: PropTypes.number,
  header: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.oneOf(Object.values(CHANGES_STATUSES)),
  nameInMapping: PropTypes.string,
});
