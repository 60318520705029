import React from 'react';
import PropTypes from 'prop-types';

import Header from 'components/Header';

import useStyles from './useStyles';

const Base = (props) => {
  const { children } = props;
  const classes = useStyles();
  return (
    <div>
      <Header />
      <div className={classes.container}>{children}</div>
    </div>
  );
};

Base.propTypes = {
  children: PropTypes.node,
};

Base.defaultProps = {
  children: null,
};

export default Base;
