import React, { useState, useEffect } from 'react';
import { Button } from '@material-ui/core';
import { AddCircleOutline as AddCircleOutlineIcon, Settings as SettingsIcon } from '@material-ui/icons';
import { isEmpty } from 'ramda';
import arrayToTree from 'array-to-tree';

import { useDatasets, useFields } from 'slices/hooks';
import { isBlank } from 'utils/conditions';
import { useContextFromRoute } from 'utils/appHooks';
import { appRoutes } from 'routes/appRoutes';
import Breadcrumbs from 'components/Breadcrumbs';
import { CreateNewDatasetField, DatasetSettings, DatasetInitialParams } from 'components/Dialogs';
import Preloader from 'components/Preloader';
import DatasetPresenter from 'presenters/DatasetPresenter';
import DatasetFieldPresenter from 'presenters/DatasetFieldPresenter';
import { getListFromTree } from 'helpers/fieldDetailsOptions';

import { TableFields, PanelControl, FormDetails } from './components';

import useStyles from './useStyles';

const DatasetConstructor = () => {
  const classes = useStyles();
  const [isCreateNewDatasetFieldOpened, setIsCreateNewDatasetFieldOpened] = useState(false);
  const [isDatasetSettingsOpened, setIsDatasetSettingsOpened] = useState(false);
  const [isDatasetInitialParamsOpened, setIsDatasetInitialParamsOpened] = useState(false);
  const {
    loadFields,
    resetFields,
    fields,
    isLoading,
    currentField,
    resetCurrentField,
    loadFieldFormats,
    resetFieldsErrors,
    loadFieldValidators,
    createField,
  } = useFields();
  const { loadCurrentDataset, resetCurrentDataset, currentDataset, loadInitialParameters } = useDatasets();
  const { datasetId } = useContextFromRoute();
  const [selectedFieldName, setSelectedFieldName] = useState('');
  const fieldsAsTree = arrayToTree(fields, { parentProperty: 'parentId' });
  const fieldsFromTreeToList = getListFromTree(fieldsAsTree);

  const handleOpenDataSetSettings = () => {
    setIsDatasetSettingsOpened(true);
  };

  const handleOpenDataSetInitialParams = () => {
    setIsDatasetInitialParamsOpened(true);
  };

  const handleCloseDataSetSettings = () => {
    setIsDatasetSettingsOpened(false);
  };

  const handleCloseDataSetInitialParams = () => {
    setIsDatasetInitialParamsOpened(false);
  };

  useEffect(() => {
    loadCurrentDataset(datasetId);
    loadFields({ datasetId });
    loadFieldFormats();
    loadFieldValidators();
    loadInitialParameters(datasetId);
    return () => {
      resetCurrentDataset();
      resetFields();
      resetCurrentField();
      resetFieldsErrors();
    };
  }, []); // eslint-disable-line

  const handleOpenCreateNewDatasetField = () => {
    setIsCreateNewDatasetFieldOpened(true);
  };

  const handleCloseCreateNewDatasetField = () => {
    setIsCreateNewDatasetFieldOpened(false);
  };

  if (isBlank(currentDataset)) {
    return <Preloader type="full" />;
  }
  const breadcrumbs = [
    { name: 'Import Tool', link: appRoutes.datasetsPath() },
    { name: DatasetPresenter.name(currentDataset), link: appRoutes.datasetPath(DatasetPresenter.id(currentDataset)) },
  ];

  const renderFieldDetailsForm = () => {
    if (isLoading) {
      return <Preloader />;
    }
    if (isEmpty(currentField)) {
      return null;
    }
    return (
      <FormDetails
        currentField={currentField}
        datasetId={DatasetPresenter.id(currentDataset)}
        fileType={DatasetPresenter.fileType(currentDataset)}
        fieldsTree={fieldsAsTree}
      />
    );
  };

  const handleCreateNewDatasetField = (values) => {
    return createField(values).then(() => {
      const nameInMapping = DatasetFieldPresenter.nameInMapping(values);
      setSelectedFieldName(nameInMapping);
    });
  };

  return (
    <div className={classes.container}>
      <div className={classes.topControls}>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <div className={classes.buttonWrapper}>
          <Button
            variant="text"
            onClick={handleOpenDataSetInitialParams}
            startIcon={<SettingsIcon className={classes.settingsIcon} />}
          >
            Initial parameters
          </Button>
          <Button
            variant="text"
            onClick={handleOpenDataSetSettings}
            startIcon={<SettingsIcon className={classes.settingsIcon} />}
          >
            Dataset settings
          </Button>
        </div>
        {isDatasetSettingsOpened && <DatasetSettings onClose={handleCloseDataSetSettings} />}
        {isDatasetInitialParamsOpened && <DatasetInitialParams onClose={handleCloseDataSetInitialParams} />}
      </div>
      <div className={classes.colorContainer}>
        <div className={classes.wrapper}>
          <div className={classes.tableWrapper}>
            <div className={classes.head}>
              <div className={classes.titleHead}>Dataset Fields</div>
              <Button
                variant="contained"
                startIcon={<AddCircleOutlineIcon className={classes.addIcon} />}
                onClick={handleOpenCreateNewDatasetField}
              >
                Create new field
              </Button>
              {isCreateNewDatasetFieldOpened && (
                <CreateNewDatasetField
                  datasetId={DatasetPresenter.id(currentDataset)}
                  fileType={DatasetPresenter.fileType(currentDataset)}
                  onClose={handleCloseCreateNewDatasetField}
                  onCreate={handleCreateNewDatasetField}
                />
              )}
            </div>
            <TableFields
              fields={fields}
              fieldsFromTreeToList={fieldsFromTreeToList}
              datasetId={DatasetPresenter.id(currentDataset)}
              selectedName={selectedFieldName}
              fileType={DatasetPresenter.fileType(currentDataset)}
            />
          </div>
          {!isBlank(fields) && <div className={classes.fieldDetails}>{renderFieldDetailsForm()}</div>}
        </div>
      </div>
      <PanelControl />
    </div>
  );
};

export default DatasetConstructor;
