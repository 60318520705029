import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(
  (theme) => ({
    icon: {
      width: 16,
      height: 16,
      color: theme.palette.disabled,
    },
    active: {
      color: theme.palette.green,
    },
    name: {
      fontSize: theme.typography.pxToRem(12),
      fontWeight: 600,
      color: theme.palette.text.darkBlue,
      textTransform: 'none',
    },
  }),
  { name: 'SheetSchemaName' },
);
