import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'clsx';
import { TableCell } from '@material-ui/core';
import { find, isEmpty, isNil, propEq } from 'ramda';

import { ReactComponent as LinkIcon } from 'assets/icons/link.svg';
import DropdownInput from 'components/DropdownInput';
import { MapDropdownContent } from 'containers/MapData/components/MapDropdownContent';
import { useMapping, useTemplates, useUserFields } from 'slices/hooks';
import DatasetFieldPresenter from 'presenters/DatasetFieldPresenter';
import { isBlank } from 'utils/conditions';
import { searchFields } from 'utils/fieldsUtils';

import useStyles from './useStyles';

const MapSelector = (props) => {
  const { mapToName, mapToIndex, initialValue, sheetNumber, datasetId, fields, setIsDisabledSubmitButton } = props;
  const { addMappedColumn, removeMappedColumn } = useMapping();
  const [isMappedColumn, setIsMappedColumn] = useState(false);
  const [selectedField, setSelectedField] = useState();
  const { setIsMappedColumnField, isListOfFieldsLoading } = useUserFields();
  const { changeTemplate, isTemplateChanged } = useTemplates();
  const [searchedFields, setSearchedFields] = useState(fields);
  const [searchValue, setSearchValue] = useState('');

  const classes = useStyles();

  useEffect(() => {
    setSearchedFields(fields);
    handleChange(searchValue);
  }, [fields]); // eslint-disable-line

  useEffect(() => {
    if (!isBlank(initialValue) && !isEmpty(fields)) {
      const { fieldName } = initialValue;
      const initialField = find(propEq('nameInMapping', fieldName))(fields);
      if (!isNil(initialField)) {
        mapField(initialField);
      }
    }
  }, [isListOfFieldsLoading, initialValue]); // eslint-disable-line

  useEffect(() => {
    setSelectedField(null);
    setIsMappedColumn(false);
  }, [sheetNumber]); // eslint-disable-line

  const unmapSelectedField = () => {
    setSelectedField(null);
    setIsMappedColumn(false);
    setIsMappedColumnField({ datasetId, name: mapToName, index: mapToIndex, isMapped: false, field: selectedField });

    removeMappedColumn({
      id: DatasetFieldPresenter.id(selectedField),
      datasetId,
    });
  };

  const mapField = (field) => {
    setIsMappedColumn(true);
    setSelectedField(field);
    setIsMappedColumnField({ datasetId, name: mapToName, index: mapToIndex, isMapped: true, field });
    addMappedColumn({
      fieldName: DatasetFieldPresenter.nameInMapping(field),
      columnIndex: mapToIndex,
      id: DatasetFieldPresenter.id(field),
      datasetId,
    });
  };

  const mapFieldToRow = (field) => {
    if (!isTemplateChanged) changeTemplate(true);
    setIsDisabledSubmitButton(false);
    if (!isNil(selectedField)) {
      unmapSelectedField();
      if (selectedField.id !== field.id) {
        DatasetFieldPresenter.isMapped(field) ? mapField(selectedField) : mapField(field);
      }
    } else {
      !DatasetFieldPresenter.isMapped(field) && mapField(field);
    }
  };

  const handleChange = (value) => {
    if (!fields) return;
    const searchResult = searchFields(fields, value);
    setSearchedFields(searchResult);
    setSearchValue(value);
  };

  return (
    <TableCell className={cn({ [classes.mapped]: isMappedColumn }, 'selector')}>
      <DropdownInput
        placeholder="Map to..."
        value={DatasetFieldPresenter.displayName(selectedField)}
        onChange={handleChange}
      >
        <MapDropdownContent fields={searchedFields} onMapField={mapFieldToRow} mapToIndex={mapToIndex} />
      </DropdownInput>
      <div className={classes.stateText}>
        <LinkIcon className={classes.linkIcon} />
        {isMappedColumn ? <span className={classes.whiteText}>MAPPED</span> : <span>NOT MAPPED</span>}
      </div>
    </TableCell>
  );
};

MapSelector.propTypes = {
  mapToName: PropTypes.string,
  mapToIndex: PropTypes.number.isRequired,
  initialValue: PropTypes.shape(),
  sheetNumber: PropTypes.number,
  datasetId: PropTypes.number.isRequired,
  fields: PropTypes.arrayOf(PropTypes.shape()),
  setIsDisabledSubmitButton: PropTypes.func.isRequired,
};

MapSelector.defaultProps = {
  mapToName: '',
  initialValue: {},
  sheetNumber: 0,
  fields: [],
};

export default MapSelector;
