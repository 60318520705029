import * as yup from 'yup';
import { mapObjIndexed } from 'ramda';
import DatasetFieldPresenter from 'presenters/DatasetFieldPresenter';

const MIN_LENGTH = 1;
const MIN_LENGTH_ERROR_MESSAGE = `Ensure this value is greater than or equal to ${MIN_LENGTH}.`;

export const validationFields = {
  displayName: yup.string().required('Name is required'),
  nameInMapping: yup.string().required('Database Field Name is required'),
  validators: yup.lazy((obj) =>
    yup.object(
      mapObjIndexed((value, key) => {
        switch (key) {
          case 'minLength':
            return yup.number().min(MIN_LENGTH, MIN_LENGTH_ERROR_MESSAGE);
          case 'maxLength':
            return yup.number().min(obj.minLength || MIN_LENGTH, 'Max length should not be less than min length.');
          case 'specificLength':
            return yup.number().min(MIN_LENGTH, MIN_LENGTH_ERROR_MESSAGE);
          case 'regularExpression':
            return yup.string().required('This field may not be blank.');
          case 'email':
            return yup.boolean();
          default:
            return null;
        }
      }, obj),
    ),
  ),
};

export const validationSchema = yup.object().shape({ ...validationFields });

export const initialValues = (field) => {
  return {
    displayName: field ? DatasetFieldPresenter.displayName(field) : '',
    nameInMapping: field ? DatasetFieldPresenter.nameInMapping(field) : '',
    mappingType: field ? DatasetFieldPresenter.mappingType(field) : '',
    format: field ? DatasetFieldPresenter.format(field) : '',
    validators: field ? DatasetFieldPresenter.validators(field) : {},
    isRequired: field ? DatasetFieldPresenter.isRequired(field) : false,
    isSubField: field ? DatasetFieldPresenter.isSubField(field) : false,
    parentId: field ? DatasetFieldPresenter.parentIdFormatted(field) : null,
  };
};
