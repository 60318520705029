import palette from 'layouts/CustomThemeProvider/palette';

export default {
  MuiAccordion: {
    root: {
      borderBottom: [1, 'solid', palette.shadow],
      boxShadow: '0px 2px 18px rgba(0, 0, 0, 0.06)',
      '&:before': {
        backgroundColor: palette.shadow,
      },
      '&:first-child': {
        borderTopLeftRadius: 12,
        borderTopRightRadius: 12,
      },
      '&:last-child': {
        borderBottomLeftRadius: 12,
        borderBottomRightRadius: 12,
      },
      '&$expanded': {
        margin: 0,
      },
      '&$disabled': {
        backgroundColor: palette.background.gray,
      },
    },
  },
};
