import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  buttonAdd: {
    display: 'flex',
    fontSize: theme.typography.pxToRem(10),
    color: theme.palette.default,
    cursor: 'pointer',
    paddingLeft: 0,
  },
  buttonLabel: {
    marginLeft: 6,
  },
  addedOptions: {
    marginTop: 14,
  },
  addedValue: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 1.3,
    padding: [10, 16],
    backgroundColor: theme.palette.background.shadow,
    borderRadius: 2,
    marginRight: 9,
  },
  option: {
    display: 'flex',
    alignItems: 'center',
    forntSize: theme.typography.pxToRem(12),
    lineHeight: 1.3,
    color: theme.palette.text.primary,
    marginRight: 3,
    marginTop: 9,
  },
  closeIcon: {
    fill: theme.palette.shadow,
    cursor: 'pointer',
    '&:first-child': {
      fontSize: theme.typography.pxToRem(16),
    },
  },
  inputText: {
    width: 64,
    height: 28,
    border: 'none',
    '& input': {
      border: [1, 'solid', theme.palette.shadow],
      padding: [6, 12],
      borderRadius: 2,
      '&:focus': {
        border: [1, 'solid', theme.palette.default],
      },
    },
  },
  hasInput: {
    padding: [4, 8, 4, 16],
  },
  addIcon: {
    '&:first-child': {
      fontSize: theme.typography.pxToRem(12),
    },
  },
  error: {
    display: 'block',
    position: 'absolute',
    bottom: -10,
    color: theme.palette.errors,
    fontSize: theme.typography.pxToRem(9),
    paddingLeft: 16,
  },
  hasError: {
    '& input': {
      border: [1, 'solid', theme.palette.errors],
    },
  },
  validatorWrapper: {
    position: 'relative',
  },
  valuesCount: {
    display: 'inline-block',
    textAlign: 'center',
    minWidth: 16,
    minHeight: 16,
    borderRadius: 8,
    backgroundColor: theme.palette.default,
    color: theme.palette.text.white,
    fontSize: theme.typography.pxToRem(10),
    fontWeight: 600,
    padding: 2,
    marginLeft: 4,
    cursor: 'pointer',
    border: 'none',
    outline: 'none',
  },
  paper: {
    minWidth: 104,
    height: 96,
    padding: 16,
    marginTop: 8,
    boxShadow: [0, 8, 24, theme.palette.shadowList],
  },
  leftoverValues: {
    display: 'block',
    fontSize: theme.typography.pxToRem(10),
    opacity: 0.7,
    cursor: 'pointer',
    marginTop: 4,
    border: 'none',
    background: 'none',
    padding: 0,
    outline: 'none',
  },
  valueItem: {
    marginTop: 3,
  },
}));
