import * as yup from 'yup';

export const validationFields = {
  templateId: yup.string().required('Template is required or press MAP FROM SCRATCH'),
};

export const validationSchema = yup.object().shape({ ...validationFields });

export const initialValues = {
  templateId: '',
};
