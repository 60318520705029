import React, { useEffect, useState } from 'react';
import { List } from '@material-ui/core';
import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';

import DatasetFieldPresenter from 'presenters/DatasetFieldPresenter';
import DatasetPresenter from 'presenters/DatasetPresenter';

import Field from './Field';

import useStyles from './useStyles';

const AvailableFields2D = (props) => {
  const { fields, dataset } = props;
  const [requiredFields, setRequiredFields] = useState([]);
  const [otherFields, setOtherFields] = useState([]);

  const classes = useStyles();

  useEffect(() => {
    setRequiredFields(fields.filter((field) => DatasetFieldPresenter.isRequired(field)));
    setOtherFields(fields.filter((field) => !DatasetFieldPresenter.isRequired(field)));
  }, [fields]);

  const renderFieldsList = (fieldsList) => {
    return (
      <List>
        {fieldsList.map((field) => (
          <Field
            key={DatasetFieldPresenter.id(field)}
            isMapped={DatasetFieldPresenter.isMapped(field)}
            mappedTo={
              DatasetFieldPresenter.isMapped(field) ? `Col  ${DatasetFieldPresenter.columnIndex(field)}` : 'Not Mapped'
            }
            title={DatasetFieldPresenter.displayName(field)}
          />
        ))}
      </List>
    );
  };

  return (
    <div className={classes.root}>
      <div className={classes.head}>
        <h3>Available fields</h3>
        <p>Data Group: {DatasetPresenter.name(dataset)}</p>
      </div>
      <div>
        {!isEmpty(requiredFields) && (
          <div className={classes.listWrapper}>
            <div className={classes.title}>Required Fields</div>
            {renderFieldsList(requiredFields)}
          </div>
        )}
        {!isEmpty(otherFields) && (
          <div className={classes.listWrapper}>
            <div className={classes.title}>Optional Fields</div>
            {renderFieldsList(otherFields)}
          </div>
        )}
      </div>
    </div>
  );
};

AvailableFields2D.propTypes = {
  fields: PropTypes.arrayOf(DatasetFieldPresenter.shape()),
  dataset: DatasetFieldPresenter.shape(),
};

AvailableFields2D.defaultProps = {
  fields: [],
  dataset: {},
};

export default AvailableFields2D;
