import React, { useEffect, useCallback } from 'react';
import { useParams } from 'react-router';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { Button } from '@material-ui/core';
import { isEmpty, path, isNil } from 'ramda';
import { useFormik } from 'formik';
import { useCookies } from 'react-cookie';

import { appRoutes } from 'routes';
import Breadcrumbs from 'components/Breadcrumbs';
import DatasetPresenter from 'presenters/DatasetPresenter';
import DocumentPresenter from 'presenters/DocumentPresenter';
import InitialParametersPresenter from 'presenters/InitialParametersPresenter';
import MappingSteps from 'components/MappingSteps';
import { getSingleDatasetMappingSteps } from 'components/MappingSteps/steps';
import { useDocuments, useUserDatasets } from 'slices/hooks';
import Icon from 'components/Icon';
import Preloader from 'components/Preloader';
import { handleErrors } from 'utils/errors';
import { ErrorAlert } from 'components/Alerts';
import { isBlank } from 'utils/conditions';
import history from 'utils/history';

import ParametersList from './components/ParametersList';

import useStyles from './useStyles';

const MapInitialParameters = () => {
  const {
    loadCurrentDocument,
    loadDocumentSheets,
    currentDocument,
    isLoading,
    updateDocument,
    apiErrors,
    resetDocuments,
    resetUsedDocuments,
  } = useDocuments();
  const { loadInitialParameters, initialParameters, resetInitialParameters } = useUserDatasets();
  const { documentId } = useParams();
  const fullScreenHandle = useFullScreenHandle();
  const { active, enter, exit } = fullScreenHandle;
  const [cookies, removeCookie] = useCookies(['dataset_initial_parameters']); // eslint-disable-line
  const classes = useStyles();

  useEffect(() => {
    loadCurrentDocument(documentId);
    return () => {
      resetUsedDocuments();
      resetInitialParameters();
    };
  }, []); // eslint-disable-line

  useEffect(() => {
    loadDocumentSheets(documentId);
  }, [currentDocument]); // eslint-disable-line

  useEffect(() => {
    if (isEmpty(currentDocument)) {
      return;
    }
    const currentDataset = DocumentPresenter.dataset(currentDocument);
    const currentDatasetId = DatasetPresenter.id(currentDataset);
    const currentDocumentId = DocumentPresenter.id(currentDocument);

    if (currentDocumentId === Number(documentId)) {
      loadInitialParameters(currentDatasetId);
    }
  }, [currentDocument]); // eslint-disable-line

  const goToNextStep = useCallback(() => {
    if (documentId) {
      const targetUrl = DatasetPresenter.hasSubDatasets()
        ? appRoutes.multiSheetMappingPath(documentId)
        : appRoutes.mappingPath(documentId);

      history.push(targetUrl);
    }
  }, [documentId]); // eslint-disable-line

  useEffect(() => {
    if (isEmpty(initialParameters)) {
      goToNextStep();
    }
  }, [initialParameters]); // eslint-disable-line

  const getInitialValues = () => {
    if (isEmpty(currentDocument) || isNil(initialParameters)) {
      return [];
    }
    const filledInitialParameters = cookies.dataset_initial_parameters;
    const initialValues = isBlank(filledInitialParameters)
      ? DocumentPresenter.initialParameters(currentDocument)
      : filledInitialParameters;

    return initialParameters.map((parameter) => {
      const parameterName = InitialParametersPresenter.name(parameter);
      const parameterDisplayName = InitialParametersPresenter.displayName(parameter);

      const newParameter = {
        name: parameterName,
        displayName: parameterDisplayName,
      };

      Array.isArray(initialValues) &&
        initialValues.map((initialParameter) => {
          const initialParameterName = InitialParametersPresenter.name(initialParameter);
          if (parameterName === initialParameterName) {
            newParameter.value = InitialParametersPresenter.value(initialParameter);
          }
          return false;
        });
      return newParameter;
    });
  };

  const handleContinue = (values, { setErrors }) => {
    return updateDocument(documentId, values)
      .then(() => {
        removeCookie('dataset_initial_parameters');
        goToNextStep();
      })
      .catch((errors) => handleErrors(errors, setErrors));
  };

  const initialValues = getInitialValues();

  const { values, setFieldValue, submitForm } = useFormik({
    initialValues: { initialParameters: initialValues },
    enableReinitialize: true,
    onSubmit: handleContinue,
    validateOnChange: false,
  });

  const handleChangeInitialParameters = (newInitialParameters) => {
    setFieldValue('initialParameters', newInitialParameters);
  };

  const isErrorAlertShowing = !isEmpty(apiErrors) && !path(['initialParameters'], apiErrors);

  const currentDocumentId = DocumentPresenter.id(currentDocument);
  const singleDatasetMappingSteps = getSingleDatasetMappingSteps(currentDocumentId);

  return (
    <div className={classes.top}>
      <form>
        <Breadcrumbs
          breadcrumbs={[
            { link: appRoutes.documentsPath(), name: 'Import Tool' },
            { name: DocumentPresenter.fileName(currentDocument), link: '' },
          ]}
        />
        <div className={classes.wrapper}>
          <FullScreen handle={fullScreenHandle}>
            <div className={classes.head}>
              <MappingSteps steps={singleDatasetMappingSteps} activeStep={1} />
              <button type="button" tabIndex="0" className={classes.fullscreenToggle} onClick={active ? exit : enter}>
                <Icon name="fullscreen" /> Toggle Fullscreen
              </button>
            </div>
            <div className={classes.content}>
              {isLoading ? (
                <Preloader />
              ) : (
                <ParametersList
                  initialParameters={values.initialParameters}
                  onChange={handleChangeInitialParameters}
                  apiErrors={apiErrors.initialParameters}
                />
              )}
            </div>
            {active && (
              <div className={classes.footer}>
                <p>When you’re done matching your data, press Continue to validate it.</p>
                <Button variant="contained" onClick={submitForm}>
                  Continue
                </Button>
              </div>
            )}
          </FullScreen>
          <ErrorAlert onClose={resetDocuments} isShowing={isErrorAlertShowing} errors={apiErrors} />
        </div>
        <div className={classes.footer}>
          <p>Set Initial Parameters</p>
          <Button variant="contained" onClick={submitForm}>
            Continue
          </Button>
        </div>
      </form>
    </div>
  );
};

export default MapInitialParameters;
