import React from 'react';
import PropTypes from 'prop-types';
import cn from 'clsx';
import { isNil } from 'ramda';
import { InputLabel, FormControl, FormHelperText, IconButton } from '@material-ui/core';
import { CloseOutlined as CloseOutlinedIcon } from '@material-ui/icons';

import FilePresenter from 'presenters/FilePresenter';
import Icon from 'components/Icon';
import useStyles from './useStyles';

const InputFile = (props) => {
  const { label, placeholder, name, file, onChange, error, helperText, fieldClassName, accept, onRemove } = props;
  const classes = useStyles();

  const renderEmptyInput = () => (
    <div className={classes.uploadField}>
      <input
        onChange={onChange}
        type="file"
        id={name}
        name={name}
        accept={accept}
        placeholder={placeholder}
        className={cn(classes.input, { [classes.error]: error })}
      />
      <div className={classes.uploadWrapper}>
        <div className={classes.uploadIcon}>
          <Icon name="uploading" className={classes.loadIcon} />
        </div>
        <div className={classes.uploadText}>Drag and drop file in this area or click to attach</div>
      </div>
    </div>
  );

  const renderFileInfo = () => (
    <div className={classes.uploadedFile}>
      <div className={classes.uploadedFileIcon}>
        <Icon name="excel" className={classes.excelIcon} />
      </div>
      <div className={classes.uploadedFileName}>{FilePresenter.name(file)}</div>
      <IconButton color="inherit" className={classes.removeButton} onClick={onRemove}>
        <CloseOutlinedIcon className={classes.removeButtonIcon} />
      </IconButton>
    </div>
  );

  return (
    <FormControl className={cn(classes.field, classes[fieldClassName])}>
      {label && (
        <InputLabel shrink htmlFor={name}>
          {label}
        </InputLabel>
      )}
      {isNil(file) ? renderEmptyInput() : renderFileInfo()}
      <FormHelperText className={classes.error}>{helperText}</FormHelperText>
    </FormControl>
  );
};

InputFile.propTypes = {
  file: FilePresenter.shape(),
  error: PropTypes.bool,
  onChange: PropTypes.func,
  helperText: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  fieldClassName: PropTypes.string,
  accept: PropTypes.string,
  onRemove: PropTypes.func,
};

InputFile.defaultProps = {
  helperText: '',
  error: false,
  onChange: null,
  file: null,
  label: null,
  placeholder: '',
  fieldClassName: 'marginBottomMd',
  accept: '',
  onRemove: null,
};

export default InputFile;
