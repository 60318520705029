import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';

import { appRoutes } from 'routes';
import history from 'utils/history';

import useStyles from '../../useStyles';

const UncorrectableErrorScreen = (props) => {
  const { documentId, errors } = props;
  const classes = useStyles();

  const renderUncorrectableErrors = () => {
    return errors.map((error, index) => <div key={index}>{error}</div>);
  };

  const handleNavigation = (to) => {
    history.push(to);
  };

  const renderFailedValidationInfo = () => (
    <div className={classes.failedInfo}>
      <div>This could have happened for two reasons:</div>
      <ul>
        <li>
          Some fields were mapped incorrectly. You can{' '}
          <Link to={appRoutes.mappingPath(documentId)} className={classes.link}>
            map the file
          </Link>{' '}
          again.
        </li>
        <li>
          There were inconsistencies in the uploaded file. You can fix the original file and{' '}
          <Link to={appRoutes.documentsImportPath()} className={classes.link}>
            re-upload it
          </Link>
          .
        </li>
      </ul>
    </div>
  );

  return (
    <>
      <div className={classes.validateContainer}>
        <div className={classes.validateFailed}>
          <h3>Validation Failed.</h3>
          {renderUncorrectableErrors()}
          {renderFailedValidationInfo()}
        </div>
      </div>
      <div className={classes.footer}>
        <p>Validation failed.</p>
        <div className={classes.footerActions}>
          <Button variant="outlined" onClick={() => handleNavigation(appRoutes.rootPath())}>
            GO TO HOME SCREEN
          </Button>
          <Button variant="outlined" onClick={() => handleNavigation(appRoutes.documentsPath())}>
            IMPORT ANOTHER
          </Button>
          <Button variant="contained" onClick={() => handleNavigation(appRoutes.multiSheetMappingPath(documentId))}>
            Back to mapping
          </Button>
        </div>
      </div>
    </>
  );
};

UncorrectableErrorScreen.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string),
  documentId: PropTypes.number.isRequired,
};

UncorrectableErrorScreen.defaultProps = {
  errors: [],
};

export default UncorrectableErrorScreen;
