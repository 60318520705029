import { useSelector } from 'react-redux';

import { useTemplatesActions } from 'slices/TemplatesSlice';

export const useTemplates = () => {
  const {
    loadTemplates,
    resetTemplates,
    create2DTemplate,
    updateTemplate,
    deleteTemplate,
    resetCurrentTemplate,
    validateTemplate,
    load3DTemplates,
    load2DTemplates,
    create3DTemplate,
    adaptive3DTemplate,
    createMultiSheetTemplate,
    loadMultiSheetTemplates,
    updateMultiSheetTemplate,
    deleteMultiSheetTemplate,
    changeTemplate,
    adaptiveMultiSheetTemplate,
  } = useTemplatesActions();
  const isLoading = useSelector((state) => state.TemplatesSlice.loading);
  const apiTemplateErrors = useSelector((state) => state.TemplatesSlice.errors);
  const templates = useSelector((state) => state.TemplatesSlice.templates);
  const templates2D = useSelector((state) => state.TemplatesSlice.templates2D);
  const templates3D = useSelector((state) => state.TemplatesSlice.templates3D);
  const meta = useSelector((state) => state.TemplatesSlice.meta);
  const currentTemplate = useSelector((state) => state.TemplatesSlice.currentTemplate);
  const isTemplateChanged = useSelector((state) => state.TemplatesSlice.isTemplateChanged);
  return {
    loadTemplates,
    updateTemplate,
    deleteTemplate,
    resetTemplates,
    isLoading,
    apiTemplateErrors,
    templates,
    meta,
    currentTemplate,
    resetCurrentTemplate,
    validateTemplate,
    load3DTemplates,
    load2DTemplates,
    templates3D,
    templates2D,
    create3DTemplate,
    create2DTemplate,
    adaptive3DTemplate,
    createMultiSheetTemplate,
    loadMultiSheetTemplates,
    updateMultiSheetTemplate,
    deleteMultiSheetTemplate,
    changeTemplate,
    isTemplateChanged,
    adaptiveMultiSheetTemplate,
  };
};
