import { createSlice } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import UserDatasetParametersRepository from 'repositories/UserDatasetParametersRepository';
import UserDatasetsRepository from 'repositories/UserDatasetsRepository';
import { useActions } from 'utils/appHooks';

const initialState = {
  loading: false,
  datasets: [],
  meta: {},
  currentDataset: {},
  errors: {},
  initialParameters: null,
};

const datasetsSlice = createSlice({
  name: 'datasets',
  initialState,
  reducers: {
    loadDatasetsSuccess(state, { payload }) {
      state.datasets = payload.items;
      state.meta = payload.meta;
      state.errors = {};
    },
    loadDatasetsFail(state, { payload }) {
      state.errors = payload;
    },
    loadingStart(state) {
      state.loading = true;
    },
    loadingFinish(state) {
      state.loading = false;
    },
    resetDatasets(state) {
      state.loading = initialState.loading;
      state.datasets = initialState.datasets;
      state.meta = initialState.meta;
      state.errors = initialState.errors;
      state.currentDataset = initialState.currentDataset;
    },
    loadCurrentDatasetSuccess(state, { payload }) {
      state.currentDataset = payload;
      state.errors = {};
    },
    loadCurrentDatasetFail(state, { payload }) {
      state.errors = payload;
    },
    resetCurrentDataset(state) {
      state.loading = initialState.loading;
      state.currentDataset = initialState.currentDataset;
      state.meta = initialState.meta;
      state.errors = initialState.errors;
      state.isErrorAlertShowing = initialState.isErrorAlertShowing;
    },
    loadInitParamsSuccess(state, { payload }) {
      state.initialParameters = payload.parameters;
    },
    loadInitParamsFail(state, { payload }) {
      state.initialParameters = initialState.initialParameters;
      state.errors = payload;
    },
    resetInitialParameters(state) {
      state.initialParameters = initialState.initialParameters;
    },
  },
});

export default datasetsSlice.reducer;
export const { resetDatasets, resetCurrentDataset, resetInitialParameters } = datasetsSlice.actions;

export const useDatasetsActions = () => {
  const dispatch = useDispatch();

  const loadDatasets = (params) => {
    dispatch(datasetsSlice.actions.loadingStart());
    return UserDatasetsRepository.index(params)
      .then((response) => dispatch(datasetsSlice.actions.loadDatasetsSuccess(response)))
      .catch((errors) => dispatch(datasetsSlice.actions.loadDatasetsFail(errors)))
      .finally(() => dispatch(datasetsSlice.actions.loadingFinish()));
  };

  const loadCurrentDataset = (datasetId) => {
    dispatch(datasetsSlice.actions.loadingStart());
    return UserDatasetsRepository.current(datasetId)
      .then((response) => dispatch(datasetsSlice.actions.loadCurrentDatasetSuccess(response.data)))
      .catch((errors) => dispatch(datasetsSlice.actions.loadCurrentDatasetFail(errors)))
      .finally(() => dispatch(datasetsSlice.actions.loadingFinish()));
  };

  const loadInitialParameters = (datasetId) => {
    dispatch(datasetsSlice.actions.loadingStart());
    return UserDatasetParametersRepository.index(datasetId)
      .then((response) => dispatch(datasetsSlice.actions.loadInitParamsSuccess(response.data)))
      .catch((errors) => dispatch(datasetsSlice.actions.loadInitParamsFail(errors)))
      .finally(() => dispatch(datasetsSlice.actions.loadingFinish()));
  };

  const actions = useActions({ resetDatasets, resetCurrentDataset, resetInitialParameters });

  return {
    loadDatasets,
    loadCurrentDataset,
    loadInitialParameters,
    ...actions,
  };
};
