import React, { useState, useEffect } from 'react';
import { Button, Tabs, Tab } from '@material-ui/core';

import ImportFile from 'components/Dialogs/ImportFile';
import { getTabLinkProps } from 'utils/tabsUtils';
import { useRouter } from 'utils/appHooks';
import LocationPresenter from 'presenters/LocationPresenter';
import TabPanel from './components/TabPanel';
import FilesPanel from './FilesPanel';
import TemplatesPanel from './TemplatesPanel';
import { useTemplates } from 'slices/hooks';

import useStyles from './useStyles';

const TABS = [
  {
    id: 1,
    hash: '#files',
    name: 'Files',
  },
  {
    id: 2,
    hash: '#templates',
    name: 'Templates',
  },
];

const TEMPLATES_TAB_HASH = '#templates';
const IMPORT_POPUP_HASH = '#import';

const Documents = () => {
  const [isImportPopupOpen, setOpen] = useState(false);
  const [currentTabIndex, setCurrentTab] = useState(0);
  const { location } = useRouter();
  const { resetTemplates } = useTemplates();

  const classes = useStyles();

  useEffect(() => {
    switch (LocationPresenter.hash(location)) {
      case TEMPLATES_TAB_HASH:
        setCurrentTab(1);
        break;
      case IMPORT_POPUP_HASH:
        setOpen(true);
        break;
      default:
    }
    return resetTemplates;
  }, []); // eslint-disable-line

  const handleOpenPopup = () => {
    setOpen(true);
  };

  const handleClosePopup = () => {
    setOpen(false);
  };

  const handleChangeTab = (event, newIndex) => {
    setCurrentTab(newIndex);
  };

  return (
    <>
      <div className={classes.top}>
        <h1 className={classes.title}>Import Tool</h1>
        <Button data-test="importFile" variant="contained" onClick={handleOpenPopup}>
          Import File
        </Button>
        {isImportPopupOpen && <ImportFile onClose={handleClosePopup} />}
      </div>
      <Tabs value={currentTabIndex} className={classes.tabs} onChange={handleChangeTab}>
        {TABS.map((tab) => (
          <Tab {...getTabLinkProps(tab)} />
        ))}
      </Tabs>
      <TabPanel value={currentTabIndex} index={0} className={classes.panelTable}>
        <FilesPanel />
      </TabPanel>
      <TabPanel value={currentTabIndex} index={1} className={classes.panelTable}>
        <TemplatesPanel />
      </TabPanel>
    </>
  );
};

export default Documents;
