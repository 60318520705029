import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { head, isEmpty, propEq, find } from 'ramda';
import PropTypes from 'prop-types';
import { useTable } from 'react-table';

import { alphabet } from 'utils/stringUtils';
import { FixErrorMenu } from 'containers/MapValidate/components';

import MapTableCell from './components/MapTableCell';

import useStyles from './useStyles';

const defaultColumn = {
  Cell: (props) => {
    /* eslint-disable */
    const {
      value: initialValue,
      column: { Header },
      onEdit,
      error,
      errorState,
      tooltipMessages,
      id,
      nameInMapping,
    } = props;
    /* eslint-disable */

    return (
      <FixErrorMenu
        onEdit={onEdit}
        index={id}
        header={Header}
        value={initialValue}
        error={error}
        errorState={errorState}
        tooltipMessages={tooltipMessages}
        nameInMapping={nameInMapping}
      />
    );
  },
};

const MapDataTable2D = (props) => {
  const { tableRows, onEdit, type } = props;
  const classes = useStyles();
  const data = React.useMemo(() => {
    return tableRows.map((row) => {
      return Object.keys(row.values).reduce((acc, key, index) => {
        return { ...acc, [index]: row.values[key] };
      }, {});
    });
  }, [tableRows]);

  const columns = React.useMemo(() => {
    return Object.keys(head(tableRows).values).map((name, i) => {
      return { Header: name.toString(), accessor: i.toString() };
    });
  }, [tableRows]);

  const tableInstance = useTable({ columns, data, defaultColumn, onEdit, type });
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  const renderAlphabetRow = (headerGroup) => (
    <TableRow {...headerGroup.getHeaderGroupProps()}>
      <TableCell />
      {headerGroup.headers.map((column, i) => {
        return <TableCell {...column.getHeaderProps()}>{alphabet[i]}</TableCell>;
      })}
    </TableRow>
  );

  const renderHeaderRow = (headerGroup) => (
    <TableRow {...headerGroup.getHeaderGroupProps()}>
      <TableCell />
      {headerGroup.headers.map((column) => {
        return <TableCell {...column.getHeaderProps()}>{column.render('Header')}</TableCell>;
      })}
    </TableRow>
  );

  const renderBodyRow = (row) => {
    const { id, errors } = tableRows[row.id];
    prepareRow(row);

    return (
      <TableRow {...row.getRowProps()}>
        <TableCell>
          <div>{Number(id) + 1}</div>
        </TableCell>
        {row.cells.map((cell, index) => {
          const error = find(propEq('errorField', cell.column.Header.toLowerCase()))(errors);
          return <MapTableCell key={index} cell={cell} error={error} id={id} />;
        })}
      </TableRow>
    );
  };

  if (isEmpty(tableRows)) {
    return null;
  }

  return (
    <>
      <TableContainer className={classes.table}>
        <Table {...getTableProps()}>
          <TableHead>{headerGroups.map((headerGroup) => renderAlphabetRow(headerGroup))}</TableHead>
          <TableHead className={classes.header}>
            {headerGroups.map((headerGroup) => renderHeaderRow(headerGroup))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>{rows.map((row) => renderBodyRow(row))}</TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

MapDataTable2D.propTypes = {
  tableRows: PropTypes.instanceOf(Array),
  onEdit: PropTypes.func.isRequired,
  type: PropTypes.string,
};

MapDataTable2D.defaultProps = {
  tableRows: [],
  type: '',
};

export default MapDataTable2D;
