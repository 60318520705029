import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  headList: {
    display: 'flex',
  },
  headListItem: {
    margin: [0, 40, 0, 0],
    fontSize: theme.typography.pxToRem(12),
    minWidth: 'auto',
    color: 'inherit',
    lineHeight: 'inherit',
    textTransform: 'none',
    letterSpacing: 0,
    padding: [12, 0],
    borderRadius: 0,
    borderBottom: [2, 'solid', theme.palette.transparent],
  },
  disabledItem: {
    opacity: '0.4',
    pointerEvents: 'none',
  },
  activeItem: {
    borderBottom: [2, 'solid', theme.palette.title],
  },
  headListIcon: {
    borderRadius: 100,
    background: theme.palette.title,
    padding: [4, 7, 3],
    marginRight: 5,
    color: theme.palette.text.white,
  },
  initialStep: {
    pointerEvents: 'none',
  },
  mappingStep: {
    pointerEvents: 'none',
    '&:first-child': {
      pointerEvents: 'auto',
    },
  },
  validateStep: {
    pointerEvents: 'auto',
    '&:last-child': {
      pointerEvents: 'none',
    },
  },
  summaryStep: {
    pointerEvents: 'none',
  },
}));
