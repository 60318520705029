import { apiRoutes } from 'routes';
import FetchHelpers from 'utils/FetchHelpers';

export default {
  current(documentId) {
    const path = apiRoutes.currentMappingPath(documentId);
    return FetchHelpers.get(path);
  },
  result(documentId) {
    const path = apiRoutes.mappingResult(documentId);
    return FetchHelpers.post(path);
  },
  validate(params) {
    const path = apiRoutes.mappingsValidatePath();
    return FetchHelpers.post(path, params);
  },
  create(params) {
    const path = apiRoutes.mappingsPath();
    return FetchHelpers.post(path, params);
  },
};
