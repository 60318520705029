import React, { useEffect, useState } from 'react';
import { List, ListItem } from '@material-ui/core';
import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';

import { useDocuments } from 'slices/hooks';
import DocumentPresenter from 'presenters/DocumentPresenter';
import DatasetFieldPresenter from 'presenters/DatasetFieldPresenter';
import DatasetPresenter from 'presenters/DatasetPresenter';
import MapDropdownItem from 'containers/MapData/components/MapDropdownItem';

import useStyles from './useStyles';

const MapColumnDropdownContent = (props) => {
  const { onMapField, fields, mapToIndex } = props;
  const [requiredFields, setRequiredFields] = useState([]);
  const [otherFields, setOtherFields] = useState([]);
  const [requiredFieldsColumnType, setRequiredFieldsColumnType] = useState([]);
  const [otherFieldsColumnType, setOtherFieldsColumnType] = useState([]);
  const { currentDocument } = useDocuments();

  const classes = useStyles();

  const isColumnType = (field) => DatasetFieldPresenter.mappingType(field) === 'column';
  useEffect(() => {
    setRequiredFields(fields.filter((field) => DatasetFieldPresenter.isRequired(field)));
    setOtherFields(fields.filter((field) => !DatasetFieldPresenter.isRequired(field)));
  }, [fields]);

  useEffect(() => {
    setRequiredFieldsColumnType(requiredFields.filter((field) => isColumnType(field)));
  }, [requiredFields]);

  useEffect(() => {
    setOtherFieldsColumnType(otherFields.filter((field) => isColumnType(field)));
  }, [otherFields]);

  const currentDataset = DocumentPresenter.dataset(currentDocument);
  const currentDatasetName = DatasetPresenter.name(currentDataset);

  return (
    <div className={classes.root}>
      {isEmpty(fields) ? (
        <div className={classes.hint}>no fields available</div>
      ) : (
        <>
          {!isEmpty(requiredFieldsColumnType) && (
            <List className={classes.list}>
              <ListItem className={classes.listTitle}>Required Fields: {currentDatasetName}</ListItem>
              {requiredFieldsColumnType.map((field) => (
                <MapDropdownItem key={field.id} onMapField={onMapField} field={field} mapToIndex={mapToIndex} />
              ))}
            </List>
          )}
          {!isEmpty(otherFieldsColumnType) && (
            <List className={classes.list}>
              <ListItem className={classes.listTitle}>All Available Fields</ListItem>
              {otherFieldsColumnType.map((field) => (
                <MapDropdownItem key={field.id} onMapField={onMapField} field={field} mapToIndex={mapToIndex} />
              ))}
            </List>
          )}
        </>
      )}
    </div>
  );
};

MapColumnDropdownContent.propTypes = {
  onMapField: PropTypes.func,
  fields: PropTypes.arrayOf(DatasetFieldPresenter.shape()),
  mapToIndex: PropTypes.number.isRequired,
};

MapColumnDropdownContent.defaultProps = {
  onMapField: () => {},
  fields: [],
};

export default MapColumnDropdownContent;
