import axios from 'axios';
import qs from 'qs';
import { serialize } from 'object-to-formdata';
import { isEmpty } from 'ramda';

import { apiErrorsToFormik } from 'utils/errors';
import { appRoutes } from 'routes';
import history from 'utils/history';
import LocationPresenter from 'presenters/LocationPresenter';

import { camelize, decamelize } from './keysConverter';

function headers() {
  return {
    Accept: '*/*',
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };
}

function headersMultipartFormData() {
  return {
    Accept: '*/*',
    'X-Requested-With': 'XMLHttpRequest',
  };
}

axios.interceptors.request.use(
  (config) => {
    return { ...config, headers: headers() };
  },
  (error) => {
    return Promise.reject(error);
  },
);

axios.interceptors.response.use(null, (error) => {
  const responsesWithParsableErrors = [400, 422, 502];
  if (axios.isCancel(error)) {
    return Promise.reject(new Error(`Request cancelled`));
  }
  if (responsesWithParsableErrors.includes(error.response.status)) {
    const errors = apiErrorsToFormik(error);
    return Promise.reject(camelize(errors));
  }
  if (error.response.status === 500) {
    return Promise.reject(new Error('Something went wrong, please retry again'));
  }
  if (error.response.status === 403) {
    history.push(appRoutes.errorPath(403));
  }
  if (error.response.status === 404) {
    history.push(appRoutes.errorPath(404));
  }
  if (error.response.status === 401) {
    const { location } = history;
    const queryParams = LocationPresenter.search(location);
    isEmpty(queryParams) ? history.push(appRoutes.loginPath()) : history.push(`${appRoutes.loginPath()}${queryParams}`);
    const errors = apiErrorsToFormik(error);
    return Promise.reject(camelize(errors));
  }
  return Promise.reject(error);
});
export default {
  get(url, params = {}, cancelToken = null) {
    return axios
      .get(url, {
        params: decamelize(params),
        paramsSerializer: (parameters) => qs.stringify(parameters, { encode: false, arrayFormat: 'brackets' }),
        cancelToken,
      })
      .then(({ data }) => camelize(data));
  },
  post(url, json) {
    const body = decamelize(json);
    return axios.post(url, body).then(({ data }) => camelize(data));
  },
  put(url, json) {
    const body = decamelize(json);
    return axios.put(url, body).then(camelize);
  },
  delete(url, json) {
    const body = decamelize(json);
    return axios.delete(url, { data: body }).then(camelize);
  },
  patch(url, json) {
    const body = decamelize(json);
    return axios.patch(url, body).then(camelize);
  },
  postMultipartFormData(url, body) {
    const formData = serialize(decamelize(body));
    return axios
      .post(url, formData, {
        headers: headersMultipartFormData(),
        withCredentials: true,
        credentials: 'same-origin',
      })
      .then((response) => camelize(response.data));
  },
};
