import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  actionsCell: {
    visibility: 'hidden',
  },
  actionsRow: {
    '&:hover td:last-child > button': {
      visibility: 'visible',
    },
  },
}));
