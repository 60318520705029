import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { isEmpty, prop } from 'ramda';
import { Button } from '@material-ui/core';

import {
  getFieldFormatOptions,
  DEFAULT_FORMAT,
  MAPPING_TYPES_OPTIONS,
  MAPPING_TYPES,
  is3DMapping,
  getFieldTreeOptions,
} from 'helpers/fieldDetailsOptions';
import { TextField, Select, Checkbox } from 'components/Common';
import DatasetFieldPresenter from 'presenters/DatasetFieldPresenter';
import { validationSchema, initialValues as initialValuesDefault } from 'forms/UpdateFieldForm';
import { handleErrors } from 'utils/errors';
import { useFields } from 'slices/hooks';
import { isBlank } from 'utils/conditions';
import { ErrorAlert } from 'components/Alerts';
import { MultiSelectValidation, DetailsMenu } from 'containers/Datasets/components';

import useStyles from './useStyles';

const FormDetails = (props) => {
  const { currentField, datasetId, fileType, fieldsTree } = props;
  const classes = useStyles();
  const {
    updateField,
    loadFields,
    loadCurrentField,
    apiErrors,
    isErrorAlertShowing,
    resetFieldsErrors,
    loadValidateFieldErrors,
    resetValidateFieldErrors,
    fieldFormats,
  } = useFields();

  const handleUpdateField = (formValues, { setErrors }) => {
    if (isEmpty(errors)) {
      const values = formValues.isSubField ? formValues : { ...formValues, parentId: null };
      updateField(DatasetFieldPresenter.id(currentField), values)
        .then(() => loadFields({ datasetId }))
        .then(() => loadCurrentField(DatasetFieldPresenter.id(currentField)))
        .then(() => loadValidateFieldErrors({}))
        .catch((errors) => handleErrors(errors, setErrors));
    }
  };

  const { values, errors, setFieldValue, submitForm, touched } = useFormik({
    initialValues: initialValuesDefault(currentField),
    validationSchema,
    onSubmit: handleUpdateField,
    validateOnChange: true,
    validateOnBlur: true,
  });

  useEffect(() => {
    loadValidateFieldErrors(errors);
    return resetValidateFieldErrors;
  }, [errors]); // eslint-disable-line

  const handleFieldChange = (field) => ({ target: { value } }) => {
    // delete this condition when added validators for another formats of field
    if (field === 'format' && value !== DEFAULT_FORMAT) {
      setFieldValue('validators', {});
    }
    setFieldValue(field, value);
  };

  const handleCheckboxChange = (field) => ({ target: { value } }) => {
    setFieldValue(field, !values[field]);
  };

  const handleChangeValidator = (newValidators) => {
    setFieldValue('validators', { ...newValidators });
  };

  const isValidatorsShowing = currentField && DatasetFieldPresenter.format(values) === DEFAULT_FORMAT;
  const isErrorsByAlertShowing = isBlank(prop('validators', apiErrors));
  const shouldContainNoFormat = values.mappingType === MAPPING_TYPES.row;
  const currentFieldId = DatasetFieldPresenter.id(currentField);

  return (
    <div>
      <div className={classes.formName}>Field Details</div>
      <DetailsMenu current={currentField} datasetId={datasetId} />
      <form className={classes.form}>
        {is3DMapping(fileType) && (
          <Select
            value={values.mappingType}
            label="Mapping"
            onChange={handleFieldChange('mappingType')}
            name="mappingType"
            options={MAPPING_TYPES_OPTIONS}
          />
        )}
        <TextField
          value={values.nameInMapping}
          onChange={handleFieldChange('nameInMapping')}
          error={touched.nameInMapping && !!errors.nameInMapping}
          name="nameInMapping"
          placeholder="email"
          label="Dataset Field Name"
          helperText={touched.nameInMapping && errors.nameInMapping}
        />
        <TextField
          value={values.displayName}
          onChange={handleFieldChange('displayName')}
          error={touched.displayName && !!errors.displayName}
          name="displayName"
          placeholder="Email"
          label="Field Name for User"
          helperText={touched.displayName && errors.displayName}
        />
        <div className={classes.checkboxWrapper}>
          <Checkbox
            checked={values.isRequired}
            onChange={handleCheckboxChange('isRequired')}
            name="isRequired"
            label="Required Field"
          />
        </div>
        <div className={classes.checkboxWrapper}>
          <Checkbox
            checked={values.isSubField}
            onChange={handleCheckboxChange('isSubField')}
            name="isSubField"
            label="Sub-field"
          />
        </div>
        {values.isSubField && (
          <div className={classes.selectParentWrapper}>
            <Select
              value={values.parentId}
              label="Parent Field"
              onChange={handleFieldChange('parentId')}
              name="parentId"
              placeholder="Select Parent Field"
              options={getFieldTreeOptions(fieldsTree, currentFieldId, values.mappingType)}
            />
          </div>
        )}
        <div className={classes.formWrapper}>
          <Select
            value={values.format}
            label="Cell Format"
            onChange={handleFieldChange('format')}
            name="format"
            options={getFieldFormatOptions(fieldFormats, shouldContainNoFormat)}
          />
          {isValidatorsShowing && (
            <MultiSelectValidation
              touched={touched.validators}
              validators={values.validators}
              onChange={handleChangeValidator}
              onSaveValidators={submitForm}
              apiErrors={apiErrors.validators}
              validateErrors={errors.validators}
            />
          )}
        </div>
        <div className={classes.actions}>
          <Button onClick={submitForm} variant="contained" className={classes.buttonSave}>
            Save
          </Button>
        </div>
      </form>
      {isErrorsByAlertShowing && (
        <ErrorAlert onClose={resetFieldsErrors} isShowing={isErrorAlertShowing} errors={apiErrors} />
      )}
    </div>
  );
};

FormDetails.propTypes = {
  currentField: DatasetFieldPresenter.shape(),
  datasetId: PropTypes.number.isRequired,
  fileType: PropTypes.string.isRequired,
  fieldsTree: PropTypes.arrayOf(DatasetFieldPresenter.shape()).isRequired,
};

FormDetails.defaultProps = {
  currentField: {},
};

export default FormDetails;
