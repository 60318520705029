import { createSlice } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import FieldsRepository from 'repositories/FieldsRepository';
import { useActions } from 'utils/appHooks';

const initialState = {
  loading: false,
  fields: [],
  meta: {},
  errors: {},
  currentField: {},
  isErrorAlertShowing: false,
  validateErrors: {},
  formats: {},
  validators: {},
};

const fieldsSlice = createSlice({
  name: 'fields',
  initialState,
  reducers: {
    loadFieldsSuccess(state, { payload }) {
      state.fields = payload.items;
      state.meta = payload.meta;
    },
    loadFieldsFail(state, { payload }) {
      state.errors = payload;
    },
    loadingStart(state) {
      state.loading = true;
    },
    loadingFinish(state) {
      state.loading = false;
    },
    resetFields(state) {
      state.loading = initialState.loading;
      state.fields = initialState.fields;
      state.meta = initialState.meta;
      state.errors = initialState.errors;
    },
    createFieldSuccess(state) {
      state.errors = {};
    },
    createFieldFail(state, { payload }) {
      state.errors = payload;
      state.isErrorAlertShowing = true;
    },
    updateFieldSuccess(state) {
      state.errors = {};
      state.isErrorAlertShowing = false;
    },
    updateFieldFail(state, { payload }) {
      state.errors = payload;
      state.isErrorAlertShowing = true;
    },
    deleteFieldSuccess(state) {
      state.errors = {};
    },
    deleteFieldFail(state, { payload }) {
      state.errors = payload;
    },
    loadCurrentFieldSuccess(state, { payload }) {
      state.currentField = payload;
    },
    loadCurrentFieldFail(state, { payload }) {
      state.errors = payload;
    },
    resetCurrentField(state) {
      state.loading = initialState.loading;
      state.currentField = initialState.currentField;
      state.meta = initialState.meta;
      state.errors = initialState.errors;
      state.validateErrors = initialState.validateErrors;
    },
    loadValidateFieldErrors(state, { payload }) {
      state.validateErrors = payload;
    },
    resetFieldsErrors(state) {
      state.errors = initialState.errors;
      state.isErrorAlertShowing = initialState.isErrorAlertShowing;
    },
    resetValidateFieldErrors(state) {
      state.validateErrors = initialState.validateErrors;
    },
    loadFieldFormatsSuccess(state, { payload }) {
      state.formats = payload.items;
    },
    loadFieldFormatsFail(state, { payload }) {
      state.errors = payload;
    },
    loadFieldValidatorsSuccess(state, { payload }) {
      state.validators = payload.items;
    },
    loadFieldValidatorsFail(state, { payload }) {
      state.errors = payload;
    },
  },
});

export default fieldsSlice.reducer;

export const {
  resetFields,
  resetCurrentField,
  resetFieldsErrors,
  loadValidateFieldErrors,
  resetValidateFieldErrors,
} = fieldsSlice.actions;

export const useFieldsActions = () => {
  const dispatch = useDispatch();

  const loadFields = (params) => {
    dispatch(fieldsSlice.actions.loadingStart());
    return FieldsRepository.index(params)
      .then((response) => dispatch(fieldsSlice.actions.loadFieldsSuccess(response)))
      .catch((errors) => dispatch(fieldsSlice.actions.loadFieldsFail(errors)))
      .finally(() => dispatch(fieldsSlice.actions.loadingFinish()));
  };

  const createField = (params) => {
    dispatch(fieldsSlice.actions.loadingStart());
    return FieldsRepository.create(params)
      .then((response) => dispatch(fieldsSlice.actions.createFieldSuccess(response.data)))
      .catch((errors) => dispatch(fieldsSlice.actions.createFieldFail(errors)))
      .finally(() => dispatch(fieldsSlice.actions.loadingFinish()));
  };

  const updateField = (fieldId, params) => {
    dispatch(fieldsSlice.actions.loadingStart());
    return FieldsRepository.update(fieldId, params)
      .then((response) => dispatch(fieldsSlice.actions.updateFieldSuccess(response.data)))
      .catch((errors) => dispatch(fieldsSlice.actions.updateFieldFail(errors)))
      .finally(() => dispatch(fieldsSlice.actions.loadingFinish()));
  };

  const deleteField = (fieldId) => {
    dispatch(fieldsSlice.actions.loadingStart());
    return FieldsRepository.delete(fieldId)
      .then((response) => dispatch(fieldsSlice.actions.deleteFieldSuccess(response.data)))
      .catch((errors) => dispatch(fieldsSlice.actions.deleteFieldFail(errors)))
      .finally(() => dispatch(fieldsSlice.actions.loadingFinish()));
  };

  const loadCurrentField = (fieldId) => {
    dispatch(fieldsSlice.actions.loadingStart());
    return FieldsRepository.current(fieldId)
      .then((response) => dispatch(fieldsSlice.actions.loadCurrentFieldSuccess(response.data)))
      .catch((errors) => dispatch(fieldsSlice.actions.loadCurrentFieldFail(errors)))
      .finally(() => dispatch(fieldsSlice.actions.loadingFinish()));
  };

  const loadFieldFormats = () => {
    dispatch(fieldsSlice.actions.loadingStart());
    return FieldsRepository.loadFormats()
      .then((response) => dispatch(fieldsSlice.actions.loadFieldFormatsSuccess(response.data)))
      .catch((errors) => dispatch(fieldsSlice.actions.loadFieldFormatsFail(errors)))
      .finally(() => dispatch(fieldsSlice.actions.loadingFinish()));
  };

  const loadFieldValidators = () => {
    dispatch(fieldsSlice.actions.loadingStart());
    return FieldsRepository.loadValidators()
      .then((response) => dispatch(fieldsSlice.actions.loadFieldValidatorsSuccess(response.data)))
      .catch((errors) => dispatch(fieldsSlice.actions.loadFieldValidatorsFail(errors)))
      .finally(() => dispatch(fieldsSlice.actions.loadingFinish()));
  };

  const actions = useActions({
    resetFields,
    resetCurrentField,
    resetFieldsErrors,
    loadValidateFieldErrors,
    resetValidateFieldErrors,
  });

  return {
    loadFields,
    createField,
    updateField,
    deleteField,
    loadCurrentField,
    loadFieldFormats,
    loadFieldValidators,
    ...actions,
  };
};
