import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { isEmpty, path } from 'ramda';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton } from '@material-ui/core';
import { CloseOutlined as CloseOutlinedIcon } from '@material-ui/icons';

import { useDatasets } from 'slices/hooks';
import { handleErrors } from 'utils/errors';
import DatasetPresenter from 'presenters/DatasetPresenter';

import ParametersList from './ParametersList';

import useStyles from 'components/Dialogs/useStyles';

const DatasetInitialParams = (props) => {
  const { onClose } = props;
  const {
    isLoading,
    currentDataset,
    apiErrors,
    updateInitialParameters,
    initialParameters,
    loadInitialParameters,
  } = useDatasets();
  const currentDatasetId = DatasetPresenter.id(currentDataset);

  const classes = useStyles();

  useEffect(() => {
    loadInitialParameters(currentDatasetId);
  }, []); // eslint-disable-line

  const handleUpdateInitialParameters = (values, { setErrors }) => {
    return updateInitialParameters(currentDatasetId, values).catch((errors) => handleErrors(errors, setErrors));
  };

  const { values, setFieldValue, submitForm } = useFormik({
    initialValues: { parameters: initialParameters },
    onSubmit: handleUpdateInitialParameters,
    validateOnChange: true,
  });

  const handleChangeParameters = (newInitialParameters) => {
    setFieldValue('parameters', newInitialParameters);
  };

  const handleSubmitForm = (e) => {
    e.preventDefault();
    submitForm(values);
  };

  const renderErrors = (errorsApi) => {
    if (!path(['parameters'], errorsApi)) {
      return Object.keys(errorsApi).map((key, index) => (
        <span className={classes.error} key={index}>
          {errorsApi[key]}
        </span>
      ));
    }
    return null;
  };

  return (
    <Dialog onClose={onClose} open maxWidth="md">
      <DialogTitle>Dataset Initial Parameters</DialogTitle>
      <IconButton color="inherit" onClick={onClose}>
        <CloseOutlinedIcon />
      </IconButton>
      <form onSubmit={handleSubmitForm}>
        <DialogContent>
          <ParametersList
            initialParameters={values.parameters}
            onChange={handleChangeParameters}
            apiErrors={apiErrors.parameters}
            datasetId={currentDatasetId}
          />
          <div className={classes.initialParamsErrors}>{!isEmpty(apiErrors) && renderErrors(apiErrors)}</div>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="text">
            Cancel
          </Button>
          <Button type="submit" disabled={isLoading || isEmpty(values.initialParameters)} variant="contained">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

DatasetInitialParams.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default DatasetInitialParams;
