export default {
  MuiTooltip: {
    popper: {
      marginTop: -14,
      maxWidth: 300,
    },
    tooltip: {
      fontSize: 12,
    },
  },
};
