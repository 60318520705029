import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    margin: [5, 0, 8],
    '& input[type="text"]': {
      border: [1, 'solid', theme.palette.shadow],
      width: '100%',
      padding: [6, 8],
      borderRadius: 2,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    '& input:focus': {
      outline: 'none',
      border: [1, 'solid', theme.palette.shadow],
    },
    '& input::placeholder': {
      color: theme.palette.text.secondary,
    },
  },
  popper: {
    padding: 5,
    background: theme.palette.background.white,
    boxShadow: [0, 8, 24, theme.palette.shadow],
    marginTop: 5,
    borderRadius: 4,
    zIndex: 3,
  },
  searchInput: {
    position: 'relative',
  },
  rowDropdown: {
    padding: 0,
    minWidth: 200,
  },
  inputRowType: {
    border: [[1, 'solid', theme.palette.default], '!important'],
    borderRadius: [2, '!important'],
  },
  arrow: {
    position: 'absolute',
    right: 10,
    top: 5,
    padding: 0,
  },
  opened: {
    transform: 'rotate(180deg)',
  },
}));
