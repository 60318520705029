import PropTypes from 'prop-types';

import Presenter from 'utils/PropTypesPresenter';

export default new Presenter({
  id: PropTypes.number,
  fileName: PropTypes.string,
  createdAt: PropTypes.string,
  dataset: PropTypes.string,
  user: PropTypes.string,
});
