import { useDispatch } from 'react-redux';
import { useMemo } from 'react';
import { bindActionCreators } from 'redux';
import { useParams, useLocation, useHistory, useRouteMatch, matchPath } from 'react-router-dom';
import queryString from 'query-string';

import { appRoutes } from 'routes';

export const useActions = (actions, deps) => {
  const dispatch = useDispatch();
  return useMemo(
    () => {
      if (Array.isArray(actions)) {
        return actions.map((a) => bindActionCreators(a, dispatch));
      }
      return bindActionCreators(actions, dispatch);
    },
    deps ? [dispatch, ...deps] : [dispatch], // eslint-disable-line
  );
};

export function useRouter() {
  const params = useParams();
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch;

  return useMemo(() => {
    return {
      push: history.push,
      replace: history.replace,
      pathname: location.pathname,
      query: {
        ...queryString.parse(location.search),
        ...params,
      },
      match,
      matchPath,
      location,
      history,
    };
  }, [params, match, location, history]);
}
export const useContextFromRoute = () => {
  const router = useRouter();
  const datasetMatch = router.matchPath(router.pathname, {
    path: appRoutes.datasetPath(':datasetId'),
  });
  if (!datasetMatch) return {};
  const { datasetId } = datasetMatch.params;

  return { datasetId };
};
