import React, { useEffect, useState } from 'react';
import { difference, isEmpty, isNil, pluck } from 'ramda';
import { useParams } from 'react-router';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { useLocalStorage } from '@rehooks/local-storage';

import { isBlank } from 'utils/conditions';

import Breadcrumbs from 'components/Breadcrumbs';
import { useDocuments, useMapping, useTemplates, useUserDatasets, useUserFields } from 'slices/hooks';
import history from 'utils/history';
import { appRoutes } from 'routes';
import DatasetPresenter from 'presenters/DatasetPresenter';
import DatasetFieldPresenter from 'presenters/DatasetFieldPresenter';
import DocumentPresenter from 'presenters/DocumentPresenter';
import { ErrorAlert } from 'components/Alerts';
import MappingTable from 'components/MappingTable';
import MappingToolbar from 'components/MappingToolbar';
import { getSingleDatasetMappingSteps } from 'components/MappingSteps/steps';
import { is3DMapping } from 'helpers/fieldDetailsOptions';
import { ExternalServiceUnavailable, TemplateLoadingProblems } from 'components/Dialogs';
import { DOCUMENT_STATUSES } from 'utils/documentStatuses';
import { FOOTER_HEIGHT } from 'utils/layoutConstants';

import useStyles from './useStyles';

const getMainBlockHeight = (offsetTop = 0) => `calc(100vh - ${offsetTop}px - ${FOOTER_HEIGHT}px)`;

const MapData = () => {
  const { loadFields, fields, clearIsMappedField, documentFieldsErrors } = useUserFields();
  const { loadCurrentDataset, currentDataset } = useUserDatasets();
  const { loadCurrentDocument, currentDocument, loadDocumentSheets, sheets, sheetTitles, isLoading } = useDocuments();
  const {
    createMapping,
    mappedColumns,
    apiErrors,
    resetCurrentMapping,
    resetCommonErrors,
    numberOfIgnoredRows,
    ignoreRows,
    mappedRows,
    create3DMapping,
  } = useMapping();
  const { apiTemplateErrors } = useTemplates();
  const [isErrorShowing, setIsErrorShowing] = useState(false);
  const [isDisabledSubmitButton, setIsDisabledSubmitButton] = useState(true);
  const fullScreenHandle = useFullScreenHandle();
  const { active } = fullScreenHandle;
  const { documentId } = useParams();
  const [selectedSheetIndexes, setSelectedSheetIndex] = useState(0);
  const initialMainHeight = getMainBlockHeight();
  const [mainHeight, setMainHeight] = useState(initialMainHeight);
  const [template] = useLocalStorage('template');
  const table = document.querySelector('[aria-label="main"]');
  const documentName = DocumentPresenter.fileName(currentDocument);
  const fileType = DatasetPresenter.fileType(currentDataset);
  const is3DMappingType = is3DMapping(fileType);
  const dataset = DocumentPresenter.dataset(currentDocument);
  const datasetId = DatasetPresenter.id(dataset);

  const classes = useStyles();

  useEffect(() => {
    loadCurrentDocument(documentId).then(({ payload }) => {
      if (DocumentPresenter.state(payload) === DOCUMENT_STATUSES.mapped) {
        history.push(appRoutes.documentsPath());
      } else {
        loadCurrentDataset(DatasetPresenter.id(payload.dataset));
        loadFields(documentId, DatasetPresenter.id(payload.dataset));
        loadDocumentSheets(documentId);
      }
    });
    return () => {
      resetCurrentMapping();
    };
  }, []); // eslint-disable-line

  useEffect(() => {
    setSelectedSheetIndex(template ? template.sheetIndex : 0);
  }, [template]); // eslint-disable-line

  useEffect(() => {
    if (!isEmpty(apiErrors)) {
      setIsErrorShowing(true);
    }
  }, [apiErrors]); // eslint-disable-line

  useEffect(() => {
    const mappedColumnIds = mappedColumns[documentId] ? pluck('id', mappedColumns[documentId]) : [];
    const mappedRowsIds = mappedRows[documentId] ? pluck('id', mappedRows[documentId]) : [];
    const mappedFieldIds = [...mappedColumnIds, ...mappedRowsIds];

    const requiredFields = fields[documentId]
      ? fields[documentId].filter((field) => DatasetFieldPresenter.isRequired(field))
      : [];
    const fieldIds = pluck('id', requiredFields);

    const isAllRequiredChosen = !isEmpty(fields[documentId]) && isEmpty(difference(fieldIds, mappedFieldIds));
    const isRequiredFieldsNoExist = !isEmpty(fields[documentId]) && isEmpty(requiredFields);

    if (isAllRequiredChosen || isRequiredFieldsNoExist) {
      setIsDisabledSubmitButton(false);
    } else {
      setIsDisabledSubmitButton(true);
    }
  }, [mappedColumns, mappedRows, fields[documentId]]); // eslint-disable-line

  useEffect(() => {
    if (!isNil(table)) {
      const sizes = table && table.getBoundingClientRect();
      const calculatedHeight = getMainBlockHeight(sizes.top);
      setMainHeight(calculatedHeight);
    }
  }, [table, active, documentName]); // eslint-disable-line

  useEffect(() => {
    if (!dataset) {
      return;
    }

    const initialSelectedSheetIndexesState = { [dataset.id]: 0 };

    setSelectedSheetIndex(initialSelectedSheetIndexesState);
  }, [dataset]); // eslint-disable-line

  const handleContinue = () => {
    const data = {
      documentId,
      template: {
        datasetId: DatasetPresenter.id(currentDocument.dataset),
        numberOfRowsToIgnore: numberOfIgnoredRows,
        columns: mappedColumns[datasetId],
        ignoreRows,
        sheetIndex: selectedSheetIndexes[datasetId],
      },
    };

    if (is3DMappingType) {
      data.template.rows = mappedRows[datasetId];
      create3DMapping(data);
    } else {
      createMapping(data);
    }
  };

  const handleSelectSheet = (index) => {
    setSelectedSheetIndex({ [datasetId]: index });
    resetCurrentMapping(datasetId);
    clearIsMappedField(datasetId);
  };

  const currentDocumentId = DocumentPresenter.id(currentDocument);
  const singleDatasetMappingSteps = getSingleDatasetMappingSteps(currentDocumentId);

  return (
    <div className={classes.top}>
      <Breadcrumbs
        breadcrumbs={[
          { link: appRoutes.documentsPath(), name: 'Import Tool' },
          { name: documentName, link: '' },
        ]}
      />
      <div className={classes.wrapper}>
        <FullScreen handle={fullScreenHandle}>
          {datasetId && (
            <MappingTable
              currentDocument={currentDocument}
              currentDataset={currentDataset}
              sheets={sheets}
              sheetTitles={sheetTitles}
              selectedSheetIndex={isBlank(selectedSheetIndexes) ? 0 : selectedSheetIndexes[datasetId]}
              isLoading={isLoading}
              onSheetButtonClick={handleSelectSheet}
              fields={fields[datasetId] ? fields[datasetId] : []}
              mappingSteps={singleDatasetMappingSteps}
              mainHeight={mainHeight}
              fullScreenHandle={fullScreenHandle}
              datasetId={datasetId}
            />
          )}

          {active && <MappingToolbar onContinueButtonClick={handleContinue} disabled={isDisabledSubmitButton} />}
          {!isEmpty(documentFieldsErrors) && <ExternalServiceUnavailable />}
          {!isEmpty(apiTemplateErrors) && <TemplateLoadingProblems details={apiTemplateErrors} />}
          <MappingToolbar onContinueButtonClick={handleContinue} disabled={isDisabledSubmitButton} />
        </FullScreen>
        <ErrorAlert onClose={resetCommonErrors} isShowing={isErrorShowing} errors={apiErrors} />
      </div>
    </div>
  );
};

export default MapData;
