import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router';

import Base from 'layouts/Base';

const Page = (props) => {
  const { layout, ...rest } = props;
  switch (layout) {
    case 'base':
      return (
        <Base>
          <Route {...rest} />
        </Base>
      );
    case 'success':
      return (
        <Base>
          <Route {...rest}>Success</Route>
        </Base>
      );
    default:
      return <Route {...rest} />;
  }
};

Page.propTypes = {
  layout: PropTypes.string,
};

Page.defaultProps = {
  layout: null,
};

export default Page;
