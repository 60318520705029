import { makeStyles } from '@material-ui/core';
import palette from 'layouts/CustomThemeProvider/palette';

export default makeStyles((theme) => ({
  icon: {
    width: 12,
    height: 12,
  },
  tabsContainer: {
    display: 'flex',
  },
  tabPanel: {
    maxWidth: 'calc(100% - 264px)',
    width: '100%',
  },
  tabPanelBordered: {
    border: [1, 'solid', theme.palette.shadow],
    background: theme.palette.background.white,
    borderLeft: 'none',
    marginBottom: 32,
  },
  verticalTabContainer: {
    paddingLeft: 0,
    background: theme.palette.background.gray,
    border: [1, 'solid', theme.palette.shadow],
    marginBottom: 32,
  },
  verticalTab: {
    backgroundColor: theme.palette.background.gray,
    marginRight: 0,
    marginBottom: 0,
    padding: [12, 16, 12, 18],
    borderBottom: [1, 'solid', theme.palette.shadow],
    borderRadius: 0,
    boxShadow: 'none',
    width: 262,
  },
  verticalTabSelected: {
    backgroundColor: palette.background.white,
  },
  verticalTabWithValidation: {
    paddingRight: 40,
  },
  tabsGroup: {
    display: 'flex',
    flexDirection: 'column',
  },
  tabWrapper: {
    position: 'relative',
  },
  validationSwitcher: {
    position: 'absolute',
    top: '50%',
    right: 16,
    transform: 'translateY(-50%)',
    border: 'none',
    background: 'transparent',
    boxSizing: 'border-box',
    minWidth: 16,
    width: 16,
    height: 16,
    padding: 0,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.text.secondary,
  },
}));
