import * as R from 'ramda';

const rejectById = (id) => R.reject(R.propEq('id', id));
const mergeIfSameId = (mergeId, mergeAttrs, original) =>
  original.map((el) => (el.id !== mergeId ? el : { ...el, ...mergeAttrs }));

export const isNumber = R.is(Number);

export const isObject = (obj) => R.is(Object, obj);

export const isFile = (obj) => obj instanceof File;

export const isArray = (obj) => obj instanceof Array;

export const change = (object, path, func) => {
  const lens = R.lensPath(path);
  return R.over(lens, func, object);
};

export const mergeById = (object, path, id, attrs) => {
  const lens = R.lensPath(path);
  return R.over(lens, mergeIfSameId.bind(null, id, attrs), object);
};

export const set = (object, path, value) => {
  const lens = R.lensPath(path);
  return R.set(lens, value, object);
};

export const merge = (object, path, attrs) => {
  const lens = R.lensPath(path);
  return R.over(lens, (value) => ({ ...value, ...attrs }), object);
};

export const removeById = (object, path, id) => {
  const lens = R.lensPath(path);
  return R.over(lens, rejectById(id), object);
};

export const removeByIndex = (object, path, index) => {
  const lens = R.lensPath(path);
  return R.over(lens, R.remove(index, 1), object);
};

export const append = (object, path, element) => {
  const lens = R.lensPath(path);
  return R.over(lens, R.append(element), object);
};

export const prepend = (object, path, element) => {
  const lens = R.lensPath(path);
  return R.over(lens, R.prepend(element), object);
};

export const removeFromArray = (object, path, element) => {
  const lens = R.lensPath(path);
  return R.over(
    lens,
    R.reject((e) => e === element),
    object,
  );
};

export const toggleListItem = R.curry((value, list) =>
  R.ifElse(R.includes(value), R.without(value), R.append(value))(list),
);
