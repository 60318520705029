import React from 'react';
import { Avatar, Box, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';

import UserPresenter from 'presenters/UserPresenter';
import { appRoutes } from 'routes/appRoutes';
import { useSession, useUsers } from 'slices/hooks';
import { isBlank } from 'utils/conditions';
import NoAvatarIcon from 'assets/icons/noavatar.svg';
import Logo from 'assets/images/logo.png';
import Icon from 'components/Icon';

import useStyles from './useStyles';

const Header = () => {
  const { signOut } = useSession();
  const { currentUser, resetCurrentUser } = useUsers();
  const classes = useStyles();

  const handleSignOut = () => {
    signOut();
    resetCurrentUser();
  };

  return (
    <Box component="header" className={classes.header}>
      <div className={classes.wrapper}>
        <Link to={appRoutes.rootPath()}>
          <img src={Logo} alt="FormataFile" className={classes.logo} />
        </Link>
        {isBlank(currentUser) ? null : (
          <div className={classes.authUser}>
            <div className={classes.info}>
              <div className={classes.name}>{UserPresenter.fullName(currentUser)}</div>
              <div className={classes.role}>{UserPresenter.formattedRole(currentUser)}</div>
            </div>
            <Avatar src={NoAvatarIcon} alt={UserPresenter.fullName(currentUser)} />
            <Button className={classes.buttonExit} onClick={handleSignOut}>
              <Icon name="exit" className={classes.iconExit} />
            </Button>
          </div>
        )}
      </div>
    </Box>
  );
};

export default Header;
