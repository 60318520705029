import React, { useRef, useState } from 'react';
import { ListItem, Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';

import DatasetFieldPresenter from 'presenters/DatasetFieldPresenter';
import Icon from 'components/Icon';

import useStyles from './useStyles';

const MapDropdownItem = (props) => {
  const { field, onMapField, mapToIndex } = props;

  const classes = useStyles();

  const fieldName = DatasetFieldPresenter.displayName(field);
  const isMapped = DatasetFieldPresenter.isMapped(field);
  const columnIndex = DatasetFieldPresenter.columnIndex(field);

  const [isTooltipOpened, setTooltipOpened] = useState(false);
  const isMouseOnItem = useRef(false);

  const renderIcon = () => {
    if (!isMapped) {
      return <Icon name="tickEmpty" />;
    }
    if (columnIndex === mapToIndex) {
      return <Icon name="tickFull" />;
    }
    return <Icon name="tickFullShadow" />;
  };

  const handleCloseTooltip = () => {
    isMouseOnItem.current = false;
    setTooltipOpened(false);
  };

  const handleOpenToolTip = () => {
    isMouseOnItem.current = true;
    setTimeout(() => {
      if (isMouseOnItem.current) {
        setTooltipOpened(true);
      }
    }, 500);
  };

  const handleClickItem = () => {
    onMapField(field);
  };

  return (
    <Tooltip
      classes={{
        popper: classes.popper,
      }}
      title={fieldName}
      placement="bottom-start"
      open={isTooltipOpened}
    >
      <ListItem
        className={classes.item}
        onClick={handleClickItem}
        onMouseEnter={handleOpenToolTip}
        onMouseLeave={handleCloseTooltip}
      >
        <div className={classes.itemTitle}>
          {renderIcon()}
          <span className={classes.itemName}>{fieldName}</span>
        </div>
      </ListItem>
    </Tooltip>
  );
};

MapDropdownItem.propTypes = {
  onMapField: PropTypes.func,
  field: PropTypes.shape(),
  mapToIndex: PropTypes.number.isRequired,
};

MapDropdownItem.defaultProps = {
  onMapField: () => {},
  field: {},
};

export default MapDropdownItem;
