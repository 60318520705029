import { apiRoutes } from 'routes';
import FetchHelpers from 'utils/FetchHelpers';

export default {
  index(datasetId) {
    const path = apiRoutes.datasetInitialParametersPath(datasetId);
    return FetchHelpers.get(path);
  },
  update(datasetId, params) {
    const path = apiRoutes.datasetInitialParametersPath(datasetId);
    return FetchHelpers.put(path, params);
  },
};
