import palette from 'layouts/CustomThemeProvider/palette';

export default {
  MuiButton: {
    root: {
      boxShadow: 'none !important',
      fontSize: 12,
      lineHeight: '100%',
      fontWeight: 'normal',
      padding: [9, 23],
      borderRadius: 2,
      '&$disabled': {
        backgroundColor: palette.disabled,
        border: [1, 'solid', palette.disabled],
        color: palette.text.white,
      },
    },
    outlined: {
      color: palette.default,
      border: [1, 'solid', palette.default],
      background: 'transparent',
      padding: [9, 23],
    },
    contained: {
      color: palette.text.white,
      backgroundColor: palette.default,
      boxShadow: [2, 0, 16, palette.shadowLight],
      padding: [9, 23],
      border: [1, 'solid', palette.default],
      '&:hover': {
        backgroundColor: palette.background.primaryLight,
      },
      '&$disabled': {
        backgroundColor: palette.disabled,
        border: [1, 'solid', palette.disabled],
        color: palette.text.white,
      },
    },
    startIcon: {
      marginRight: 4,
    },
    text: {
      color: palette.default,
      background: 'transparent',
      padding: [9, 0, 9, 5],
      '&$disabled': {
        backgroundColor: palette.transparent,
        border: 'none',
        color: palette.text.gray,
      },
    },
  },
};
