import React from 'react';
import { is } from 'ramda';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';

import TextField from 'components/Common/TextField';
import { initialValues, validationSchema } from 'forms/CreateTemplateForm';
import { handleErrors } from 'utils/errors';

import useStyles from 'containers/MapSummary/useStyles';

const CreateTemplateDialog = (props) => {
  const { onClose, apiErrors, onSaveMapping } = props;
  const classes = useStyles();

  const handleSaveMapping = (values, { setErrors }) => {
    return onSaveMapping(values).catch((errors) => handleErrors(errors, setErrors));
  };

  const { values, errors, setFieldValue, submitForm } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSaveMapping,
    validateOnChange: true,
  });

  const handleFieldChange = (field) => ({ target: { value } }) => {
    setFieldValue(field, value);
  };

  const handleSubmitForm = (e) => {
    e.preventDefault();
    submitForm(values);
  };

  const renderErrors = () => {
    return Object.keys(apiErrors).map((key, index) => {
      if (!is(Array, apiErrors[key])) {
        return (
          <span className={classes.error} key={index}>
            is invalid
          </span>
        );
      }
      return (
        <span className={classes.error} key={index}>
          {apiErrors[key]}
        </span>
      );
    });
  };

  return (
    <Dialog open onClose={onClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle>Save Mapping as Template</DialogTitle>
      <form onSubmit={handleSubmitForm}>
        <DialogContent>
          <DialogContentText classes={{ root: classes.dialogContent }}>
            Save the current mapping as a template to automatically map the same files in the future.
          </DialogContentText>
          <div className={classes.fieldWrapper}>
            <TextField
              name="name"
              value={values.name}
              error={!!errors.name}
              onChange={handleFieldChange('name')}
              label="Template Name"
              id="standard-start-adornment"
              placeholder="Input the Template Name"
              helperText={errors.name}
            />
            {renderErrors()}
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="contained" type="submit">
            Save as Template
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

CreateTemplateDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  apiErrors: PropTypes.shape(),
  onSaveMapping: PropTypes.func.isRequired,
};

CreateTemplateDialog.defaultProps = {
  apiErrors: {},
};

export default CreateTemplateDialog;
