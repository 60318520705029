import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(
  (theme) => ({
    top: {
      margin: [20, 'auto'],
      maxWidth: 1360,
    },
    wrapper: {
      minHeight: '80vh',
      boxShadow: [1, 1, 5, theme.palette.shadow],
      borderRadius: [5, 5, 0, 0],
      background: theme.palette.background.white,
      margin: [20, 0, 0, 0],
    },
    head: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      alignItems: 'center',
      padding: [0, 20],
      boxShadow: ['inset', 0, -1, 0, theme.palette.shadow],
      background: theme.palette.background.white,
    },
    main: {
      display: 'flex',
      height: '100%',
      alignItems: 'stretch',
      background: theme.palette.background.white,
      paddingBottom: 65,
      '& [role="tabpanel"]': {
        flexBasis: '82%',
        overflowX: 'scroll',
      },
    },
    mainFullScreen: {
      display: 'flex',
      height: '100%',
      background: theme.palette.background.white,
    },
    fullscreenToggle: {
      display: 'flex',
      background: 'none',
      border: 'none',
      outline: 'none',
      alignItems: 'center',
      color: theme.palette.title,
      cursor: 'pointer',
      '& svg': {
        marginRight: 10,
      },
    },
    footer: {
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0,
      boxShadow: ['inset', 0, 1, 0, theme.palette.shadow],
      padding: [10, 120, 10, 40],
      background: theme.palette.background.white,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      zIndex: 10,
      '& p': {
        color: theme.palette.text.lightDark,
        fontSize: theme.typography.pxToRem(12),
      },
      '& Button': {
        padding: 4,
        height: 32,
        width: 112,
      },
    },
    tabLabel: {
      paddingLeft: 12,
      textAlign: 'left',
    },
    name: {
      fontSize: theme.typography.pxToRem(12),
      lineHeight: 1.3,
      color: theme.palette.text.primary,
    },
    tabs: {
      flexBasis: '18%',
      minWidth: 256,
      padding: [0, 0, 2, 0],
      borderWidth: [0, 1, 0, 0],
      border: ['inset', theme.palette.shadow],
      '& [role="tablist"]': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        '& button': {
          width: '100%',
          padding: [12, 22],
          color: theme.palette.text.primary,
          fontSize: theme.typography.pxToRem(12),
          lineHeight: 1.3,
          textTransform: 'none',
          borderRadius: 0,
          border: [1, 'solid', theme.palette.shadow],
          borderLeft: ['solid', theme.palette.transparent],
          opacity: 1,
          boxShadow: 'none',
          borderWidth: [1, 0, 1, 4],
          '&:first-child': {
            borderWidth: [0, 0, 1, 4],
          },
        },
        '& [aria-selected="true"]': {
          background: theme.palette.background.gray,
          borderLeft: [4, 'solid', theme.palette.title],
          fontWeight: 600,
        },
      },
      '& .MuiTab-wrapper': {
        justifyContent: 'flex-start',
      },
    },
    state: {
      color: theme.palette.text.lightDark,
      fontSize: theme.typography.pxToRem(10),
      lineHeight: 1,
      marginTop: 4,
      fontWeight: 300,
    },
    controlsPanel: {
      width: '100%',
    },
    controls: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      padding: [20, 32],
    },
    typeName: {
      fontWeight: 600,
      fontSize: theme.typography.pxToRem(20),
      lineHeight: 1.2,
      color: theme.palette.title,
      textAlign: 'left',
    },
    typeMessage: {
      marginTop: 4,
      fontSize: theme.typography.pxToRem(12),
      lineHeight: 1.2,
      color: theme.palette.text.lightDark,
    },
    resolveButton: {
      marginLeft: 32,
    },
    revertButton: {
      marginLeft: 12,
    },
    errorIcon: {
      fill: theme.palette.yellow,
    },
    successIcon: {
      fill: theme.palette.green,
    },
    validateFailed: {
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      color: theme.palette.errors,
      padding: 32,
      '& h3': {
        color: theme.palette.errors,
      },
      '& div': {
        fontSize: theme.typography.pxToRem(12),
        lineHeight: 1.3,
      },
    },
    error: {
      position: 'absolute',
      display: 'block',
      bottom: -25,
      color: theme.palette.errors,
      fontSize: theme.typography.pxToRem(9),
      paddingLeft: 16,
    },
    footerActions: {
      display: 'flex',
      alignItems: 'center',
      '& Button': {
        whiteSpace: 'nowrap',
        width: 'auto',
        padding: [4, 24],
        height: 32,
        marginLeft: 8,
      },
    },
    failedInfo: {
      marginTop: 32,
      color: theme.palette.text.primary,
      fontSize: theme.typography.pxToRem(12),
      '& li': {
        paddingBottom: 8,
        '&::marker': {
          paddingRight: 8,
        },
      },
      '& ul': {
        paddingLeft: 15,
        marginTop: 16,
      },
    },
    link: {
      color: theme.palette.default,
      fontWeight: 600,
    },
  }),
  { name: 'MapValidate' },
);
