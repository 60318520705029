import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  table: {
    height: '100%',
    backgroundColor: theme.palette.background.white,
    '& th': {
      border: [1, 'solid', theme.palette.shadow],
      borderLeft: 'none',
      borderTop: 'none',
      padding: 0,
      textAlign: 'center',
      color: theme.palette.text.secondary,
      fontSize: theme.typography.pxToRem(10),
      '&:first-child': {
        width: 30,
      },
      '&:nth-child(2)': {
        borderRightWidth: 0,
        boxShadow: ['inset', -1, 0, 0, theme.palette.shadow],
        paddingRight: 1,
      },
    },
    '& td': {
      padding: [4, 10, 2, 10],
      borderRight: [1, 'solid', theme.palette.shadow],
      minWidth: 200,
      height: 20,
      '&:first-child': {
        width: 32,
        minWidth: 32,
        background: theme.palette.background.gray,
        textAlign: 'center',
        whiteSpace: 'nowrap',
        color: theme.palette.text.secondary,
      },
    },
    '& .MuiSelect-select': {
      padding: [4, 6],
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  },
  table3D: {
    position: 'relative',
    '& td': {
      '&:nth-child(2)': {
        padding: [0, 1, 0, 0],
        position: 'sticky',
        left: -1,
        boxShadow: ['inset', -1, 0, 0, theme.palette.shadow],
        background: theme.palette.background.gray,
        borderRightWidth: 0,

        '& div': {
          margin: 0,
          height: '100%',
          position: 'relative',
          background: theme.palette.background.white,
        },
      },
    },
  },
  ignoredRow: {
    opacity: 0.3,
    '& td': {
      background: theme.palette.disabled,
      '&:first-child': {
        background: theme.palette.disabled,
      },
    },
  },
  contextMenu: {
    background: theme.palette.default,
    padding: 10,
    borderRadius: 4,
    position: 'fixed',
    zIndex: 10,
    '& button': {
      display: 'block',
      border: 'none',
      background: 'none',
      color: theme.palette.text.white,
      cursor: 'pointer',
      textTransform: 'uppercase',
      fontSize: theme.typography.pxToRem(11),
      outline: 'none',
      marginBottom: 10,
      '&:last-child': {
        marginBottom: 0,
      },
    },
  },
  ignoreIcon: {
    border: [1, 'solid', theme.palette.background.white],
    padding: [1, 3],
    fontSize: theme.typography.pxToRem(10),
    marginRight: 5,
  },
  mappedRow: {
    '& td': {
      background: theme.palette.lightGreen,
      '&:first-child': {
        background: theme.palette.green,
        color: theme.palette.text.white,
      },
    },
  },
  mapSelector: {
    '& td': {
      position: 'sticky',
      top: -1,
      zIndex: 2,
      background: theme.palette.background.gray,
      boxShadow: [0, 1, 1, theme.palette.shadow],
    },
  },
  fixedEmptyTableCell: {
    zIndex: [3, '!important'],
  },
  // only for Safari
  '@media not all and (min-resolution:.001dpcm)': {
    '@media': {
      mapSelector: {
        '& td': {
          top: -25,
        },
      },
    },
  },
  emptySheet: {
    color: theme.palette.text.dark,
    opacity: 0.4,
    fontSize: theme.typography.pxToRem(16),
    margin: [0, 'auto'],
    display: 'block',
    padding: [24, 0],
  },
}));
