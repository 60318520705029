import React, { useState } from 'react';
import { Button, Dialog, DialogContent, DialogActions } from '@material-ui/core';

import history from 'utils/history';
import { appRoutes } from 'routes';

import useStyles from 'components/Dialogs/useStyles';

const ExternalServiceUnavailable = () => {
  const [open, setOpen] = useState(true);

  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
    history.push(appRoutes.documentsPath());
  };

  const handleReload = () => {
    setOpen(false);
    window.location.reload();
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      open={open}
      classes={{ root: classes.root, paper: classes.paper, container: classes.container }}
      BackdropProps={{ classes: { root: classes.backdropRoot } }}
      container={document.getElementsByClassName('fullscreen')[0]}
    >
      <DialogContent>
        <div className={classes.title}>External service unavailable</div>

        <div className={classes.errorText}>
          The mapping process is not working right now. We are aware of this incident and will fix it soon. Please go
          back to the Entities section and try again.
        </div>
      </DialogContent>
      <DialogActions classes={{ root: classes.rootActions }}>
        <Button onClick={handleClose} variant="outlined">
          Go back
        </Button>
        <Button onClick={handleReload} type="submit" variant="contained">
          Reload
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExternalServiceUnavailable;
