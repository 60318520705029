import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Table as MUITable, TableBody, TableCell, TableHead, TableRow, Paper, TableContainer } from '@material-ui/core';

import { InputSearch } from 'components/Common';
import DatasetPresenter from 'presenters/DatasetPresenter';
import DocumentPresenter from 'presenters/DocumentPresenter';
import UserPresenter from 'presenters/UserPresenter';
import { formatDate } from 'presenters/DateTimePresenter';
import { appRoutes } from 'routes';

import useStyles from './useStyles';

const Table = (props) => {
  const { rows, columns, tableCellClassName, title, onSearch } = props;
  const classes = useStyles();

  const renderRow = (dataset) => {
    const lastUpdatedByUser = DatasetPresenter.lastUpdatedBy(dataset);
    const lastUpdatedByUserFullName = UserPresenter.fullName(lastUpdatedByUser);
    const createdByUser = DatasetPresenter.createdBy(dataset);
    const createdByUserFullName = UserPresenter.fullName(createdByUser);
    const datasetStatus = DatasetPresenter.status(dataset);
    const datasetName = DatasetPresenter.name(dataset);
    const updatedAtDate = formatDate(DatasetPresenter.updatedAt(dataset));
    const createdAtDate = formatDate(DatasetPresenter.createdAt(dataset));
    const datasetRoute = appRoutes.datasetPath(DatasetPresenter.id(dataset));

    return (
      <>
        <TableCell className={classes[tableCellClassName]} align="left">
          <Link to={datasetRoute}>{datasetName}</Link>
        </TableCell>
        <TableCell className={classes[tableCellClassName]} align="left">
          {datasetStatus}
        </TableCell>
        <TableCell className={classes[tableCellClassName]} align="left">
          {updatedAtDate}
          <div className={classes.author}>by {lastUpdatedByUserFullName}</div>
        </TableCell>
        <TableCell className={classes[tableCellClassName]} align="left">
          {createdAtDate}
          <div className={classes.author}> by {createdByUserFullName}</div>
        </TableCell>
      </>
    );
  };

  return (
    <TableContainer component={Paper} elevation={3}>
      <MUITable className={classes.table} aria-label="table datasets">
        <TableHead>
          <TableRow className={classes.header}>
            <TableCell className={classes[tableCellClassName]} colSpan={5}>
              <div className={classes.headerInner}>
                <div className={classes.title}>{title}</div>
                <InputSearch onSearch={onSearch} />
              </div>
            </TableCell>
          </TableRow>
          <TableRow>
            {columns.map((column, index) => (
              <TableCell key={`th-${index}`} align="left">
                {column}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={index}>{renderRow(row)}</TableRow>
          ))}
        </TableBody>
      </MUITable>
    </TableContainer>
  );
};

Table.propTypes = {
  rows: PropTypes.oneOfType([
    PropTypes.arrayOf(DatasetPresenter.shape()),
    PropTypes.arrayOf(DocumentPresenter.shape()),
  ]),
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
  tableCellClassName: PropTypes.string,
  title: PropTypes.string,
  onSearch: PropTypes.func.isRequired,
};

Table.defaultProps = {
  rows: [],
  tableCellClassName: 'datasets',
  title: 'Import Datasets',
};

export default Table;
