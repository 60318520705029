import palette from 'layouts/CustomThemeProvider/palette';

export default {
  MuiSelect: {
    icon: {
      marginRight: 16,
      fill: palette.text.iconDark,
    },
  },
};
