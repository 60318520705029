import React from 'react';
import ListItem from '@material-ui/core/ListItem';

import { ReactComponent as TickIcon } from 'assets/icons/tick_full.svg';
import { ReactComponent as TickEmptyIcon } from 'assets/icons/tick_empty.svg';
import { ReactComponent as LinkIcon } from 'assets/icons/link.svg';
import useStyles from './useStyles';
import PropTypes from 'prop-types';

const Field = (props) => {
  const { isMapped, mappedTo, title } = props;
  const classes = useStyles();

  return (
    <ListItem className={classes.item}>
      <div className={classes.itemTitle}>
        {isMapped ? <TickIcon /> : <TickEmptyIcon />}
        <span>{title}</span>
      </div>
      <div className={classes.itemStateText}>
        <LinkIcon />
        {mappedTo ? <span>{mappedTo}</span> : <span>Not Mapped</span>}
      </div>
    </ListItem>
  );
};

Field.propTypes = {
  isMapped: PropTypes.bool,
  mappedTo: PropTypes.string,
  title: PropTypes.string,
};

Field.defaultProps = {
  isMapped: false,
  mappedTo: '',
  title: '',
};

export default Field;
