import React from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { InputBase } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import PropTypes from 'prop-types';

import useStyles from './useStyles';

const InputSearch = (props) => {
  const { onSearch } = props;
  const classes = useStyles();

  const [handleDebounceOnChange] = useDebouncedCallback((searchValue) => {
    onSearch({ search: searchValue });
  }, 600);

  return (
    <div className={classes.search}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <InputBase
        placeholder="Search"
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        inputProps={{ 'aria-label': 'search' }}
        onChange={(e) => handleDebounceOnChange(e.target.value)}
      />
    </div>
  );
};

InputSearch.propTypes = {
  onSearch: PropTypes.func.isRequired,
};

export default InputSearch;
