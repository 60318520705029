import palette from 'layouts/CustomThemeProvider/palette';

export default {
  MuiInput: {
    root: {
      fontSize: 12,
      lineHeight: 1,
      backgroundColor: palette.background.white,
      border: [1, 'solid', palette.shadow],
      borderRadius: 2,
      width: '100%',
      '&$error': {
        border: [1, 'solid', palette.errors],
      },
    },
    input: {
      padding: [11, 16],
      '&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus, &:-webkit-autofill:active': {
        transition: ['background-color', '5000s', 'ease-in-out', '0s'],
      },
    },
    underline: {
      '&:before': {
        display: 'none',
      },
      '&:after': {
        display: 'none',
      },
    },
    multiline: {
      padding: 0,
    },
    inputMultiline: {
      position: 'relative',
    },
  },
};
