import { createSlice } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import SessionRepository from 'repositories/SessionRepository';
import history from 'utils/history';
import { appRoutes } from 'routes';

const initialState = {
  loading: false,
};

const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    signInStart(state) {
      state.loading = true;
    },
    signInSuccess(state, { payload }) {
      state.token = payload.token;
      state.errors = {};
    },
    signInFail(state, { payload }) {
      state.errors = payload;
    },
    signInFinish(state) {
      state.loading = false;
    },
  },
});

export default sessionSlice.reducer;

export const useSessionActions = () => {
  const dispatch = useDispatch();

  const signIn = (params) => {
    dispatch(sessionSlice.actions.signInStart());
    return SessionRepository.create({ ...params })
      .then((response) => {
        const { token } = response.data;
        dispatch(sessionSlice.actions.signInSuccess(response.data));
        localStorage.setItem('token', token);
        history.push(appRoutes.rootPath());
      })
      .catch((errors) => dispatch(sessionSlice.actions.signInFail(errors)))
      .finally(() => dispatch(sessionSlice.actions.signInFinish()));
  };

  const signOut = () => {
    localStorage.removeItem('token');
    history.push(appRoutes.loginPath());
  };

  return {
    signIn,
    signOut,
  };
};
