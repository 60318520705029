import React from 'react';
import { Alert } from '@material-ui/lab';
import Icon from 'components/Icon';
import { CheckCircle as CheckCircleIcon } from '@material-ui/icons';

import useStyles from './useStyles';

const SuccessMappingAlert = (props) => {
  const classes = useStyles();

  return (
    <Alert
      iconMapping={{ success: <CheckCircleIcon fontSize="inherit" /> }}
      variant="filled"
      classes={{
        root: classes.root,
        icon: classes.icon,
        filledSuccess: classes.filledSuccess,
        message: classes.message,
      }}
    >
      <div className={classes.body}>
        <div className={classes.bodyMessage}>
          <div>All issues resolved!</div>
          <div>Click to continue importing.</div>
        </div>
        <div className={classes.iconWrapper}>
          <Icon name="rightDownArrow" />
        </div>
      </div>
    </Alert>
  );
};

export default SuccessMappingAlert;
