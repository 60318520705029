import PropTypes from 'prop-types';
import { isEmpty, isNil, all } from 'ramda';

import Presenter from 'utils/PropTypesPresenter';
import ValidatorsPresenter from 'presenters/ValidatorsPresenter';

export default new Presenter(
  {
    id: PropTypes.number,
    displayName: PropTypes.string,
    nameInMapping: PropTypes.string,
    isRequired: PropTypes.bool,
    format: PropTypes.string,
    createdAt: PropTypes.string,
    datasetId: PropTypes.number,
    updatedAt: PropTypes.string,
    isMapped: PropTypes.bool,
    columnIndex: PropTypes.number,
    validators: ValidatorsPresenter.shape(),
    mappingType: PropTypes.string,
    rowIndexes: PropTypes.arrayOf(PropTypes.number),
    parentId: PropTypes.number,
  },
  {
    isExistValidators(field) {
      return !isEmpty(this.validators(field));
    },
    isSubField(field) {
      return !isNil(this.parentId(field));
    },
    parentIdFormatted(field) {
      const parentId = this.parentId(field);
      return isNil(parentId) ? '' : parentId;
    },
    areRequiredFieldsMapped(fields) {
      if (!fields) {
        return false;
      }

      const requiredFields = fields.filter(this.isRequired);

      return isEmpty(requiredFields) ? true : all(this.isMapped)(requiredFields);
    },
  },
);
