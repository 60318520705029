import React from 'react';
import PropTypes from 'prop-types';
import { TableCell } from '@material-ui/core';

import { isBlank } from 'utils/conditions';

const MapTableCell = (props) => {
  const { cell, error, id } = props;

  const isErrorExist = !isBlank(error);
  const { errorState, tooltipMessages, nameInMapping } = error;

  return (
    <TableCell {...cell.getCellProps()}>
      {cell.render('Cell', {
        error: isErrorExist,
        errorState,
        tooltipMessages,
        id,
        nameInMapping,
      })}
    </TableCell>
  );
};

MapTableCell.propTypes = {
  cell: PropTypes.shape(),
  error: PropTypes.shape(),
  id: PropTypes.number.isRequired,
};

MapTableCell.defaultProps = {
  cell: {},
  error: {},
};

export default MapTableCell;
