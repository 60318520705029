import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { head } from 'ramda';
import cn from 'clsx';
import { ClickAwayListener, TableCell, TableRow } from '@material-ui/core';

import { MapRowSelector } from 'containers/MapData/components/MapSelector';

import useStyles from 'components/MappingTable/components/MapDataTable/useStyles';
import { getMapSelectorInitialValue } from 'utils/mapSelectorUtils';

const TableRow3D = React.memo((props) => {
  const {
    sheetNumber,
    row,
    index,
    mapped,
    onClick,
    isContextVisible,
    datasetId,
    fields,
    currentTemplate,
    setIsDisabledSubmitButton,
  } = props;
  const [isSelectedRow, setIsSelectedRow] = useState(false);

  const classes = useStyles();

  const handleClickAway = () => {
    setIsSelectedRow(false);
  };

  const initialValue = getMapSelectorInitialValue(currentTemplate, datasetId, null, row);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <TableRow
        {...row.getRowProps({ onClick: () => setIsSelectedRow(true) })}
        selected={isSelectedRow}
        className={cn({ [classes.mappedRow]: mapped })}
      >
        <TableCell>{index + 1}</TableCell>
        <MapRowSelector
          {...head(row.cells).getCellProps()}
          mapToIndex={Number(row.id)}
          mapToName={String(head(row.cells).value)}
          initialValue={initialValue}
          sheetNumber={sheetNumber}
          onClick={onClick}
          isContextVisible={isContextVisible}
          datasetId={datasetId}
          fields={fields}
          setIsDisabledSubmitButton={setIsDisabledSubmitButton}
        />

        {row.cells.map((cell) => {
          return <TableCell {...cell.getCellProps()}>{cell.render('Cell')}</TableCell>;
        })}
      </TableRow>
    </ClickAwayListener>
  );
});

TableRow3D.propTypes = {
  row: PropTypes.shape(),
  sheetNumber: PropTypes.number,
  index: PropTypes.number,
  isContextVisible: PropTypes.bool,
  mapped: PropTypes.bool,
  onClick: PropTypes.func,
  currentTemplate: PropTypes.shape(),
  datasetId: PropTypes.number.isRequired,
  fields: PropTypes.arrayOf(PropTypes.any),
  setIsDisabledSubmitButton: PropTypes.func.isRequired,
};

TableRow3D.defaultProps = {
  row: {},
  sheetNumber: 0,
  index: 0,
  mapped: false,
  onClick: () => {},
  isContextVisible: false,
  currentTemplate: {},
  fields: [],
};

export default TableRow3D;
