import PropTypes from 'prop-types';

import Presenter from 'utils/PropTypesPresenter';

export default new Presenter(
  {
    nameInMapping: PropTypes.string,
    displayName: PropTypes.string,
    isMapped: PropTypes.bool,
  },
  {
    formattedState(field) {
      return {
        nameInMapping: this.nameInMapping(field),
        displayName: this.displayName(field),
        isMapped: this.isMapped(field),
      };
    },
  },
);
