import { apiRoutes } from 'routes';
import FetchHelpers from 'utils/FetchHelpers';

export default {
  index(params) {
    const path = apiRoutes.documentsPath();
    return FetchHelpers.get(path, params);
  },
  current(documentId) {
    const path = apiRoutes.documentPath(documentId);
    return FetchHelpers.get(path);
  },
  preview(documentId) {
    const path = apiRoutes.documentPreviewPath(documentId);
    return FetchHelpers.get(path);
  },
  create(params) {
    const path = apiRoutes.documentsPath();
    return FetchHelpers.postMultipartFormData(path, params);
  },
  delete(documentId) {
    const path = apiRoutes.documentPath(documentId);
    return FetchHelpers.delete(path);
  },
  update(documentId, params) {
    const path = apiRoutes.documentPath(documentId);
    return FetchHelpers.put(path, params);
  },
};
