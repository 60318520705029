import React from 'react';
import { createMuiTheme } from '@material-ui/core/styles';
import palette from './palette';
import overrides from './overrides';
import Icon from 'components/Icon';

const htmlFontSize = 14;

const theme = createMuiTheme({
  props: {
    MuiCheckbox: {
      icon: <Icon name="checkboxEmpty" />,
      checkedIcon: <Icon name="checkboxChecked" />,
    },
  },
  typography: {
    htmlFontSize,
    fontSize: 14,
    headline: {
      fontSize: `${24 / htmlFontSize}rem`,
      fontWeight: 400,
    },
    title: {
      fontSize: `${18 / htmlFontSize}rem`,
      fontWeight: 400,
    },
    subheading: {
      fontSize: `${16 / htmlFontSize}rem`,
      fontWeight: 500,
    },
  },
  palette,
  overrides,
  htmlFontSize,
});

export default theme;
