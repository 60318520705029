import { apiRoutes } from 'routes';
import FetchHelpers from 'utils/FetchHelpers';

export default {
  index(params) {
    const path = apiRoutes.templates2DPath();
    return FetchHelpers.get(path, params);
  },
  show(templateId) {
    const path = apiRoutes.template2DPath(templateId);
    return FetchHelpers.get(path, templateId);
  },
  create(params) {
    const path = apiRoutes.templates2DPath();
    return FetchHelpers.post(path, params);
  },
  validate(params) {
    const path = apiRoutes.templateValidatePath();
    return FetchHelpers.post(path, params);
  },
};
