import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  tableField: {
    tableLayout: 'fixed',
    width: '100%',
    margin: [24, 40, 0, 0],

    '& tbody::before': {
      content: '""',
      display: 'block',
      height: 8,
    },
    '& th': {
      padding: [7, 0],
      color: theme.palette.text.lightDark,
      backgroundColor: theme.palette.background.white,
      fontSize: theme.typography.pxToRem(10),
      lineHeight: 1.2,
      fontWeight: 500,
      width: '100%',
      '&:first-child': {
        paddingLeft: 0,
        width: '100%',
        maxWidth: 'none',
      },
      '&:last-child': {
        maxWidth: 32,
        width: 32,
      },
    },
    '& td': {
      padding: 0,
      borderBottom: 'none',
      width: '100%',
      '&:first-child': {
        width: '100%',
        maxWidth: 'none',
        borderRadius: 2,
        '& div': {
          paddingLeft: 9,
          marginLeft: -9,
          marginRight: 30,
        },
      },
      '&:last-child': {
        paddingRight: 0,
        maxWidth: 32,
        width: 32,
      },
    },
  },
  icon: {
    width: 16,
    height: 16,
    verticalAlign: 'middle',
  },
  removeIcon: {
    fill: theme.palette.shadow,
    cursor: 'pointer',
  },

  tableRow: {
    '& td': {
      width: '100%',
      position: 'relative',
      '&:first-child': {
        '& div::before': {
          content: '""',
          height: '100%',
          position: 'absolute',
          display: 'block',
          left: -6,
          width: 6,
          background: `linear-gradient(to right, ${theme.palette.title} 50%, ${theme.palette.background.hover} 50%)`,
          opacity: 0,
          top: 0,
          borderRadius: [3, 0, 0, 3],
        },
      },
    },

    '& div': {
      padding: [8, 0],
      lineHeight: 1,
    },
    '&:hover': {
      backgroundColor: theme.palette.background.hover,
      cursor: 'pointer',

      '& td:first-child div::before': {
        opacity: 1,
      },

      '& td:last-child': {
        backgroundColor: theme.palette.background.white,
      },
    },
  },
  sortIcon: {
    width: 5,
    marginLeft: 6,
    cursor: 'pointer',
  },
  thead: {
    display: 'flex',
    alignItems: 'center',
  },
  buttonDelete: {
    padding: 0,
    maxWidth: 30,
    minWidth: 30,
    '&:hover': {
      backgroundColor: theme.palette.background.white,
    },
  },
  checked: {
    backgroundColor: theme.palette.background.hover,
    cursor: 'pointer',

    '& td:first-child div::before': {
      opacity: 1,
    },

    '& td:last-child': {
      backgroundColor: theme.palette.background.white,
    },
  },
  noRows: {
    marginTop: 36,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.text.primary,
  },
  mappingTypeCell: {
    flexBasis: 84,
  },
  fieldNameCell: {
    width: '100%',
    maxWidth: 'none',
  },
  fieldNameForUserCell: {},
  noWrap: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));
