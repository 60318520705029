import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { DeleteFile } from 'components/Dialogs';
import Icon from 'components/Icon';
import useStyles from './useStyles';
import { useDocuments } from 'slices/hooks';
import { getLoadingParams } from 'utils/loadingParams';
import CurrentDocumentPresenter from 'presenters/CurrentDocumentPresenter';

const ActionsCell = (props) => {
  const { row, documentsPageNumber } = props;
  const { deleteDocument, loadDocuments } = useDocuments();
  const classes = useStyles();
  const [isDeleteFileDialogOpen, setIsDeleteFileDialogOpen] = useState(false);

  const handleDeleteFileDialogOpen = () => {
    setIsDeleteFileDialogOpen(true);
  };

  const handleDeleteFileDialogClose = () => {
    setIsDeleteFileDialogOpen(false);
  };

  const handleDeleteFile = (id) => {
    setIsDeleteFileDialogOpen(false);
    deleteDocument(id).then(() => loadDocuments(getLoadingParams(documentsPageNumber)));
  };

  return (
    <>
      <Button
        aria-controls="dropdown-menu"
        aria-haspopup="true"
        className={classes.actionsCell}
        onClick={handleDeleteFileDialogOpen}
      >
        <Icon name="trash" className={classes.icon} />
      </Button>
      {isDeleteFileDialogOpen && (
        <DeleteFile
          documentId={CurrentDocumentPresenter.id(row)}
          onSubmit={handleDeleteFile}
          onClose={handleDeleteFileDialogClose}
        />
      )}
    </>
  );
};

ActionsCell.propTypes = {
  documentsPageNumber: PropTypes.number,
  row: CurrentDocumentPresenter.shape().isRequired,
};

ActionsCell.defaultProps = {
  documentsPageNumber: 1,
};

export default ActionsCell;
