import PropTypes from 'prop-types';

import Presenter from 'utils/PropTypesPresenter';

export default new Presenter({
  fieldName: PropTypes.string,
  row: PropTypes.number,
  state: PropTypes.string,
  type: PropTypes.string,
  messages: PropTypes.arrayOf(PropTypes.string),
});
