import React from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, Checkbox as MUICheckbox } from '@material-ui/core';

const Checkbox = (props) => {
  const { label, name } = props;
  return <FormControlLabel control={<MUICheckbox {...props} name={name} />} label={label} />;
};

Checkbox.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
};

Checkbox.defaultProps = {
  label: 'Required',
};

export default Checkbox;
