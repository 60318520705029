import { configureStore } from '@reduxjs/toolkit';

import SessionSlice from 'slices/SessionSlice';
import DocumentsSlice from 'slices/DocumentsSlice';
import UsersSlice from 'slices/UsersSlice';
import TemplatesSlice from 'slices/TemplatesSlice';
import DatasetsSlice from 'slices/DatasetsSlice';
import FieldsSlice from 'slices/FieldsSlice';
import UserFieldsSlice from 'slices/UserFieldsSlice';
import UserDatasetsSlice from 'slices/UserDatasetsSlice';
import MappingSlice from 'slices/MappingSlice';

const store = configureStore({
  reducer: {
    SessionSlice,
    DocumentsSlice,
    UsersSlice,
    TemplatesSlice,
    DatasetsSlice,
    FieldsSlice,
    UserDatasetsSlice,
    MappingSlice,
    UserFieldsSlice,
  },
});

export default store;
