import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    height: '100vh',
  },
  details: {
    position: 'relative',
    width: 360,
    margin: 'auto',
    padding: [32, 40],
    borderRadius: 8,
    textAlign: 'center',
  },
  logo: {
    position: 'absolute',
    width: 124,
    top: -96,
    left: '50%',
    marginLeft: -62,
  },
  numberError: {
    color: theme.palette.title,
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(100),
    lineHeight: 1,
    margin: [0, 0, 16, 0],
  },
  messageError: {
    fontSize: theme.typography.pxToRem(32),
    color: theme.palette.title,
    lineHeight: 1,
    fontWeight: 600,
    marginBottom: 15,
  },
}));
