import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  pagination: {
    display: 'block',
    width: '100%',
    margin: [40, 'auto'],
    textAlign: 'center',
  },
}));
