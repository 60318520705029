import palette from 'layouts/CustomThemeProvider/palette';

export default {
  MuiAlert: {
    root: {
      position: 'fixed',
      display: 'flex',
      alignItems: 'center',
      bottom: 60,
      right: 10,
      padding: [8, 44, 8, 24],
      borderRadius: 'none',
      zIndex: 1000,
    },
    icon: {
      '& svg': {
        fontSize: 32,
        fill: palette.errors,
      },
    },
    message: {
      paddingLeft: 15,
      fontSize: 16,
      lineHeight: 1.7,
      color: palette.text.primary,
    },
    outlinedError: {
      background: palette.background.white,
    },
    action: {
      position: 'absolute',
      display: 'inline-block',
      right: 0,
      top: 0,
      '& svg': {
        top: 3,
        position: 'absolute',
      },
    },
  },
};
