import { isBlank } from './conditions';
import { find, propEq } from 'ramda';

const lineIndexTypes = {
  columnIndex: 'columnIndex',
  rowIndex: 'rowIndex',
};

export const getMapSelectorInitialValue = (template, datasetId, cell = null, row = null) => {
  if (isBlank(template)) return {};
  const templateLines = row ? template.rows : template.columns;
  const lineIndex = row ? Number(row.id) : Number(cell.column.id);
  const lineIndexType = row ? lineIndexTypes.rowIndex : lineIndexTypes.columnIndex;
  const hasInitialValue = template ? !isBlank(template) && !isBlank(templateLines) : false;
  const foundedValue = find(propEq(lineIndexType, lineIndex))(templateLines) || {};
  const initialValue = hasInitialValue ? foundedValue : {};

  return initialValue;
};
