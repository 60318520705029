import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';

import Icon from 'components/Icon';
import MappingFieldPresenter from 'presenters/MappingFieldPresenter';
import { ReactComponent as ArrowDown } from 'assets/icons/arrow_down.svg';

import useStyles from './useStyles';

const Columns = (props) => {
  const classes = useStyles();
  const [tableCollapsed, setTableCollapsed] = useState(false);
  const [visibleFields, setVisibleFields] = useState([]);
  const [isAllVisible, setAllVisible] = useState(false);
  const { fields, rowQty, title, mapped } = props;
  const mappedFields = fields.filter((field) => MappingFieldPresenter.isMapped(field) === mapped);

  useEffect(() => {
    if (!isEmpty(mappedFields)) {
      setVisibleFields(mappedFields.slice(0, rowQty));
      setAllVisible(mappedFields.length <= rowQty);
    }
  }, [fields, rowQty]); // eslint-disable-line

  const toggleMappedColumnsSection = () => {
    setTableCollapsed(!tableCollapsed);
  };

  const showAllFields = () => {
    setVisibleFields(mappedFields);
    setAllVisible(true);
  };

  if (isEmpty(mappedFields)) {
    return null;
  }

  return (
    <div className={classes.mappedColumnsSection}>
      <div className={classes.mappedColumnsSectionHeader}>
        <span>
          {title}: {mappedFields.length}
        </span>
        <button type="button" onClick={toggleMappedColumnsSection}>
          <span>hide</span>
          <ArrowDown />
        </button>
      </div>

      {!tableCollapsed && (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Column</TableCell>
                <TableCell align="center">Mapped</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {visibleFields.map((field, i) => (
                <TableRow key={i}>
                  <TableCell>{field.displayName}</TableCell>
                  <TableCell align="center">
                    <Icon name={mapped ? 'tick' : 'cross'} className={classes.icon} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            {!isAllVisible && (
              <TableFooter>
                <TableRow>
                  <TableCell>
                    <button type="button" onClick={showAllFields}>
                      SHOW ALL
                    </button>
                    <span>{`Showed: ${rowQty} of ${mappedFields.length}`} </span>
                  </TableCell>
                </TableRow>
              </TableFooter>
            )}
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

Columns.propTypes = {
  fields: PropTypes.arrayOf(MappingFieldPresenter.shape()),
  rowQty: PropTypes.number,
  title: PropTypes.string.isRequired,
  mapped: PropTypes.bool.isRequired,
};

Columns.defaultProps = {
  fields: [],
  rowQty: 5,
};
export default Columns;
