import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(
  (theme) => ({
    footer: {
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0,
      boxShadow: ['inset', 0, 1, 0, theme.palette.shadow],
      padding: [10, 120, 10, 40],
      background: theme.palette.background.default,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      zIndex: 3,
      '& p': {
        color: theme.palette.text.lightDark,
        fontSize: theme.typography.pxToRem(12),
      },
      '& Button': {
        background: theme.palette.default,
        padding: 4,
        height: 32,
        width: 112,
      },
    },
  }),
  { name: 'MappingToolbar' },
);
