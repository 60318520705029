const API_V1_PREFIX = '/api/v1';

export const apiRoutes = {
  sessionPath: () => `${API_V1_PREFIX}/sessions/`,
  documentsPath: () => `${API_V1_PREFIX}/documents/`,
  documentPreviewPath: (documentId) => `${API_V1_PREFIX}/documents/${documentId}/multi-sheet-preview/`,
  documentPath: (documentId) => `${API_V1_PREFIX}/documents/${documentId}/`,
  currentUserPath: () => `${API_V1_PREFIX}/users/current/`,
  datasetsPath: () => `${API_V1_PREFIX}/manager/datasets/`,
  datasetPath: (datasetId) => `${API_V1_PREFIX}/manager/datasets/${datasetId}/`,
  fieldsDatasetPath: () => `${API_V1_PREFIX}/manager/fields/`,
  fieldDatasetPath: (fieldId) => `${API_V1_PREFIX}/manager/fields/${fieldId}/`,
  datasetPublishPath: (datasetId) => `${API_V1_PREFIX}/manager/datasets/${datasetId}/publish/`,
  mappingsPath: () => `${API_V1_PREFIX}/mappings/`,
  mappingsValidatePath: () => `${API_V1_PREFIX}/validate-mappings/`,
  datasetsUserPath: () => `${API_V1_PREFIX}/datasets/`,
  datasetUserPath: (datasetId) => `${API_V1_PREFIX}/datasets/${datasetId}/`,
  fieldFormatsPath: () => `${API_V1_PREFIX}/field-formats/`,
  fieldValidatorsPath: () => `${API_V1_PREFIX}/field-validators/`,
  documentFieldsPath: (documentId) => `${API_V1_PREFIX}/documents/${documentId}/fields/`,
  currentMappingPath: (documentId) => `${API_V1_PREFIX}/mappings/${documentId}/`,
  mappingResult: (documentId) => `${API_V1_PREFIX}/mappings/${documentId}/send-result/`,
  datasetInitialParametersPath: (datasetId) => `${API_V1_PREFIX}/manager/dataset-parameters/${datasetId}/`,
  datasetInitialParametersUserPath: (datasetId) => `${API_V1_PREFIX}/dataset-parameters/${datasetId}/`,
  mappings3DPath: () => `${API_V1_PREFIX}/3d/mappings/`,
  currentMapping3DPath: (documentId) => `${API_V1_PREFIX}/3d/mappings/${documentId}/`,
  mapping3DResult: (documentId) => `${API_V1_PREFIX}/3d/mappings/${documentId}/send-result/`,
  mappings3DValidatePath: () => `${API_V1_PREFIX}/3d/validate-mappings/`,
  templateValidatePath: () => `${API_V1_PREFIX}/validate-template/`,
  templates3DPath: () => `${API_V1_PREFIX}/3d/templates/`,
  adaptive3DTemplatePath: (templateId) => `${API_V1_PREFIX}/3d/adaptive-templates/${templateId}/`,
  templates2DPath: () => `${API_V1_PREFIX}/2d/templates/`,
  templatesPath: () => `${API_V1_PREFIX}/templates/`,
  template2DPath: (templateId) => `${API_V1_PREFIX}/2d/templates/${templateId}/`,
  template3DPath: (templateId) => `${API_V1_PREFIX}/3d/templates/${templateId}/`,
  templatePath: (templateId) => `${API_V1_PREFIX}/templates/${templateId}/`,
  currentMappingMultiSheetPath: (documentId) => `${API_V1_PREFIX}/multisheet/mappings/${documentId}/`,
  mappingsMultiSheetPath: () => `${API_V1_PREFIX}/multisheet/mappings/`,
  mappingsMultiSheetValidatePath: () => `${API_V1_PREFIX}/multisheet/validate-mappings/`,
  mappingMultiSheetResult: (documentId) => `${API_V1_PREFIX}/multisheet/mappings/${documentId}/send-result/`,
  templatesMultiSheetPath: () => `${API_V1_PREFIX}/multisheet-templates/`,
  templateMultiSheetPath: (templateId) => `${API_V1_PREFIX}/multisheet-templates/${templateId}/`,
  adaptiveMultiSheetTemplatePath: (templateId) => `${API_V1_PREFIX}/multisheet/adaptive-templates/${templateId}/`,
};
