import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  scrumb: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.4,
  },
  icon: {
    paddingTop: 2,
  },
}));
