import React, { useEffect } from 'react';
import TemplatesTable from './components/TemplatesTable';
import Pagination from 'components/Pagination';
import Preloader from 'components/Preloader';
import { useTemplates } from 'slices/hooks';
import { COLUMNS_TEMPLATES } from 'helpers/documents';
import { INITIAL_LOADING_PARAMS, getLoadingParams } from 'utils/loadingParams';
import MetaPresenter from 'presenters/MetaPresenter';
import TemplatePresenter from 'presenters/TemplatePresenter';

const TemplatesPanel = () => {
  const { loadMultiSheetTemplates, resetTemplates, templates, meta, isLoading } = useTemplates();
  useEffect(() => {
    loadMultiSheetTemplates(INITIAL_LOADING_PARAMS);
    return resetTemplates;
  }, []); // eslint-disable-line

  const handleChangePage = (event, pageNumber) => {
    loadMultiSheetTemplates(getLoadingParams(pageNumber));
  };

  const rows = TemplatePresenter.templatesRows(templates);

  return (
    <>
      {isLoading && <Preloader type="table" />}
      <TemplatesTable rows={rows} columns={COLUMNS_TEMPLATES} title="Templates" onSearch={loadMultiSheetTemplates} />
      <Pagination totalPages={MetaPresenter.totalPages(meta)} onChange={handleChangePage} />
    </>
  );
};

export default TemplatesPanel;
