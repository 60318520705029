import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  wrapper: {
    marginTop: 12,
  },
  buttonAdd: {
    display: 'flex',
    fontSize: theme.typography.pxToRem(10),
    color: theme.palette.default,
    cursor: 'pointer',
    paddingLeft: 0,
  },
  buttonLabel: {
    marginLeft: 6,
  },

  closeIcon: {
    fill: theme.palette.shadow,
    cursor: 'pointer',
    '&:first-child': {
      fontSize: theme.typography.pxToRem(16),
    },
  },

  addIcon: {
    '&:first-child': {
      fontSize: theme.typography.pxToRem(12),
    },
  },
  error: {
    display: 'block',
    position: 'absolute',
    bottom: -10,
    color: theme.palette.errors,
    fontSize: theme.typography.pxToRem(9),
    paddingLeft: 16,
  },
  hasError: {
    '& input': {
      border: [1, 'solid', theme.palette.errors],
    },
  },
  hint: {
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.text.lightDark,
    marginTop: 12,
    marginBottom: 28,
  },
  parameterForm: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 24,
  },
  disabled: {
    pointerEvents: 'none',
    opacity: 0.5,
  },
  fieldWrapper: {
    marginRight: 24,
    width: 256,
  },
  selectWrapper: {
    width: 160,
  },
  buttonWrapper: {
    marginTop: 13,
  },
}));
