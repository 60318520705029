import palette from 'layouts/CustomThemeProvider/palette';

export default {
  MuiPaginationItem: {
    page: {
      color: palette.text.lightDark,
      borderRadius: 3,
      margin: 0,
      background: palette.background.white,
      '&$selected': {
        color: palette.text.white,
        backgroundColor: palette.default,
        '&:hover': {
          color: palette.text.white,
          backgroundColor: palette.default,
        },
      },
      '&:hover': {
        color: palette.default,
        backgroundColor: palette.background.white,
      },
    },
    icon: {
      fill: palette.default,
    },
  },
};
