import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { all, equals, isEmpty, isNil, keys, uniq } from 'ramda';

import { Box } from '@material-ui/core';

import { appRoutes } from 'routes';

import { useDocuments, useMapping, useTemplates, useUserDatasets, useUserFields } from 'slices/hooks';

import { DOCUMENT_STATUSES } from 'utils/documentStatuses';
import history from 'utils/history';

import DatasetPresenter from 'presenters/DatasetPresenter';
import DocumentPresenter from 'presenters/DocumentPresenter';

import { ErrorAlert } from 'components/Alerts';
import Breadcrumbs from 'components/Breadcrumbs';
import MappingTable from 'components/MappingTable';
import MappingToolbar from 'components/MappingToolbar';
import { getSingleDatasetMappingSteps } from 'components/MappingSteps/steps';
import Tabs from 'components/Tabs';
import Icon from 'components/Icon';

import SheetSchemaName from './components/SheetSchemaName';

import useStyles from './useStyles';
import DatasetFieldPresenter from 'presenters/DatasetFieldPresenter';
import { isBlank } from 'utils/conditions';
import { FOOTER_HEIGHT } from 'utils/layoutConstants';
import { TemplateLoadingProblems } from 'components/Dialogs';
import { ERROR_STATES, getAllErrors, getTabs } from './hooks/validation';
import { ValidateTab, ValidateTabPanels } from './components/SheetSchemaValidate';
import { useLocalStorage } from '@rehooks/local-storage';
import { UncorrectableErrorScreen } from '../MapValidate/components';

const MAIN_BLOCK_OFFSET = 32;

const getMainBlockHeight = (offsetTop = 0) => `calc(100vh - ${offsetTop}px - ${FOOTER_HEIGHT}px)`;
const getValidationBlockHeight = (offsetTop = 0) =>
  `calc(100vh - ${offsetTop}px - ${FOOTER_HEIGHT}px - ${MAIN_BLOCK_OFFSET}px)`;
const isSchemaType3D = (fileType) => fileType === '3D';

const MultiSheetMapData = () => {
  const classes = useStyles();
  const { loadCurrentDocument, currentDocument, loadDocumentSheets, sheets, sheetTitles, isLoading } = useDocuments();

  const [isDisabledSubmitButton, setIsDisabledSubmitButton] = useState(true);
  const { fields, setFields, clearIsMappedField } = useUserFields();
  const { currentDataset, loadCurrentDataset, resetCurrentDataset } = useUserDatasets();
  const documentName = DocumentPresenter.fileName(currentDocument);
  const sheetSchemas = DatasetPresenter.sheetSchemas(currentDataset);

  const {
    createMultiSheetMapping,
    mappedColumns,
    apiErrors,
    resetCurrentMapping,
    resetCommonErrors,
    allIgnoredRows,
    mappedRows,
    validators,
    changes,
    validateMultiSheetMapping,
    uncorrectableErrors,
    resetIgnoreRows,
    resetAllIgnoreRows,
  } = useMapping();

  const [isErrorShowing, setIsErrorShowing] = useState(false);
  const [selectedSheetIndexes, setSelectedSheetIndex] = useState({});
  const [activeTabItemIndex, setActiveTabItemIndex] = useState(sheetSchemas ? 0 : null);
  const fullScreenHandle = useFullScreenHandle();
  const { active, enter, exit } = fullScreenHandle;
  const initialMainHeight = getMainBlockHeight();
  const initialValidationHeight = getValidationBlockHeight();
  const [mainHeight, setMainHeight] = useState(initialMainHeight);
  const [validationHeight, setValidationHeight] = useState(initialValidationHeight);
  const table = document.querySelector('[aria-label="main"]');

  const { documentId } = useParams();
  const { apiTemplateErrors, isTemplateChanged } = useTemplates();
  const [template] = useLocalStorage('template');

  const isNoChanges = isEmpty(changes.changes2d) && isEmpty(changes.changes3d);

  const lastChanges = useRef({});

  const handleSelectSheet = useCallback(
    (index, datasetId) => {
      setSelectedSheetIndex({ ...selectedSheetIndexes, [datasetId]: index });
      resetCurrentMapping(datasetId);
      clearIsMappedField(datasetId);
      resetIgnoreRows(datasetId);
    },
    [selectedSheetIndexes, resetCurrentMapping, clearIsMappedField, resetIgnoreRows],
  );

  const getTemplate = useCallback(() => {
    if (template && !isTemplateChanged) {
      const newTemplate = {
        templates3d: [...template.templates3d],
        templates2d: [...template.templates2d],
      };

      Object.values(newTemplate).forEach((templateTypeList) => {
        templateTypeList.forEach((templateType) => {
          if (!templateType.sheetSchemaId) {
            // eslint-disable-next-line no-param-reassign
            templateType.sheetSchemaId = templateType.sheetSchema.id;
          }
        });
      });
      return newTemplate;
    }

    const templates2d = [];
    const templates3d = [];

    if (!sheetSchemas) {
      return false;
    }

    sheetSchemas.forEach((schema) => {
      if (isSchemaType3D(schema.fileType)) {
        templates3d.push({
          sheetSchemaId: schema.id,
          columns: uniq(mappedColumns[schema.id] || []),
          rows: uniq(mappedRows[schema.id] || []),
          sheetIndex: selectedSheetIndexes[schema.id],
        });
      } else {
        templates2d.push({
          sheetSchemaId: schema.id,
          columns: uniq(mappedColumns[schema.id] || []),
          ignoreRows: uniq(allIgnoredRows[schema.id] || []),
          sheetIndex: selectedSheetIndexes[schema.id],
          numberOfRowsToIgnore: allIgnoredRows[schema.id]?.length || 0,
        });
      }
    });

    return { templates2d, templates3d };
  }, [allIgnoredRows, isTemplateChanged, mappedColumns, mappedRows, selectedSheetIndexes, sheetSchemas, template]);

  const sendChanges = useCallback(() => {
    if (isNoChanges) return null;
    const { changes2d = [], changes3d = [] } = changes;

    if (equals(lastChanges.current, changes)) return false;

    const data = {
      documentId,
      template: getTemplate(),
      changes: {
        changes2d,
        changes3d,
      },
    };

    lastChanges.current = { ...changes };

    return validateMultiSheetMapping(data);
  }, [changes, documentId, getTemplate, isNoChanges, validateMultiSheetMapping]);

  const sendChangesBeforeTabSwitch = useCallback(
    async (tabIndex) => {
      await sendChanges();
      await setActiveTabItemIndex(tabIndex);
    },
    [sendChanges],
  );

  const handleContinue = useCallback(async () => {
    const currentTemplate = getTemplate();

    await sendChanges(currentTemplate);

    const { changes2d = [], changes3d = [] } = changes;

    const data = {
      documentId,
      template: currentTemplate,
      changes: { changes2d, changes3d },
    };

    createMultiSheetMapping(data);

    return false;
  }, [changes, createMultiSheetMapping, documentId, getTemplate, sendChanges]);

  const items = useMemo(() => {
    if (sheetSchemas?.length) {
      return sheetSchemas.map((sheetSchema) => {
        const allValidators = Object.values(validators).reduce((acc, results) => {
          return [...acc, ...results.map((result) => result)];
        }, []);
        const currentValidator = allValidators.find((validator) => validator.sheetSchemaId === sheetSchema.id);
        return {
          id: sheetSchema.id,
          title: (
            <SheetSchemaName
              sheetSchema={sheetSchema}
              isActive={DatasetFieldPresenter.areRequiredFieldsMapped(fields[sheetSchema.id])}
            />
          ),
          validationTabs: currentValidator
            ? getTabs(currentValidator).map((tab) => {
                return (
                  <ValidateTab
                    tab={tab}
                    schemaId={sheetSchema.id}
                    validators={currentValidator}
                    is3DMappingType={currentValidator?.uncorrectableErrors}
                    onChange={sendChangesBeforeTabSwitch}
                    currentTabIndex={activeTabItemIndex}
                  />
                );
              })
            : [],
          content: (
            <div className={classes.mappingTable}>
              <MappingTable
                currentDocument={currentDocument}
                currentDataset={sheetSchema}
                sheets={sheets}
                sheetTitles={sheetTitles}
                selectedSheetIndex={isBlank(selectedSheetIndexes) ? 0 : selectedSheetIndexes[sheetSchema.id]}
                isLoading={isLoading}
                onSheetButtonClick={handleSelectSheet}
                fields={!isBlank(fields) ? fields[sheetSchema.id] : []}
                mainHeight={mainHeight}
                fullScreenHandle={fullScreenHandle}
                datasetId={sheetSchema.id}
                setIsDisabledSubmitButton={setIsDisabledSubmitButton}
              />
              {active && <MappingToolbar onContinueButtonClick={handleContinue} disabled={isDisabledSubmitButton} />}
              <ErrorAlert onClose={resetCommonErrors} isShowing={isErrorShowing} errors={apiErrors} />
            </div>
          ),
          validationContent: allValidators
            ? allValidators.map((validator) => {
                if (sheetSchema.id !== validator.sheetSchemaId) return null;
                return (
                  <ValidateTabPanels
                    validators={validator}
                    schemaId={sheetSchema.id}
                    currentTabIndex={activeTabItemIndex}
                    is3DMappingType={validator?.uncorrectableErrors}
                    panelHeight={validationHeight}
                    sheetSchemas={sheetSchemas}
                    isNoChanges={isNoChanges}
                  />
                );
              })
            : [],
        };
      });
    }
    return [];
  }, [
    sheetSchemas,
    validators,
    fields,
    classes.mappingTable,
    currentDocument,
    sheets,
    sheetTitles,
    selectedSheetIndexes,
    isLoading,
    handleSelectSheet,
    mainHeight,
    fullScreenHandle,
    active,
    handleContinue,
    isDisabledSubmitButton,
    resetCommonErrors,
    isErrorShowing,
    apiErrors,
    sendChangesBeforeTabSwitch,
    activeTabItemIndex,
    validationHeight,
    isNoChanges,
  ]);

  useEffect(() => {
    if (!isEmpty(apiErrors)) {
      setIsErrorShowing(true);
    }
  }, [apiErrors]); // eslint-disable-line

  useEffect(() => {
    if (isEmpty(fields)) {
      return;
    }

    const sheetSchemasIds = keys(fields);

    const isAllDatasetsRequiredFieldsMapped = all((sheetSchemasId) => {
      return DatasetFieldPresenter.areRequiredFieldsMapped(fields[sheetSchemasId]);
    }, sheetSchemasIds);

    setIsDisabledSubmitButton(!isAllDatasetsRequiredFieldsMapped);
  }, [fields, selectedSheetIndexes]); // eslint-disable-line

  useEffect(() => {
    loadCurrentDocument(documentId).then(({ payload }) => {
      if (DocumentPresenter.state(payload) === DOCUMENT_STATUSES.mapped) {
        history.push(appRoutes.documentsPath());
      } else {
        const dataset = DocumentPresenter.dataset(payload);
        const datasetId = DatasetPresenter.id(dataset);

        loadCurrentDataset(datasetId);
        loadDocumentSheets(documentId);
      }
    });
    return () => {
      resetCurrentMapping();
      resetCurrentDataset();
      resetAllIgnoreRows();
    };
  }, []); // eslint-disable-line

  useEffect(() => {
    if (!isNil(table)) {
      const sizes = table && table.getBoundingClientRect();
      const calculatedMainBlockHeight = getMainBlockHeight(sizes.top);
      const calculatedValidationBlockHeight = getValidationBlockHeight(sizes.top);
      setMainHeight(calculatedMainBlockHeight);
      setValidationHeight(calculatedValidationBlockHeight);
    }
  }, [table, active, documentName]); // eslint-disable-line

  useEffect(() => {
    if (!sheetSchemas) {
      return;
    }
    let initialIndexes = {};
    if (template) {
      const allTemplates = template.templates2d.concat(template.templates3d);
      initialIndexes = allTemplates.reduce((acc, item) => {
        const { sheetSchema = {}, sheetIndex = null } = item;
        const { id = null } = sheetSchema;
        if (id) {
          return { ...acc, [id]: sheetIndex };
        }
        return acc;
      }, {});
    }

    const initialSelectedSheetIndexesState = sheetSchemas.reduce(
      (acc, { id }) => ({
        ...acc,
        [id]: initialIndexes[id] || 0,
      }),
      {},
    );

    const userFields = sheetSchemas.reduce((acc, sheetSchema) => {
      return {
        ...acc,
        [sheetSchema.id]: sheetSchema.fields,
      };
    }, {});
    setFields(userFields);
    setSelectedSheetIndex(initialSelectedSheetIndexesState);
    setActiveTabItemIndex(0);
  }, [sheetSchemas]); // eslint-disable-line

  useEffect(() => {
    const isAllErrorsFixed = getAllErrors(validators).every((error) => {
      return error.state === ERROR_STATES.fixed;
    });
    setIsDisabledSubmitButton(!isAllErrorsFixed);
  }, [validators]);

  return (
    <>
      <Box mt={2.5} mb={3}>
        <Breadcrumbs
          breadcrumbs={[
            { link: appRoutes.documentsPath(), name: 'Import Tool' },
            { name: documentName, link: '' },
          ]}
        />
      </Box>
      <FullScreen handle={fullScreenHandle}>
        <div className={classes.mappingTableTopContainer}>
          <span className={classes.mappingTableCaption}>{getSingleDatasetMappingSteps()[1].name}</span>
          <button type="button" tabIndex="0" className={classes.fullscreenToggle} onClick={active ? exit : enter}>
            <Icon name="fullscreen" /> Toggle Fullscreen
          </button>
        </div>
        {!isEmpty(uncorrectableErrors) ? (
          <Box mb={8}>
            <UncorrectableErrorScreen documentId={documentId} errors={uncorrectableErrors} />
          </Box>
        ) : (
          <>
            <Box mb={8}>
              <Tabs activeItemIndex={activeTabItemIndex} items={items} onChange={setActiveTabItemIndex} />
            </Box>
            <MappingToolbar onContinueButtonClick={handleContinue} disabled={isDisabledSubmitButton && isNoChanges} />
            {!isEmpty(apiTemplateErrors) && <TemplateLoadingProblems details={apiTemplateErrors} />}
          </>
        )}
      </FullScreen>
    </>
  );
};

export default MultiSheetMapData;
