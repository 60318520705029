import { useSelector } from 'react-redux';

import { useDatasetsActions } from 'slices/UserDatasetsSlice';

export const useUserDatasets = () => {
  const {
    loadDatasets,
    resetDatasets,
    loadCurrentDataset,
    resetCurrentDataset,
    loadInitialParameters,
    resetInitialParameters,
  } = useDatasetsActions();
  const isLoading = useSelector((state) => state.UserDatasetsSlice.loading);
  const errors = useSelector((state) => state.UserDatasetsSlice.errors);
  const datasets = useSelector((state) => state.UserDatasetsSlice.datasets);
  const meta = useSelector((state) => state.UserDatasetsSlice.meta);
  const initialParameters = useSelector((state) => state.UserDatasetsSlice.initialParameters);
  const currentDataset = useSelector((state) => state.UserDatasetsSlice.currentDataset);

  return {
    loadDatasets,
    loadCurrentDataset,
    resetCurrentDataset,
    resetDatasets,
    isLoading,
    errors,
    datasets,
    meta,
    loadInitialParameters,
    initialParameters,
    currentDataset,
    resetInitialParameters,
  };
};
