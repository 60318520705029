import PropTypes from 'prop-types';
import { propEq, find, isNil } from 'ramda';

import Presenter from 'utils/PropTypesPresenter';
import { formatDate } from 'presenters/DateTimePresenter';
import UserPresenter from './UserPresenter';
import DatasetPresenter from './DatasetPresenter';

export default new Presenter(
  {
    id: PropTypes.number,
    name: PropTypes.string,
    createdAt: PropTypes.string,
    dataset: DatasetPresenter.shape(),
    user: UserPresenter.shape(),
  },
  {
    formattedState(template) {
      return {
        id: this.id(template),
        fileName: this.name(template),
        dataset: DatasetPresenter.name(this.dataset(template)),
        createdAt: formatDate(this.createdAt(template)),
        user: UserPresenter.fullName(this.user(template)),
      };
    },
    templatesRows(templates) {
      if (isNil(templates)) {
        return [];
      }
      return templates.map((template) => {
        return this.formattedState(template);
      });
    },
    options(templates) {
      if (isNil(templates)) {
        return [];
      }
      return templates.map((template) => {
        return {
          value: String(this.id(template)),
          label: this.name(template),
        };
      });
    },
    findTemplateById(templates, templateId) {
      const template = find(propEq('id', Number(templateId)))(templates);
      return {
        ...template,
        datasetId: this.getDatasetId(template),
      };
    },
    getDatasetId(template) {
      return DatasetPresenter.id(this.dataset(template));
    },
  },
);
