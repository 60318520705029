import { path, is, forEach, lensPath, view, isEmpty, toLower, isNil, pathOr, values } from 'ramda';

import { toUpperCaseFirstLetter } from 'utils/stringUtils';
import { set } from 'utils/storeUtils';
import { camelize } from './keysConverter';

const isFormikError = (error) => is(Error, error) && error.name === 'ValidationError';

export const hasErrorAt = (errors, lens) => {
  return !!view(lensPath(lens), errors);
};

export const errorValueAt = (errors, lens) => {
  return view(lensPath(lens), errors);
};

export const yupToFormErrors = (yupErrors) => {
  let errors = {};

  const formatError = (err) => {
    if (!errors[err.path]) {
      const fieldName = err.path;
      const formattedFieldName = toUpperCaseFirstLetter(toLower(fieldName));
      const error = err.message.replace(fieldName, formattedFieldName);

      errors = set(errors, err.path.split('.'), error);
    }
  };

  if (isEmpty(yupErrors.inner)) {
    formatError(yupErrors);
  } else {
    const yupErrorsValues = values(yupErrors.inner);
    forEach(formatError, yupErrorsValues);
  }

  return errors;
};

export const apiErrorsToFormik = (error) => {
  const { errors } = error.response.data;
  if (isNil(errors)) return {};
  return camelize(errors);
};

export const toCommonErrorInterface = (errors) => {
  if (isFormikError(errors)) {
    return yupToFormErrors(errors);
  }
  return errors;
};

export const handleErrors = (err, handler) => {
  const commonErrors = toCommonErrorInterface(err);
  handler(commonErrors);
};

export const isNotFound = (error) => {
  return path(['response', 'status'], error) === 404;
};

export const isUnauthorized = (error) => {
  return path(['response', 'status'], error) === 401;
};

export const isUnprocessableEntity = (error) => {
  return path(['response', 'status'], error) === 422;
};

export const isLocked = (error) => {
  return path(['response', 'status'], error) === 423;
};

export const getIndexedErrorText = (errors, association, index, key) => {
  const errorKey = `${association}[${index}].${key}`;
  return pathOr(null, [errorKey], errors);
};
