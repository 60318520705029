import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  formName: {
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(20),
    lineHeight: 1.2,
    color: theme.palette.text.primary,
  },
  form: {
    position: 'relative',
    marginTop: 23,
  },
  formWrapper: {
    marginTop: 20,
  },
  checkboxWrapper: {
    margin: [10, 0],
  },
  selectParentWrapper: {
    marginTop: 20,
  },
  error: {
    position: 'relative',
    display: 'block',
    color: theme.palette.errors,
    fontSize: theme.typography.pxToRem(9),
    paddingLeft: 16,
    marginTop: 15,
  },
  actions: {
    marginTop: 90,
    textAlign: 'right',
  },
  buttonSave: {
    padding: [4, 12],
  },
}));
