import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { Link } from 'react-router-dom';
import { DialogContent, DialogActions, Button } from '@material-ui/core';
import { is, isEmpty, find, propEq } from 'ramda';
import { writeStorage } from '@rehooks/local-storage';

import { Select } from 'components/Common';
import Preloader from 'components/Preloader';
import { useTemplates, useDocuments } from 'slices/hooks';
import TemplatePresenter from 'presenters/TemplatePresenter';
import DocumentPresenter from 'presenters/DocumentPresenter';
import { validationSchema, initialValues } from 'forms/LoadMappingForm';
import { appRoutes } from 'routes';
import useStyles from 'components/Dialogs/useStyles';
import history from 'utils/history';

const Load2DMappingForm = (props) => {
  const classes = useStyles();

  const { onClose, backPreviousStep, apiErrors } = props;
  const { isLoading, resetTemplates, templates2D } = useTemplates();
  const { createdDocument } = useDocuments();

  useEffect(() => {
    return resetTemplates;
  }, []); // eslint-disable-line

  const redirectToInitialMappingRoute = () => {
    const documentId = DocumentPresenter.id(createdDocument);
    const initialMappingRoute = appRoutes.initialParametersPath(documentId);
    history.push(initialMappingRoute);
  };

  useEffect(() => {
    if (isEmpty(templates2D)) {
      redirectToInitialMappingRoute();
    }
  }, [templates2D]); // eslint-disable-line

  const handleLoadMapping = (values) => {
    if (values.templateId) {
      const template = find(propEq('id', Number(values.templateId)))(templates2D);
      writeStorage('template', template);
    }
    onClose();
    redirectToInitialMappingRoute();
  };

  const { values, errors, setFieldValue, submitForm } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleLoadMapping,
    validateOnChange: true,
  });

  const handleFieldChange = (field) => ({ target: { value } }) => {
    setFieldValue(field, value);
  };

  const renderErrors = () => {
    return Object.keys(apiErrors).map((key, index) => {
      if (!is(Array, apiErrors[key])) {
        return (
          <span className={classes.error} key={index}>
            is invalid
          </span>
        );
      }
      return (
        <span className={classes.error} key={index}>
          {apiErrors[key]}
        </span>
      );
    });
  };

  const handleMapFromScratch = () => {
    onClose();
    redirectToInitialMappingRoute();
  };

  return (
    <form>
      <DialogContent aria-label="choose-template">
        {isLoading ? (
          <Preloader type="inner" />
        ) : (
          <div className={classes.loadMapping}>
            <div className={classes.text}>
              Seems like this file has already been mapped earlier. Do you want to load previous mapping from this
              template?
            </div>
            <Button
              variant="text"
              component={Link}
              to={appRoutes.documentsTabPath('templates')}
              target="_blank"
              className={classes.buttonLink}
            >
              manage templates
            </Button>
            <Select
              value={values.templateId}
              label="Template"
              name="templateId"
              placeholder="Select the Template"
              onChange={handleFieldChange('templateId')}
              options={TemplatePresenter.options(templates2D)}
              helperText={errors.templateId}
              error={!!errors.templateId || !!apiErrors.template}
            />
            {renderErrors()}
          </div>
        )}
      </DialogContent>

      <DialogActions>
        <Button variant="text" onClick={backPreviousStep}>
          Back
        </Button>
        <Button onClick={handleMapFromScratch} variant="outlined">
          Map from scratch
        </Button>
        <Button data-test="load" onClick={submitForm} disabled={isLoading} variant="contained" autoFocus>
          Load Mapping
        </Button>
      </DialogActions>
    </form>
  );
};

Load2DMappingForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  backPreviousStep: PropTypes.func.isRequired,
  apiErrors: PropTypes.shape(),
};

Load2DMappingForm.defaultProps = {
  apiErrors: {},
};

export default Load2DMappingForm;
