import React, { useState, useEffect } from 'react';
import { Button } from '@material-ui/core';

import { CreateDataset } from 'components/Dialogs';
import { useDatasets } from 'slices/hooks';
import Preloader from 'components/Preloader';
import MetaPresenter from 'presenters/MetaPresenter';
import Pagination from 'components/Pagination';
import { getLoadingParams, INITIAL_LOADING_PARAMS } from 'utils/loadingParams';
import { Table } from './components';

import useStyles from './useStyles';

const columns = ['Name', 'Status', 'Updated', 'Created'];

const Datasets = () => {
  const { loadDatasets, resetDatasets, datasets, meta, isLoading } = useDatasets();

  const classes = useStyles();
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    loadDatasets(INITIAL_LOADING_PARAMS);
    return resetDatasets;
  }, []); // eslint-disable-line

  const handleChangePage = (event, pageNumber) => {
    loadDatasets(getLoadingParams(pageNumber));
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div className={classes.top}>
        <h1 className={classes.title}>Import Tool</h1>
        <Button variant="outlined" onClick={handleOpen}>
          create dataset
        </Button>
        {isOpen && <CreateDataset onClose={handleClose} />}
      </div>
      <div className={classes.content}>
        {isLoading && <Preloader type="table" />}
        <Table rows={datasets} columns={columns} onSearch={loadDatasets} />
        <Pagination totalPages={MetaPresenter.totalPages(meta)} onChange={handleChangePage} />
      </div>
    </>
  );
};

export default Datasets;
