import React, { useEffect, useState } from 'react';
import { Button, Tab as MuiTab, Tabs as MuiTabs } from '@material-ui/core';
import PropTypes from 'prop-types';

import useStyles from './useStyles';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import clsx from 'clsx';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  const classes = useStyles();

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      className={classes.tabPanel}
      {...other}
    >
      {children}
    </div>
  );
};

const Tabs = (props) => {
  const { items, activeItemIndex, onChange } = props;

  const [visibleValidations, setVisibleValidations] = useState(new Array(items.length).fill(true));

  const classes = useStyles();

  const handleChange = (_, itemIndex) => {
    onChange(itemIndex);
  };

  const handleTabClick = (e) => {
    onChange(Number(e.currentTarget.dataset.value));
  };

  const handleChangeValidationVisibility = (e) => {
    const itemIndex = Number(e.currentTarget.dataset.index);
    const newVisibleValidations = [...visibleValidations];
    newVisibleValidations[itemIndex] = !newVisibleValidations[itemIndex];
    setVisibleValidations(newVisibleValidations);
  };

  useEffect(() => {
    setVisibleValidations(new Array(items.length).fill(true));
  }, [items.length]);

  return (
    <div className={classes.tabsContainer}>
      <MuiTabs
        orientation="vertical"
        value={activeItemIndex}
        variant="scrollable"
        onChange={handleChange}
        scrollButtons="off"
        className={classes.verticalTabContainer}
      >
        {items.map((item, index) => {
          const validationTabs = visibleValidations[index] ? [...item.validationTabs] : [];
          const hasValidationTabs = !!item.validationTabs.length;
          return (
            <div className={classes.tabsGroup}>
              {[
                <div className={classes.tabWrapper}>
                  <MuiTab
                    label={item.title}
                    data-value={index}
                    className={clsx(classes.verticalTab, {
                      [classes.verticalTabWithValidation]: hasValidationTabs,
                      [classes.verticalTabSelected]: index === activeItemIndex,
                    })}
                    onClick={handleTabClick}
                  />
                  {hasValidationTabs && (
                    <Button
                      className={classes.validationSwitcher}
                      onClick={handleChangeValidationVisibility}
                      type="button"
                      data-index={index}
                    >
                      {visibleValidations[index] ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
                    </Button>
                  )}
                </div>,
                ...validationTabs,
              ]}
            </div>
          );
        })}
      </MuiTabs>

      {items.map((item, index) => {
        return [
          <TabPanel value={activeItemIndex} index={index}>
            {item.content}
          </TabPanel>,
          [...item.validationContent],
        ];
      })}
    </div>
  );
};

TabPanel.propTypes = {
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
};

Tabs.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      title: PropTypes.node.isRequired,
      content: PropTypes.node.isRequired,
      disabled: PropTypes.bool,
      validators: PropTypes.arrayOf(PropTypes.any),
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  activeItemIndex: PropTypes.number,
};

Tabs.defaultProps = {
  activeItemIndex: 0,
};

export default Tabs;
