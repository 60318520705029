import { adjust } from 'ramda';

const createInitialParametersWithValues = (data) => {
  const { initialParameters, index, field, value } = data;
  const addValue = (currentItem) => {
    return { ...currentItem, [field]: value };
  };
  const newValues = adjust(index, addValue, initialParameters);

  return newValues;
};

export { createInitialParametersWithValues };
