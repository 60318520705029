import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { Switch, Router } from 'react-router';
import { hot } from 'react-hot-loader';
import { isEmpty } from 'ramda';
import { useLocalStorage, writeStorage } from '@rehooks/local-storage';
import { useCookies, CookiesProvider } from 'react-cookie';

import { appRoutes } from 'routes';
import { CustomThemeProvider } from 'layouts/CustomThemeProvider';
import {
  Datasets,
  DatasetConstructor,
  ErrorScreen,
  Login,
  Documents,
  MapData,
  MultiSheetMapData,
  MapValidate,
  MapSummary,
  MapInitialParameters,
} from 'containers';
import Page from 'components/Page';
import history from 'utils/history';
import { useUsers } from 'slices/hooks';
import UserPresenter from 'presenters/UserPresenter';
import LocationPresenter from 'presenters/LocationPresenter';
import { USER, MANAGER } from 'utils/userRoles';
import 'utils/ErrorTracking';

const Routes = () => {
  const { currentUser } = useUsers();
  const defaultRedirect = (user) => {
    switch (UserPresenter.role(user)) {
      case USER:
        return <Redirect to={appRoutes.documentsPath()} />;
      case MANAGER:
        return <Redirect to={appRoutes.datasetsPath()} />;
      default:
        return <Redirect to={appRoutes.loginPath()} />;
    }
  };

  return (
    <Switch>
      <Page layout="base" component={Datasets} exact path={appRoutes.datasetsPath()} />
      <Page layout="base" component={DatasetConstructor} exact path={appRoutes.datasetPath(':datasetId')} />
      <Page layout="base" component={Documents} exact path={appRoutes.documentsPath()} />
      <Page layout="success" path={appRoutes.successImportFilePath()} />
      <Page
        layout="base"
        exact
        component={MapInitialParameters}
        path={appRoutes.initialParametersPath(':documentId')}
      />
      <Page layout="base" exact component={MapData} path={appRoutes.mappingPath(':documentId')} />
      <Page layout="base" exact component={MultiSheetMapData} path={appRoutes.multiSheetMappingPath(':documentId')} />
      <Page layout="base" exact component={MapValidate} path={appRoutes.validateMappingPath(':documentId')} />
      <Page layout="base" exact component={MapSummary} path={appRoutes.mappingSummary(':documentId')} />
      <Page component={Login} exact path={appRoutes.loginPath()} />
      <Page layout="error" component={ErrorScreen} exact path={appRoutes.errorPath(':errorCode')} />
      {currentUser && defaultRedirect(currentUser)}
    </Switch>
  );
};

const App = () => {
  const [authToken] = useLocalStorage('token');
  const { loadCurrentUser } = useUsers();
  const { location } = history;
  const queryParams = LocationPresenter.search(location);

  const [cookies, setCookie, removeCookie] = useCookies(['token']); // eslint-disable-line

  useEffect(() => {
    if (cookies.token) {
      writeStorage('token', cookies.token);
      removeCookie('token');
      loadCurrentUser();
    } else if (authToken) {
      loadCurrentUser();
    } else {
      isEmpty(queryParams)
        ? history.push(appRoutes.loginPath())
        : history.push(`${appRoutes.loginPath()}${queryParams}`);
    }
  }, []); // eslint-disable-line

  return (
    <CustomThemeProvider>
      <Router history={history}>
        <CookiesProvider>
          <Routes />
        </CookiesProvider>
      </Router>
    </CustomThemeProvider>
  );
};

export default hot(module)(App);
