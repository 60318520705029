import { createSlice } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import DatasetsRepository from 'repositories/DatasetsRepository';
import DatasetParametersRepository from 'repositories/DatasetParametersRepository';
import { useActions } from 'utils/appHooks';
import history from 'utils/history';
import { appRoutes } from 'routes/appRoutes';

const initialState = {
  loading: false,
  datasets: [],
  meta: {},
  errors: {},
  currentDataset: null,
  isErrorAlertShowing: false,
  initialParameters: [],
};

const datasetsSlice = createSlice({
  name: 'datasets',
  initialState,
  reducers: {
    loadDatasetsSuccess(state, { payload }) {
      state.datasets = payload.items;
      state.meta = payload.meta;
      state.errors = {};
    },
    loadDatasetsFail(state, { payload }) {
      state.errors = payload;
    },
    loadingStart(state) {
      state.loading = true;
    },
    loadingFinish(state) {
      state.loading = false;
    },
    resetDatasets(state) {
      state.loading = initialState.loading;
      state.datasets = initialState.datasets;
      state.meta = initialState.meta;
      state.errors = initialState.errors;
    },
    createDatasetSuccess(state, { payload }) {
      state.errors = {};
      state.currentDataset = payload;
    },
    createDatasetFail(state, { payload }) {
      state.errors = payload;
    },
    updateDatasetSuccess(state) {
      state.errors = {};
    },
    updateDatasetFail(state, { payload }) {
      state.errors = payload;
    },
    deleteDatasetSuccess(state) {
      state.errors = {};
    },
    deleteDatasetFail(state, { payload }) {
      state.errors = payload;
      state.isErrorAlertShowing = true;
    },
    loadCurrentDatasetSuccess(state, { payload }) {
      state.currentDataset = payload;
      state.errors = {};
    },
    loadCurrentDatasetFail(state, { payload }) {
      state.errors = payload;
    },
    resetCurrentDataset(state) {
      state.loading = initialState.loading;
      state.currentDataset = initialState.currentDataset;
      state.meta = initialState.meta;
      state.errors = initialState.errors;
      state.isErrorAlertShowing = initialState.isErrorAlertShowing;
    },
    resetDatasetErrors(state) {
      state.errors = initialState.errors;
      state.isErrorAlertShowing = initialState.isErrorAlertShowing;
    },
    publishDatasetSuccess(state) {
      state.errors = {};
    },
    publishDatasetFail(state, { payload }) {
      state.errors = payload;
    },
    loadInitParamsSuccess(state, { payload }) {
      state.initialParameters = payload.parameters;
    },
    loadInitParamsFail(state, { payload }) {
      state.initialParameters = initialState.initialParameters;
      state.errors = payload;
    },
    updateInitParamsFail(state, { payload }) {
      state.errors = payload;
    },
    updateInitParamsSuccess(state, { payload }) {
      state.initialParameters = payload.parameters;
      state.errors = initialState.errors;
    },
  },
});

export default datasetsSlice.reducer;
export const { resetDatasets, resetCurrentDataset, resetDatasetErrors } = datasetsSlice.actions;

export const useDatasetsActions = () => {
  const dispatch = useDispatch();

  const loadDatasets = (params) => {
    dispatch(datasetsSlice.actions.loadingStart());
    return DatasetsRepository.index(params)
      .then((response) => dispatch(datasetsSlice.actions.loadDatasetsSuccess(response)))
      .catch((errors) => dispatch(datasetsSlice.actions.loadDatasetsFail(errors)))
      .finally(() => dispatch(datasetsSlice.actions.loadingFinish()));
  };

  const createDataset = (params) => {
    dispatch(datasetsSlice.actions.loadingStart());
    return DatasetsRepository.create(params)
      .then((response) => dispatch(datasetsSlice.actions.createDatasetSuccess(response.data)))
      .catch((errors) => dispatch(datasetsSlice.actions.createDatasetFail(errors)))
      .finally(() => dispatch(datasetsSlice.actions.loadingFinish()));
  };

  const updateDataset = (datasetId, params) => {
    dispatch(datasetsSlice.actions.loadingStart());
    return DatasetsRepository.update(datasetId, params)
      .then((response) => dispatch(datasetsSlice.actions.updateDatasetSuccess(response.data)))
      .catch((errors) => dispatch(datasetsSlice.actions.updateDatasetFail(errors)))
      .finally(() => dispatch(datasetsSlice.actions.loadingFinish()));
  };

  const deleteDataset = (datasetId) => {
    dispatch(datasetsSlice.actions.loadingStart());
    return DatasetsRepository.delete(datasetId)
      .then((response) => {
        dispatch(datasetsSlice.actions.deleteDatasetSuccess(response.data));
        history.push(appRoutes.datasetsPath());
      })
      .catch((errors) => dispatch(datasetsSlice.actions.deleteDatasetFail(errors)))
      .finally(() => dispatch(datasetsSlice.actions.loadingFinish()));
  };

  const loadCurrentDataset = (datasetId) => {
    dispatch(datasetsSlice.actions.loadingStart());
    return DatasetsRepository.current(datasetId)
      .then((response) => dispatch(datasetsSlice.actions.loadCurrentDatasetSuccess(response.data)))
      .catch((errors) => dispatch(datasetsSlice.actions.loadCurrentDatasetFail(errors)))
      .finally(() => dispatch(datasetsSlice.actions.loadingFinish()));
  };

  const publishDataset = (datasetId, params) => {
    dispatch(datasetsSlice.actions.loadingStart());
    return DatasetsRepository.publish(datasetId, params)
      .then((response) => {
        dispatch(datasetsSlice.actions.publishDatasetSuccess(response.data));
        history.push(appRoutes.datasetsPath());
      })
      .catch((errors) => dispatch(datasetsSlice.actions.publishDatasetFail(errors)))
      .finally(() => dispatch(datasetsSlice.actions.loadingFinish()));
  };

  const loadInitialParameters = (datasetId) => {
    dispatch(datasetsSlice.actions.loadingStart());
    return DatasetParametersRepository.index(datasetId)
      .then((response) => dispatch(datasetsSlice.actions.loadInitParamsSuccess(response.data)))
      .catch((errors) => dispatch(datasetsSlice.actions.loadInitParamsFail(errors)))
      .finally(() => dispatch(datasetsSlice.actions.loadingFinish()));
  };

  const updateInitialParameters = (datasetId, params) => {
    dispatch(datasetsSlice.actions.loadingStart());
    return DatasetParametersRepository.update(datasetId, params)
      .then((response) => dispatch(datasetsSlice.actions.updateInitParamsSuccess(response.data)))
      .catch((errors) => dispatch(datasetsSlice.actions.updateInitParamsFail(errors)))
      .finally(() => dispatch(datasetsSlice.actions.loadingFinish()));
  };

  const actions = useActions({ resetDatasets, resetCurrentDataset, resetDatasetErrors });

  return {
    loadDatasets,
    createDataset,
    updateDataset,
    deleteDataset,
    loadCurrentDataset,
    publishDataset,
    loadInitialParameters,
    updateInitialParameters,
    ...actions,
  };
};
