import React from 'react';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';

import { TextField } from 'components/Common';
import { initialValues, validationSchema } from 'forms/LogInForm';
import { handleErrors } from 'utils/errors';

import useStyles from './useStyles';

const Form = (props) => {
  const { onSignIn, loading, apiErrors } = props;
  const classes = useStyles();

  const handleSubmit = (values, { setErrors }) => {
    return onSignIn(values).catch((errors) => handleErrors(errors, setErrors));
  };

  const { values, errors, setFieldValue, submitForm, touched } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
    validateOnChange: true,
  });

  const handleTextFieldChange = (field) => ({ target: { value } }) => {
    setFieldValue(field, value);
  };

  const handleSubmitForm = (e) => {
    e.preventDefault();
    submitForm(values);
  };

  return (
    <form onSubmit={handleSubmitForm}>
      <div className={classes.formWrapper}>
        <TextField
          value={values.email}
          onChange={handleTextFieldChange('email')}
          error={touched.email && !!errors.email}
          name="email"
          placeholder="Email"
          helperText={touched.email && errors.email}
        />
        <TextField
          type="password"
          value={values.password}
          onChange={handleTextFieldChange('password')}
          error={touched.password && !!errors.password}
          name="password"
          placeholder="Password"
          helperText={touched.password && errors.password}
        />
        {apiErrors && <span className={classes.error}>{Object.keys(apiErrors).map((key) => apiErrors[key])}</span>}
      </div>
      <Button disabled={loading} className={classes.loginButton} type="submit" variant="contained">
        LOG IN
      </Button>
    </form>
  );
};

Form.propTypes = {
  loading: PropTypes.bool,
  onSignIn: PropTypes.func.isRequired,
  apiErrors: PropTypes.shape(),
};

Form.defaultProps = {
  apiErrors: {},
  loading: false,
};

export default Form;
