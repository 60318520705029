import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Breadcrumbs as MUIBreadcrumbs } from '@material-ui/core';

import { appRoutes } from 'routes/appRoutes';
import BreadcrumbsPresenter from 'presenters/BreadcrumbsPresenter';

import useStyles from './useStyles';
import Icon from 'components/Icon';

const Breadcrumbs = (props) => {
  const { breadcrumbs } = props;
  const classes = useStyles();
  return (
    <MUIBreadcrumbs aria-label="breadcrumb">
      <Link to={appRoutes.rootPath()}>
        <Icon name="home" className={classes.icon} />
      </Link>
      {breadcrumbs.map((crumb, id) => (
        <Link key={id} to={BreadcrumbsPresenter.link(crumb)} title={BreadcrumbsPresenter.name(crumb)}>
          {BreadcrumbsPresenter.name(crumb)}
        </Link>
      ))}
    </MUIBreadcrumbs>
  );
};

Breadcrumbs.propTypes = {
  breadcrumbs: PropTypes.arrayOf(BreadcrumbsPresenter.shape()).isRequired,
};

export default Breadcrumbs;
