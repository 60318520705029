import React, { useEffect, useState } from 'react';
import { is, isEmpty } from 'ramda';
import { useParams } from 'react-router';
import { Box, Button } from '@material-ui/core';

import { useDocuments, useMapping, useTemplates, useUserDatasets } from 'slices/hooks';
import { appRoutes } from 'routes';
import Breadcrumbs from 'components/Breadcrumbs';
import { ReactComponent as TickIcon } from 'assets/icons/tick.svg';
import { ReactComponent as CrossIcon } from 'assets/icons/cross_outline.svg';
import history from 'utils/history';
import DocumentPresenter from 'presenters/DocumentPresenter';
import MappingSummaryPresenter from 'presenters/MappingSummaryPresenter';
import MappingResultPresenter from 'presenters/MappingResultPresenter';
import DatasetPresenter from 'presenters/DatasetPresenter';
import { getSingleDatasetMappingSteps } from 'components/MappingSteps/steps';
import { is3DMapping } from 'helpers/fieldDetailsOptions';
import Icon from 'components/Icon';
import { isBlank } from 'utils/conditions';

import CreateTemplateDialog from './components/CreateTemplateDialog';
import Columns from './components/Columns';

import useStyles from './useStyles';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { deleteFromStorage } from '@rehooks/local-storage';

const IMPORT_STATUSES = {
  success: 'success',
  process: 'process',
  failure: 'failure',
  submitted: 'submitted',
};

const TEMPLATE_TYPES = {
  templates3d: 'templates3d',
  templates2d: 'templates2d',
};

const MapSummary = () => {
  const { loadCurrentDocument, currentDocument } = useDocuments();
  const { loadCurrentDataset, currentDataset } = useUserDatasets();
  const {
    currentMapping,
    loadCurrentMapping,
    mappingResult,
    resetMappingErrors,
    sendResultErrors,
    resetMappedColumns,
    resetChanges,
    loadCurrent3DMapping,
    loadCurrentMultiSheetMapping,
    importMessage,
    loadMultiSheetResult,
  } = useMapping();
  const { apiTemplateErrors, createMultiSheetTemplate } = useTemplates();
  const { documentId } = useParams();
  const [popupOpen, setPopupOpen] = useState(false);
  const [importDisabled, setImportDisabled] = useState(false);
  const [importStatus, setImportStatus] = useState(IMPORT_STATUSES.process);
  const [templateSaved, setTemplateSaved] = useState(false);
  const template = JSON.parse(localStorage.getItem('template'));

  const classes = useStyles();

  const fileType = DatasetPresenter.fileType(currentDataset);
  const is3DMappingType = is3DMapping(fileType);

  useEffect(() => {
    loadCurrentDocument(documentId);
    return () => {
      resetMappedColumns();
      resetMappingErrors();
    };
  }, []); // eslint-disable-line

  useEffect(() => {
    if (!isEmpty(currentDocument)) {
      const dataset = DocumentPresenter.dataset(currentDocument);
      const datasetId = DatasetPresenter.id(dataset);
      loadCurrentDataset(datasetId);
    }
  }, [currentDocument]); // eslint-disable-line

  useEffect(() => {
    if (!isEmpty(currentDataset)) {
      if (currentDataset?.sheetSchemas?.length) {
        loadCurrentMultiSheetMapping(documentId);
      } else {
        is3DMappingType ? loadCurrent3DMapping(documentId) : loadCurrentMapping(documentId);
      }
    }
  }, [currentDataset]); // eslint-disable-line

  useEffect(() => {
    if (!isEmpty(sendResultErrors)) {
      setImportStatus(IMPORT_STATUSES.failure);
    }
    if (!isEmpty(mappingResult)) {
      setImportStatus(IMPORT_STATUSES.success);
    }
    if (importMessage) {
      setImportStatus(IMPORT_STATUSES.submitted);
    }
  }, [sendResultErrors, mappingResult, importMessage]); // eslint-disable-line

  const saveMapping = (values) => {
    const { templates2d = [], templates3d = [] } = Object.keys(template).reduce((total, type) => {
      return {
        ...total,
        [type]: template[type].map((currentTemplate, i) => {
          // eslint-disable-next-line no-param-reassign
          currentTemplate.name = `${values.name}-${i}`;
          if (type === TEMPLATE_TYPES.templates3d) {
            // eslint-disable-next-line no-param-reassign
            currentTemplate.documentId = documentId;
          }
          return currentTemplate;
        }),
      };
    }, {});

    const data = {
      name: values.name,
      templates2d,
      templates3d,
      datasetId: currentDataset.id,
      documentId,
    };

    return createMultiSheetTemplate(data).then(() => setTemplateSavedState());
  };

  const setTemplateSavedState = () => {
    handleClosePopup();
    setTemplateSaved(true);
    resetMappedColumns();
  };

  const handleOpenPopup = () => {
    setPopupOpen(true);
  };

  const handleClosePopup = () => {
    setPopupOpen(false);
  };

  const handleNavigation = (to) => {
    history.push(to);
  };

  const handleNewImportClick = () => {
    handleNavigation(appRoutes.documentsPath());
    deleteFromStorage('template');
  };

  const handleImport = () => {
    setImportDisabled(true);
    loadMultiSheetResult(documentId);
    resetChanges();
  };

  const renderErrors = (errors) => {
    if (isEmpty(errors)) {
      return null;
    }

    return (
      <div>
        {Object.keys(errors).map((key, i) => {
          if (is(Array, errors[key])) {
            return errors[key].map((error, index) => <div key={index}>{error}</div>);
          }
          return <div key={i}>{errors[key]}</div>;
        })}
      </div>
    );
  };

  const renderMappingState = () => {
    switch (importStatus) {
      case IMPORT_STATUSES.success:
        return (
          <div className={classes.importSuccess}>
            <div>
              <h3>Import Successfull!</h3>
              <div className={classes.successInfo}>Now the import is finished. Please close this tab.</div>
            </div>
            <div>
              <p>{MappingResultPresenter.importedRows(mappingResult)} rows imported successfully</p>
            </div>
          </div>
        );
      case IMPORT_STATUSES.failure:
        return (
          <div className={classes.importFailed}>
            <h3>Import Failed!</h3>
            {renderErrors(sendResultErrors)}
          </div>
        );
      case IMPORT_STATUSES.process:
        return <p>Your file has been pre-processed. Please click the Import button below.</p>;
      case IMPORT_STATUSES.submitted:
        return <p>The data was submitted. You will see the report in the Lobby in an hour.</p>;
      default:
        return null;
    }
  };

  const renderMappingStateFooter = () => {
    switch (importStatus) {
      case IMPORT_STATUSES.success:
        return (
          <>
            <Button variant="outlined" onClick={() => handleNavigation(appRoutes.rootPath())}>
              GO TO HOME SCREEN
            </Button>
            <Button variant="outlined" onClick={() => handleNavigation(appRoutes.documentsPath())}>
              IMPORT ANOTHER
            </Button>
            {renderSaveMappingButton()}
          </>
        );
      case IMPORT_STATUSES.failure:
        return (
          <>
            <Button variant="outlined" onClick={handleNewImportClick}>
              New Import
            </Button>
            {renderSaveMappingButton()}
          </>
        );
      case IMPORT_STATUSES.process:
        return (
          <Button variant="contained" onClick={handleImport} disabled={importDisabled}>
            Import
          </Button>
        );
      case IMPORT_STATUSES.submitted:
        return (
          <>
            <Button variant="outlined" onClick={() => handleNavigation(appRoutes.rootPath())}>
              GO TO HOME SCREEN
            </Button>
            <Button variant="outlined" onClick={() => handleNavigation(appRoutes.documentsPath())}>
              IMPORT ANOTHER
            </Button>
            {renderSaveMappingButton()}
          </>
        );
      default:
        return null;
    }
  };
  const renderMappingStateMessage = () => {
    switch (importStatus) {
      case IMPORT_STATUSES.success:
        return <p>Import completed successfully!</p>;
      case IMPORT_STATUSES.failure:
        return <p>Please update the problem fields in the Excel file and upload the file again.</p>;
      case IMPORT_STATUSES.process:
        return <p>Check all details and press Import to finish.</p>;
      default:
        return <p />;
    }
  };

  const renderSaveMappingButton = () => {
    if (isBlank(apiTemplateErrors) && templateSaved) {
      return (
        <span className={classes.saved}>
          <TickIcon className={classes.tickIcon} />
          Saved as Template
        </span>
      );
    }
    if (!isBlank(apiTemplateErrors)) {
      return (
        <span className={classes.saved}>
          <CrossIcon className={classes.tickIcon} />
          Try again later
        </span>
      );
    }
    return (
      <Button variant="contained" onClick={handleOpenPopup}>
        SAVE THIS MAPPING
      </Button>
    );
  };

  const fullScreenHandle = useFullScreenHandle();
  const { active, enter, exit } = fullScreenHandle;
  return (
    <>
      <Box mt={2.5} mb={3}>
        <Breadcrumbs
          breadcrumbs={[
            { link: appRoutes.documentsPath(), name: 'Import Tool' },
            { name: DocumentPresenter.fileName(currentDocument), link: '' },
          ]}
        />
      </Box>
      <FullScreen handle={fullScreenHandle}>
        <div className={classes.mappingTableTopContainer}>
          <span className={classes.mappingTableCaption}>{getSingleDatasetMappingSteps()[3].name}</span>
          <button type="button" tabIndex="0" className={classes.fullscreenToggle} onClick={active ? exit : enter}>
            <Icon name="fullscreen" /> Toggle Fullscreen
          </button>
        </div>
        <div className={classes.wrapper}>
          <div className={classes.importMessageSection}>{renderMappingState()}</div>

          {currentMapping && (
            <>
              <Columns
                fields={MappingSummaryPresenter.mappedFields(currentMapping)}
                rowQty={2}
                mapped
                title="Mapped Columns"
              />
              <Columns
                fields={MappingSummaryPresenter.mappedFields(currentMapping)}
                rowQty={2}
                mapped={false}
                title="Columns that exist in the dataset, but NOT mapped"
              />
            </>
          )}
        </div>
        <div className={classes.footer}>
          {renderMappingStateMessage()}
          <div className={classes.footerActions}>{renderMappingStateFooter()}</div>
        </div>
        {popupOpen && (
          <CreateTemplateDialog onClose={handleClosePopup} apiErrors={apiTemplateErrors} onSaveMapping={saveMapping} />
        )}
      </FullScreen>
    </>
  );
};

export default MapSummary;
