import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  top: {
    margin: [20, 'auto'],
    maxWidth: 1360,
  },
  wrapper: {
    margin: [20, 0, 0, 0],
    '& .fullscreen': {
      position: 'relative',
      overflow: 'hidden',
    },
  },
  head: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
    padding: [0, 20],
    boxShadow: [
      ['inset', 0, -1, 0, theme.palette.shadow],
      [1, 0, 0, theme.palette.shadow],
    ],
    background: theme.palette.background.white,
    zIndex: 3,
    position: 'relative',
  },
  main: {
    display: 'flex',
    height: 'calc(100vh - 97px)',
    borderTop: [1, 'solid', theme.palette.shadow],
  },
  mainFullScreen: {
    display: 'flex',
    height: 'calc(100vh - 106px)',
    background: theme.palette.background.white,
    '& [aria-label="button group"]': {
      maxWidth: '100%',
    },
    '& [aria-label="button group wrapper"]': {
      padding: 0,
    },
  },
  withoutMappingSteps: {
    justifyContent: 'flex-end',
  },
  footer: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    boxShadow: ['inset', 0, 1, 0, theme.palette.shadow],
    padding: [10, 120, 10, 40],
    background: theme.palette.background.default,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    zIndex: 3,
    '& p': {
      color: theme.palette.text.lightDark,
      fontSize: theme.typography.pxToRem(12),
    },
    '& Button': {
      background: theme.palette.default,
      padding: 4,
      height: 32,
      width: 112,
    },
  },
  selectSheet: {
    position: 'relative',
    width: '100%',
    background: theme.palette.background.default,
    boxShadow: ['inset', 0, 1, 0, theme.palette.shadowLight],
    padding: [0, 0, 8, 24],
    margin: [0, 'auto'],
    display: 'block',
    maxWidth: 1360,
    whiteSpace: 'nowrap',
    overflowX: 'auto',
    overflowY: 'hidden',
    height: 33,
  },
  tableWrapper: {
    width: '100%',
    overflowX: 'auto',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    paddingBottom: 33,
  },
  sheetButton: {
    border: [1, 'solid', theme.palette.shadow],
    boxShadow: [0, 2, 18, theme.palette.shadowLight],
    borderRadius: [0, 0, 8, 8],
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 1.3,
    color: theme.palette.title,
    padding: [4, 16],
    textTransform: 'none',
    marginRight: 4,
    opacity: 0.6,
  },
  active: {
    opacity: 1,
    background: 'linear-gradient(0deg, #FFFFFF, #FFFFFF), #FDFDFD',
  },
  selectSheetWrapper: {
    width: '100%',
    position: 'absolute',
    bottom: 0,
    left: 0,
  },
}));
