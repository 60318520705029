import palette from 'layouts/CustomThemeProvider/palette';

export default {
  MuiPaper: {
    elevation3: {
      boxShadow: [0, 2, 18, palette.shadowLight],
      borderRadius: 8,
    },
  },
};
