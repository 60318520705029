import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(
  (theme) => ({
    mappingTable: {
      width: '100%',
      '& .fullscreen': {
        position: 'relative',
        overflow: 'hidden',
      },
    },
    fullscreenToggle: {
      display: 'flex',
      background: 'none',
      border: 'none',
      outline: 'none',
      alignItems: 'center',
      color: theme.palette.title,
      cursor: 'pointer',
      margin: [0, 0, 0, 'auto'],

      '& svg': {
        marginRight: 10,
      },
    },
    mappingTableTopContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      border: [1, 'solid', theme.palette.shadow],
      borderBottom: 'none',
      backgroundColor: theme.palette.background.white,
      height: 44,
      padding: [0, 16],
    },
    mappingTableCaption: {
      fontWeight: 500,
      fontSize: theme.typography.pxToRem(17),
      color: theme.palette.title,
      lineHeight: '20px',
    },
    validateContainer: {
      paddingLeft: 0,
      background: theme.palette.background.white,
      border: [1, 'solid', theme.palette.shadow],
      marginBottom: 32,
    },
    validateFailed: {
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      color: theme.palette.errors,
      padding: 32,
      '& h3': {
        color: theme.palette.errors,
      },
      '& div': {
        fontSize: theme.typography.pxToRem(12),
        lineHeight: 1.3,
      },
    },
    error: {
      position: 'absolute',
      display: 'block',
      bottom: -25,
      color: theme.palette.errors,
      fontSize: theme.typography.pxToRem(9),
      paddingLeft: 16,
    },
    footer: {
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0,
      boxShadow: ['inset', 0, 1, 0, theme.palette.shadow],
      padding: [10, 120, 10, 40],
      background: theme.palette.background.white,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      zIndex: 10,
      '& p': {
        color: theme.palette.text.lightDark,
        fontSize: theme.typography.pxToRem(12),
      },
      '& Button': {
        padding: 4,
        height: 32,
        width: 112,
      },
    },
    footerActions: {
      display: 'flex',
      alignItems: 'center',
      '& Button': {
        whiteSpace: 'nowrap',
        width: 'auto',
        padding: [4, 24],
        height: 32,
        marginLeft: 8,
      },
    },
    failedInfo: {
      marginTop: 32,
      color: theme.palette.text.primary,
      fontSize: theme.typography.pxToRem(12),
      '& li': {
        paddingBottom: 8,
        '&::marker': {
          paddingRight: 8,
        },
      },
      '& ul': {
        paddingLeft: 15,
        marginTop: 16,
      },
    },
  }),
  { name: 'MultiSheetMapData' },
);
