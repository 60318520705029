import * as yup from 'yup';

export const validationFields = {
  name: yup.string().required('Name is required'),
  endpointUrl: yup.string().url('End-Point URL must be a valid URL').required('End-Point URL is required'),
  endpointSecret: yup.string().required('End-Point Secret is required'),
  externalDefinitionUrl: yup.string().url('External Definition URL must be a valid URL').nullable(),
  adaptiveTemplateUrl: yup.string().url('Adaptive Template URL must be a valid URL').nullable(),
};

export const validationSchema = yup.object().shape({ ...validationFields });

export const initialValues = {
  name: '',
  endpointUrl: '',
  endpointSecret: '',
  fileType: '2D',
  externalDefinitionUrl: null,
  adaptiveTemplateUrl: null,
};
